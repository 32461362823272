$extensions-list: (
  apk: #5eb533,
  ipa: #8e44ad,
  txt: #a6b1b5,
  log: #373a43,
  pdf: #db3e3d,
  doc: #307cf1,
  docx: #307cf1,
  xls: #0f9d58,
  xlsx: #0f9d58,
  xlsm: #0f9d58,
  zip: #855d40,
  jpg: #80c081,
  jpeg: #80c081,
  png: #80c081,
  bmp: #80c081,
);

$risk-critical: #ef4836;
$risk-danger: #ff8c00;
$risk-warning: #f5d76e;
$risk-info: #2cc2f8;
$risk-success: #80c081;
$risk-default: #6b6b6b;

$attachment-icon-width: 4em;

/*------- button vars start -------*/
/* button generic vars */
body {
  --button-border-radius: 2px;
}

/* button primary filled */
body {
  --button-primary-filled-border-width__focus: 1px;
}

/* button primary outlined */
body {
  --button-primary-outlined-border-width: 1px;
  --button-primary-outlined-border-width__hover: 1.5px;
  --button-primary-outlined-border-width__focus: 2px;
}

/* button neutral outlined */
body {
  --button-neutral-outlined-border-width: 1px;
  --button-neutral-outlined-border-width__hover: 1.5px;
  --button-neutral-outlined-border-width__focus: 2px;
}
/*------- button vars end -------*/
