body {
  // variables for ak-icon
  --ak-icon-color-primary: var(--primary-main);
  --ak-icon-color-secondary: var(--secondary-main);
  --ak-icon-color-text-primary: var(--text-primary);
  --ak-icon-color-text-secondary: var(--text-secondary);
  --ak-icon-color-error: var(--error-main);
  --ak-icon-color-success: var(--success-main);
  --ak-icon-color-warn: var(--warn-main);
  --ak-icon-color-info: var(--info-main);

  // variables for ak-loader
  --ak-loader-stroke-primary-main: var(--primary-main);
  --ak-loader-stroke-primary-light: var(--primary-light);
  --ak-loader-linear-root-bg-color: var(--neutral-grey-200);

  // variables for ak-popover
  --ak-popover-arrow-background-light: var(--background-main);
  --ak-popover-arrow-border-light: var(--border-color-1);
  --ak-popover-arrow-background-dark: var(--background-dark);
  --ak-popover-zIndex: var(--zIndex-tooltip);

  // variables for ak-popover
  --ak-tooltip-border-radius: var(--border-radius);
  --ak-tooltip-font-weight-regular: var(--font-weight-regular);
  --ak-tooltip-font-family: var(--font-family);
  --ak-tooltip-box-shadow-2: var(--box-shadow-2);
  --ak-tooltip-background-light: var(--background-main);
  --ak-tooltip-background-dark: var(--background-dark);
  --ak-tooltip-color-on-light: var(--text-primary);
  --ak-tooltip-color-on-dark: var(--common-white);
  --ak-tooltip-border-light: var(--border-color-1);
  --ak-tooltip-zIndex: var(--zIndex-tooltip);

  // variables for ak-toggle
  --ak-toggle-slider-background-color: var(--background-main);
  --ak-toggle-checked-background-color: var(--success-main);
  --ak-toggle-unchecked-background-color: var(--neutral-grey-300);

  // variables for ak-divider
  --ak-divider-color-light: var(--divider);
  --ak-divider-color-dark: var(--divider-dark);

  // variables for ak-link
  --ak-link-color-text-disabled: var(--text-disabled);
  --ak-link-color-primary: var(--primary-main);
  --ak-link-color-secondary: var(--secondary-main);
  --ak-link-color-text-primary: var(--text-primary);
  --ak-link-color-text-secondary: var(--text-secondary);
  --ak-link-color-error: var(--error-main);
  --ak-link-color-success: var(--success-main);
  --ak-link-color-warn: var(--warn-main);
  --ak-link-color-info: var(--info-main);

  // variables for ak-icon-button
  --ak-icon-button-color-text-disabled: var(--text-disabled);
  --ak-icon-button-color-text-primary: var(--text-primary);
  --ak-icon-button-border-radius: var(--border-radius);
  --ak-icon-button-border-color-default: var(--border-color-2);
  --ak-icon-button-border-color-primary: var(--primary-main);
  --ak-icon-button-border-color-secondary: var(--secondary-main);
  --ak-icon-button-hover-background: var(--hover-light-background);

  // variables for ak-list
  --ak-list-item-color-text-disabled: var(--text-disabled);
  --ak-list-item-hover-background: var(--hover-light-background);
  --ak-list-item-selected-background: var(--primary-light);
  --ak-list-item-color-text-primary: var(--text-primary);

  // variables for ak-menu
  --ak-menu-background: var(--background-main);
  --ak-menu-border-radius: var(--border-radius);
  --ak-menu-box-shadow: var(--box-shadow-2);
  --ak-menu-border-color: var(--border-color-1);
  --ak-menu-z-index: var(--zIndex-tooltip);

  // variables for ak-select
  --ak-select-form-helper-color: var(--text-secondary);
  --ak-select-error-color: var(--error-main);
  --ak-select-dropdown-background: var(--background-main);
  --ak-select-dropdown-border-radius: var(--border-radius);
  --ak-select-dropdown-box-shadow: var(--box-shadow-2);
  --ak-select-dropdown-border-color: var(--border-color-1);
  --ak-select-dropdown-zIndex: var(--zIndex-tooltip);
  --ak-select-option-color-text-primary: var(--text-primary);
  --ak-select-option-hover-background: var(--hover-light-background);
  --ak-select-option-selected-background: var(--primary-light);
  --ak-select-option-divider-color: var(--divider);
  --ak-select-trigger-color-text-primary: var(--text-primary);
  --ak-select-trigger-border-radius: var(--border-radius);
  --ak-select-trigger-border-color: var(--border-color-2);
  --ak-select-trigger-disabled-color: var(--text-secondary);
  --ak-select-trigger-disabled-background: var(--disabled-background-textfield);
  --ak-select-trigger-arrow-color: var(--text-primary);

  // variables for ak-modal
  --ak-modal-zIndex: var(--zIndex-modal);
  --ak-modal-backdrop-overlay-background: var(--backdrop-overlay-background);
  --ak-modal-border-radius: var(--border-radius);

  // variables for ak-table
  --ak-table-font-weight-regular: var(--font-weight-regular);
  --ak-table-font-weight-medium: var(--font-weight-medium);
  --ak-table-font-family: var(--font-family);
  --ak-table-color-text-primary: var(--text-primary);
  --ak-table-header-background-neutral: var(--neutral-grey-100);
  --ak-table-header-border-neutral: var(--border-color-1);
  --ak-table-border-light: var(--divider);
  --ak-table-border-dark: var(--divider-dark);
  --ak-table-hover-background: var(--hover-light-background);

  // variables for ak-checkbox
  --ak-checkbox-color-checked: var(--success-main);
  --ak-checkbox-color-indeterminate: var(--success-main);
  --ak-checkbox-color-text-disabled: var(--text-disabled);
  --ak-checkbox-hover-background: var(--hover-light-background);

  // variables for ak-button
  --ak-button-border-radius: var(--border-radius);
  --ak-button-color-primary: var(--primary-main);
  --ak-button-filled-primary-hover-background: var(--primary-dark);
  --ak-button-color-primary-contrast-text: var(--primary-contrast-text);
  --ak-button-color-secondary: var(--secondary-main);
  --ak-button-color-text-primary: var(--text-primary);
  --ak-button-color-text-secondary: var(--text-secondary);
  --ak-button-color-error: var(--error-main);
  --ak-button-color-success: var(--success-main);
  --ak-button-color-warn: var(--warn-main);
  --ak-button-color-info: var(--info-main);
  --ak-button-color-neutral: var(--neutral-grey-400);
  --ak-button-color-text-disabled: var(--text-disabled);
  --ak-button-disabled-background: var(--disabled-background-button);

  // variables for ak-chip
  --ak-chip-font-family: var(--font-family);
  --ak-chip-border-radius: var(--border-radius);
  --ak-chip-color-text-primary: var(--text-primary);
  --ak-chip-delete-btn-hover-background: var(--hover-light-background);

  --ak-chip-color-filled-default-background: var(--neutral-grey-100);
  --ak-chip-color-filled-default-contrast-text: var(--text-primary);
  --ak-chip-color-filled-primary-background: var(--primary-main);
  --ak-chip-color-filled-primary-contrast-text: var(--common-white);
  --ak-chip-color-filled-secondary-background: var(--secondary-main);
  --ak-chip-color-filled-secondary-contrast-text: var(--common-white);
  --ak-chip-color-filled-success-background: var(--success-main);
  --ak-chip-color-filled-success-contrast-text: var(--common-white);
  --ak-chip-color-filled-error-background: var(--error-main);
  --ak-chip-color-filled-error-contrast-text: var(--common-white);
  --ak-chip-color-filled-warn-background: var(--warn-main);
  --ak-chip-color-filled-warn-contrast-text: var(--common-white);
  --ak-chip-color-filled-info-background: var(--info-main);
  --ak-chip-color-filled-info-contrast-text: var(--common-white);

  --ak-chip-color-semi-filled-default-background: var(--neutral-grey-200);
  --ak-chip-color-semi-filled-default-contrast-text: var(--neutral-grey-700);
  --ak-chip-color-semi-filled-primary-background: var(--primary-light);
  --ak-chip-color-semi-filled-primary-contrast-text: var(--primary-dark);
  --ak-chip-color-semi-filled-secondary-background: var(--secondary-light);
  --ak-chip-color-semi-filled-secondary-contrast-text: var(--secondary-main);
  --ak-chip-color-semi-filled-success-background: var(--success-light);
  --ak-chip-color-semi-filled-success-contrast-text: var(--success-main);
  --ak-chip-color-semi-filled-error-background: var(--error-light);
  --ak-chip-color-semi-filled-error-contrast-text: var(--error-main);
  --ak-chip-color-semi-filled-warn-background: var(--warn-light);
  --ak-chip-color-semi-filled-warn-contrast-text: var(--warn-dark);
  --ak-chip-color-semi-filled-info-background: var(--info-light);
  --ak-chip-color-semi-filled-info-contrast-text: var(--info-dark);

  --ak-chip-color-outlined-default: var(--border-color-1);
  --ak-chip-color-outlined-primary: var(--primary-main);
  --ak-chip-color-outlined-secondary: var(--secondary-main);
  --ak-chip-color-outlined-success: var(--success-main);
  --ak-chip-color-outlined-error: var(--error-main);
  --ak-chip-color-outlined-warn: var(--warn-main);
  --ak-chip-color-outlined-info: var(--info-main);

  // variables for ak-app-bar
  --ak-appbar-default-background: var(--background-main);
  --ak-appbar-default-border-bottom: 1px solid var(--border-color-1);
  --ak-appbar-default-background-text-color: var(--text-primary);
  --ak-appbar-light-background: var(--background-light);
  --ak-appbar-light-border-bottom: 1px solid var(--border-color-1);
  --ak-appbar-light-background-text-color: var(--text-primary);
  --ak-appbar-dark-background: var(--background-dark);
  --ak-appbar-dark-border-bottom: none;
  --ak-appbar-dark-background-text-color: var(--common-white);
  --ak-appbar-dark-hover-background: var(--hover-dark-background);
  --ak-appbar-zIndex: var(--zIndex-appbar);

  &.theme-light {
    --ak-appbar-inherit-background: var(--background-light);
    --ak-appbar-inherit-border-bottom: 1px solid var(--border-color-1);
    --ak-appbar-inherit-background-text-color: var(--text-primary);
    --ak-appbar-inherit-hover-background: var(--hover-light-background);
    --ak-appbar-box-shadow: var(--box-shadow-light);
  }

  &.theme-dark {
    --ak-appbar-inherit-background: var(--background-dark);
    --ak-appbar-inherit-border-bottom: none;
    --ak-appbar-inherit-background-text-color: var(--common-white);
    --ak-appbar-inherit-hover-background: var(--hover-dark-background);
    --ak-appbar-box-shadow: var(--box-shadow-dark);
  }

  // variables for ak-autocomplete
  --ak-autocomplete-x-btn-zIndex: var(--zIndex-tooltip);
  --ak-autocomplete-background: var(--background-main);
  --ak-autocomplete-border-radius: var(--border-radius);
  --ak-autocomplete-box-shadow: var(--box-shadow-2);
  --ak-autocomplete-border-color: var(--border-color-1);

  // variables for ak-drawer
  --ak-drawer-backdrop-overlay-background: var(--backdrop-overlay-background);
  --ak-drawer-background: var(--background-main);
  --ak-drawer-color-text-primary: var(--text-primary);
  --ak-drawer-zIndex: var(--zIndex-drawer);

  // variables for ak-radio
  --ak-radio-hover-background: var(--hover-light-background);
  --ak-radio-color-text-disabled: var(--text-disabled);
  --ak-radio-color-success-main: var(--success-main);

  // variables for ak-date-picker
  --ak-date-picker-background: var(--background-main);
  --ak-date-picker-border-color: var(--border-color-1);
  --ak-date-picker-day-border-color: var(--common-white);
  --ak-date-picker-border-radius: var(--border-radius);
  --ak-date-picker-box-shadow: var(--box-shadow-2);
  --ak-date-picker-day-btn-hover-background: var(--hover-light-background);
  --ak-date-picker-color-text-primary: var(--text-primary);
  --ak-date-picker-color-text-secondary: var(--text-secondary);
  --ak-date-picker-color-text-disabled: var(--text-disabled);
  --ak-date-picker-color-primary: var(--primary-main);
  --ak-date-picker-color-primary-light: var(--primary-light);
  --ak-date-picker-color-primary-contrast-text: var(--primary-contrast-text);
  --ak-date-picker-font-family: var(--font-family);
  --ak-date-picker-font-weight-regular: var(--font-weight-regular);
  --ak-date-picker-font-weight-medium: var(--font-weight-medium);

  // variables for ak-pagination
  --ak-pagination-background: var(--neutral-grey-100);
  --ak-pagination-border-radius: var(--border-radius);
  --ak-pagination-divider-background: var(--divider-dark);
  --ak-pagination-button-background: var(--common-white);

  // variables for ak-tabs
  --ak-tabs-font-family: var(--font-family);
  --ak-tabs-border-color: var(--border-color-1);
  --ak-tabs-color-primary: var(--primary-main);
  --ak-tabs-color-primary-light: var(--primary-light);
  --ak-tabs-text-color-secondary: var(--text-secondary);
  --ak-tabs-text-color-primary: var(--text-primary);
  --ak-tabs-font-weight-bold: var(--font-weight-bold);
  --ak-tabs-font-weight-regular: var(--font-weight-regular);
  --ak-tabs-border-radius: var(--border-radius);
  --ak-tabs-neutral-background: var(--neutral-grey-200);

  // variables for ak-breadcrumbs
  --ak-breadcrumbs-color-text-primary: var(--text-primary);
  --ak-breadcrumbs-color-text-secondary: var(--text-secondary-color);
  --ak-breadcrumbs-font-weight-medium: var(--font-weight-medium);

  // variables for ak-accordion
  --ak-accordion-summary-secondary-background: var(--background-light);
  --ak-accordion-summary-secondary-border-color: var(--border-color-1);
  --ak-accordion-summary-primary-background: var(--primary-main-10);
  --ak-accordion-summary-primary-border-color: var(--primary-main-10);

  // variables for ak-skeleton
  --ak-skeleton-linear-gradient-color-1: var(--neutral-grey-100);
  --ak-skeleton-linear-gradient-color-2: var(--neutral-grey-200);
}

body {
  //variables for home-page/organization-dashboard/header
  &.theme-light {
    --organization-dashboard-header-background-color: var(--background-main);
    --organization-dashboard-header-text-color: var(--text-primary);
    --organization-dashboard-header-security-dashboard-btn-color: var(
      --secondary-main
    );
  }

  &.theme-dark {
    --organization-dashboard-header-background-color: var(--secondary-main);
    --organization-dashboard-header-text-color: var(--secondary-contrast-text);
    --organization-dashboard-header-security-dashboard-btn-color: var(
      --secondary-contrast-text
    );
  }

  //variables for home-page/organization-dashboard/side-nav
  --organization-dashboard-side-nav-font-weight-medium: var(
    --font-weight-medium
  );
  --organization-dashboard-side-nav-text-primary: var(--primary-main);
  --organization-dashboard-side-nav-text-secondary: var(--text-secondary);
  --organization-dashboard-side-nav-item-active-badge-background: var(
    --primary-main
  );
  --organization-dashboard-side-nav-item-active-badge-text-color: var(
    --primary-contrast-text
  );
  --organization-dashboard-side-nav-item-active-border-color: var(
    --primary-main
  );
  --organization-dashboard-side-nav-zIndex: calc(var(--zIndex-appbar) + 1);

  &.theme-light {
    --organization-dashboard-side-nav-bg-color: var(--background-main);
    --organization-dashboard-side-nav-text-color: var(--text-primary);
    --organization-dashboard-side-nav-box-shadow: var(--box-shadow-light);
    --organization-dashboard-side-nav-item-badge-background: var(
      --neutral-grey-200
    );
    --organization-dashboard-side-nav-item-badge-text-color: var(
      --text-primary
    );
    --organization-dashboard-side-nav-item-hover-background: var(
      --hover-light-background
    );
    --organization-dashboard-side-nav-item-active-background: var(
      --side-menu-active-background-light
    );
    --organization-dashboard-side-nav-border: 1px solid var(--border-color-1);
  }

  &.theme-dark {
    --organization-dashboard-side-nav-bg-color: var(--background-dark);
    --organization-dashboard-side-nav-text-color: var(
      --secondary-contrast-text
    );
    --organization-dashboard-side-nav-box-shadow: var(--box-shadow-dark);
    --organization-dashboard-side-nav-item-badge-background: var(
      --neutral-grey-900
    );
    --organization-dashboard-side-nav-item-badge-text-color: var(
      --secondary-contrast-text
    );
    --organization-dashboard-side-nav-item-hover-background: var(
      --hover-dark-background
    );
    --organization-dashboard-side-nav-item-active-background: var(
      --side-menu-active-background-dark
    );
    --organization-dashboard-side-nav-border: none;
  }

  // variables for organization-namespace/namespace-value
  --organization-namespace-value-color-ios: var(--ios);
  --organization-namespace-value-color-android: var(--android);

  // variables for organization-namespace
  --organization-namespace-loading-container-background: var(
    --background-light
  );
  --organization-namespace-loading-container-border: var(--border-color-1);
  --organization-namespace-loading-container-border-radius: var(
    --border-radius
  );

  // variables for organization-member/list
  --organization-member-list-loading-container-background: var(
    --background-light
  );
  --organization-member-list-loading-container-border: var(--border-color-1);
  --organization-member-list-loading-container-border-radius: var(
    --border-radius
  );

  // variables for organization-member/list/member-details
  --organization-member-list-member-details-border-radius: var(--border-radius);
  --organization-member-list-member-details-border-color: var(--border-color-1);
  --organization-member-list-member-details-divider-color: var(
    --neutral-grey-200
  );
  --organization-member-list-member-details-box-shadow: var(--box-shadow-3);

  // variables for organization-member/list/add-to-team
  --organization-member-list-add-to-team-border-radius: var(--border-radius);
  --organization-member-list-add-to-team-border-color: var(--border-color-1);

  // variables for organization-team
  --organization-team-loading-container-background: var(--background-light);
  --organization-team-loading-container-border: var(--border-color-1);
  --organization-team-loading-container-border-radius: var(--border-radius);

  // variables for organization-team/add-team-member
  --add-team-member-loading-container-border-radius: var(--border-radius);
  --add-team-member-loading-container-border-color: var(--border-color-1);

  // variables for organization-team/add-team-project
  --add-team-project-color-ios: var(--ios);
  --add-team-project-color-android: var(--android);
  --add-team-project-loading-container-border-radius: var(--border-radius);
  --add-team-project-loading-container-border-color: var(--border-color-1);

  // variables for organization-team/overview
  --organization-team-overview-border-radius: var(--border-radius);
  --organization-team-overview-border-color: var(--border-color-1);
  --organization-team-overview-hover-background: var(--hover-light-background);

  // variables for organization-team/details/active-action
  --organization-team-details-active-action-footer-shadow: var(--box-shadow-4);

  // variables for organization-team/details/team-info
  --organization-team-details-team-info-summary-shadow: var(--box-shadow-3);

  // variables for organization-team/invitation-list
  --organization-team-invitation-list-loading-container-border-radius: var(
    --border-radius
  );
  --organization-team-invitation-list-loading-container-border-color: var(
    --border-color-1
  );

  // variables for organization-team/member-list
  --organization-team-member-list-loading-container-border-radius: var(
    --border-radius
  );
  --organization-team-member-list-loading-container-border-color: var(
    --border-color-1
  );

  // variables for organization-team/project-list
  --organization-team-project-list-loading-container-border-radius: var(
    --border-radius
  );
  --organization-team-project-list-loading-container-border-color: var(
    --border-color-1
  );

  // variables for organization-team/project-list/project-info
  --organization-team-project-list-info-color-ios: var(--ios);
  --organization-team-project-list-info-color-android: var(--android);

  // variables for organization-mfa
  --organization-mfa-border-radius: var(--border-radius);
  --organization-mfa-alert-error-border-color: var(--error-main);
  --organization-mfa-alert-error-background: var(--error-light);
  --organization-mfa-alert-warn-border-color: var(--warn-dark);
  --organization-mfa-alert-warn-text-color: var(--warn-dark);
  --organization-mfa-alert-warn-background: var(--warn-light);

  // variables for sso-settings
  --sso-settings-border-color: var(--border-color-1);
  --sso-settings-border-radius: var(--border-radius);
  --sso-settings-dropzone-active-shadow-color: var(--neutral-grey-200);
  --sso-settings-file-upload-btn-color: var(--primary-main);

  // variables for organization-archive
  --organization-archive-border-color: var(--border-color-1);
  --organization-archive-border-radius: var(--border-radius);

  // variables for organization-archive/list
  --organization-archive-list-expired-background: var(--neutral-grey-50);
  --organization-archive-list-bordered-container-border-radius: var(
    --border-radius
  );
  --organization-archive-list-bordered-container-border-color: var(
    --border-color-1
  );

  // variables for jira-account
  --jira-account-border-radius: var(--border-radius);
  --jira-account-border-color: var(--border-color-1);

  // variables for github-account
  --github-account-border-radius: var(--border-radius);
  --github-account-border-color: var(--border-color-1);

  // variables for organization-name-header
  --organization-name-header-border-color: var(--neutral-grey-100);
  --organization-name-header-border-radius: var(--border-radius);
  --organization-name-color-primary-light: var(--primary-light);
  --organization-name-color-text-disabled: var(--text-disabled);
  --organization-name-add-btn-disabled-background: var(--neutral-white-100);

  // variables for organization-details
  --organization-details-color-primary: var(--primary-main);
  --organization-details-color-primary-light: var(--primary-light);
  --organization-details-color-text-primary: var(--text-primary);
  --organization-details-color-text-secondary: var(--text-secondary);
  --organization-details-border-color: var(--border-color-1);
  --organization-details-border-radius: var(--border-radius);
  --organization-details-neutral-background: var(--neutral-grey-200);
  --organization-details-font-weight-regular: var(--font-weight-regular);
  --organization-details-font-weight-bold: var(--font-weight-bold);

  //variables for notifications
  --notification-bell-icon-dot-background-color: var(--primary);
  --notification-bell-icon-dot-border-color: var(--black-700);
  --notification-bell-icon-color: var(--background-main);

  &.theme-light {
    --notification-bell-icon-dot-border-color: var(--background-main);
    --notification-bell-icon-color: var(--text-primary);
  }

  --notification-unread-count-color-primary: var(--primary-main);
  --notification-unread-count-background-color: var(--primary-main-10);
  --notification-divider-border-color: var(--common-black);
  --notification-message-hover-background-color: var(--neutral-grey-50);
  --notification-message-read-unread-dot-background-color: var(
    --secondary-main
  );
  --notification-message-read-unread-ring-border-color: var(
    --notification-message-read-unread-dot-background-color
  );
  --notification-message-time-color: var(--neutral-grey-400);
  --notification-message-color-text-primary: var(--text-primary);
  --notification-dropdown-border-color: var(--neutral-grey-100);
  --notification-dropdown-arrow-border-color: var(--neutral-grey-200);

  //variables for notifications-dropdown
  --notifications-dropdown-background-color: var(--background-main);
  --notifications-dropdown-border-color: var(--border-color-1);
  --notifications-dropdown-box-shadow: var(--box-shadow-9);

  //variables for appmonitoring
  --appmonitoring-font-weight-medium: var(--font-weight-medium);
  --appmonitoring-color-black: var(--common-black);
  --appmonitoring-platform-ios-color: var(--ios);
  --appmonitoring-platform-android-color: var(--android);
  --appmonitoring-border-radius: var(--border-radius);
  --appmonitoring-border-color: var(--neutral-white-100);

  //variables for appmonitoring/details
  --appmonitoring-details-border-color: var(--neutral-grey-100);
  --appmonitoring-details-toggle-border-color: var(--neutral-grey-200);

  //variables for appmonitoring/settings
  --appmonitoring-settings-block-description-text-color: var(
    --text-secondary-color
  );

  //variables for appmonitoring/table/status-column
  --appmonitoring-table-status-last-sync-color: var(--neutral-grey-600);

  // variables for file/report-drawer/va-reports
  --file-report-dr-va-reports-border-radius: var(--border-radius);
  --file-report-dr-va-reports-border-color: var(--border-color-1);
  --file-report-dr-va-reports-gen-progress-indicator-bg: var(
    --background-light
  );

  // variables for file/report-drawer/sbom-reports
  --file-report-dr-sbom-reports-border-color: var(--neutral-grey-200);
  --file-report-dr-sbom-reports-border-radius: var(--border-radius);

  // variables for sbom/app-logo
  --sbom-app-logo-border-radius: var(--border-radius);
  --sbom-app-logo-border-color: var(--neutral-grey-100);

  // variables for sbom/app-platform
  --sbom-app-platform-border-radius: var(--border-radius);
  --sbom-app-platform-border-color: var(--neutral-grey-100);
  --sbom-app-platform-color-ios: var(--ios);
  --sbom-app-platform-color-android: var(--android);

  // variables for sbom/scan-report-drawer
  --sbom-scan-report-dr-border-radius: var(--border-radius);
  --sbom-scan-report-dr-border-color: var(--border-color-1);

  // variables for sbom/scan-report-drawer/app-details
  --sbom-scan-report-dr-app-details-box-shadow: var(--box-shadow-3);

  // variables for sbom/empty-loading-view
  --sbom-empty-loading-view-border-radius: var(--border-radius);
  --sbom-empty-loading-view-border-color: var(--border-color-1);

  // variables for sbom/scan-details
  --sbom-scan-details-link-border-radius: var(--border-radius);
  --sbom-scan-details-link-border-color: var(--border-color-1);

  // variables for sbom/component-details/vulnerabilities
  --sbom-component-details-vulnerabilities-list-header-background-color: var(
    --ak-table-header-background-neutral
  );
  --sbom-component-details-vulnerabilities-list-header-border-color-dark: var(
    --ak-table-border-dark
  );
  --sbom-component-details-vulnerabilities-list-border-color-light: var(
    --ak-table-border-light
  );

  // variables for sbom/component-details/vulnerabilities/detail-wrapper
  --sbom-component-details-vulnerabilities-detail-wrapper-background-color-main: var(
    --background-main
  );
  --sbom-component-details-vulnerabilities-detail-wrapper-background-color-light: var(
    --background-light
  );
  --sbom-component-details-vulnerabilities-detail-wrapper-border-color: var(
    --border-color-1
  );
  --sbom-component-details-vulnerabilities-detail-wrapper-border-radius: var(
    --border-radius
  );

  // variables for sbom/component-details/vulnerabilities/severity
  --sbom-component-details-vulnerabilities-severity-critical: var(
    --severity-critical
  );
  --sbom-component-details-vulnerabilities-severity-high: var(--severity-high);
  --sbom-component-details-vulnerabilities-severity-medium: var(
    --severity-medium
  );
  --sbom-component-details-vulnerabilities-severity-low: var(--severity-low);
  --sbom-component-details-vulnerabilities-severity-info: var(--severity-info);
  --sbom-component-details-vulnerabilities-severity-none: var(--severity-none);
  --sbom-component-details-vulnerabilities-severity-unknown: var(
    --severity-unknown
  );
  --sbom-component-details-vulnerabilities-severity-chip-text-color: var(
    --common-white
  );

  // variables for sbom/summary-header
  --sbom-summary-header-border-radius: var(--border-radius);
  --sbom-summary-header-border-color: var(--border-color-1);

  // variables for risk-tag
  --risk-tag-font-family: var(--font-family);
  --risk-tag-border-radius: var(--border-radius);
  --risk-tag-text-color: var(--common-white);
  --risk-tag-color-critical: var(--severity-critical);
  --risk-tag-color-high: var(--severity-high);
  --risk-tag-color-medium: var(--severity-medium);
  --risk-tag-color-low: var(--severity-low);
  --risk-tag-color-passed: var(--severity-passed);
  --risk-tag-color-none: var(--severity-none);
  --risk-tag-color-waiting: var(--neutral-grey-500);

  // variables for file-details/severity-level
  --file-details-severity-level-border-color: var(--border-color-1);

  // variables for file-details/insights-wrapper
  --file-details-insights-wrapper-border-color: var(--border-color-1);
  --file-details-insights-wrapper-background-color: var(--background-main);

  // variables for file-details/skeleton-loader
  --file-details-skeleton-loader-border-color: var(--border-color-1);
  --file-details-skeleton-loader-background-color: var(--background-main);

  // variables for file-details/key-insights
  --file-details-key-insights-border-color: var(--border-color-1);
  --file-details-key-insights-border-radius: var(--border-radius);
  --file-details-key-insights-link-btn-background-color: var(--primary-main);
  --file-details-key-insights-link-btn-contrast-text-color: var(
    --primary-contrast-text
  );
  --file-details-key-insights-link-btn-hover-color: var(--primary-dark);

  // variables for file-details/summary
  --file-details-summary-background-color: var(--background-main);
  --file-details-summary-border-color: var(--border-color-1);
  --file-details-summary-border-radius: var(--border-radius);
  --file-details-summary-store-logo-container-background-color: var(
    --common-white
  );
  --file-details-summary-store-logo-container-border-color: var(
    --neutral-grey-200
  );

  // variables for file-details/summary/app-platform
  --file-details-summary-app-platform-color-ios: var(--ios);
  --file-details-summary-app-platform-color-android: var(--android);

  // variables for file-details/scan-actions
  --file-details-scan-actions-border-color: var(--border-color-1);
  --file-details-scan-actions-background-color: var(--background-main);

  // variables for file-details/scan-actions/api-scan
  --file-details-scan-actions-api-scan-border-radius: var(--border-radius);
  --file-details-scan-actions-api-scan-alert-background-color: var(
    --error-light
  );
  --file-details-scan-actions-api-scan-alert-text-color: var(--error-main);
  --file-details-scan-actions-api-scan-alert-border-color: var(--error-main);

  // variables for file-details/scan-actions/api-scan/captured-apis
  --file-details-scan-actions-api-scan-captured-apis-border-radius: var(
    --border-radius
  );
  --file-details-scan-actions-api-scan-captured-apis-border-color: var(
    --border-color-1
  );

  // variables for file-details/vulnerability-analysis
  --file-details-vulnerability-analysis-border-color: var(--border-color-1);
  --file-details-vulnerability-analysis-background-color: var(
    --background-main
  );

  // variables for file-details/vulnerability-analysis-details
  --file-details-vulnerability-analysis-details-background-main: var(
    --background-main
  );
  --file-details-vulnerability-analysis-details-header-box-shadow: var(
    --box-shadow-5
  );
  --file-details-vulnerability-analysis-details-zIndex: var(--zIndex-appbar);
  --file-details-vulnerability-analysis-details-vul-finding-border-color: var(
    --border-color-1
  );
  --file-details-vulnerability-analysis-details-vul-finding-background-color: var(
    --neutral-grey-100
  );
  --file-details-vulnerability-analysis-details-vul-finding-font-family: var(
    --font-family
  );
  --file-details-vulnerability-analysis-details-color-primary: var(
    --primary-main
  );
  --file-details-vulnerability-analysis-details-border-radius: var(
    --border-radius
  );
  --file-details-vulnerability-analysis-details-divider-color: var(
    --divider-dark
  );

  // variables for file-details/vulnerability-analysis-details/edit-analysis-button
  --file-details-vulnerability-analysis-details-edit-analysis-button-background-main: var(
    --background-main
  );
  --file-details-vulnerability-analysis-details-edit-analysis-button-box-shadow: var(
    --box-shadow-5
  );

  // variables for vnc-viewer
  --vnc-viewer-modal-zIndex: var(--zIndex-drawer);
  --vnc-viewer-modal-backdrop-overlay-background: var(
    --backdrop-overlay-background
  );
  --vnc-viewer-modal-background: var(--background-main);
  --vnc-viewer-modal-text-color: var(--text-primary);

  // variables for proxy-settings-view
  --proxy-settings-view-border-color: var(--border-color-1);

  // variables for dynamic-scan/modal
  --dynamic-scan-modal-border-color: var(--border-color-1);
  --dynamic-scan-modal-border-radius: var(--border-radius);
  --dynamic-scan-modal-alert-background-color: var(--warn-light);
  --dynamic-scan-modal-alert-text-color: var(--warn-dark);
  --dynamic-scan-modal-alert-border-color: var(--warn-dark);
  --dynamic-scan-modal-device-settings-warning-text-color: var(
    --neutral-grey-500
  );

  // variables for file-list
  --file-list-root-background-color: var(--background-main);
  --file-list-selected-file-chip-color: var(--text-primary);
  --file-list-selected-file-chip-background: var(--primary-main-10);

  // variables for file-compare/index
  --file-compare-tabs-background-color: var(--common-white);

  // variables for file-compare/analysis-details
  --file-compare-analysis-details-border-color: var(--border-color-1);

  // variables for file-compare/file-overview
  --file-compare-file-overview-border-radius: var(--border-radius);
  --file-compare-file-overview-background-white: var(--common-white);
  --file-compare-file-overview-background-grey: var(--neutral-grey-100);
  --file-compare-file-overview-border-color-light: var(--neutral-grey-100);
  --file-compare-file-overview-open-in-new-icon-background-color: var(
    --hover-light-background
  );
  --file-compare-file-overview-border-color-dark: var(--border-color-1);
  --file-compare-file-overview-color-android: var(--android);
  --file-compare-file-overview-color-ios: var(--ios);
  --file-compare-store-logo-container-background-color: var(--neutral-grey-200);

  // variables for file-compare/compare-list
  --file-compare-compare-list-wrapper-background-color: var(--background-main);

  // variables for file-compare/header
  --file-compare-header-white-background-color: var(--common-white);
  --file-compare-header-divider-background-color: var(--border-color-2);
  --file-compare-header-dark-border-color: var(--neutral-grey-300);
  --file-compare-header-files-overview-border-color: var(--neutral-grey-200);
  --file-compare-header-file-icon-border-color: var(--neutral-grey-100);
  --file-compare-header-file-icon-border-radius: var(--border-radius);
  --file-compare-header-info-box-shadow: var(--box-shadow-6);
  --file-compare-header-platform-android-color: var(--android);
  --file-compare-header-platform-ios-color: var(--ios);

  // variables for file-compare/vulnerability-details
  --file-compare-vulnerability-details-white-background: var(--common-white);
  --file-compare-vulnerability-details-border-color: var(--neutral-grey-200);
  --file-compare-vulnerability-details-header-wrapper-background-color: var(
    --common-white
  );

  // variables for file-compare/compare-list/skeleton-loader
  --file-compare-list-skeleton-loader-border-color: var(--border-color-1);
  --file-compare-list-skeleton-loader-background-color: var(--background-main);
  --file-compare-list-skeleton-loader-breadcrumbs-seperator-color: var(
    --neutral-grey-500
  );
  --file-compare-list-skeleton-loader-divider-background-color: var(
    --border-color-2
  );

  // variables for login-component
  --login-component-button-loader-color: var(--primary-contrast-text);

  // variables for file-chart
  --file-chart-severity-level-color-critical: var(--severity-critical);
  --file-chart-severity-level-color-high: var(--severity-high);
  --file-chart-severity-level-color-medium: var(--severity-medium);
  --file-chart-severity-level-color-low: var(--severity-low);
  --file-chart-severity-level-color-passed: var(--severity-passed);
  --file-chart-severity-level-color-untested: var(--severity-untested);
  --file-chart-severity-level-color-none: var(--severity-none);

  // variables for account-settings/mfa
  --mfa-loader-primary-contrast-text: var(--primary-contrast-text);

  // variables for project-settings/empty-list
  --project-settings-empty-list-border-radius: var(--border-radius);
  --project-settings-empty-list-border-color: var(--border-color-1);

  // variables for project-settings/empty-list
  --project-settings-loading-container-background: var(--background-light);
  --project-settings-loading-container-border: var(--border-color-1);
  --project-settings-loading-container-border-radius: var(--border-radius);

  // variables for project-settings/page-wrapper
  --project-settings-page-wrapper-background-color: var(--background-light);

  // variables for project-settings/header
  --project-settings-header-background-color: var(--background-light);
  --project-settings-header-border-color: var(--border-color-1);
  --project-settings-header-border-radius: var(--border-radius);
  --project-settings-header-info-text-color: var(--text-secondary-color);
  --project-settings-header-tabs-box-shadow: var(--box-shadow-6);
  --project-settings-header-project-details-box-shadow: var(--box-shadow-8);
  --project-settings-header-color-android: var(--android);
  --project-settings-header-color-ios: var(--ios);

  // variables for project-settings/general-settings
  --project-settings-general-settings-root-border-color: var(--border-color-1);
  --project-settings-general-settings-root-background-color: var(
    --background-main
  );

  // variables for project-settings/general-settings/dynamicscan-automation-settings
  --project-settings-general-settings-dynamicscan-automation-settings-box-shadow: var(
    --box-shadow-7
  );
  --project-settings-general-settings-dynamicscan-automation-settings-border-radius: var(
    --border-radius
  );
  --project-settings-general-settings-dynamicscan-automation-settings-border-color: var(
    --border-color-1
  );

  // variables for project-settings/general-settings/dynamicscan-automation-settings/scenario
  --project-settings-general-settings-dynamicscan-automation-scenario-textfield-bg: var(
    --neutral-grey-100
  );
  --project-settings-general-settings-dynamicscan-automation-scenario-form-footer-box-shadow: var(
    --box-shadow-4
  );

  // variables for project-settings/general-settings/github-project
  --project-settings-general-settings-github-project-background-color: var(
    --neutral-grey-200
  );

  // variables for project-settings/general-settings/jira-project
  --project-settings-general-settings-jira-project-background-color: var(
    --neutral-grey-200
  );

  // variables for project-settings/analysis-settings
  --project-settings-analysis-settings-border-color: var(--neutral-grey-200);
  --project-settings-analysis-settings-border-radius: var(--border-radius);

  // variables for project-settings/analysis-settings/vulnerability-details
  --project-settings-analysis-settings-vulnerability-details-border-color: var(
    --neutral-grey-200
  );
  --project-settings-analysis-settings-vulnerability-details-border-radius: var(
    --border-radius
  );

  // variables for project-settings/analysis-settings/vulnerability-list
  --project-settings-analysis-settings-vulnerability-list-border-color: var(
    --neutral-grey-200
  );
  --project-settings-analysis-settings-vulnerability-list-background-color: var(
    --neutral-grey-100
  );
  --project-settings-analysis-settings-vulnerability-list-border-color-warning: var(
    --error-main
  );
  --project-settings-analysis-settings-vulnerability-list-background-color-warning: var(
    --primary-light
  );
  --project-settings-analysis-settings-vulnerability-list-border-radius: var(
    --border-radius
  );

  // variables for project-settings/view-scenario
  --project-settings-view-scenario-background-white: var(--background-main);
  --project-settings-view-scenario-background-dark: var(--neutral-grey-100);
  --project-settings-view-scenario-modal-footer-box-shadow: var(--box-shadow-4);
  --project-settings-view-scenario-border-light: var(--border-color-1);
  --project-settings-view-scenario-header-border-color: var(--border-color-1);
  --project-settings-view-scenario-header-border-radius: var(--border-radius);
  --project-settings-view-scenario-header-scenario-details-box-shadow: var(
    --box-shadow-8
  );

  // variables for project-settings/view-scenario/parameter-item
  --project-settings-view-scenario-parameter-item-border-color: var(
    --neutral-grey-100
  );
  --project-settings-view-scenario-parameter-text-field-background: var(
    --neutral-grey-100
  );

  // variables for tri-state-checkbox
  --tri-state-checkbox-overridden-bg: var(--success-light);
  --tri-state-checkbox-border-color: var(--success-main);
  --tri-state-reset-icon-bg: var(--common-white);

  // variables for organization-analytics/app-scan-chart
  --organization-analytics-app-scan-chart-border-color: var(--border-color-1);
  --organization-analytics-app-scan-chart-border-radius: var(--border-radius);

  // variables for app-upload/status/details
  --app-upload-status-details-footer-background-color: var(--background-light);
  --app-upload-status-details-footer-border-color: var(--neutral-grey-100);
  --app-upload-status-details-image-border-color: var(--neutral-grey-100);
  --app-upload-status-details-loader-background-color: var(--success-main);

  // variables for app-upload/status
  --app-upload-status-arrow-border-color: var(--neutral-grey-200);
  --app-upload-status-dropdown-background-color: var(--common-white);
  --app-upload-status-dropdown-border-color: var(--neutral-white-100);
  --app-upload-status-dropdown-box-shadow: var(--box-shadow-9);
  --app-upload-status-green-loader-color: var(--success-main);
  --app-upload-status-green-loader-track-color: var(--success-light);

  // variables for app-upload/via-link
  --app-upload-via-link-button-stack-shadow: var(--box-shadow-4);
  --app-upload-via-link-url-format-bullet-color: var(--text-primary);

  &.theme-light {
    --app-upload-via-link-button-color: var(--text-primary);
  }

  &.theme-dark {
    --app-upload-via-link-button-color: var(--common-white);
  }

  // variables for app-upload/via-link/preview
  --app-upload-via-link-preview-background-color: var(--background-light);
  --app-upload-via-link-preview-border-color: var(--neutral-grey-100);
  --app-upload-via-link-preview-footer-background-color: var(
    --neutral-grey-100
  );

  // variables for file-overview
  --file-overview-store-logo-container-background-color: var(--common-white);
  --file-overview-store-logo-container-border-color: var(--neutral-grey-200);
}
