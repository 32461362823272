/* styles for irene/components/account-settings/security/multi-factor-auth/index.scss */
._mfa__method_1msecg {
  border: 1px solid darken(#f3f5f8, 5%);
  background: #f3f5f8;
  padding: 0.7em 1em 1em;

  h3 {
    margin-bottom: 0;
  }

  label {
    cursor: default;
  }
}

._mfa__method__enabled_1msecg {
  background: #80c081;
  color: white;
  border-radius: 0.2em;
  padding: 0.3em 0.8em;
}

._ak-btn-loader_1msecg {
  .ak-loader-circle-indicator {
    stroke: var(--mfa-loader-primary-contrast-text) !important;
  }
}

/* styles for irene/components/ak-accordion/index.scss */
._ak-accordion-root_12v6qm {
  &,
  ._details_12v6qm,
  ._summary_12v6qm {
    width: 100%;
  }

  ._details_12v6qm {
    height: auto;
    overflow: hidden;

    &._collapsed_12v6qm {
      max-height: 0px !important;
    }
  }

  ._summary_12v6qm {
    cursor: pointer;
    border: 1px solid;
    padding: 0.7857em 1.4285em;

    &._primary_12v6qm {
      background-color: var(--ak-accordion-summary-primary-background);
      border-color: var(--ak-accordion-summary-primary-border-color);
    }

    &._secondary_12v6qm,
    &._disabled_12v6qm {
      background-color: var(--ak-accordion-summary-secondary-background);
      border-color: var(--ak-accordion-summary-secondary-border-color);
    }

    &._disabled_12v6qm {
      cursor: not-allowed;
    }

    ._summary-expand-icon_12v6qm {
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      transform: rotate(0deg);

      &._expanded_12v6qm {
        transform: rotate(-180deg);
      }
    }
  }
}

/* styles for irene/components/ak-appbar/index.scss */
._ak-appbar-root_oxtibf {
  width: 100%;
  z-index: var(--ak-appbar-zIndex);
}

._ak-appbar-gutter_oxtibf {
  padding: 0.8em 1em;
}

._ak-appbar-color-inherit_oxtibf {
  background: var(--ak-appbar-inherit-background);
  color: var(--ak-appbar-inherit-background-text-color);
  border-bottom: var(--ak-appbar-inherit-border-bottom);

  ._ak-appbar-default-icon-button_oxtibf {
    .ak-icon {
      color: var(--ak-appbar-inherit-background-text-color) !important;
    }

    &:hover {
      background-color: var(--ak-appbar-inherit-hover-background) !important;
    }
  }
}

._ak-appbar-color-default_oxtibf {
  background: var(--ak-appbar-default-background);
  color: var(--ak-appbar-default-background-text-color);
  border-bottom: var(--ak-appbar-default-border-bottom);
}

._ak-appbar-color-light_oxtibf {
  background: var(--ak-appbar-light-background);
  color: var(--ak-appbar-light-background-text-color);
  border-bottom: var(--ak-appbar-light-border-bottom);
}

._ak-appbar-color-dark_oxtibf {
  background: var(--ak-appbar-dark-background);
  color: var(--ak-appbar-dark-background-text-color);
  border-bottom: var(--ak-appbar-dark-border-bottom);

  ._ak-appbar-default-icon-button_oxtibf {
    .ak-icon {
      color: var(--ak-appbar-dark-background-text-color) !important;
    }

    &:hover {
      background-color: var(--ak-appbar-dark-hover-background) !important;
    }
  }
}

._ak-appbar-position-static_oxtibf {
  position: static;
}

._ak-appbar-position-relative_oxtibf {
  position: relative;
}

._ak-appbar-position-fixed_oxtibf {
  position: fixed;
}

._ak-appbar-position-sticky_oxtibf {
  position: sticky;
}

._ak-appbar-position-absolute_oxtibf {
  position: absolute;
}

._ak-appbar-placement-top_oxtibf {
  top: 0;
  left: 0;
  right: 0;
}

._ak-appbar-placement-bottom_oxtibf {
  bottom: 0;
  left: 0;
  right: 0;
}

._ak-appbar-bottom-elevation_oxtibf {
  box-shadow: var(--ak-appbar-box-shadow);
}

/* styles for irene/components/ak-autocomplete/index.scss */
._ak-autocomplete-input-root_g1ximl {
  .ak-text-field-right-adornment {
    position: absolute;
    right: 0px;
  }
}

._ak-autocomplete-clear-button_g1ximl {
  z-index: calc(var(--ak-autocomplete-x-btn-zIndex) + 1);
}

._ak-autocomplete-dropdown-root_g1ximl {
  display: inline-flex;
  background-color: var(--ak-autocomplete-background);
  border-radius: var(--ak-autocomplete-border-radius);
  box-shadow: var(--ak-autocomplete-box-shadow);
  border: 1px solid var(--ak-autocomplete-border-color);
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  max-height: 10em;
  overflow-y: auto;
}

._ak-autocomplete-text-field_g1ximl {
  padding-left: 1em !important;
  padding-right: 2.5em !important;
}

._ak-autocomplete-dropdown-list_g1ximl {
  width: 100%;
}

/* styles for irene/components/ak-breadcrumbs/item/index.scss */
._ak-breadcrumb-item_1k3v44 {
  width: fit-content !important;

  ._breadcrumb-title_1k3v44,
  ._separator_1k3v44 {
    font-size: 0.929em;
    line-height: 1.286em;
    letter-spacing: -0.0176em;
    color: var(--ak-breadcrumbs-color-text-secondary);
  }

  ._separator_1k3v44 {
    margin-left: 0.24em;
    margin-right: 0.24em;
  }

  ._ak-breadcrumb-item-link-text_1k3v44:hover,
  ._ak-breadcrumb-item-link-text_1k3v44:focus,
  ._ak-breadcrumb-item-link-text_1k3v44:active {
    background-color: transparent !important;
  }

  &:not(:last-of-type) ._ak-breadcrumb-item-link-text_1k3v44:hover ._breadcrumb-title_1k3v44 {
    text-decoration: underline;
  }

  &:last-of-type {
    pointer-events: none;
  }

  &:last-of-type ._ak-breadcrumb-item-link-text_1k3v44 ._breadcrumb-title_1k3v44 {
    font-weight: var(--ak-breadcrumbs-font-weight-medium);
    color: var(--ak-breadcrumbs-color-text-primary);
  }

  &:last-of-type ._separator_1k3v44 {
    display: none;
  }
}

/* styles for irene/components/ak-button/index.scss */
._ak-button-root_1vgcus,
._ak-button-text-root_1vgcus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px; // This was global value for button setting it here for uniformity across elements
  min-width: 80px;
  height: auto;
  border-radius: var(--ak-button-border-radius);

  &:hover,
  &:focus {
    border: unset; //overwrite global styles
    transition: unset !important;
  }

  &:disabled {
    opacity: unset;
  }

  &._ak-button-disabled_1vgcus,
  &._ak-button-loading_1vgcus {
    pointer-events: none; // for non button elements
  }
}

._ak-button-root_1vgcus {
  padding: 0.4em 0.7em;
}

._ak-button-text-root_1vgcus {
  padding: 0;

  &:disabled ._ak-button-text_1vgcus,
  &._ak-button-disabled_1vgcus ._ak-button-text_1vgcus {
    color: var(--ak-button-color-text-disabled);
  }

  &:hover ._ak-button-text_1vgcus {
    text-decoration: underline;
  }

  &:focus ._ak-button-text_1vgcus {
    text-decoration: underline;
  }

  &._ak-button-text-underline-none_1vgcus {
    &:hover ._ak-button-text_1vgcus {
      text-decoration: none;
    }

    &:focus ._ak-button-text_1vgcus {
      text-decoration: none;
    }
  }
}

._ak-button-filled-primary_1vgcus {
  background-color: var(--ak-button-color-primary);
  color: var(--ak-button-color-primary-contrast-text);

  &:disabled,
  &._ak-button-disabled_1vgcus {
    color: var(--ak-button-color-primary-contrast-text);
    background-color: var(--ak-button-disabled-background);
  }

  &:disabled._ak-button-loading_1vgcus {
    background-color: var(--ak-button-color-primary);
    color: var(--ak-button-color-primary-contrast-text);
  }

  &:hover,
  &:focus {
    color: var(--ak-button-color-primary-contrast-text);
    background: var(--ak-button-filled-primary-hover-background);
  }
}

._ak-button-outlined-neutral_1vgcus {
  border: 1px solid var(--ak-button-color-neutral);
  color: var(--ak-button-color-text-primary);

  &:disabled,
  &._ak-button-disabled_1vgcus {
    border-color: var(--ak-button-color-neutral);
    color: var(--ak-button-color-text-disabled);
  }

  &:hover,
  &:focus {
    color: var(--ak-button-color-text-primary);
    border: 1px solid var(--ak-button-color-neutral);
    box-shadow: inset 0 0 0 0.5px var(--ak-button-color-neutral);
  }
}

._ak-button-outlined-primary_1vgcus {
  border: 1px solid var(--ak-button-color-primary);
  color: var(--ak-button-color-primary);

  &:disabled,
  &._ak-button-disabled_1vgcus {
    border-color: var(--ak-button-color-neutral);
    color: var(--ak-button-color-text-disabled);
  }

  &:hover,
  &:focus {
    color: var(--ak-button-color-primary);
    border: 1px solid var(--ak-button-color-primary);
    box-shadow: inset 0 0 0 0.5px var(--ak-button-color-primary);
  }
}

._ak-button-loader_1vgcus {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5em;

  .ak-loader-circle-indicator {
    stroke: var(--ak-button-color-primary-contrast-text) !important;
  }
}

._ak-button-left-icon_1vgcus,
._ak-button-right-icon_1vgcus {
  display: inline-flex;
  align-items: center;

  .ak-icon {
    font-size: 1.125rem !important;
  }
}

._ak-button-icon-medium_1vgcus {
  .ak-icon {
    font-size: 1.5rem !important;
  }
}

._ak-button-icon-small_1vgcus {
  .ak-icon {
    font-size: 1.125rem !important;
  }
}

._ak-button-left-icon_1vgcus {
  margin-right: 0.5em;
}

._ak-button-right-icon_1vgcus {
  margin-left: 0.5em;
}

/* styles for irene/components/ak-checkbox/index.scss */
._ak-checkbox-root_124z1x {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  padding: 0.4em;
  border-radius: 50%;

  &._ak-checkbox-hover-focus_124z1x {
    &:hover,
    &:focus {
      background-color: var(--ak-checkbox-hover-background);
    }
  }
}

._ak-checkbox_124z1x {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;

  &:disabled + ._ak-svg-checkbox-icon_124z1x {
    color: var(--ak-checkbox-color-text-disabled) !important;
  }

  &[data-indeterminate='true'] + ._ak-svg-checkbox-icon_124z1x {
    color: var(--ak-checkbox-color-indeterminate);

    &::before {
      content: '\e909';
    }
  }

  &:checked + ._ak-svg-checkbox-icon_124z1x {
    color: var(--ak-checkbox-color-checked);

    &::before {
      content: '\e834';
    }
  }
}

._ak-svg-checkbox-icon_124z1x {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  color: currentColor;
  flex-shrink: 0;
  font-size: 1.5rem !important;

  &::before {
    content: '\e835';
  }
}

/* styles for irene/components/ak-chip/index.scss */
._ak-chip-root_t086gp {
  max-width: 100%;
  font-family: var(--ak-chip-font-family);
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  background-color: transparent;
  border-radius: var(--ak-chip-border-radius);
  white-space: nowrap;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}

._ak-chip-button_t086gp {
  cursor: pointer;
}

._ak-chip-variant-filled_t086gp {
  border: 0px;

  &._ak-chip-color-default_t086gp {
    background-color: var(--ak-chip-color-filled-default-background);
    color: var(--ak-chip-color-filled-default-contrast-text);
  }

  &._ak-chip-color-primary_t086gp {
    background-color: var(--ak-chip-color-filled-primary-background);
    color: var(--ak-chip-color-filled-primary-contrast-text);
  }

  &._ak-chip-color-secondary_t086gp {
    background-color: var(--ak-chip-color-filled-secondary-background);
    color: var(--ak-chip-color-filled-secondary-contrast-text);
  }

  &._ak-chip-color-success_t086gp {
    background-color: var(--ak-chip-color-filled-success-background);
    color: var(--ak-chip-color-filled-success-contrast-text);
  }

  &._ak-chip-color-error_t086gp {
    background-color: var(--ak-chip-color-filled-error-background);
    color: var(--ak-chip-color-filled-error-contrast-text);
  }

  &._ak-chip-color-warn_t086gp {
    background-color: var(--ak-chip-color-filled-warn-background);
    color: var(--ak-chip-color-filled-warn-contrast-text);
  }

  &._ak-chip-color-info_t086gp {
    background-color: var(--ak-chip-color-filled-info-background);
    color: var(--ak-chip-color-filled-info-contrast-text);
  }
}

._ak-chip-variant-semi-filled_t086gp {
  border: 0px;

  &._ak-chip-color-default_t086gp {
    background-color: var(--ak-chip-color-semi-filled-default-background);
    color: var(--ak-chip-color-semi-filled-default-contrast-text);
  }

  &._ak-chip-color-primary_t086gp {
    background-color: var(--ak-chip-color-semi-filled-primary-background);
    color: var(--ak-chip-color-semi-filled-primary-contrast-text);
  }

  &._ak-chip-color-secondary_t086gp {
    background-color: var(--ak-chip-color-semi-filled-secondary-background);
    color: var(--ak-chip-color-semi-filled-secondary-contrast-text);
  }

  &._ak-chip-color-success_t086gp {
    background-color: var(--ak-chip-color-semi-filled-success-background);
    color: var(--ak-chip-color-semi-filled-success-contrast-text);
  }

  &._ak-chip-color-error_t086gp {
    background-color: var(--ak-chip-color-semi-filled-error-background);
    color: var(--ak-chip-color-semi-filled-error-contrast-text);
  }

  &._ak-chip-color-warn_t086gp {
    background-color: var(--ak-chip-color-semi-filled-warn-background);
    color: var(--ak-chip-color-semi-filled-warn-contrast-text);
  }

  &._ak-chip-color-info_t086gp {
    background-color: var(--ak-chip-color-semi-filled-info-background);
    color: var(--ak-chip-color-semi-filled-info-contrast-text);
  }
}

._ak-chip-variant-outlined_t086gp {
  border-width: 1px;
  border-style: solid;

  &._ak-chip-color-default_t086gp {
    border-color: var(--ak-chip-color-outlined-default);
    color: var(--ak-chip-color-text-primary);
  }

  &._ak-chip-color-primary_t086gp {
    border-color: var(--ak-chip-color-outlined-primary);
    color: var(--ak-chip-color-outlined-primary);
  }

  &._ak-chip-color-secondary_t086gp {
    border-color: var(--ak-chip-color-outlined-secondary);
    color: var(--ak-chip-color-outlined-secondary);
  }

  &._ak-chip-color-success_t086gp {
    border-color: var(--ak-chip-color-outlined-success);
    color: var(--ak-chip-color-outlined-success);
  }

  &._ak-chip-color-error_t086gp {
    border-color: var(--ak-chip-color-outlined-error);
    color: var(--ak-chip-color-outlined-error);
  }

  &._ak-chip-color-warn_t086gp {
    border-color: var(--ak-chip-color-outlined-warn);
    color: var(--ak-chip-color-outlined-warn);
  }

  &._ak-chip-color-info_t086gp {
    border-color: var(--ak-chip-color-outlined-info);
    color: var(--ak-chip-color-outlined-info);
  }
}

._ak-chip-label_t086gp {
  margin: 0 0.5em !important;
}

._ak-chip-icon_t086gp {
  display: inline-flex;
  align-items: center;
  margin-left: 0.5em;
}

._ak-chip-size-medium_t086gp {
  height: 32px;

  ._ak-chip-delete-icon-button_t086gp {
    padding: 0.143em !important;
  }

  ._ak-chip-icon_t086gp {
    .ak-icon {
      font-size: 1.125rem !important;
    }
  }
}

._ak-chip-size-small_t086gp {
  height: 22px;

  ._ak-chip-delete-icon-button_t086gp {
    padding: 0.107em !important;
  }

  ._ak-chip-label_t086gp {
    font-size: 0.857rem;
  }

  ._ak-chip-icon_t086gp {
    .ak-icon {
      font-size: 0.85rem !important;
    }
  }
}

._ak-chip-delete-icon-button_t086gp {
  margin-right: 0.25em !important;

  &:hover {
    background-color: var(--ak-chip-delete-btn-hover-background) !important;
  }
}

/* styles for irene/components/ak-date-picker/index.scss */
._ak-date-picker-trigger_3r3ak4 {
  display: inline-block;
}

._ak-date-picker-popup-container_3r3ak4 {
  display: flex;
  flex-direction: column;
  background-color: var(--ak-date-picker-background);
  border: 1px solid var(--ak-date-picker-border-color);
  border-radius: var(--ak-date-picker-border-radius);
  box-shadow: var(--ak-date-picker-box-shadow);
  padding: 0;
  margin-top: 0.25em;
  box-sizing: border-box;

  .ember-power-calendar-weekday {
    padding: 2px 0;
    font-family: var(--ak-date-picker-font-family);
    font-weight: var(--ak-date-picker-font-weight-medium);
    font-size: 0.857rem;
    color: var(--ak-date-picker-color-text-primary);
    text-transform: uppercase;
  }

  .ember-power-calendar-day {
    width: 36px;
    height: 36px;
    padding: 2px 0;
    border-radius: var(--ak-date-picker-border-radius);
    font-size: 0.928rem;
    font-family: var(--ak-date-picker-font-family);
    font-weight: var(--ak-date-picker-font-weight-regular);
    border-left: 1px solid var(--ak-date-picker-day-border-color);
    border-top: 1px solid var(--ak-date-picker-day-border-color);
    opacity: unset; // override bohemia global

    &:hover,
    &:focus {
      background-color: var(--ak-date-picker-day-btn-hover-background);
    }

    &:disabled {
      color: var(--ak-date-picker-color-text-disabled) !important;
    }
  }

  &._ak-date-picker-single_3r3ak4 {
    .ember-power-calendar-day--selected {
      background-color: var(--ak-date-picker-color-primary);
      color: var(--ak-date-picker-color-primary-contrast-text) !important;
    }
  }

  &._ak-date-picker-range_3r3ak4 {
    .ember-power-calendar-day--selected {
      background-color: var(--ak-date-picker-color-primary-light);
      border-radius: 0;
      border-left: none;
    }

    .ember-power-calendar-day--interactive[disabled] {
      opacity: unset;
    }

    .ember-power-calendar-day--range-start,
    .ember-power-calendar-day--range-end {
      background-color: var(--ak-date-picker-color-primary) !important;
      color: var(--ak-date-picker-color-primary-contrast-text) !important;
      border-radius: var(--ak-date-picker-border-radius) !important;
    }
  }

  &._ak-date-picker-multiple_3r3ak4 {
    .ember-power-calendar-day--selected {
      background-color: var(--ak-date-picker-color-primary);
      color: var(--ak-date-picker-color-primary-contrast-text) !important;
    }
  }

  .ember-power-calendar-day--other-month {
    color: var(--ak-date-picker-color-text-secondary);
  }

  .ember-power-calendar-day--current-month {
    color: var(--ak-date-picker-color-text-primary);
  }

  ._ak-date-picker-quick-select-container_3r3ak4 {
    display: grid;
    gap: 0 1em;
    grid-template-areas:
      'header header'
      '. .';
    border-top: 1px solid var(--ak-date-picker-border-color);
    box-sizing: border-box;

    ._ak-date-picker-quick-select-header_3r3ak4 {
      grid-area: header;
      margin-bottom: 0.5em;
    }
  }
}

/* styles for irene/components/ak-divider/index.scss */
._ak-divider-root_dmtdmy {
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
}

._ak-divider-variant-fullWidth_dmtdmy {
  width: 100%;
  margin: 0px;
}

._ak-divider-variant-middle_dmtdmy {
  margin: 0 1em;
}

._ak-divider-color-light_dmtdmy {
  border-color: var(--ak-divider-color-light);
}

._ak-divider-color-dark_dmtdmy {
  border-color: var(--ak-divider-color-dark);
}

/* styles for irene/components/ak-drawer/index.scss */
._drawer-root_o268os {
  position: fixed;
  inset: 0px;
  z-index: var(--ak-drawer-zIndex);
}

._backdrop-root_o268os {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  background-color: var(--ak-drawer-backdrop-overlay-background);
  z-index: -1;
  opacity: 0;
}

._drawer-container_o268os {
  background-color: var(--ak-drawer-background);
  color: var(--ak-drawer-color-text-primary);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: var(--ak-drawer-zIndex);
  position: fixed;
  outline: 0px;
}

._drawer-anchor-left_o268os {
  top: 0px;
  left: 0px;
  transform: translateX(-100%);
}

._drawer-anchor-right_o268os {
  top: 0px;
  right: 0px;
  transform: translateX(100%);
}

/* styles for irene/components/ak-form-control-label/index.scss */
._ak-form-control-label-root_1nmqwz {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
}

._ak-form-control-label_1nmqwz {
  margin: 0px;
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

._ak-form-control-label-placement-right_1nmqwz {
  flex-direction: row;
}

._ak-form-control-label-placement-left_1nmqwz {
  flex-direction: row-reverse;
}

._ak-form-control-label-disabled_1nmqwz {
  color: var(--text-disabled) !important;
}

/* styles for irene/components/ak-icon-button/index.scss */
._ak-icon-button-root_1zo6qj {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  height: auto;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: var(--ak-icon-button-color-text-primary) !important;
  text-align: center;
  flex: 0 0 auto;
  border-radius: var(--ak-icon-button-border-radius);
  overflow: visible;

  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: transparent;
    color: var(--ak-icon-button-color-text-disabled);

    .ak-icon {
      color: var(--ak-icon-button-color-text-disabled);
    }
  }

  &._ak-icon-button-variant-outlined_1zo6qj {
    border: 1px solid;

    &:disabled {
      border-color: var(--ak-icon-button-border-color-default) !important;
    }

    &._ak-icon-button-border-color-default_1zo6qj {
      border-color: var(--ak-icon-button-border-color-default);

      &:hover:not(:disabled) {
        box-shadow: inset 0 0 0 0.5px var(--ak-icon-button-border-color-default);
      }
    }

    &._ak-icon-button-border-color-primary_1zo6qj {
      border-color: var(--ak-icon-button-border-color-primary);

      &:hover:not(:disabled) {
        box-shadow: inset 0 0 0 0.5px var(--ak-icon-button-border-color-primary);
      }
    }

    &._ak-icon-button-border-color-secondary_1zo6qj {
      border-color: var(--ak-icon-button-border-color-secondary);

      &:hover:not(:disabled) {
        box-shadow: inset 0 0 0 0.5px
          var(--ak-icon-button-border-color-secondary);
      }
    }
  }

  &._ak-icon-button-variant-default_1zo6qj {
    &:hover:not(:disabled) {
      background-color: var(--ak-icon-button-hover-background);
    }
  }

  &._ak-icon-button-size-small_1zo6qj {
    .ak-icon {
      font-size: 1.125rem !important;
    }

    padding: 0.35em;
  }

  &._ak-icon-button-size-medium_1zo6qj {
    .ak-icon {
      font-size: 1.35rem !important;
    }

    padding: 0.4em;
  }
}

/* styles for irene/components/ak-icon/index.scss */
._ak-icon-root_1c2k6u {
  font-size: inherit;
}

// color styles
._ak-icon-color-primary_1c2k6u {
  color: var(--ak-icon-color-primary);
}

._ak-icon-color-secondary_1c2k6u {
  color: var(--ak-icon-color-secondary);
}

._ak-icon-color-textPrimary_1c2k6u {
  color: var(--ak-icon-color-text-primary);
}

._ak-icon-color-textSecondary_1c2k6u {
  color: var(--ak-icon-color-text-secondary);
}

._ak-icon-color-error_1c2k6u {
  color: var(--ak-icon-color-error);
}

._ak-icon-color-success_1c2k6u {
  color: var(--ak-icon-color-success);
}

._ak-icon-color-warn_1c2k6u {
  color: var(--ak-icon-color-warn);
}

._ak-icon-color-info_1c2k6u {
  color: var(--ak-icon-color-info);
}

._ak-icon-color-inherit_1c2k6u {
  color: inherit;
}

// size styles
._ak-icon-size-small_1c2k6u {
  font-size: 1.125rem !important;
}

._ak-icon-size-medium_1c2k6u {
  font-size: 1.5rem !important;
}

/* styles for irene/components/ak-link/index.scss */
._ak-link-root_9h6zsb {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  font-weight: 400;
  font-size: 1rem;

  &._ak-link-disabled_9h6zsb {
    color: var(--ak-link-color-text-disabled);
    pointer-events: none;

    &:hover ._ak-link-text_9h6zsb {
      text-decoration: none;
    }
  }
}

._ak-link-color-primary_9h6zsb {
  color: var(--ak-link-color-primary);
}

._ak-link-color-secondary_9h6zsb {
  color: var(--ak-link-color-secondary);
}

._ak-link-color-textPrimary_9h6zsb {
  color: var(--ak-link-color-text-primary);
}

._ak-link-color-textSecondary_9h6zsb {
  color: var(--ak-link-color-text-secondary);
}

._ak-link-color-warn_9h6zsb {
  color: var(--ak-link-color-warn);
}

._ak-link-color-error_9h6zsb {
  color: var(--ak-link-color-error);
}

._ak-link-color-success_9h6zsb {
  color: var(--ak-link-color-success);
}

._ak-link-color-info_9h6zsb {
  color: var(--ak-link-color-info);
}

._ak-link-color-inherit_9h6zsb {
  color: inherit;
}

a:hover {
  &._ak-link-color-primary_9h6zsb {
    color: var(--ak-link-color-primary);
  }

  &._ak-link-color-secondary_9h6zsb {
    color: var(--ak-link-color-secondary);
  }

  &._ak-link-color-textPrimary_9h6zsb {
    color: var(--ak-link-color-text-primary);
  }

  &._ak-link-color-textSecondary_9h6zsb {
    color: var(--ak-link-color-text-secondary);
  }

  &._ak-link-color-warn_9h6zsb {
    color: var(--ak-link-color-warn);
  }

  &._ak-link-color-error_9h6zsb {
    color: var(--ak-link-color-error);
  }

  &._ak-link-color-success_9h6zsb {
    color: var(--ak-link-color-success);
  }

  &._ak-link-color-info_9h6zsb {
    color: var(--ak-link-color-info);
  }
}

._ak-link-underline-none_9h6zsb ._ak-link-text_9h6zsb {
  text-decoration: none;
}

._ak-link-underline-always_9h6zsb ._ak-link-text_9h6zsb {
  text-decoration: underline;
}

._ak-link-underline-hover_9h6zsb {
  text-decoration: none;

  &:hover ._ak-link-text_9h6zsb {
    text-decoration: underline;
  }
}

._ak-link-left-icon_9h6zsb,
._ak-link-right-icon_9h6zsb {
  display: inline-flex;
  align-items: center;

  .ak-icon {
    font-size: 1.125rem !important;
    user-select: none;
  }
}

._ak-link-left-icon_9h6zsb {
  margin-right: 0.5em;
}

._ak-link-right-icon_9h6zsb {
  margin-left: 0.5em;
}

._ak-link-noWrap_9h6zsb {
  overflow: hidden;
}

/* styles for irene/components/ak-list/index.scss */
._ak-list-root_jnx4sp {
  list-style: none;
  margin: 0px;
  padding: 0.5em 0px;
  position: relative;
}

/* styles for irene/components/ak-list/item-icon/index.scss */
._ak-list-item-icon-root_1wfnu1 {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;

  .ak-icon {
    color: var(--ak-list-item-color-text-primary);
  }
}

._ak-list-item-icon-disabled_1wfnu1 {
  color: var(--ak-list-item-color-text-disabled);

  .ak-icon {
    color: var(--ak-list-item-color-text-disabled);
  }
}

._ak-list-item-icon-gutter-right_1wfnu1 {
  margin-right: 0.5em;
}

._ak-list-item-icon-gutter-left_1wfnu1 {
  margin-left: 0.5em;
}

/* styles for irene/components/ak-list/item-text/index.scss */
._ak-list-item-text-root_4wx003 {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0px;
}

._ak-list-item-text-disabled_4wx003 {
  color: var(--ak-list-item-color-text-disabled) !important;
}

/* styles for irene/components/ak-list/item/index.scss */
._ak-list-item-root_1rdo24 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

._ak-list-item-button-root_1rdo24,
._ak-list-item-link-root_1rdo24 {
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-width: 0px;
  box-sizing: border-box;
  text-align: left;
  height: auto;
  line-height: normal;

  &:hover:not(:disabled) {
    background-color: var(--ak-list-item-hover-background);
  }

  &:focus:not(:disabled) {
    background-color: var(--ak-list-item-hover-background);
  }

  &:disabled {
    color: var(--ak-list-item-color-text-disabled);
  }
}

._ak-list-item-link-disabled_1rdo24 {
  pointer-events: none;
  color: var(--ak-list-item-color-text-disabled);

  &:hover {
    background-color: transparent;
  }
}

._ak-list-item-selected_1rdo24 {
  background-color: var(--ak-list-item-selected-background);
}

._ak-list-item-gutters_1rdo24 {
  padding: 0.5em 1em;
}

/* styles for irene/components/ak-loader/index.scss */
._ak-loader-root_kekvu {
  display: inline-flex;
  position: relative;

  ._ak-loader-svg_kekvu {
    transform: rotate(-90deg);

    &._indeterminate_kekvu {
      transform-origin: 50% 50%;
      animation: _SpinnerFrames_kekvu 1s infinite linear;
    }

    ._ak-loader-svg-track_kekvu,
    ._ak-loader-svg-indicator_kekvu {
      fill: transparent;
    }

    ._ak-loader-svg-track_kekvu {
      stroke: var(--ak-loader-stroke-primary-light);
    }

    ._ak-loader-svg-indicator_kekvu {
      stroke: var(--ak-loader-stroke-primary-main);
      stroke-linecap: round;

      &._indeterminate_kekvu {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: 0;
      }
    }
  }

  ._ak-loader-svg-label_kekvu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
  }
}

@keyframes _SpinnerFrames_kekvu {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

/* styles for irene/components/ak-loader/linear/index.scss */
._ak-loader-linear-root_160lt0 {
  width: 100%;
  min-width: 100px;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 100px; // Fixed border radius value for all loader heights
  background-color: var(--ak-loader-linear-root-bg-color);

  ._ak-loader-linear-indicator_160lt0,
  ._indeterminate-indicator-bar--1_160lt0,
  ._indeterminate-indicator-bar--2_160lt0 {
    background-color: var(--ak-loader-stroke-primary-main);
    bottom: 0px;
    top: 0px;
  }

  ._ak-loader-linear-indicator_160lt0 {
    width: 100%;
    position: absolute;
    transition: transform 0.4s linear 0s;
    left: 0px;
    transform-origin: left center;
  }

  ._indeterminate-indicator-bar--1_160lt0,
  ._indeterminate-indicator-bar--2_160lt0 {
    width: auto;
    position: absolute;
    left: 0px;
    transition: transform 0.2s linear 0s;
    transform-origin: left center;
    animation: 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal
      none running _linear-loader_160lt0;
  }

  ._indeterminate-indicator-bar--1_160lt0 {
    animation-delay: 0s;
  }
}

._ak-loader-linear-label_160lt0 {
  white-space: nowrap;
}

@keyframes _linear-loader_160lt0 {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

/* styles for irene/components/ak-menu/index.scss */
._ak-menu-root_vuwd08 {
  position: fixed;
  inset: 0px;
  z-index: var(--ak-menu-z-index) !important;
}

._ak-menu-dropdown-root_vuwd08 {
  display: inline-flex;
  background-color: var(--ak-menu-background);
  border-radius: var(--ak-menu-border-radius);
  box-shadow: var(--ak-menu-box-shadow);
  border: 1px solid var(--ak-menu-border-color);
  min-width: 100px;
  padding: 0;
  box-sizing: border-box;
}

._ak-menu-dropdown-full-width_vuwd08 {
  width: 100%;
}

._ak-menu-dropdown-list_vuwd08 {
  width: 100%;
}

._ak-menu-arrow-popover-root_vuwd08 {
  &[data-popper-placement*='top'] ._ak-menu-dropdown-root_vuwd08 {
    margin-bottom: 0.8em;
  }

  &[data-popper-placement*='bottom'] ._ak-menu-dropdown-root_vuwd08 {
    margin-top: 0.8em;
  }

  &[data-popper-placement*='left'] ._ak-menu-dropdown-root_vuwd08 {
    margin-right: 0.8em;
  }

  &[data-popper-placement*='right'] ._ak-menu-dropdown-root_vuwd08 {
    margin-left: 0.8em;
  }
}

/* styles for irene/components/ak-modal/index.scss */
._ak-modal-overlay_1t1w6n {
  z-index: var(--ak-modal-zIndex);

  &.translucent {
    background-color: var(--ak-modal-backdrop-overlay-background);
  }

  ._ak-modal-dialog-container_1t1w6n {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100% - 64px);
    padding: 0;
    border-radius: var(--ak-modal-border-radius);
    box-shadow: none;
  }

  &._ak-modal-overlay-blur_1t1w6n {
    backdrop-filter: blur(5px);
  }
}

._ak-modal-body_1t1w6n {
  min-width: 400px;
  flex: 1 1 auto;
  overflow-y: auto;

  &._ak-modal-body-gutter_1t1w6n {
    padding: 1.2em;
  }
}

/* styles for irene/components/ak-pagination/index.scss */
/* Pagination Component */
._ak-pagination_c191ev {
  background: var(--ak-pagination-background);
  border-radius: var(--ak-pagination-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.571em 1.071em;
  margin-top: 0.714em;
  width: 100%;
  height: 45px;

  ._ak-pagination-divider_c191ev {
    width: 1px;
    height: 100%;
    min-height: 32px;
    background: var(--ak-pagination-divider-background);
  }

  ._ak-pagination-divider_c191ev._pagination-buttons_c191ev {
    margin-right: 0.643em;
  }

  ._ak-pagination-button-prev-icon_c191ev {
    margin-right: 0.214em;
  }

  ._ak-pagination-button-next-icon_c191ev {
    margin-left: 0.214em;
  }

  ._ak-pagination-select_c191ev {
    height: 30px !important;
  }

  ._ak-pagination-prev-button_c191ev,
  ._ak-pagination-next-button_c191ev {
    height: 30px;
    background-color: var(--ak-pagination-button-background);
    min-width: unset;
    padding-left: 0.143em;
    padding-right: 0.857em;
  }

  ._ak-pagination-next-button_c191ev {
    padding-left: 0.857em;
    padding-right: 0.143em;
  }
}

._ak-pagination-variant-compact_c191ev {
  ._ak-pagination-divider_c191ev._pagination-buttons_c191ev {
    display: none;
  }

  ._ak-pagination-prev-button_c191ev,
  ._ak-pagination-next-button_c191ev {
    padding-left: 0.357em;
    padding-right: 0.357em;
  }

  ._ak-pagination-button-prev-icon_c191ev,
  ._ak-pagination-button-next-icon_c191ev {
    margin: 0em;
  }
}

/* styles for irene/components/ak-popover/index.scss */
._ak-popover-root_18ey6o {
  position: fixed;
  z-index: var(--ak-popover-zIndex);
  display: block;
  inset: 0;
}

._ak-popover-root_18ey6o._hide_18ey6o {
  visibility: hidden;
  pointer-events: none;
}

._backdrop-root_18ey6o {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  background-color: transparent;
  z-index: -1;
}

._ak-popover-arrow_18ey6o {
  position: absolute;
  font-size: 10px;
  width: 2.1em;
  height: 2.1em;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 1em;
    height: 1em;
    z-index: 0;
  }
}

._ak-popover-arrow-color-light_18ey6o::before {
  background-color: var(--ak-popover-arrow-background-light);
  border-width: 1px;
  border-style: solid;
}

._ak-popover-arrow-color-dark_18ey6o::before {
  background-color: var(--ak-popover-arrow-background-dark);
}

._ak-popover-container_18ey6o {
  z-index: 1;

  &[data-popper-placement*='bottom'] {
    ._ak-popover-arrow_18ey6o {
      top: 0;
      left: 0;
      width: 2.1em;
      height: 0.7em;
      margin-top: 0.6em;

      &::before {
        transform: translateX(50%) rotate(-45deg);
      }
    }

    ._ak-popover-arrow-color-light_18ey6o::before {
      border-color: var(--ak-popover-arrow-border-light)
        var(--ak-popover-arrow-border-light) transparent transparent;
    }
  }

  &[data-popper-placement*='top'] {
    ._ak-popover-arrow_18ey6o {
      bottom: 0;
      left: 0;
      width: 2.1em;
      height: 0.7em;
      margin-bottom: 0.9em;

      &::before {
        transform: translateX(50%) rotate(-45deg);
      }
    }

    ._ak-popover-arrow-color-light_18ey6o::before {
      border-color: transparent transparent var(--ak-popover-arrow-border-light)
        var(--ak-popover-arrow-border-light);
    }
  }

  &[data-popper-placement*='right'] {
    ._ak-popover-arrow_18ey6o {
      left: 0;
      width: 0.7em;
      height: 2.1em;
      margin-left: 0.6em;

      &::before {
        transform: translateY(50%) rotate(-45deg);
      }
    }

    ._ak-popover-arrow-color-light_18ey6o::before {
      border-color: var(--ak-popover-arrow-border-light) transparent transparent
        var(--ak-popover-arrow-border-light);
    }
  }

  &[data-popper-placement*='left'] {
    ._ak-popover-arrow_18ey6o {
      right: 0;
      width: 0.7em;
      height: 2.1em;
      margin-right: 0.9em;

      &::before {
        transform: translateY(50%) rotate(-45deg);
      }
    }

    ._ak-popover-arrow-color-light_18ey6o::before {
      border-color: transparent var(--ak-popover-arrow-border-light)
        var(--ak-popover-arrow-border-light) transparent;
    }
  }
}

/* styles for irene/components/ak-radio/group/index.scss */
._ak-radio-group-root_jf2j8q {
  display: inline-flex;
  box-sizing: border-box;
}

._ak-radio-group-column_jf2j8q {
  flex-direction: column;
}

._ak-radio-group-row_jf2j8q {
  flex-direction: row;
}

/* styles for irene/components/ak-radio/index.scss */
._ak-radio-root_5892nu {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  padding: 0.4em;
  border-radius: 50%;

  &._ak-radio-hover-focus_5892nu {
    &:hover,
    &:focus {
      background-color: var(--ak-radio-hover-background);
    }
  }
}

._ak-radio_5892nu {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;

  &:disabled + ._ak-svg-radio-icon_5892nu {
    color: var(--ak-radio-color-text-disabled) !important;
  }
}

._ak-svg-radio-icon_5892nu {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  color: currentColor;
  flex-shrink: 0;
  font-size: 1.5rem !important;

  &._ak-svg-radio-icon-checked_5892nu {
    color: var(--ak-radio-color-success-main);
  }
}

/* styles for irene/components/ak-select/index.scss */
._ak-form-control_1bcyr {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

._ak-form-label_1bcyr {
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._ak-form-label-spacing_1bcyr {
  margin-top: 0.4em;
}

._ak-form-helper-text_1bcyr {
  color: var(--ak-select-form-helper-color);
  font-weight: 400;
  font-size: 0.857rem;
  text-align: left;
  margin-top: 0.2em;
  margin-right: 0.5em;
  margin-bottom: 0;
  margin-left: 0.5em;
}

._ak-error-form-helper-text_1bcyr {
  color: var(--ak-select-error-color) !important;
}

._ak-select-dropdown_1bcyr {
  background-color: var(--ak-select-dropdown-background) !important;
  border-radius: var(--ak-select-dropdown-border-radius) !important;
  box-shadow: var(--ak-select-dropdown-box-shadow) !important;
  border: 1px solid var(--ak-select-dropdown-border-color) !important;
  z-index: var(--ak-select-dropdown-zIndex) !important;

  .ember-power-select-options {
    padding: 0.5em 0;

    .ember-power-select-option {
      padding: 0.5em 1em;
      line-height: normal;
      color: var(--ak-select-option-color-text-primary);

      &[aria-current='true'] {
        background-color: var(--ak-select-option-hover-background);
        color: var(--ak-select-option-color-text-primary);
      }

      &[aria-selected='true'] {
        background-color: var(--ak-select-option-selected-background);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--ak-select-option-divider-color);
      }
    }
  }
}

._ak-select-trigger-root_1bcyr {
  font-weight: 400;
  font-size: 1rem;
  color: var(--ak-select-trigger-color-text-primary);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: var(--ak-select-trigger-border-radius);

  .ember-basic-dropdown {
    width: 100%;
  }

  ._ak-select-trigger_1bcyr {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--ak-select-trigger-border-color);
    border-radius: var(--ak-select-trigger-border-radius);
    height: 32px;

    &[aria-disabled='true'] {
      pointer-events: none;
      color: var(--ak-select-trigger-disabled-color);
      background-color: var(--ak-select-trigger-disabled-background);
      border-color: var(--ak-select-trigger-disabled-background);
    }

    .ember-power-select-status-icon {
      position: absolute;
      font-size: 4.5px;
      width: 3em;
      height: 1em;
      border: none;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin: auto;
        border-style: solid;
        border-width: 1em 1em 0 1em;
        border-color: var(--ak-select-trigger-arrow-color) transparent
          transparent transparent;
      }
    }
  }

  ._ak-select-trigger-error_1bcyr {
    border-color: var(--ak-select-error-color);

    &:focus {
      border-color: var(--ak-select-error-color);
    }
  }
}

/* styles for irene/components/ak-skeleton/index.scss */
._ak-skeleton-root_n0fox6,
._ak-skeleton-variant-rectangular_n0fox6 {
  display: block;
  background: linear-gradient(
    306deg,
    var(--ak-skeleton-linear-gradient-color-1) 19.42%,
    var(--ak-skeleton-linear-gradient-color-2) 81.49%
  );
  animation: _pulseKeyframe_n0fox6 3s ease-in-out 0.5s infinite normal none running;
}

._ak-skeleton-variant-circular_n0fox6 {
  border-radius: 50%;
}

._ak-skeleton-variant-rounded_n0fox6 {
  border-radius: 4px;
}

@keyframes _pulseKeyframe_n0fox6 {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 0.45;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

/* styles for irene/components/ak-stack/index.scss */
// Direction
._ak-stack-direction-row_har3vp,
._ak-stack-direction-row-reverse_har3vp {
  display: flex;
}

._ak-stack-direction-column_har3vp,
._ak-stack-direction-column-reverse_har3vp {
  display: flex;
  flex-direction: column;
}

._ak-stack-direction-row-reverse_har3vp {
  flex-direction: row-reverse;
}

._ak-stack-direction-column-reverse_har3vp {
  flex-direction: column-reverse;
}

// Align items
._ak-stack-alignitems-flex-start_har3vp {
  align-items: flex-start;
}

._ak-stack-alignitems-center_har3vp {
  align-items: center;
}

._ak-stack-alignitems-flex-end_har3vp {
  align-items: flex-end;
}

._ak-stack-alignitems-stretch_har3vp {
  align-items: stretch;
}

// Justify content
._ak-stack-justifycontent-start_har3vp {
  justify-content: start;
}

._ak-stack-justifycontent-flex-start_har3vp {
  justify-content: flex-start;
}

._ak-stack-justifycontent-center_har3vp {
  justify-content: center;
}

._ak-stack-justifycontent-stretch_har3vp {
  justify-content: stretch;
}

._ak-stack-justifycontent-normal_har3vp {
  justify-content: normal;
}

._ak-stack-justifycontent-space-between_har3vp {
  justify-content: space-between;
}

._ak-stack-justifycontent-space-around_har3vp {
  justify-content: space-around;
}

._ak-stack-justifycontent-space-evenly_har3vp {
  justify-content: space-evenly;
}

._ak-stack-justifycontent-flex-end_har3vp {
  justify-content: flex-end;
}

._ak-stack-justifycontent-right_har3vp {
  justify-content: right;
}

._ak-stack-justifycontent-left_har3vp {
  justify-content: left;
}

._ak-stack-justifycontent-end_har3vp {
  justify-content: end;
}

// Wrap
._ak-stack-flexwrap-nowrap_har3vp {
  flex-wrap: nowrap;
}

._ak-stack-flexwrap-wrap_har3vp {
  flex-wrap: wrap;
}

._ak-stack-flexwrap-wrap-reverse_har3vp {
  flex-wrap: wrap-reverse;
}

// Spacing
._ak-stack-spacing-0_har3vp {
  gap: 0em;
}

._ak-stack-spacing-1\/2_har3vp {
  gap: 0.25em;
}

._ak-stack-spacing-1_har3vp {
  gap: 0.5em;
}

._ak-stack-spacing-3\/2_har3vp {
  gap: 0.75em;
}

._ak-stack-spacing-2_har3vp {
  gap: 1em;
}

._ak-stack-spacing-5\/2_har3vp {
  gap: 1.25em;
}

._ak-stack-spacing-3_har3vp {
  gap: 1.5em;
}

._ak-stack-spacing-7\/2_har3vp {
  gap: 1.75em;
}

._ak-stack-spacing-4_har3vp {
  gap: 2em;
}

._ak-stack-spacing-9\/2_har3vp {
  gap: 2.25em;
}

._ak-stack-spacing-5_har3vp {
  gap: 2.5em;
}

._ak-stack-spacing-11\/2_har3vp {
  gap: 2.75em;
}

._ak-stack-spacing-6_har3vp {
  gap: 3em;
}

// Width
._ak-stack-width-1\/12_har3vp {
  width: 8.33333%;
}

._ak-stack-width-2\/12_har3vp {
  width: 16.66667%;
}

._ak-stack-width-3\/12_har3vp {
  width: 25%;
}

._ak-stack-width-4\/12_har3vp {
  width: 33.33333%;
}

._ak-stack-width-5\/12_har3vp {
  width: 41.66667%;
}

._ak-stack-width-6\/12_har3vp {
  width: 50%;
}

._ak-stack-width-7\/12_har3vp {
  width: 58.33333%;
}

._ak-stack-width-8\/12_har3vp {
  width: 66.66667%;
}

._ak-stack-width-9\/12_har3vp {
  width: 75%;
}

._ak-stack-width-10\/12_har3vp {
  width: 83.33333%;
}

._ak-stack-width-11\/12_har3vp {
  width: 91.66667%;
}

._ak-stack-width-12\/12_har3vp,
._ak-stack-width-full_har3vp {
  width: 100%;
}

._ak-stack-width-fit-content_har3vp {
  width: fit-content;
}

._ak-stack-width-auto_har3vp {
  width: auto;
}

/* styles for irene/components/ak-table/index.scss */
._ak-table-root_1jqw2e table {
  th,
  td {
    font-family: var(--font-family);
    color: var(--ak-table-color-text-primary);
    font-size: 1rem;
    vertical-align: middle;
  }

  td {
    font-weight: var(--ak-table-font-weight-regular);
  }

  th {
    font-weight: var(--ak-table-font-weight-medium);
    border-bottom: none;
  }
}

._ak-table-padding-dense_1jqw2e {
  table thead th {
    padding: 0.5em 1.2143em;
  }

  table tbody td {
    padding: 0.6em 1.2143em;
  }
}

._ak-table-padding-default_1jqw2e {
  table thead th {
    padding: 0.75em 1.2143em;
  }

  table tbody td {
    padding: 1em 1.2143em;
  }
}

._ak-table-header-color-neutral_1jqw2e table {
  thead th {
    background-color: var(--ak-table-header-background-neutral);
  }
}

._ak-table-header-color-transparent_1jqw2e table {
  thead th {
    background-color: transparent;
  }
}

._ak-table-variant-semi-bordered_1jqw2e {
  table thead tr {
    border: 1px solid;
  }

  table tbody tr {
    &:nth-last-child(2) td {
      border-bottom: none;
    }

    td {
      // reset global styles
      border-top: none;
      border-left: none;
      border-right: none;

      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
  }

  &._ak-table-header-color-neutral_1jqw2e {
    table thead tr {
      border-color: var(--ak-table-header-border-neutral);
    }
  }

  &._ak-table-header-color-transparent_1jqw2e {
    &._ak-table-border-color-light_1jqw2e table {
      thead tr {
        border-color: var(--ak-table-border-light);
      }
    }

    &._ak-table-border-color-dark_1jqw2e table {
      thead tr {
        border-color: var(--ak-table-border-dark);
      }
    }
  }
}

._ak-table-variant-full-bordered_1jqw2e {
  table thead tr {
    border-width: 1px;
    border-style: solid;

    th {
      &:not(:last-child) {
        border-right-width: 1px;
        border-right-style: solid;
      }
    }
  }

  &._ak-table-header-color-neutral_1jqw2e {
    thead tr,
    thead tr th {
      border-color: var(--ak-table-header-border-neutral);
    }
  }

  &._ak-table-header-color-transparent_1jqw2e {
    &._ak-table-border-color-light_1jqw2e table {
      thead tr,
      thead tr th {
        border-color: var(--ak-table-border-light);
      }
    }

    &._ak-table-border-color-dark_1jqw2e table {
      thead tr,
      thead tr th {
        border-color: var(--ak-table-border-dark);
      }
    }
  }
}

._ak-table-variant-full-bordered_1jqw2e table {
  tbody tr td {
    border: 1px solid;

    &:first-child {
      border-left-width: 1px;
      border-left-style: solid;
    }

    border-right-width: 1px;
    border-right-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
}

._ak-table-border-color-light_1jqw2e table {
  tbody tr td {
    border-color: var(--ak-table-border-light);
  }
}

._ak-table-border-color-dark_1jqw2e table {
  tbody tr td {
    border-color: var(--ak-table-border-dark);
  }
}

._ak-table-hoverable_1jqw2e table {
  tbody tr:hover td {
    background-color: var(--ak-table-hover-background);
  }
}

/* styles for irene/components/ak-tabs/index.scss */
._ak-tabs-container_en281b {
  border-bottom: 1px solid var(--ak-tabs-border-color);
  box-sizing: border-box;
}

/* styles for irene/components/ak-tabs/item/index.scss */
._ak-tab-item_1hbkda {
  height: 40px;
  width: fit-content;

  a,
  button {
    height: 100%;
    padding: 0em 1.143em;
    font-family: var(--ak-tabs-font-family);
    color: var(--ak-tabs-text-color-secondary);
    font-weight: var(--ak-tabs-font-weight-bold);

    &:hover {
      color: var(--ak-tabs-text-color-secondary);
    }
  }

  ._ak-tab-badge_1hbkda {
    font-size: 0.857em;
    min-width: 24px;
    height: 20px;
    padding: 0em 0.357em;
    margin-left: 0.7em;
    font-weight: var(--ak-tabs-font-weight-regular);
    border-radius: var(--ak-tabs-border-radius);
    background-color: var(--ak-tabs-neutral-background);
  }

  ._ak-tab-icon_1hbkda {
    .ak-icon {
      font-size: 1.29rem !important;
      color: var(--ak-tabs-text-color-secondary);
    }
  }

  ._active_1hbkda {
    position: relative;
    color: var(--ak-tabs-text-color-primary) !important;

    &:hover,
    &:focus {
      background-color: unset;
    }

    ._ak-tab-badge_1hbkda {
      background: var(--ak-tabs-color-primary-light);
    }

    ._ak-tab-icon_1hbkda {
      .ak-icon {
        color: var(--ak-tabs-text-color-primary);
      }
    }

    &::after {
      display: block;
      width: 100%;
      height: 3px;
      content: '';
      position: absolute;
      left: 0px;
      bottom: 0px;
      border-radius: 3px 3px 0px 0px;
      background-color: var(--ak-tabs-color-primary);
    }
  }
}

/* styles for irene/components/ak-text-field/index.scss */
._ak-form-control_1uz7ue {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

._ak-form-label_1uz7ue {
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._ak-form-label-spacing_1uz7ue {
  margin-top: 0.4em;
}

._ak-form-helper-text_1uz7ue {
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 0.857rem;
  text-align: left;
  margin-top: 0.2em;
  margin-right: 0.5em;
  margin-bottom: 0;
  margin-left: 0.5em;
}

._ak-error-form-helper-text_1uz7ue {
  color: var(--error-main) !important;
}

._ak-text-field-root_1uz7ue {
  font-weight: 400;
  font-size: 1rem;
  color: var(--text-primary);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius);
}

._ak-text-input_1uz7ue {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  box-sizing: content-box;
  background: none;
  border: 0;
  height: 1.4375em;
  margin: 0;
  display: block;
  min-width: 0;
  width: 100%;
  padding: 0.54em;
  outline: unset;

  &::placeholder {
    color: var(--text-secondary);
    font-style: normal;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--text-secondary);
    font-style: normal;
  }

  &::-ms-input-placeholder {
    color: var(--text-secondary);
    font-style: normal;
  }

  &:focus ~ ._ak-text-input-outlined_1uz7ue,
  &:focus-visible ~ ._ak-text-input-outlined_1uz7ue {
    border-width: 2px;
  }

  &:disabled {
    color: var(--text-secondary);
  }

  &:disabled ~ ._ak-text-input-outlined_1uz7ue {
    background-color: var(--disabled-background-textfield);
    border-color: var(--disabled-background-textfield);
    z-index: -1;
  }
}

._ak-error-text-input_1uz7ue {
  border-color: var(--error-main) !important;
}

._ak-text-input-outlined_1uz7ue {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0 0.54em;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: var(--border-color-2);
}

._ak-text-field-left-adornment_1uz7ue,
._ak-text-field-right-adornment_1uz7ue {
  display: inline-flex;
  align-items: center;

  .ak-icon {
    font-size: 1.25rem !important;
  }
}

._ak-text-field-left-adornment_1uz7ue {
  margin-left: 0.54em;
}

._ak-text-field-right-adornment_1uz7ue {
  margin-right: 0.54em;
}

/* styles for irene/components/ak-toggle/index.scss */
$defaultTrackHeight: 1.6875em;

@mixin toggleContainerSize($scale: 1) {
  height: $defaultTrackHeight * $scale;
  margin: 0.4em;
}

// static base css
._toggle-container_1kt16v {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  aspect-ratio: 2/1;
}

._toggle-input_1kt16v {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;

  &:checked + ._track_1kt16v::before {
    flex: auto;
  }

  &:checked + ._track_1kt16v {
    background-color: var(--ak-toggle-checked-background-color);
  }

  &:disabled + ._track_1kt16v {
    opacity: 0.5;
  }
}

._track_1kt16v {
  cursor: pointer;
  display: flex;
  background-color: var(--ak-toggle-unchecked-background-color);
  border-radius: 999px;
  height: 100%;
  width: 100%;
  padding: 5%;

  &::before, &::after {
    display: block;
    content: '';
    flex: 0;
    height: 100%;
  }

  &::before {
    transition: flex 0.4s;
  }

  &::after {
    flex: 0;
    aspect-ratio: 1/1;
    max-width: fit-content;
    border-radius: 999px;
    background-color: var(--ak-toggle-slider-background-color);
  }
}


// small switch
._toggle-container-small_1kt16v {
  @include toggleContainerSize(0.7);
}

// default switch
._toggle-container-default_1kt16v {
  @include toggleContainerSize;
}

// large switch
._toggle-container-large_1kt16v {
  @include toggleContainerSize(1.3);
}



/* styles for irene/components/ak-tooltip/index.scss */
._ak-tooltip-wrapper_2utk8u {
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
}

._ak-tooltip-container-root_2utk8u {
  position: fixed;
  inset: 0px;
  z-index: var(--ak-tooltip-zIndex);
  width: 0;
}

._ak-tooltip-root_2utk8u {
  border-radius: var(--ak-tooltip-border-radius);
  padding: 0.4em 0.8em;
  font-size: 0.857rem;
  max-width: 400px;
  margin: 0 2px;
  white-space: nowrap;
  font-weight: var(--ak-tooltip-font-weight-regular);
  font-family: var(--ak-tooltip-font-family);
  box-shadow: var(--ak-tooltip-box-shadow-2);
}

._ak-tooltip-color-light_2utk8u {
  background-color: var(--ak-tooltip-background-light);
  color: var(--ak-tooltip-color-on-light);
  border: 1px solid var(--ak-tooltip-border-light);
}

._ak-tooltip-color-dark_2utk8u {
  background-color: var(--ak-tooltip-background-dark);
  color: var(--ak-tooltip-color-on-dark);
}

._ak-tooltip-arrow-popover-root_2utk8u {
  &[data-popper-placement*='top'] ._ak-tooltip-root_2utk8u {
    margin-bottom: 0.8em;
  }

  &[data-popper-placement*='bottom'] ._ak-tooltip-root_2utk8u {
    margin-top: 0.8em;
  }

  &[data-popper-placement*='left'] ._ak-tooltip-root_2utk8u {
    margin-right: 0.8em;
  }

  &[data-popper-placement*='right'] ._ak-tooltip-root_2utk8u {
    margin-left: 0.8em;
  }
}

/* styles for irene/components/ak-typography/index.scss */
._ak-typography_jq5r3g {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  margin: 0;
  padding: 0;
}

._ak-typography-no-gutter-bottom_jq5r3g {
  margin-bottom: 0 !important;
}

._ak-typography-gutter-bottom_jq5r3g {
  margin-bottom: 0.5em !important;
}

._ak-typography-color-primary_jq5r3g {
  color: var(--primary-main);
}

._ak-typography-color-secondary_jq5r3g {
  color: var(--secondary-main);
}

._ak-typography-color-textPrimary_jq5r3g {
  color: var(--text-primary);
}

._ak-typography-color-textSecondary_jq5r3g,
._ak-typography-color-neutral_jq5r3g {
  color: var(--text-secondary);
}

._ak-typography-color-error_jq5r3g {
  color: var(--error-main);
}

._ak-typography-color-success_jq5r3g {
  color: var(--success-main);
}

._ak-typography-color-warn_jq5r3g {
  color: var(--warn-main);
}

._ak-typography-color-info_jq5r3g {
  color: var(--info-main);
}

._ak-typography-color-inherit_jq5r3g {
  color: inherit;
}

// Typography font weight
._ak-typography-font-weight-bold_jq5r3g {
  font-weight: var(--font-weight-bold) !important;
}

._ak-typography-font-weight-medium_jq5r3g {
  font-weight: var(--font-weight-medium) !important;
}

._ak-typography-font-weight-regular_jq5r3g {
  font-weight: var(--font-weight-regular) !important;
}

._ak-typography-font-weight-light_jq5r3g {
  font-weight: var(--font-weight-light) !important;
}

._ak-typography-align-left_jq5r3g {
  text-align: left;
}

._ak-typography-align-center_jq5r3g {
  text-align: center;
}

._ak-typography-align-right_jq5r3g {
  text-align: right;
}

._ak-typography-align-justify_jq5r3g {
  text-align: justify;
}

._ak-typography-align-inherit_jq5r3g {
  text-align: inherit;
}

._ak-typography-h1_jq5r3g {
  font-weight: var(--font-weight-regular);
  font-size: 2.285rem;
}

._ak-typography-h2_jq5r3g {
  font-weight: var(--font-weight-regular);
  font-size: 2rem;
}

._ak-typography-h3_jq5r3g {
  font-weight: var(--font-weight-bold);
  font-size: 1.714rem;
}

._ak-typography-h4_jq5r3g {
  font-weight: var(--font-weight-medium);
  font-size: 1.428rem;
}

._ak-typography-h5_jq5r3g {
  font-weight: var(--font-weight-bold);
  font-size: 1.143rem;
}

._ak-typography-h6_jq5r3g {
  font-weight: var(--font-weight-medium);
  font-size: 1rem;
}

._ak-typography-subtitle1_jq5r3g {
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
}

._ak-typography-subtitle2_jq5r3g {
  font-weight: var(--font-weight-medium);
  font-size: 0.928rem;
}

._ak-typography-body1_jq5r3g {
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
}

._ak-typography-body2_jq5r3g {
  font-weight: var(--font-weight-regular);
  font-size: 0.928rem;
}

._ak-typography-body3_jq5r3g {
  font-weight: var(--font-weight-regular);
  font-size: 0.7143rem;
  line-height: 1em;
}

._no-wrap_jq5r3g {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._ak-typography-underline-none_jq5r3g {
  text-decoration: none;
}

._ak-typography-underline-always_jq5r3g {
  text-decoration: underline;
}

._ak-typography-underline-hover_jq5r3g {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* styles for irene/components/app-monitoring/details/index.scss */
._app-details-container_1hz763 {
  padding-bottom: 1.3571em;

  ._app-details-wrapper_1hz763 {
    padding: 1.4286em;
    border: 1px solid var(--appmonitoring-details-border-color);
    margin-bottom: 1.5714em;

    ._overview-container_1hz763 {
      height: fit-content;
      margin-bottom: 1.4286em;

      ._overview-icons_1hz763 {
        margin-bottom: 0.857em;

        ._platform-icon_1hz763,
        ._project-icon_1hz763 {
          border: 1px solid var(--appmonitoring-details-border-color);
          border-radius: var(--appmonitoring-border-radius);
          width: 2.714em;
          height: 2.714em;
        }

        img {
          width: 1.7143em;
          height: 1.7143em;
        }

        ._platform-icon_1hz763 {
          i::before {
            font-size: 1.714rem;
          }
        }

        ._platform-android_1hz763 {
          color: var(--appmonitoring-platform-android-color);
        }

        ._platform-apple_1hz763 {
          color: var(--appmonitoring-platform-ios-color);
        }
      }

      ._monitoring-toggle-container_1hz763 {
        border-left: 1px solid var(--appmonitoring-details-toggle-border-color);
        padding-left: 1.214em;

        ._monitoring-toggle_1hz763 {
          margin: 0em;
        }
      }
    }

    ._monitoring-details-container_1hz763 {
      width: 100%;

      ._monitoring-details-group_1hz763 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 400px);

        &:first-of-type {
          border-bottom: 1px solid var(--appmonitoring-details-border-color);
          padding-bottom: 0.6428em;
          margin-bottom: 0.6428em;
        }
      }

      ._details-header_1hz763,
      ._details-value_1hz763 {
        letter-spacing: -0.02857em;
      }

      ._details-header_1hz763 {
        display: flex;
        min-width: 152px;
        margin-right: 1.4286em;
      }

      ._details-value_1hz763 {
        color: var(--appmonitoring-color-black);
      }
    }
  }

  ._app-details-tabs_1hz763 {
    width: 100%;
  }
}

/* styles for irene/components/app-monitoring/empty/index.scss */
._empty-container_j7tfp2 {
  margin-top: 4.625em;
  margin-bottom: 4.625em;

  ._empty-state-vector_j7tfp2 {
    margin-bottom: 1.5625em;
  }

  ._body-text_j7tfp2 {
    text-align: center;
    max-width: 302px;
  }
}

/* styles for irene/components/app-monitoring/index.scss */
._app-monitoring-container_1np8fu {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
}

/* styles for irene/components/app-monitoring/loading/index.scss */
._loading_1dlns0 {
  border: 1px solid var(--appmonitoring-border-color);
  border-radius: var(--appmonitoring-border-radius);
  width: 100%;
  min-height: 35em;

  ._loading-state-vector_1dlns0 {
    margin-bottom: 1.5625em;
  }

  ._loading-text_1dlns0 {
    text-align: center;
    max-width: 302px;
  }
}

/* styles for irene/components/app-monitoring/settings/index.scss */
._settings-block_1qtcxt {
  padding: 1em 1.5em;
  border-radius: var(--appmonitoring-border-radius);
  border: 1px solid var(--appmonitoring-border-color);
  box-sizing: border-box;

  ._settings-description_1qtcxt {
    color: var(--appmonitoring-settings-block-description-text-color);
    max-width: 900px;
  }
}

/* styles for irene/components/app-monitoring/status/index.scss */
._status_1rko5d {
  text-transform: uppercase;
  width: fit-content;

  & > span {
    font-weight: var(--appmonitoring-font-weight-medium);
  }
}

/* styles for irene/components/app-monitoring/table/application/index.scss */
._app-name_1e77gw,
._app-namespace_1e77gw {
  width: 100%;
  letter-spacing: -0.02857em;
}

/* styles for irene/components/app-monitoring/table/index.scss */
._app-monitoring-table-container_1sycuc {
  margin-top: 1.25em;

  ._app-monitoring-table-row_1sycuc {
    cursor: pointer;
  }
}

/* styles for irene/components/app-monitoring/table/last-scanned/index.scss */
._last-scanned-version_1hfp3l {
  letter-spacing: -0.02857em;
}

/* styles for irene/components/app-monitoring/table/platform/index.scss */
// Icon styles
._platform-android_1xgbkz {
  color: var(--appmonitoring-platform-android-color);
}

._platform-apple_1xgbkz {
  color: var(--appmonitoring-platform-ios-color);
}

/* styles for irene/components/app-monitoring/table/status/index.scss */
._last-sync-date_1mcemk {
  letter-spacing: -0.0286em;
  margin-top: 0.4375em;
  color: var(--appmonitoring-table-status-last-sync-color);
  font-size: 0.928em;

  span i {
    font-size: 1em;
  }
}
/* styles for irene/components/app-monitoring/table/store-version/index.scss */
._production-version_o5936i {
  letter-spacing: -0.0286em;
  color: var(--appmonitoring-color-black);

  ._multi-geo-tooltip-header_o5936i {
    margin-bottom: 0.2857em;
  }

  ._multi-geo-icon_o5936i {
    margin-left: 0.2857em;
  }
}

/* styles for irene/components/app-monitoring/version-table/actions-header/index.scss */
._tooltip-content_1bhv2d {
  width: 260px;
  padding: 0.5em;
  box-sizing: border-box;
  white-space: normal;
}

/* styles for irene/components/app-monitoring/version-table/actions/index.scss */
._horizontal-divider_4mb3ig {
  display: block;
  height: 17px;
  width: 1px;
  background-color: var(--neutral-grey-300);
  margin: 0 0.5em;
}

/* styles for irene/components/app-monitoring/version-table/index.scss */
._app-details-table-wrapper_1n5kwk {
  overflow-x: auto;

  ._app-details-table-container_1n5kwk {
    min-width: 1150px;

    ._app-details-table_1n5kwk {
      td:first-child {
        border-left: none !important;
      }

      td:last-child {
        border-right: none !important;
      }

      ._table-row_1n5kwk {
        height: 5.5714em !important;
      }
    }
  }
}

/* styles for irene/components/dynamic-scan/modal/index.scss */
._dynamic-scan-modal-body_imocof {
  width: 650px;
}

._dynamic-scan-modal-alert_imocof {
  background-color: var(--dynamic-scan-modal-alert-background-color);
  color: var(--dynamic-scan-modal-alert-text-color);
  border: 1px solid var(--dynamic-scan-modal-alert-border-color);
  border-radius: var(--dynamic-scan-modal-border-radius);
}

._device-settings-warning_imocof {
  color: var(--dynamic-scan-modal-device-settings-warning-text-color);
  line-height: 1.3;
}

._bordered-box_imocof {
  border: 1px solid var(--dynamic-scan-modal-border-color);
  border-radius: var(--dynamic-scan-modal-border-radius);
  box-sizing: border-box;

  ._bordered-box-item_imocof {
    padding: 0.5em 1em;

    &:not(:last-child) {
      border-bottom: 1px solid var(--dynamic-scan-modal-border-color);
    }
  }
}

/* styles for irene/components/file-chart/index.scss */
._severity-indicator_wdasff {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

._severity-critical_wdasff {
  background-color: var(--file-chart-severity-level-color-critical);
}

._severity-high_wdasff {
  background-color: var(--file-chart-severity-level-color-high);
}

._severity-medium_wdasff {
  background-color: var(--file-chart-severity-level-color-medium);
}

._severity-low_wdasff {
  background-color: var(--file-chart-severity-level-color-low);
}

._severity-passed_wdasff {
  background-color: var(--file-chart-severity-level-color-passed);
}

._severity-none_wdasff {
  background-color: var(--file-chart-severity-level-color-none);
}

/* styles for irene/components/file-cleanup/styles.scss */
._cleanup-descripton_vbtgxp {
  padding: 12px 0;
  font-size: .98em;
}

._cleanup-title_vbtgxp {
  font-weight: 700;
  font-size: 1.2em;
}

._files-count-input_vbtgxp {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 12px;

  ._error-input_vbtgxp {
    border-color: red;
  }

  ._file-count-err_vbtgxp {
    color: red;
    font-size: 12px;
    grid-column: 2;
  }

  ._save-input_vbtgxp {
    border: 1px solid;
    height: 41px;
    padding: 0 8px;
    border-radius: 4px;

    i {
      line-height: 41px;
    }
  }
}

._last-activity_vbtgxp {
  font-weight: 400;
}

/* styles for irene/components/file-compare/analysis-details/index.scss */
._analysis-details-root_1ajx64 {
  width: 50%;
  border: 1px solid var(--file-compare-analysis-details-border-color);
  border-top: none;
  border-bottom-width: 1px;

  ._analysis-static-content_1ajx64 {
    display: flex;
    flex-direction: column;
    gap: 0.75em;

    code {
      @extend .scrollable-box;
    }

    h1 {
      font-size: 1.75rem;
      margin: 0.4em 0 !important;
    }

    h3 {
      margin: 0.4em 0 !important;
    }

    ul {
      list-style: unset;
    }

    ul,
    ol {
      margin: 0.25em 1em;

      li {
        margin: 0.25em 1em;
      }
    }
  }

  &:first-of-type {
    border-left-width: 1px;
  }

  &:last-of-type {
    border-right-width: 1px;
  }

  ._analysis-content-title_1ajx64 {
    width: 185px;
  }

  ._analysis-content-value_1ajx64 {
    width: calc(100% - 185px);
  }
}

/* styles for irene/components/file-compare/analysis-details/regulatory-content/index.scss */
._regulatory-content-title_xmsjpd {
  width: 185px;
}

._regulatory-content-value_xmsjpd {
  width: calc(100% - 185px);

  ._regulatory-content-value-description_xmsjpd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
}

/* styles for irene/components/file-compare/compare-list/index.scss */
._file-compare-list-root_1p0ts8 {
  background-color: var(--file-compare-compare-list-wrapper-background-color);
  min-height: calc(100vh - 70px);

  ._file-compare-list-files-wrapper_1p0ts8 {
    margin-top: 1.4286em;
    margin-bottom: 5.7143em;
    padding: 0em 0.9286em;
  }

  ._file-compare-list-file-overviews_1p0ts8 {
    display: grid;
    justify-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 1.4286em;
  }
}

/* styles for irene/components/file-compare/compare-list/skeleton-loader/index.scss */
._header-section_7hhvhx {
  width: 100%;
  border: 1px solid var(--file-compare-list-skeleton-loader-border-color);
  background-color: var(--file-compare-list-skeleton-loader-background-color);
  box-sizing: border-box;

  &:first-of-type {
    border-bottom: none;
  }
}

._breadcrumbs-seperator_7hhvhx {
  color: var(--file-compare-list-skeleton-loader-breadcrumbs-seperator-color);
}

._divider_7hhvhx {
  width: 1px;
  height: 26px;
  background-color: var(
    --file-compare-list-skeleton-loader-divider-background-color
  );
}

/* styles for irene/components/file-compare/empty/index.scss */
._empty-container_10bxd8 {
  margin-top: 4.625em;
  margin-bottom: 4.625em;

  ._empty-state-vector_10bxd8 {
    margin-bottom: 1.5625em;
  }

  ._body-text_10bxd8 {
    text-align: center;
    max-width: 302px;
  }
}

/* styles for irene/components/file-compare/file-overview/file-details/index.scss */
._file-overview-file-details_8dr341 {
  padding: 0.6429em 1.1429em;
  border-bottom: 1px solid var(--file-compare-file-overview-border-color-light);
}

._file-overview-versions-tags_8dr341 {
  padding: 1.1429em;
  padding-top: 0.5714em;

  ._file-version-container_8dr341 {
    padding: 0.5714em 0em;
    border-bottom: 1px solid
      var(--file-compare-file-overview-border-color-light);

    ._file-version-title_8dr341 {
      min-width: 140px;
    }
  }

  ._file-tags-container_8dr341 {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 0.3571em;
    height: 26px;
    margin-top: 0.9286em;
  }
}

// Icon styles
._platform-android_8dr341 {
  color: var(--file-compare-file-overview-color-android);
}

._platform-apple_8dr341 {
  color: var(--file-compare-file-overview-color-ios);
}

._store-logo-container_8dr341 {
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid var(--file-compare-store-logo-container-background-color);
  }
}

._appstore-logo-vector_8dr341 {
  height: 16px;
  width: 16px;
}

/* styles for irene/components/file-compare/file-overview/header/index.scss */
._file-overview-header_17zybp {
  padding: 0.9286em 1.1429em;
  gap: 0.8714em;
  background-color: var(--file-compare-file-overview-background-grey);
  border-bottom: 1px solid var(--file-compare-file-overview-border-color-dark);

  ._file-icon_17zybp {
    width: 38px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-image: none;
      overflow: hidden;
    }
  }

  ._open-in-new-tab-link-class_17zybp {
    display: inline-flex;
  }

  ._open-in-new-tab-link_17zybp:hover::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: var(
      --file-compare-file-overview-open-in-new-icon-background-color
    );
  }
}

/* styles for irene/components/file-compare/file-overview/index.scss */
._file-compare-file-overview_1o3p5o {
  border-radius: var(--file-compare-file-overview-border-radius);
  border: 1px solid var(--file-compare-file-overview-border-color-dark);
  background-color: var(--file-compare-file-overview-background-white);
}

/* styles for irene/components/file-compare/file-overview/scan-statuses/index.scss */
._file-overview-statuses_n18sc8 {
  background: var(--file-compare-file-overview-background-grey);
  border: 1px solid var(--file-compare-file-overview-border-color-dark);
  border-left: none;
  border-right: none;
}

/* styles for irene/components/file-compare/header/index.scss */
._file-compare-header-container_1vzv1a {
  background-color: var(--file-compare-header-white-background-color);
  padding: 0em 0.7143em;
  position: sticky;
  top: -0.5em;
  z-index: 10;

  ._file-compare-header-breadcrumbs_1vzv1a {
    padding: 1.2857em 0em;
  }

  ._file-compare-header-project-overview_1vzv1a {
    background-color: var(--file-compare-header-white-background-color);
    border: 1px solid var(--file-compare-header-dark-border-color);
    padding: 1em 1.5em;

    ._divider_1vzv1a {
      width: 1px;
      height: 26px;
      background-color: var(--file-compare-header-divider-background-color);
    }

    ._file-icon_1vzv1a {
      width: 24px;
      height: 24px;
      padding: 0.1786em;
      border-radius: var(--file-compare-header-file-icon-border-radius);
      border: 1px solid var(--file-compare-header-file-icon-border-color);

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--file-compare-header-file-icon-border-radius);
        object-fit: contain;
        background-image: none;
        overflow: hidden;
      }
    }
  }

  ._file-compare-header-info_1vzv1a {
    padding: 1.4286em;
    padding-bottom: 1em;
    border: 1px solid var(--file-compare-header-dark-border-color);
    border-top: none;
    box-shadow: var(--file-compare-header-info-box-shadow);
    background-color: var(--file-compare-header-white-background-color);
  }
}

._files-overview-container_1vzv1a {
  margin-left: 0.7143em;
  margin-right: 0.7143em;
  padding: 1.4286em;
  border: 1px solid var(--file-compare-header-files-overview-border-color);
  border-top: none;
  background-color: var(--file-compare-header-white-background-color);
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(475px, 1fr));
  gap: 1.4286em;
}

._platform-android_1vzv1a {
  color: var(--file-compare-header-platform-android-color);
}

._platform-apple_1vzv1a {
  color: var(--file-compare-header-platform-ios-color);
}
/* styles for irene/components/file-compare/index.scss */
._file-compare-tabs_1313f3 {
  position: sticky;
  margin-top: 1.4286em;
  top: calc(200px - 0.5em);
  z-index: 10;
  background: var(--file-compare-tabs-background-color);
}

/* styles for irene/components/file-compare/loader/index.scss */
._loading_1ce45z {
  border: 1px solid var(--appmonitoring-border-color);
  border-radius: var(--appmonitoring-border-radius);
  width: 100%;
  min-height: 35em;

  ._loading-state-vector_1ce45z {
    margin-bottom: 1.5625em;
  }

  ._loading-text_1ce45z {
    text-align: center;
    max-width: 302px;
  }
}

/* styles for irene/components/file-compare/not-found/index.scss */
._file-compare-not-found_l3ri9 {
  height: calc(100vh - 70px);
}

/* styles for irene/components/file-compare/table/index.scss */
._file-compare-table_1rtg5c {
  table {
    background-color: transparent;
  }

  tr:first-of-type:hover td {
    background-color: unset;
  }

  tr:first-of-type td {
    cursor: default;
  }

  td,
  th {
    border-right: none !important;
    border-left: none !important;
  }

  ._table-row_1rtg5c td {
    padding-top: 0em !important;
    padding-bottom: 0em !important;
    height: 4.4286em !important;
    cursor: pointer;
  }
}

/* styles for irene/components/file-compare/table/risk-type/index.scss */
._first-row_1pyu8z {
  position: relative;
}

._risk-type-file-ids_1pyu8z {
  gap: 0.35714em;

  & * {
    letter-spacing: -0.01636em;
  }
}

._vulnerability-row-item_1pyu8z,
._first-row_1pyu8z {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 4em;
}

._file-compare-arrow-left-icon_1pyu8z {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* styles for irene/components/file-compare/vulnerability-details/index.scss */
._file-compare-vulnerability-details-header-wrapper_pn4edw {
  background-color: var(
    --file-compare-vulnerability-details-header-wrapper-background-color
  );
  position: sticky;
  top: calc(200px - 0.5em);
  z-index: 1;

  ._file-compare-vulnerability-details-header_pn4edw {
    padding: 1em;
    background-color: var(
      --file-compare-vulnerability-details-white-background
    );
    border: 1px solid var(--file-compare-vulnerability-details-border-color);
  }
}

._file-compare-vulnerability-details-empty-data_pn4edw {
  border: 1px solid var(--file-compare-vulnerability-details-border-color);
  border-top: none;
  padding: 4.4286em 1em;
}

._file-compare-vulnerability-details-content_pn4edw {
  background-color: var(--file-compare-vulnerability-details-white-background);

  & > *:first-child {
    border-right-width: 0.5px;
  }

  & > *:last-child {
    border-left-width: 0.5px;
  }
}

/* styles for irene/components/file-details/insights-wrapper/index.scss */
._bordered-box_10rttw {
  width: 100%;
  border: 1px solid var(--file-details-insights-wrapper-border-color);
  background-color: var(--file-details-insights-wrapper-background-color);
  box-sizing: border-box;
}

/* styles for irene/components/file-details/key-insights/index.scss */
._key-insights-root_3iegv9 {
  width: 100%;
  border: 1px solid var(--file-details-key-insights-border-color);
  box-sizing: border-box;

  ._key-insights-header_3iegv9 {
    padding: 0.75em 1.5em;
    border-bottom: 1px solid var(--file-details-key-insights-border-color);
  }
}

._insights-container_3iegv9 {
  display: flex;
  gap: 1em;

  ._insights-item_3iegv9:not(:last-child) {
    border-right: 1px solid var(--file-details-key-insights-border-color);
    padding-right: 2em;
  }
}

._ak-link-btn_3iegv9 {
  padding: 0.5em 0.7em;
  background-color: var(--file-details-key-insights-link-btn-background-color);
  border-radius: var(--file-details-key-insights-border-radius);
  color: var(--file-details-key-insights-link-btn-contrast-text-color);

  &:hover,
  &:focus {
    color: var(--file-details-key-insights-link-btn-contrast-text-color);
    background-color: var(--file-details-key-insights-link-btn-hover-color);
  }
}

/* styles for irene/components/file-details/scan-actions/api-scan/captured-apis/index.scss */
._bordered-box_yk4d09 {
  padding: 1.25em;
  border: 1px solid
    var(--file-details-scan-actions-api-scan-captured-apis-border-color);
  box-sizing: border-box;
}

._loading-container_yk4d09 {
  padding: 1.5em;
  height: 150px;
  border-radius: var(
    --file-details-scan-actions-api-scan-captured-apis-border-radius
  );
  border: 1px solid
    var(--file-details-scan-actions-api-scan-captured-apis-border-color);
  box-sizing: border-box;
}

/* styles for irene/components/file-details/scan-actions/api-scan/captured-apis/overview/index.scss */
._api-endpoint-container_f1s3yt {
  margin-top: 0.4em;

  ._api-endpoint_f1s3yt {
    word-break: break-all;
    white-space: normal;
  }
}

/* styles for irene/components/file-details/scan-actions/api-scan/index.scss */
._api-scan-modal-alert_1y5fhy {
  padding: 1em;
  background-color: var(
    --file-details-scan-actions-api-scan-alert-background-color
  );
  color: var(--file-details-scan-actions-api-scan-alert-text-color);
  border: 1px solid var(--file-details-scan-actions-api-scan-alert-border-color);
  border-radius: var(--file-details-scan-actions-api-scan-border-radius);
}

/* styles for irene/components/file-details/scan-actions/index.scss */
._scan-actions-root_73wvzp {
  width: 100%;
  border: 1px solid var(--file-details-scan-actions-border-color);
  background-color: var(--file-details-scan-actions-background-color);
  box-sizing: border-box;

  ._scan-actions-header_73wvzp {
    padding: 0.75em 1.5em;
  }
}

/* styles for irene/components/file-details/scan-actions/manual-scan/basic-info/index.scss */
._basic-info-group_1ty5g7 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;
  box-sizing: border-box;

  ._basic-info-label_1ty5g7 {
    flex: 1;
  }

  ._basic-info-input-container_1ty5g7 {
    flex: 1;
    max-width: 200px;
  }
}

/* styles for irene/components/file-details/scan-actions/manual-scan/login-details/index.scss */
._login-details-group_1ueggc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;
  box-sizing: border-box;

  ._login-details-label_1ueggc {
    flex: 1;
  }

  ._login-details-input-container_1ueggc {
    flex: 1;
    max-width: 200px;
  }
}

/* styles for irene/components/file-details/scan-actions/manual-scan/vpn-details/index.scss */
._vpn-details-group_lalcey {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;
  box-sizing: border-box;

  ._vpn-details-label_lalcey {
    flex: 1;
  }

  ._vpn-details-input-container_lalcey {
    flex: 1;
    max-width: 200px;
  }
}

/* styles for irene/components/file-details/severity-level/index.scss */
._severity-level-root_1adn9m {
  width: 100%;
  border: 1px solid var(--file-details-severity-level-border-color);
  box-sizing: border-box;

  ._severity-level-header_1adn9m {
    padding: 0.75em 1.5em;
    border-bottom: 1px solid var(--file-details-severity-level-border-color);
  }
}

._severity-indicator_1adn9m {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

._severity-critical_1adn9m {
  background-color: var(--file-details-severity-level-color-critical);
}

._severity-high_1adn9m {
  background-color: var(--file-details-severity-level-color-high);
}

._severity-medium_1adn9m {
  background-color: var(--file-details-severity-level-color-medium);
}

._severity-low_1adn9m {
  background-color: var(--file-details-severity-level-color-low);
}

._severity-passed_1adn9m {
  background-color: var(--file-details-severity-level-color-passed);
}

._severity-none_1adn9m {
  background-color: var(--file-details-severity-level-color-none);
}

/* styles for irene/components/file-details/skeleton-loader/index.scss */
._box-section-root_1lj3ei {
  width: 100%;
  border: 1px solid var(--file-details-skeleton-loader-border-color);
  background-color: var(--file-details-skeleton-loader-background-color);
  box-sizing: border-box;

  ._box-section-header_1lj3ei,
  ._box-section-item_1lj3ei {
    padding: 0.75em 1.5em;
  }
}

/* styles for irene/components/file-details/summary/app-platform/index.scss */
._app-platform-icon-apple_1matsu {
  color: var(--file-details-summary-app-platform-color-ios);
}

._app-platform-icon-android_1matsu {
  color: var(--file-details-summary-app-platform-color-android);
}

._app-platform-icon_1matsu {
  font-size: 1.25rem;
  vertical-align: unset;
}

/* styles for irene/components/file-details/summary/file-tags/index.scss */
._add-tag-btn_cal9j7 {
  height: 32px;
}

._add-tag-text-field-root_cal9j7 {
  height: 32px;
}

/* styles for irene/components/file-details/summary/index.scss */
._file-summary-root_hkabs6 {
  width: 100%;
  border: 1px solid var(--file-details-summary-border-color);
  background-color: var(--file-details-summary-background-color);
  box-sizing: border-box;

  ._app-logo-container_hkabs6 {
    width: 53px;
    height: 53px;
    padding: 0.5em;
    border-radius: var(--file-details-summary-border-radius);
    border: 1px solid var(--file-details-summary-border-color);
    box-sizing: border-box;

    img {
      object-fit: contain;
      background-image: none;
      overflow: hidden;
    }
  }

  ._file-summary-padding_hkabs6 {
    padding: 0.75em 0;
  }
}

._file-more-menu-item-group_hkabs6 {
  padding: 0.5em 1em 0.75em 1em;

  &:not(:first-child) {
    padding-top: 1em;
  }
}

._store-logo-container_hkabs6 {
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(
      --file-details-summary-store-logo-container-background-color
    );
    border: 1px solid
      var(--file-details-summary-store-logo-container-border-color);
  }
}

._appstore-logo-vector_hkabs6 {
  height: 16px;
  width: 16px;
}

/* styles for irene/components/file-details/vulnerability-analysis-details/edit-analysis-button/index.scss */
._edit-analysis-modal-header_g4oqjd {
  width: 600px;
  position: relative;
  padding: 1.5em 1.2em;
  background-color: var(
    --file-details-vulnerability-analysis-details-edit-analysis-button-background-main
  );
  box-shadow: var(
    --file-details-vulnerability-analysis-details-edit-analysis-button-box-shadow
  );
  box-sizing: border-box;
}

._edit-analysis-modal-body_g4oqjd {
  padding: 1.5em 1.2em;
  background-color: var(
    --file-details-vulnerability-analysis-details-edit-analysis-button-background-main
  );
}

/* styles for irene/components/file-details/vulnerability-analysis-details/index.scss */
._analysis-details-root_1nv92z {
  margin: -0.5em;

  ._analysis-details-header_1nv92z {
    background-color: var(
      --file-details-vulnerability-analysis-details-background-main
    );
    box-shadow: var(
      --file-details-vulnerability-analysis-details-header-box-shadow
    );
    padding: 1.5em;
    box-sizing: border-box;
    position: sticky;
    top: -0.5em;
    z-index: calc(
      var(--file-details-vulnerability-analysis-details-zIndex) - 1
    );
  }

  ._analysis-section_1nv92z:not(:last-child) {
    width: 100%;
    padding-bottom: 1.5em;
    border-bottom: 1px solid
      var(--file-details-vulnerability-analysis-details-divider-color);
  }

  ._analysis-content_1nv92z {
    max-width: 600px;
  }

  ._analysis-static-content_1nv92z {
    display: flex;
    flex-direction: column;
    gap: 0.75em;

    h1 {
      font-size: 1.75rem;
      margin: 0.4em 0 !important;
    }

    h3 {
      margin: 0.4em 0 !important;
    }

    ul {
      list-style: unset;
    }

    ul,
    ol {
      margin: 0.25em 1em;

      li {
        margin: 0.25em 1em;
      }
    }
  }

  ._analysis-content-title_1nv92z {
    min-width: 180px;
    max-width: 180px;
  }

  ._analysis-content-value_1nv92z {
    max-width: 460px;
  }

  ._vulnerabiliy-finding-container_1nv92z {
    max-width: 600px;
    width: 100%;
    max-height: 300px;
    overflow: scroll;
    text-overflow: clip;
    padding: 1em 0.75em;
    margin: 1em 0 1em;
    background-color: var(
      --file-details-vulnerability-analysis-details-vul-finding-background-color
    );
    border: 1px solid
      var(
        --file-details-vulnerability-analysis-details-vul-finding-border-color
      );
    border-radius: var(
      --file-details-vulnerability-analysis-details-border-radius
    );

    ._vulnerabiliy-finding-description_1nv92z {
      background-color: unset;
      font-family: var(
        --file-details-vulnerability-analysis-details-vul-finding-font-family
      );
      color: var(--file-details-vulnerability-analysis-details-color-primary);
      white-space: pre-line;
      width: max-content;
      line-height: normal;
    }
  }
}

/* styles for irene/components/file-details/vulnerability-analysis-details/regulatory-content/index.scss */
._regulatory-content-title_1e95qn {
  min-width: 180px;
  max-width: 180px;
}

._regulatory-content-value_1e95qn {
  max-width: 460px;
}

/* styles for irene/components/file-details/vulnerability-analysis/index.scss */
._vulnerability-header_5a117l {
  border: 1px solid var(--file-details-vulnerability-analysis-border-color);
  background-color: var(--file-details-vulnerability-analysis-background-color);
  box-sizing: border-box;
  padding: 1em;
  margin: 1em;
}

._vulnerability-type-filter-select-trigger_5a117l {
  height: 35px !important;
}

._empty-container_5a117l {
  padding: 2.5em;
  height: 300px;
  border: 1px solid var(--file-details-vulnerability-analysis-border-color);
  background-color: var(--file-details-vulnerability-analysis-background-color);
  box-sizing: border-box;
}

/* styles for irene/components/file-list/index.scss */
._file-list-root_q7v9r9 {
  background-color: var(--file-list-root-background-color);
  min-height: calc(100vh - 70px);

  ._file-list-compare-header-chip_q7v9r9 {
    height: 30px;

    &._selected-file-chip_q7v9r9 {
      border-style: dashed !important;
      color: var(--file-list-selected-file-chip-color);
      background-color: var(--file-list-selected-file-chip-background);
    }
  }

  ._file-list-wrapper_q7v9r9 {
    margin-top: 1.4286em;
    margin-bottom: 1em;
    padding: 0em 0.9286em;
  }

  ._file-list-container_q7v9r9 {
    display: grid;
    justify-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 1.4286em;
  }
}

/* styles for irene/components/file-overview/index.scss */
._card-header_1q58rd {
  background-color: var(--neutral-grey-100);
  padding: 1em;
  box-sizing: border-box;
}

._app-information_1q58rd {
  background-color: var(--neutral-grey-50);
  padding: 0.2em 1em;
  border: 1px solid var(--neutral-grey-100);
  box-sizing: border-box;

  ._file-id-text_1q58rd {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 0.75em;
    line-height: 1.4;
    color: var(--neutral-grey-800);

    ._file-in-active-icon_1q58rd {
      font-size: 13px;
    }
  }
}

._file-tags-container_1q58rd {
  overflow-x: auto;
  min-height: 26px;
}

._store-logo-container_1q58rd {
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(
      --file-overview-store-logo-container-background-color
    );
    border: 1px solid var(--file-overview-store-logo-container-border-color);
  }
}

._appstore-logo-vector_1q58rd {
  height: 16px;
  width: 16px;
}

/* styles for irene/components/file/report-drawer/sbom-reports/sample/index.scss */
._sample-report-description_f44ies {
  width: 100%;
  border: 1px solid var(--file-report-dr-sbom-reports-border-color);
  border-radius: var(--file-report-dr-sbom-reports-border-radius);
}

/* styles for irene/components/file/report-drawer/va-reports/index.scss */
._file-report-va-reports-drawer_1g1a1 {
  ._generate-container_1g1a1 {
    width: 100%;
    border: 1px solid var(--file-report-dr-va-reports-border-color);
    border-radius: var(--file-report-dr-va-reports-border-radius);
    padding: 1.5em;
  }

  ._report-gen-progress-indicator_1g1a1 {
    background-color: var(
      --file-report-dr-va-reports-gen-progress-indicator-bg
    );
  }
}

/* styles for irene/components/github-account/index.scss */
._bordered-box_m5ywdh {
  display: flex;
  max-width: 800px;
  border: 1px solid var(--github-account-border-color);
  border-radius: var(--github-account-border-radius);
  box-sizing: border-box;

  ._integration-logo-container_m5ywdh {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-right: 1px solid var(--github-account-border-color);
    padding: 1em;

    img {
      width: 40px;
    }
  }

  ._integration-account-container_m5ywdh {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
  }
}

/* styles for irene/components/home-page/organization-dashboard/header/index.scss */
._navbar-root_2sv7ps {
  padding: 0.65em 1em;
  background-color: var(--organization-dashboard-header-background-color);
  color: var(--organization-dashboard-header-text-color);
  max-height: 4em;

  ._navbar-btn_2sv7ps {
    color: var(--organization-dashboard-header-text-color);
    background-color: unset;

    .ak-icon {
      font-size: 1.5rem !important;
    }

    :hover {
      color: var(--organization-dashboard-header-text-color);
      background-color: unset;
    }
  }
}

/* styles for irene/components/home-page/organization-dashboard/index.scss */
._organization-dashboard-root_1e7mbh {
  width: 100%;
  display: grid;
  grid-template-areas:
    "sidenav topnav"
    "sidenav main";
  height: 100vh;
  grid-template-rows: 4em auto;

  &._expanded_1e7mbh, &._collapsed_1e7mbh {
    transition: grid-template-columns 0.2s ease-in-out;
  }

  &._collapsed_1e7mbh {
    grid-template-columns: 56px auto;
  }

  &._expanded_1e7mbh {
    grid-template-columns: 250px auto;
  }

  ._dashboard-main_1e7mbh {
    grid-area: main;
    max-height: 100vh;
    padding: 0.5em;
    box-sizing: border-box;
    overflow-y: auto;
  }

  ._dashboard-header_1e7mbh {
    grid-area: topnav;
  }

  ._dashboard-sidenav_1e7mbh {
    grid-area: sidenav;
  }
}

/* styles for irene/components/home-page/organization-dashboard/side-nav/index.scss */
._side-menu-root_1u0ueu {
  width: 100%;
  height: 100vh;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  box-sizing: border-box;
  z-index: var(--organization-dashboard-side-nav-zIndex);
  background-color: var(--organization-dashboard-side-nav-bg-color);
  box-shadow: var(--organization-dashboard-side-nav-box-shadow);
  border-right: var(--organization-dashboard-side-nav-border);
  display: flex;
  flex-direction: column;

  &._expanded_1u0ueu {
    ._divider_1u0ueu {
      width: 90%;
    }
  }

  &._collapsed_1u0ueu {
    ._divider_1u0ueu {
      width: 75%;
    }

    ._scroll-container_1u0ueu {
      display: flex;
      flex: 1;
    }

    ._side-menu-list_1u0ueu {
      margin: auto;
    }
  }

  ._app-logo_1u0ueu {
    margin: 3em auto;
    max-width: 56%;
    max-height: 9em;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  ._favicon-logo_1u0ueu {
    max-width: 35px;
    max-height: 35px;
    margin: 0.5em auto 1em auto;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  ._side-menu-list_1u0ueu {
    width: 100%;
    align-items: center;
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  ._scroll-container_1u0ueu {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ._menu-item-tooltip_1u0ueu {
    display: flex;
    width: 100%;
    padding: 0.6em 1em;
    justify-content: center;
  }

  ._menu-item-link_1u0ueu {
    display: flex;
    width: 100%;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(
        --organization-dashboard-side-nav-item-hover-background
      ) !important;
    }

    &._active_1u0ueu {
      position: relative;
      background-color: var(
        --organization-dashboard-side-nav-item-active-background
      );

      &::before {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background-color: var(
          --organization-dashboard-side-nav-item-active-border-color
        );
        position: absolute;
        left: 0;
        top: 0;
      }

      ._menu-item-text_1u0ueu {
        font-weight: var(--organization-dashboard-side-nav-font-weight-medium);
      }

      ._menu-item-badge_1u0ueu {
        height: 18px !important;
        background-color: var(
          --organization-dashboard-side-nav-item-active-badge-background
        );

        span {
          color: var(
            --organization-dashboard-side-nav-item-active-badge-text-color
          );
          font-weight: var(
            --organization-dashboard-side-nav-font-weight-medium
          );
          opacity: unset;
        }
      }
    }

    ._menu-item-badge_1u0ueu {
      height: 18px !important;
      background-color: var(
        --organization-dashboard-side-nav-item-badge-background
      );

      span {
        color: var(--organization-dashboard-side-nav-item-badge-text-color);
        font-weight: var(--organization-dashboard-side-nav-font-weight-medium);
        opacity: 0.4;
      }
    }
  }

  ._menu-item-icon_1u0ueu,
  ._menu-item-text_1u0ueu {
    color: var(--organization-dashboard-side-nav-text-color) !important;
  }

  ._submission-container_1u0ueu {
    height: 100%;
  }

  ._lower-menu_1u0ueu {
    display: flex;
    flex-direction: column;

    ._pendo-icon_1u0ueu {
      height: 18px;
      width: 18px;
      position: absolute;
    }

    ._pendo-ak-icon_1u0ueu {
      position: relative;
    }

    ._rotated-icon_1u0ueu {
      transform: rotate(180deg);
    }

    ._lower-menu-chat_1u0ueu {
      color: var(--organization-dashboard-side-nav-text-primary) !important;
    }
  }

  ._divider_1u0ueu {
    align-self: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  ._no-hover_1u0ueu {
    &:hover,
    &:focus {
      background-color: transparent !important;
      cursor: default;
    }
  }
}

/* styles for irene/components/home-page/security-dashboard-nav/index.scss */
._profile-menu-popover_1xr6ey {
  inset: 12px auto auto 0px !important;
}

/* styles for irene/components/img-logo/index.scss */
._img-logo__img_zf7jft {
  max-width: 30em;
  min-width: 5em;
  max-height: 30em;
}

/* styles for irene/components/jira-account/index.scss */
._bordered-box_pwij9s {
  display: flex;
  max-width: 800px;
  border: 1px solid var(--jira-account-border-color);
  border-radius: var(--jira-account-border-radius);
  box-sizing: border-box;

  ._integration-logo-container_pwij9s {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-right: 1px solid var(--jira-account-border-color);
    padding: 1em;

    img {
      width: 40px;
    }
  }

  ._integration-account-container_pwij9s {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
  }
}

/* styles for irene/components/login-component/index.scss */
._container_ts5uz1 {
  height: 100vh;
}

._inner-container_ts5uz1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20vh;
}

._login-container_ts5uz1 {
  padding: 0em;
  background-color: var(--white);
  border-radius: 5px;
  min-width: 26em;
  box-shadow: 2px 2px 17px -1px rgba(0, 0, 0, 0.5);
  margin-bottom: 3em;
  margin-top: 3em;
}

._login-title_ts5uz1 {
  font-size: 1.72rem;
  text-align: center;
  margin-top: 2em;
}

._login-footer_ts5uz1 {
  margin-top: 3em;
  border-top: 1px solid #efefef;
  background: #f6f7f8;
  padding: 1.5em 1em 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

._register-link_ts5uz1 {
  margin-left: 0.5em;
}

._action-container_ts5uz1 {
  margin: 0 2em 3em 2em;
}

._input-group_ts5uz1 {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  margin-top: 1em;
}

._input_ts5uz1 {
  align-items: center;
  background-color: var(--white);
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  color: #6b6b6b;
  display: inline-flex;
  font-size: 14px;
  height: 32px;
  justify-content: flex-start;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  vertical-align: top;
  box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
  max-width: 100%;
  width: 100%;
  &:active,
  &:hover {
    border: 1px solid #b3b3b3;
    box-shadow: 0 0 1px grey;
  }
}

._input-connect-label_ts5uz1 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._input-label_ts5uz1 {
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #e6e6e6;
  box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
  padding: 0 0.6em;
  display: flex;
  align-items: center;
  background: #f7f7f7;
  border-radius: 3px 0 0 3px;
}

._input-label_ts5uz1 .input-icon {
  color: #fe4d3f;
  font-size: 1.13em;
  display: flex;
  align-items: center;
}

._forgot-password_ts5uz1 {
  text-align: right;
  font-size: 0.8em;
  margin-top: 0.5em;
}

._login-button_ts5uz1 {
  margin-top: 1em;
}

._login-button-text_ts5uz1 {
  margin-left: 0.5em;
}

._ak-button-loader_ts5uz1 {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5em;
  margin-left: 0.5em;

  .ak-loader-circle-indicator {
    stroke: var(--login-component-button-loader-color) !important;
  }
}
/* styles for irene/components/login-component/sso/index.scss */
._login-form-sso-separator_16ev3f {
  height: 0.06em;
  width: 100%;
  background: #ebebeb;
  position: relative;
  margin: 3em 0em;
}

._login-form-sso-separator-or_16ev3f {
  text-align: center;
  background: var(--white);
  width: 2.3em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

._login-form-sso-button_16ev3f {
  width: 100%;
  display: block;
  margin: auto;
  margin-top: 1em;
  background: #ececec;
  border-color: #ececec;
  &:hover,
  &:focus,
  &:active {
    color: inherit;
    background: darken(#ececec, 5%);
    border-color: darken(#ececec, 5%);
  }
}

/* styles for irene/components/main-loader/index.scss */
._loading-container_1dpyez {
  font-size: 1.5em;
  width: 100%;
  display: flex;
  text-align: center;
  height: 100%;
}

._progress-container_1dpyez {
  width: 30%;
  margin-top: 3em;
}
/* styles for irene/components/marketplace/card-content/index.scss */
._marketplace-card-logo_x6zzbg {
  height: 50%;
  width: 50%;
  margin: auto;
  padding: 1rem 0;

  img {
    height: 6rem;
    width: 6rem;
  }
}

._marketplace-card-desc_x6zzbg {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* styles for irene/components/marketplace/integration-card/index.scss */
._marketplace-card_1lq61i {
  border: 1px solid #efefef;
  text-align: center;
  display: block;
  box-shadow: 0px 0px 6px #ccc;
  padding: 1em;
  color: #6b6b6b;

  &:hover {
    box-shadow: 1px 1px 20px #999;
  }
}

._marketplace-card-disabled_1lq61i {
  opacity: 0.5;

  ._marketplace-card_1lq61i:hover {
    box-shadow: 0px 0px 6px #ccc;
  }
}

/* styles for irene/components/marketplace/integration-list/index.scss */
._marketplace-icon_yw1c6l {
  margin: 1em;
  width: 15em;
}

._marketplace-list_yw1c6l {
  flex-wrap: wrap;
}

/* styles for irene/components/marketplace/plugin-card/index.scss */
._marketplace-card_tcveto {
  border: 1px solid #efefef;
  text-align: center;
  display: block;
  box-shadow: 0px 0px 6px #ccc;
  padding: 1em;
  color: #6b6b6b;

  &:hover {
    box-shadow: 1px 1px 20px #999;
  }
}

._marketplace-modal-logo_tcveto {
  height: auto;
  width: 6em;
  margin: auto;

  img {
    height: 100%;
    width: 100%;
  }
}

/* styles for irene/components/marketplace/plugin-list/index.scss */
._marketplace-icon_sgmq1m {
  margin: 1em;
  width: 15em;
}
._marketplace-list_sgmq1m {
  flex-wrap: wrap;
}

/* styles for irene/components/modals/org-file-cleanup-list/styles.scss */
._file-cleanup-list-container_qfzuuq {
  ._list-head_qfzuuq {
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    align-items: center;
  }

  ._list-head_qfzuuq {
    height: 40px;
    font-weight: bold;
    box-shadow: 0px 2px 4px -4px #000;
    padding: 0 8px;
    font-weight: bold;
  }

  ._list-body_qfzuuq {
    margin-bottom: 15px;
    max-height: 320px;
    overflow: auto;
    padding: 0 8px;

    ._list-body__row_qfzuuq {
      display: grid;
      grid-template-columns: 100px 1fr 1fr;
      height: 40px;
      align-items: center;
      border-bottom: 1px solid;

      ._list-body__row-item_qfzuuq {
        font-size: 13px;

        ._username_qfzuuq {
          text-transform: capitalize;
        }
      }
    }

    ._no-result_qfzuuq {
      text-align: center;
      padding-top: 8px;
    }
  }
}

/* styles for irene/components/notifications-dropdown/footer/index.scss */
._notification-dropdown-footer_1lx3qb {
  border-top: 1px solid var(--notification-dropdown-border-color);
}

/* styles for irene/components/notifications-dropdown/header/index.scss */
._notification_header_1tq9hf {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 1em;
}
._notification_header_line_1tq9hf {
  display: flex;
  justify-content: space-between;
}

._notification_title_1tq9hf {
  margin-right: 0.5em;
}

._notification_title_area_1tq9hf {
  display: flex;
  align-items: center;
}

._notifications_unread_count_1tq9hf {
  color: var(--notification-unread-count-color-primary);
  background-color: var(--notification-unread-count-background-color);
  line-height: 1em;
  padding: 0.25em 0.4em;
}

/* styles for irene/components/notifications-dropdown/index.scss */
._notification-dropdown-container_1w8qht {
  width: 35em;
  background: var(--notifications-dropdown-background-color);
  border: 1px solid var(--notifications-dropdown-border-color);
  box-shadow: var(--notifications-dropdown-box-shadow);
  margin-top: 10px;
}

._notification_main_list_1w8qht {
  min-height: 30em;
  max-height: 70vh;
  overflow-y: scroll;
}

// Loading styles
._loading_1w8qht {
  height: 12.5em;
}

/* styles for irene/components/notifications-page/bell-icon/index.scss */
._nf-icon-container_qtqgwy {
  position: relative;
}

._nf-icon_qtqgwy,
._nf-icon_qtqgwy:hover,
._nf-icon_qtqgwy:focus,
._nf-icon_qtqgwy:active {
  color: var(--notification-bell-icon-color);
}

._nf-button_qtqgwy {
  border: 0;
  border-radius: 0;
  display: inline-block;
  height: auto;
  padding: 0;
  margin: 0.5em;
}

._nf-unread-dot_qtqgwy {
  background-color: var(--notification-bell-icon-dot-background-color);
  border: 2px solid var(--notification-bell-icon-dot-border-color);
  height: 0.9em;
  width: 0.9em;
  border-radius: 50%;
  position: absolute;
  top: -0.3em;
  right: -0.1em;
}

._icon-container_qtqgwy {
  position: relative;
  display: flex;
}

/* styles for irene/components/notifications-page/divider/index.scss */
._divider_grkrfm {
  width: 100%;
  border: 0.01em solid var(--neutral-grey-200);
}

/* styles for irene/components/notifications-page/empty/index.scss */
._container_wuelo6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
  padding-bottom: 4.5em;
}

._message_wuelo6 {
  margin: 2em 0;
}

/* styles for irene/components/notifications-page/header/index.scss */
._notification_header_zq8kbg {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-left: 1em;
  padding-bottom: 0.5em;
}
._notification_header_line_zq8kbg {
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
}

._notification_title_zq8kbg {
  margin-right: 0.5em;
}

._notification_title_area_zq8kbg {
  display: flex;
  align-items: center;
}

._notification_settings_area_zq8kbg {
  display: flex;
  align-items: center;
}

._notifications_unread_count_zq8kbg {
  color: var(--notification-unread-count-color-primary);
  background-color: var(--notification-unread-count-background-color);
  line-height: 1em;
  padding: 0.25em 0.4em;
}

._notification_unread_text_zq8kbg {
  margin-right: 1em;
  color: var(--body-type-color);
}

._notification_unread_toggle_zq8kbg {
  margin: 0;
}

._vertical-divider_zq8kbg {
  height: 100%;
  border: 1px solid;
  border-color: var(--notification-divider-border-color);
  opacity: 0.1;
  margin: 0 1em;
}

/* styles for irene/components/notifications-page/index.scss */
._notification_container_1164i7 {
  display: flex;
  width: 80%;
  padding-left: 1em;
  flex-direction: column;
}

._notification_main_list_1164i7 {
  display: flex;
  flex-direction: column;
}

// Loading styles
._loading_1164i7 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12.5em;
}

/* styles for irene/components/notifications-page/message/index.scss */
._message_container_outer_1kragi {
  padding: 1em;
  display: flex;
  flex-direction: row;
}
._message_container_outer_1kragi:hover,
._message_container_outer_1kragi:focus,
._message_container_outer_1kragi:active {
  background-color: var(--notification-message-hover-background-color);
}

._message_container_inner_1kragi {
  flex: auto;
}

._unread_container_1kragi {
  height: 100%;
  margin: 0.5em;
}

._unread_label_1kragi {
  display: block;
  background-color: transparent;
  width: 0.5em;
  border-radius: 999px;
  aspect-ratio: 1/1;
  position: relative;
}

._unread_1kragi:checked + ._unread_label_1kragi {
  background-color: var(
    --notification-message-read-unread-dot-background-color
  );
}

._unread_label_1kragi::before,
._unread_label_1kragi::after {
  content: '';
  display: block;
  position: absolute;
  top: -100%;
  left: -100%;
  width: 1.5em;
  border-radius: 999px;
  aspect-ratio: 1/1;
}

._unread_label_1kragi::before {
  opacity: 0;
}

._unread_label_1kragi::after {
  border: 1px solid var(--notification-message-read-unread-ring-border-color);
  opacity: 0;
}

._unread_label_1kragi:hover::after,
._unread_label_1kragi:active::after {
  opacity: 0.1;
}

._message_container_outer_1kragi:hover,
._message_container_outer_1kragi:focus,
._message_container_outer_1kragi:active {
  ._unread_label_1kragi::after {
    opacity: 0.1;
  }
}

._unread_1kragi {
  width: 0;
  height: 0;
  opacity: 0;
}

._notification_created_on_1kragi {
  color: var(--notification-message-time-color);
  font-size: 0.9em;
  margin-top: 0.4em;
}

._message_container_inner_1kragi {
  color: var(--notification-message-color-text-primary);
}

/* styles for irene/components/notifications-page/messages/nf-apistcmpltd1/index.scss */
._meta-info_1n2fd3 {
  font-size: 0.95em;
}
._pipe_1n2fd3 {
  color: var(--neutral-grey-200);
}

._vulnerability-stats_1n2fd3 {
  border: 1px solid var(--neutral-grey-100);
  padding: 1em 1.2em;
  margin: 0.8em 0 0.5em;
  border-radius: 0.2em;
  max-width: 40em;
}
._severity-levels_1n2fd3 {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  row-gap: 0.35em;
  column-gap: 5em;
}
._severity-level_1n2fd3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95em;
}
._severity-label_1n2fd3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
._severity-color_1n2fd3 {
  height: 0.8em;
  width: 0.8em;
  border-radius: 50%;
  margin-right: 0.4em;
}
._severity-color--critical_1n2fd3 {
  background-color: var(--risk-critical);
}
._severity-color--high_1n2fd3 {
  background-color: var(--risk-high);
}
._severity-color--medium_1n2fd3 {
  background-color: var(--risk-medium);
}
._severity-color--low_1n2fd3 {
  background-color: var(--risk-low);
}
._severity-color--passed_1n2fd3 {
  background-color: var(--risk-passed);
}
._severity-color--untested_1n2fd3 {
  background-color: var(--risk-untested);
}

/* styles for irene/components/notifications-page/messages/nf-dastcmpltd1/index.scss */
._meta-info_1ekhr4 {
  font-size: 0.95em;
}
._pipe_1ekhr4 {
  color: var(--neutral-grey-200);
}

._vulnerability-stats_1ekhr4 {
  border: 1px solid var(--neutral-grey-100);
  padding: 1em 1.2em;
  margin: 0.8em 0 0.5em;
  border-radius: 0.2em;
  max-width: 40em;
}
._severity-levels_1ekhr4 {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  row-gap: 0.35em;
  column-gap: 5em;
}
._severity-level_1ekhr4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95em;
}
._severity-label_1ekhr4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
._severity-color_1ekhr4 {
  height: 0.8em;
  width: 0.8em;
  border-radius: 50%;
  margin-right: 0.4em;
}
._severity-color--critical_1ekhr4 {
  background-color: var(--risk-critical);
}
._severity-color--high_1ekhr4 {
  background-color: var(--risk-high);
}
._severity-color--medium_1ekhr4 {
  background-color: var(--risk-medium);
}
._severity-color--low_1ekhr4 {
  background-color: var(--risk-low);
}
._severity-color--passed_1ekhr4 {
  background-color: var(--risk-passed);
}
._severity-color--untested_1ekhr4 {
  background-color: var(--risk-untested);
}

/* styles for irene/components/notifications-page/messages/nf-sastcmpltd1/index.scss */
._meta-info_10aey8 {
  font-size: 0.95em;
}
._pipe_10aey8 {
  color: var(--neutral-grey-200);
}

._vulnerability-stats_10aey8 {
  border: 1px solid var(--neutral-grey-100);
  padding: 1em 1.2em;
  margin: 0.8em 0 0.5em;
  border-radius: 0.2em;
  max-width: 40em;
}
._severity-levels_10aey8 {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  row-gap: 0.35em;
  column-gap: 5em;
}
._severity-level_10aey8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95em;
}
._severity-label_10aey8 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
._severity-color_10aey8 {
  height: 0.8em;
  width: 0.8em;
  border-radius: 50%;
  margin-right: 0.4em;
}
._severity-color--critical_10aey8 {
  background-color: var(--risk-critical);
}
._severity-color--high_10aey8 {
  background-color: var(--risk-high);
}
._severity-color--medium_10aey8 {
  background-color: var(--risk-medium);
}
._severity-color--low_10aey8 {
  background-color: var(--risk-low);
}
._severity-color--passed_10aey8 {
  background-color: var(--risk-passed);
}
._severity-color--untested_10aey8 {
  background-color: var(--risk-untested);
}

/* styles for irene/components/notifications-page/messages/nf-sbomcmpltd/index.scss */
._pipe_orbzuo {
  color: var(--neutral-grey-200);
}

/* styles for irene/components/notifications-page/messages/nf-str-url-upldfailnprjdeny2/index.scss */
._pipe_1sgemi {
  color: var(--neutral-grey-200);
}

/* styles for irene/components/notifications-page/messages/nf-str-url-upload-success/index.scss */
._pipe_153m4k {
  color: var(--neutral-grey-200);
}

/* styles for irene/components/notifications-page/messages/nf-str-url-vldtn-err/index.scss */
._error-container_1w28zj {
  border: 1px solid var(--neutral-grey-100);
  padding: 1em 1.2em;
  margin: 0.5em 0 0.5em;
  border-radius: 0.2em;
  max-width: 40em;
}

/* styles for irene/components/notifications-page/messages/nf-systm-file-upload-success/index.scss */
._pipe_ob70ze {
  color: var(--neutral-grey-200);
}

/* styles for irene/components/notifications-page/messages/nf-upldfailnprjdeny2/index.scss */
._help-text_13y1yh {
  color: var(--neutral-grey-500);
}

/* styles for irene/components/notifications-page/namespace-message/index.scss */
._pipe_ysm3fq {
  color: var(--neutral-grey-200);
}

._message-container_ysm3fq {
  border: 1px solid var(--neutral-grey-100);
  padding: 1em 1.2em;
  margin: 0.75em 0 0.5em;
  border-radius: 0.2em;
}

/* styles for irene/components/novnc-rfb/index.scss */
._novnc-rfb_lddxu5 {
  width: 100%;
  height: 100%;

  canvas {
    cursor: pointer !important;
    overflow: hidden;
  }
}

/* styles for irene/components/organization-analytics/app-scan-chart/index.scss */
._date-picker-btn_xspylt {
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--organization-analytics-app-scan-chart-border-color);
  border-radius: var(--organization-analytics-app-scan-chart-border-radius);

  ._date-picker-dateText_xspylt {
    padding: 0.5em 1em;
    border-left: 1px solid
      var(--organization-analytics-app-scan-chart-border-color);
  }

  ._date-picker-icon_xspylt {
    margin: 0 0.6em;
  }
}

/* styles for irene/components/organization-archive/index.scss */
._organization-archive-header-container_1bwemw {
  max-width: 600px;
}

._date-picker-btn_1bwemw {
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--organization-archive-border-color);
  border-radius: var(--organization-archive-border-radius);

  ._date-picker-dateText_1bwemw {
    padding: 0.5em 1em;
    border-left: 1px solid var(--organization-archive-border-color);
    border-right: 1px solid var(--organization-archive-border-color);
  }

  ._date-picker-icon_1bwemw {
    margin: 0 0.6em;
  }

  ._date-picker-clearBtn_1bwemw {
    min-width: 60px;
  }
}

/* styles for irene/components/organization-archive/list/index.scss */
._archive-row-expired_biuddh {
  background-color: var(--organization-archive-list-expired-background);
}

._bordered-container_biuddh {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin: 1em 0;
  max-width: 800px;
  min-height: 100px;
  border-radius: var(
    --organization-archive-list-bordered-container-border-radius
  );
  border: 1px solid
    var(--organization-archive-list-bordered-container-border-color);
  box-sizing: border-box;

  ._empty-svg_biuddh {
    height: 70px;
  }
}

/* styles for irene/components/organization-billing/invoice-list/index.scss */
._invoice-tbody_1ns2px tr:last-child td {
  border-bottom-width: unset;
}

/* styles for irene/components/organization-details/index.scss */
._header-container_l8pshk {
  padding: 0.5em 1em 1em 1em;
  box-sizing: border-box;
}

._tabs-container_l8pshk {
  margin: 0 1em;
}

/* styles for irene/components/organization-email-domain/index.scss */
._org-email-domain_fzhqhn {
  max-width: 600px;
}

/* styles for irene/components/organization-member/invite-user/index.scss */
._drawer-content-container_ksvdao {
  height: 100%;
  box-sizing: border-box;
}

._invite-drawer-content_ksvdao {
  padding: 1em;
  height: 100%;
  width: 400px;
  overflow-y: auto;
}

._invite-drawer-footer_ksvdao {
  box-shadow: 0px -4px 8px 4px rgba(0, 0, 0, 0.03);
}

/* styles for irene/components/organization-member/list/add-to-team/index.scss */
._empty-container_1d42zy {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  margin-right: 1.5em;
  margin-left: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 150px;
  border-radius: var(
    --organization-member-list-add-to-team-border-radius
  );
  border: 1px solid
    var(--organization-member-list-add-to-team-border-color);
  box-sizing: border-box;

  ._empty-svg_1d42zy {
    height: 120px;
  }
}
/* styles for irene/components/organization-member/list/index.scss */
._search-input_khzonb {
  width: 21.42em;
}

._no-result-container_khzonb {
  height: 300px;
}

._loading-container_khzonb {
  width: 100%;
  background: var(--organization-member-list-loading-container-background);
  border: 1px solid var(--organization-member-list-loading-container-border);
  border-radius: var(
    --organization-member-list-loading-container-border-radius
  );
  padding: 2em;

  ._loading-text_khzonb {
    font-style: italic;
  }
}

/* styles for irene/components/organization-member/list/member-details/index.scss */
._empty-container_1najrx {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  margin-right: 1.5em;
  margin-left: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 150px;
  border-radius: var(
    --organization-member-list-member-details-border-radius
  );
  border: 1px solid
    var(--organization-member-list-member-details-border-color);
  box-sizing: border-box;

  ._empty-svg_1najrx {
    height: 120px;
  }
  }

._box_1najrx {
  border-bottom: 1px solid var(--organization-member-list-member-details-divider-color) !important;
  box-shadow: var(--organization-member-list-member-details-box-shadow);
}

._divider-gap_1najrx {
  margin-top: 0.65em;
  margin-bottom: 0.65em;
}
/* styles for irene/components/organization-member/list/member-drawer/index.scss */
._drawer-content-container_1gfrh4 {
    height: 100%;
    box-sizing: border-box;
  }
  
  ._user-drawer-content_1gfrh4 {
    height: 100%;
    width: 650px;
    overflow-y: auto;
  }
  
/* styles for irene/components/organization-mfa/index.scss */
._org-setting-mfa_1r6rkx {
  max-width: 600px;
}

._alert-error_1r6rkx,
._alert-warn_1r6rkx {
  display: flex;
  align-items: center;
  padding: 1em;
  border-radius: var(--organization-mfa-border-radius);
  box-sizing: border-box;
}

._alert-error_1r6rkx {
  border: 1px solid var(--organization-mfa-alert-error-border-color);
  background-color: var(--organization-mfa-alert-error-background);
}

._alert-warn_1r6rkx {
  border: 1px solid var(--organization-mfa-alert-warn-border-color);
  background-color: var(--organization-mfa-alert-warn-background);
  color: var(--organization-mfa-alert-warn-text-color);

  ._alert-warn-link_1r6rkx {
    color: var(--organization-mfa-alert-warn-text-color) !important;
  }
}

/* styles for irene/components/organization-name-header/index.scss */
._header-container_qagg7j {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border: 1px solid var(--organization-name-header-border-color);
  border-radius: var(--organization-name-header-border-radius);
  box-sizing: border-box;
}

._tooltip-content-container_qagg7j {
  width: 320px;
  padding: 0.5em;
  box-sizing: border-box;
  white-space: normal;
}

._add-button_qagg7j {
  box-shadow: none;
  border-style: dashed;
  background-color: var(--organization-name-color-primary-light);

  &:hover,
  &:focus {
    box-shadow: none;
    border-style: dashed;

    ._add-button-text_qagg7j {
      text-decoration: underline;
    }
  }

  &:disabled {
    box-shadow: none;
    border-color: var(--organization-name-color-text-disabled);
    background-color: var(--organization-name-add-btn-disabled-background);
    color: var(--organization-name-color-text-disabled);
    text-decoration: none;
  }
}

/* styles for irene/components/organization-namespace/index.scss */
._no-result-container_jlorll {
  height: 350px;
}

._loading-container_jlorll {
  width: 100%;
  background: var(--organization-namespace-loading-container-background);
  border: 1px solid var(--organization-namespace-loading-container-border);
  border-radius: var(--organization-namespace-loading-container-border-radius);
  padding: 2em;

  ._loading-text_jlorll {
    font-style: italic;
  }
}

/* styles for irene/components/organization-namespace/namespace-value/index.scss */
._team-project-icon-apple_1tz8zi {
  color: var(--organization-namespace-value-color-ios);
}

._team-project-icon-android_1tz8zi {
  color: var(--organization-namespace-value-color-android);
}

._team-project-icon_1tz8zi {
  font-size: 1.2rem;
  vertical-align: unset;
}

/* styles for irene/components/organization-settings/index.scss */
._org-settings-container_12xn75 {
  padding: 1em 2em 5em 2em;
  box-sizing: border-box;
}

/* styles for irene/components/organization-team/add-team-member/index.scss */
._emtpy-container_1kzlll {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 250px;
  box-sizing: border-box;

  ._empty-svg_1kzlll {
    height: 100px;
  }
}

._loading-container_1kzlll {
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  border-radius: var(--add-team-member-loading-container-border-radius);
  border: 1px solid var(--add-team-member-loading-container-border-color);
  box-sizing: border-box;
}

/* styles for irene/components/organization-team/add-team-project/index.scss */
._emtpy-container_pho3up {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 250px;
  box-sizing: border-box;

  ._empty-svg_pho3up {
    height: 100px;
  }
}

._loading-container_pho3up {
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  border-radius: var(--add-team-project-loading-container-border-radius);
  border: 1px solid var(--add-team-project-loading-container-border-color);
  box-sizing: border-box;
}

/* styles for irene/components/organization-team/add-team-project/project-info/index.scss */
._team-project-icon-apple_mzixco {
  color: var(--add-team-project-color-ios);
}

._team-project-icon-android_mzixco {
  color: var(--add-team-project-color-android);
}

._team-project-icon_mzixco {
  font-size: 1.2rem;
  vertical-align: unset;
}

/* styles for irene/components/organization-team/details/active-action/index.scss */
._team-action-container_plrviy {
  padding: 1.5em;
  padding-bottom: 5em;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
}

._action-footer-shadow_plrviy {
  box-shadow: var(--organization-team-details-active-action-footer-shadow);
}

/* styles for irene/components/organization-team/details/index.scss */
._team-detail-root_1nqz49 {
  width: 650px;
  height: 100%;
}

/* styles for irene/components/organization-team/details/team-info/index.scss */
._team-detail-content_8gk2sb {
  width: 100%;
  height: 100%;
  overflow: auto;
}

._team-detail-info_8gk2sb {
  padding: 1.5em;
  box-shadow: var(--organization-team-details-team-info-summary-shadow);
}

/* styles for irene/components/organization-team/index.scss */
._emtpy-container_1uhqsy {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 250px;
  box-sizing: border-box;

  ._empty-svg_1uhqsy {
    height: 150px;
  }
}

._loading-container_1uhqsy {
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-color: var(--organization-team-loading-container-background);
  border-radius: var(--organization-team-loading-container-border-radius);
  border: 1px solid var(--organization-team-loading-container-border);
  box-sizing: border-box;

  ._loading-text_1uhqsy {
    font-style: italic;
  }
}

/* styles for irene/components/organization-team/invitation-list/index.scss */
._emtpy-container_12bvml {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 150px;
  border-radius: var(
    --organization-team-invitation-list-loading-container-border-radius
  );
  border: 1px solid
    var(--organization-team-invitation-list-loading-container-border-color);
  box-sizing: border-box;

  ._empty-svg_12bvml {
    height: 70px;
  }
}

/* styles for irene/components/organization-team/member-list/index.scss */
._emtpy-container_15qcor {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 150px;
  border-radius: var(
    --organization-team-member-list-loading-container-border-radius
  );
  border: 1px solid
    var(--organization-team-member-list-loading-container-border-color);
  box-sizing: border-box;

  ._empty-svg_15qcor {
    height: 70px;
  }
}

/* styles for irene/components/organization-team/overview/index.scss */
._org-team-overview-container_f0c963 {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border-radius: var(--organization-team-overview-border-radius);
  border: 1px solid var(--organization-team-overview-border-color);
  cursor: pointer;

  &:hover {
    background-color: var(--organization-team-overview-hover-background);
  }
}

._upper-case_f0c963 {
  text-transform: uppercase;
}

/* styles for irene/components/organization-team/project-list/index.scss */
._emtpy-container_9jtij6 {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 150px;
  border-radius: var(
    --organization-team-project-list-loading-container-border-radius
  );
  border: 1px solid
    var(--organization-team-project-list-loading-container-border-color);
  box-sizing: border-box;

  ._empty-svg_9jtij6 {
    height: 70px;
  }
}

/* styles for irene/components/organization-team/project-list/project-info/index.scss */
._team-project-icon-apple_10pwg2 {
  color: var(--organization-team-project-list-info-color-ios);
}

._team-project-icon-android_10pwg2 {
  color: var(--organization-team-project-list-info-color-android);
}

._team-project-icon_10pwg2 {
  font-size: 1.2rem;
  vertical-align: unset;
}

/* styles for irene/components/pagination/index.scss */
._columns_8v8j8g {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    display: flex;
    flex-wrap: wrap;
}

._column_8v8j8g {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 10px;
  
    &._is-full_8v8j8g {
      flex: none;
      width: 100%;
    }
  
    &._is-half_8v8j8g {
      flex: none;
      width: 50%;
    }
  
    &._is-one-third_8v8j8g {
      flex: none;
      width: 33.3333%;
    }
  
    &._is-one-quarter_8v8j8g {
      flex: none;
      width: 25%;
    }
  
    &._is-one-sixth_8v8j8g {
      flex: none;
      width: 16.6666%;
    }
}

._pagination_8v8j8g {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;

    ._action_8v8j8g {
        margin: 0px 3px;
        border-radius: 5px;

        &:hover,
        &:focus {
          color:  var(--primary-contrast-text);
          border: 1px solid var(--primary-main);
          background-color: var(--primary-main);
          box-shadow: unset;
        }
    }
    
    ._pagination-list_8v8j8g {
        ._page_8v8j8g._is-primary_8v8j8g,
        ._page_8v8j8g._is-default_8v8j8g:hover {
          color:  var(--primary-contrast-text);
          border: 1px solid var(--primary-main);
          background-color: var(--primary-main);
          box-shadow: unset;
        }
        ._page_8v8j8g {
          min-width: fit-content;
          border-radius: 5px;
        }
    }

    button {
      margin: 0px 3px;
    }
  
    span {
      display: block;
    }
  
    li {
      margin: 0 2px;
    }
  
    ul {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }
}
  
/* styles for irene/components/partner/client-detail/styles.scss */
._client_ewv5rm {
  padding-top: 1.2rem;
  & > div {
    width: 100%;
  }
}

/* styles for irene/components/partner/client-info/styles.scss */
$card-header-height: 5rem;
$card-thumbnail-size: 2.8rem;

._client-info-card_1aubmn {
  border: 1px solid var(--bg-gray-darken-050);
  border-radius: 0.3rem;
  grid-template-rows: $card-header-height 1fr;
  background-color: var(--bg-gray-lighten-015);
}

._client-info-header_1aubmn {
  display: grid;
  grid-template-columns: $card-thumbnail-size 1fr;
  column-gap: 0.5rem;
  padding: 0 1rem;
  height: $card-header-height;
  align-items: center;
}

._thumbnail_1aubmn {
  width: $card-thumbnail-size;
  height: $card-thumbnail-size;
  border-radius: 50%;
  background-color: var(--bg-darkgray);
  color: $white;
  display: grid;
  align-items: center;
  justify-content: center;
}

._thumbnail-icon_1aubmn {
  font-size: 1.2rem;
}

._title_1aubmn {
  text-transform: capitalize;
  font-weight: bold;
  color: var(--black-700);
  max-height: 92%;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &._empty-title_1aubmn {
    color: var(--black-400);
    font-size: 0.95rem;
    font-style: italic;
  }
}

._table_1aubmn {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 1rem;
}

._row_1aubmn {
  display: flex;
  border-bottom: 1px dashed var(--bg-gray-darken-050);
  padding: 0.2rem 0;

  &:last-child {
    border-bottom: none;
  }
}

._key_1aubmn {
  width: 30%;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
}

._value_1aubmn {
  width: 70%;
  display: flex;
  align-items: center;
}

._actions_1aubmn {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  padding-right: 1.2rem;
}

._action_1aubmn {
  padding-bottom: 1.2rem;
}

/* styles for irene/components/partner/client-list/styles.scss */
._header_b5aiht {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
}
._title_b5aiht {
  font-size: 1.2rem;
  font-weight: bold;
  color: #5e5f63;
  padding-top: 0.8rem;
  padding-bottom: 0.9rem;
}

._client-items_b5aiht {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 912px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

._loading_b5aiht {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}

._loading-error_b5aiht {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--loading-error-color);
  align-items: center;
  justify-content: center;
}

._empty-data_b5aiht {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--empty-state-color);
  font-style: italic;
  align-items: center;
  justify-content: center;
}

/* styles for irene/components/partner/client-plan/styles.scss */
._plan_gwxfxs {
  display: flex;
  align-items: center;
}

._plan-type_gwxfxs {
  border-radius: 0.2rem;
  height: 1.2rem;
  width: 4.8rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &._per-app_gwxfxs {
    color: var(--label-blue-color);
    background-color: var(--label-blue-bg);
  }

  &._per-scan_gwxfxs {
    color: var(--label-green-color);
    background-color: var(--label-green-bg);
  }
}

._plan-status_gwxfxs {
  display: flex;
  align-items: center;
  color: var(--black);
  margin-left: 0.5rem;
}

._expiry-date_gwxfxs {
  font-size: 0.9em;
  color: var(--empty-state-color);
}

._expiry-date-expired_gwxfxs {
  color: var(--text-error);
}

/* styles for irene/components/partner/client-project-detail/index.scss */
._project-card_rqw580 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bg-gray-darken-050);
  border-radius: 0.3rem;
  background-color: var(--bg-gray-lighten-015);
  padding: 1rem;
  gap: 0.5rem;
}

/* styles for irene/components/partner/client-project-list/index.scss */
._header_12qa68 {
  margin-bottom: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
._title_12qa68 {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--black-600);
  margin-bottom: 0 !important;
}

._table_12qa68 {
  border: 1px solid var(--border-table);
  border-radius: 0.3rem;
}

._table-header_12qa68 {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  background-color: var(--bg-gray);
  word-break: break-all;
  grid-template-columns: 10% 1fr 15%;
  &._add-column_12qa68 {
    grid-template-columns: 10% 1fr 15% 15%;
  }
}

._table-row_12qa68 {
  border-top: 1px solid var(--border-table);
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0.8rem;
  min-height: 2.5rem;
  grid-template-columns: 10% 1fr 15%;
  &._add-column_12qa68 {
    grid-template-columns: 10% 1fr 15% 15%;
  }
}

._pagination_12qa68 {
  margin: 1rem -3px;
}

._loading-error_12qa68 {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--loading-error-color);
  align-items: center;
  justify-content: center;
}

._loading_12qa68 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}

._empty-data_12qa68 {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--empty-state-color);
  font-style: italic;
  align-items: center;
  justify-content: center;
}

/* styles for irene/components/partner/client-project-list/item/index.scss */
._platform_1vxth7 {
  padding-left: 1.5em;
  color: var(--black-500);
}

._platform-android_1vxth7 {
  color: var(--android);
}
._platform-apple_1vxth7 {
  color: var(--ios);
}

._version_1vxth7 {
  display: grid;
  align-items: center;
  font-size: 0.95em;
}

/* styles for irene/components/partner/client-report-download/index.scss */
._report-actions_fza66m {
  display: flex;
  gap: 0.5rem;
}

._button-small_fza66m {
  height: 2rem;
  padding: 0.5rem;
}

._button-progress_fza66m {
  &:hover {
    background-color: transparent !important;
    color: var(--primary) !important;
  }
}

._progress-loader_fza66m {
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: var(--primary);
  bottom: 0;
  left: 0;
  opacity: 0.2;
}

._download-button_fza66m {
  width: 8rem;
}

._report-loading-error_fza66m {
  color: var(--text-error);
  font-size: 0.95rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* styles for irene/components/partner/client-report-download/report-password/index.scss */
._dropdown-toggle_1tysls {
  height: 2rem;
  padding: 0.5rem;
}

._dropdown-tray_1tysls {
  background-color: var(--background-main);
  border: 1px solid var(--border-color-1);
  border-radius: 0.3rem;
  width: 19rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

._password-title_1tysls {
  text-align: center;
  padding-bottom: 1rem;
}

._password-display_1tysls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 15rem;
}

._password-loading_1tysls {
  color: var(--black-400);
}

._password-loading-error_1tysls {
  color: var(--text-error);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

._password-input_1tysls {
  background-color: var(--black-025);
  border: 1px solid var(--black-100);
  border-radius: 0.3rem;
  height: 2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  width: 9rem;
  text-align: center;
}

/* styles for irene/components/partner/client-report-summary/index.scss */
._summary_186shm {
  width: 100%;
}

._riskbar_186shm {
  display: flex;
  width: 100%;
  height: 1.6rem;
  &:hover {
    transform: scale(1.01);
  }
}

._riskkey-color_186shm {
  width: 0.8rem;
  height: 0.8rem;
  background-color: var(--black-200);
}

._riskblock--critical_186shm,
._riskkey-color--critical_186shm {
  background: var(--risk-critical);
}
._riskblock--high_186shm,
._riskkey-color--high_186shm {
  background: var(--risk-high);
}
._riskblock--medium_186shm,
._riskkey-color--medium_186shm {
  background: var(--risk-medium);
}
._riskblock--low_186shm,
._riskkey-color--low_186shm {
  background: var(--risk-low);
}
._riskblock--passed_186shm,
._riskkey-color--passed_186shm {
  background: var(--risk-passed);
}
._riskblock--untested_186shm,
._riskkey-color--untested_186shm {
  background: var(--risk-untested);
}

._index_186shm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.4rem 1.2rem;
}
._riskkey_186shm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
  font-size: 0.95rem;
}
._riskkey-label_186shm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.36rem;
}
._riskkey-text_186shm {
  color: var(--black-800);
}
._riskkey-count_186shm {
  color: var(--black-900);
  font-weight: 600;
}

/* styles for irene/components/partner/client-uploads-list/styles.scss */
._header_1scctn {
  margin-bottom: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
._title_1scctn {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--black-600);
  margin-bottom: 0 !important;
}

._table_1scctn {
  border: 1px solid var(--border-table);
  border-radius: 0.3rem;
}
._table-header_1scctn {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  background-color: var(--bg-gray);
  word-break: break-all;
  text-transform: capitalize;
}
._table-row_1scctn {
  border-top: 1px solid var(--border-table);
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.8rem;
  min-height: 2.5rem;
}
._table-header_1scctn,
._table-row_1scctn {
  grid-template-columns: 1.3fr 1fr 1fr;
  gap: 2rem;
  &._has-report-column_1scctn {
    grid-template-columns: 1fr 16% 13% 0.6fr 14rem;
    gap: 0.8rem;
  }
  &._has-results-column_1scctn {
    grid-template-columns: 1fr 16% 13% 0.6fr;
    gap: 0.8rem;
  }
}

._app_1scctn {
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 0.5rem;
}
._app-icon_1scctn {
  width: 3rem;
  height: 3rem;
  display: block;
  border-radius: 0.3rem;
  background-color: var(--bg-gray);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
._app-name_1scctn {
  color: var(--black-900);
}

._fileid-key_1scctn {
  color: var(--black-300);
}
._fileid-value_1scctn {
  color: var(--black-900);
}
._version-info_1scctn {
  font-size: 0.95em;
}
._version_1scctn {
  width: 100%;
  word-break: break-word;
}
._version-key_1scctn {
  color: var(--black-300);
}
._version-value_1scctn {
  color: var(--black-900);
}
._uploaded_1scctn {
  color: var(--black-300);
}
._summary_1scctn {
  padding-right: 2rem;
}

._pagination_1scctn {
  margin: 1rem -3px;
}

._loading-error_1scctn {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--loading-error-color);
  align-items: center;
  justify-content: center;
}

._loading_1scctn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}

._empty-data_1scctn {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--empty-state-color);
  font-style: italic;
  align-items: center;
  justify-content: center;
}

/* styles for irene/components/partner/client-uploads-stat-chart/index.scss */
._title_1toiua {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

._header_1toiua {
  line-height: 3rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--black-600);
}

._body_1toiua {
  border: 1px solid var(--bg-gray-darken-050);
  border-radius: 0.3rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  background: var(--bg-gray-lighten-015);
}

._filter_1toiua {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 15px;

  ._date-range-picker_1toiua {
    ._date-range_1toiua {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 8px;
      justify-content: left;
      align-items: center;
      border: 1px solid var(--bg-gray-darken-050);
      background: var(--white);
      border-radius: 0.3rem;
      padding: 0.4rem 1rem;

      ._date_1toiua {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  ._duration-options_1toiua {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: right;

    ._option_1toiua {
      border: 1px solid var(--primary);
      border-radius: 12px;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      color: var(--primary);
      border-radius: 0;
      &:hover,
      &:active,
      &:focus {
        background-color: var(--primary-transparent-005);
      }
      &:first-child {
        border-top-left-radius: 0.34rem;
        border-bottom-left-radius: 0.34rem;
      }
      &:last-child {
        border-top-right-radius: 0.34rem;
        border-bottom-right-radius: 0.34rem;
      }

      // Disable border for first two ele
      &:nth-child(-n + 2) {
        border-right: none;
      }

      &._active_1toiua {
        &,
        &:hover,
        &:active,
        &:focus {
          background-color: var(--primary);
          color: var(--white);
        }
      }
    }
  }
}

._tooltip_1toiua {
  border: 1px solid var(--bg-gray-darken-080);
  width: max-content;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: $white;
}

._tt-val-label_1toiua {
  font-weight: bold;
}

._loading-spinner_1toiua {
  position: absolute;
  top: 50%;
  left: 50%;
}

/* styles for irene/components/partner/clients-nav/index.scss */
._subnav_bhhamr {
  background-color: var(--bg-gray-darken-040);
  margin: 0 -10px;
  padding: 0.4rem 1.5rem 0;
  border-bottom: 1px solid var(--bg-gray-darken-080);
}

._subnav-link_bhhamr {
  padding: 0.45rem 1.2rem 0.5rem;
  margin-right: 0.1rem;
  display: inline-block;
  color: inherit;
  margin-bottom: -1px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--bg-gray-darken-080);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: var(--bg-gray-darken-020);
  &._active_bhhamr {
    background-color: var(--white);
    color: var(--primary);
    border-color: var(--bg-gray-darken-080) var(--bg-gray-darken-080)
      var(--white) var(--bg-gray-darken-080);
  }
}

/* styles for irene/components/partner/credit-transfer/credit-transfer-confirm/index.scss */
._row_1dqn0k {
  display: flex;
  padding: 0.2rem 0;
}

._key_1dqn0k {
  width: 30%;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
}

._value_1dqn0k {
  width: 70%;
  display: flex;
  align-items: center;
}

._action-btns_1dqn0k {
  display: flex;
  justify-content: center;
}

._empty-title_1dqn0k {
  font-style: italic;
}

/* styles for irene/components/partner/credit-transfer/credit-transfer-input/index.scss */
._client_1p5fmz {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 1rem;
  justify-content: center;
}

._invalid-credits_1p5fmz {
  color: $red;
  font-size: 0.8rem;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: center;
}

._title_1p5fmz {
  text-transform: capitalize;
  font-weight: bold;
  color: var(--black-700);
  max-height: 92%;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &._empty-title_1p5fmz {
    color: $secondary;
    font-style: italic;
  }
}

._credit-tranfer_1p5fmz {
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 1.5rem;
}

._transferable-credits_1p5fmz {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--black);
  text-align: center;
}

._tranfer-count_1p5fmz {
  display: flex;
  align-items: center;
}

._input-count_1p5fmz {
  width: 8rem;
  height: 2rem;
  border: none;
  border-bottom: 1px solid var(--black);
  color: var(--black);
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}

._credit-type_1p5fmz {
  width: 3em;
  text-transform: lowercase;
  font-size: 1rem;
  font-weight: bold;
}

._tranfer-btn_1p5fmz {
  justify-self: center;
}
._disabled-btn_1p5fmz {
  opacity: 0.5;
  cursor: default;
}

/* styles for irene/components/partner/credit-transfer/index.scss */
._disabled-btn_zjw35y {
  opacity: 0.5;
  cursor: default;
}

/* styles for irene/components/partner/export-stats/index.scss */
._header_h72av9 {
  line-height: 3rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--black-600);
}
._body_h72av9 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-gray-lighten-015);
  padding: 2rem;
  border: 1px solid var(--bg-gray-darken-050);
  border-radius: 0.3rem;
}

._date-range-picker_h72av9 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14rem;
  padding: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--bg-gray-darken-080);
  border-radius: 0.3rem;
  background-color: var(--white);
}

._date-range_h72av9 {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

/* styles for irene/components/partner/invitation-list/index.scss */
._header_13iccc {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
}

._title_13iccc {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--black-600);
  margin-bottom: 0 !important;
}

._table_13iccc {
  border: 1px solid var(--border-table);
  border-radius: 0.3rem;
  margin-top: 0.7rem;
}

._table-header_13iccc {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  background-color: var(--bg-gray);
  word-break: break-all;
  grid-template-columns: 1fr 20% 8rem 6rem 6.5rem 6rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 25% 9rem 7rem 6.5rem 6rem;
  }
}

._table-row_13iccc {
  border-top: 1px solid var(--border-table);
}

._pagination_13iccc {
  margin: 1rem -3px;
}

._loading-error_13iccc {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--loading-error-color);
  align-items: center;
  justify-content: center;
}

._loading_13iccc {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}

._empty-data_13iccc {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--empty-state-color);
  font-style: italic;
  align-items: center;
  justify-content: center;
}

/* styles for irene/components/partner/invitation/index.scss */
._invitation_1lsv64 {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0.8rem;
  grid-template-columns: 1fr 20% 8rem 6rem 6.5rem 6rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 25% 9rem 7rem 6.5rem 6rem;
  }
}
._invitation-expired_1lsv64 {
  background-color: var(--bg-gray-lighten-015);
  opacity: 0.6;
}
._user_1lsv64 {
  padding-right: 1rem;
}
._email_1lsv64 {
  color: var(--black-700);
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.2;
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
._name_1lsv64 {
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-top: 0.3rem;
  font-size: 0.9rem;
}
._company_1lsv64 {
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-right: 1rem;
  font-size: 0.92rem;
}
._date_1lsv64 {
  font-style: italic;
  font-size: 0.9rem;
  padding-right: 0.5rem;
}
._expired_1lsv64 {
  color: var(--text-error);
  text-transform: lowercase;
}
._source_1lsv64 {
  font-size: 0.85rem;
  padding: 0.1rem 0.6rem 0.2rem;
  display: inline-block;
  border-radius: 0.3rem;
}
._source-backoffice_1lsv64 {
  color: var(--label-orange-color);
  background-color: var(--label-orange-bg);
}
._source-invitation_1lsv64 {
  color: var(--label-green-color);
  background-color: var(--label-green-bg);
}
._source-registration_1lsv64 {
  color: var(--label-blue-color);
  background-color: var(--label-blue-bg);
}
._actions_1lsv64 {
  display: flex;
  justify-content: flex-end;
}

/* styles for irene/components/partner/invite-client/index.scss */
._submit_cwunzk {
  display: flex;
  justify-content: flex-end;
}
._required-marker_cwunzk {
  color: var(--primary);
}

/* styles for irene/components/partner/partner-plan/index.scss */
._partner-plan_2ycn0d {
  display: flex;
  justify-content: center;
  align-items: center;
}

._plan_2ycn0d {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: auto;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--bg-gray-darken-040);
}

._plan-key_2ycn0d {
  display: flex;
  flex-direction: column;
}
._plan-label_2ycn0d {
  color: var(--black);
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
  text-align: center;
}
._plan-type_2ycn0d {
  border-radius: 0.2rem;
  height: 1.2rem;
  width: 4.8rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  &._per-app_2ycn0d {
    color: var(--label-blue-color);
    background-color: var(--label-blue-bg);
  }

  &._per-scan_2ycn0d {
    color: var(--label-green-color);
    background-color: var(--label-green-bg);
  }
}

._plan-value_2ycn0d {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-gray-darken-040);
  padding: 0.35rem 0.6rem;
  border-radius: 0.4rem;
  margin-left: 0.5rem;
}

._credits-left_2ycn0d {
  color: $primary-color;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: bold;
}

._credits-type_2ycn0d {
  text-transform: lowercase;
  font-size: 0.8rem;
  line-height: 1.2;
  font-weight: bold;
}

/* styles for irene/components/partner/registration-request-pending-list/index.scss */
._title_1ledau {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--black-600);
  padding-top: 1.5rem;
  margin-bottom: 0.6rem;
}

._table_1ledau {
  border: 1px solid var(--border-table);
  border-radius: 0.3rem;
  margin-top: 0.7rem;
}
._table-header_1ledau {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  background-color: var(--bg-gray);
  grid-template-columns: 1fr 21% 14% 12rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 30% 11.5rem 12rem;
  }
}
._table-row_1ledau {
  border-top: 1px solid var(--border-table);
}

._pagination_1ledau {
  margin: 1rem -3px;
}

._loading_1ledau {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}

._loading-error_1ledau {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--loading-error-color);
  align-items: center;
  justify-content: center;
}
._empty-data_1ledau {
  display: flex;
  height: 7.5rem;
  background: var(--bg-gray);
  border: 1px solid var(--bg-gray-darken-040);
  border-radius: 0.3rem;
  color: var(--empty-state-color);
  font-style: italic;
  align-items: center;
  justify-content: center;
}

/* styles for irene/components/partner/registration-request-pending/index.scss */
._request_1dp04c {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0.8rem;
  grid-template-columns: 1fr 21% 14% 12rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 30% 11.5rem 12rem;
  }
}
._user_1dp04c {
  padding-right: 1rem;
}
._email_1dp04c {
  color: var(--black-700);
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.2;
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
._name_1dp04c {
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-top: 0.3rem;
  font-size: 0.9rem;
}
._company_1dp04c {
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-right: 1rem;
  font-size: 0.92rem;
}
._date_1dp04c {
  font-style: italic;
  font-size: 0.9rem;
  padding-right: 0.5rem;
}
._actions_1dp04c {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* styles for irene/components/partner/registration-request-rejected-list/index.scss */
._title_7akxsc {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--black-600);
  padding-top: 1.5rem;
  margin-bottom: 0.6rem;
}

._table_7akxsc {
  border: 1px solid var(--border-table);
  border-radius: 0.3rem;
  margin-top: 0.7rem;
}
._table-header_7akxsc {
  display: grid;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  background-color: var(--bg-gray);
  grid-template-columns: 1fr 20% 15% 15% 3.6rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 30% 10rem 10rem 3.6rem;
  }
}
._table-row_7akxsc {
  border-top: 1px solid var(--border-table);
}

._pagination_7akxsc {
  margin: 1rem -3px;
}

._loading_7akxsc {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}

/* styles for irene/components/partner/registration-request-rejected/index.scss */
._request_is8ggz {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0.8rem;
  grid-template-columns: 1fr 20% 15% 15% 3.6rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 30% 10rem 10rem 3.6rem;
  }
}
._user_is8ggz {
  padding-right: 1rem;
}
._email_is8ggz {
  color: var(--black-700);
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.2;
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
._name_is8ggz {
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-top: 0.3rem;
  font-size: 0.9rem;
}
._company_is8ggz {
  word-break: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  padding-right: 1rem;
  font-size: 0.92rem;
}
._date_is8ggz {
  font-style: italic;
  font-size: 0.9rem;
  padding-right: 0.5rem;
}
._actions_is8ggz {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* styles for irene/components/partner/styles.scss */
._header_1x0fah {
  display: flex;
  justify-content: space-between;
  background-color: var(--bg-gray);
  margin: -10px -10px 0 -10px;
}
._header-left_1x0fah {
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
._header-title_1x0fah {
  color: var(--black);
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.5;
}
._header-right_1x0fah {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 1.8rem;
}
._nav_1x0fah {
  display: flex;
  justify-content: flex-end;
}
._nav-link_1x0fah {
  padding: 0.3rem 1rem;
  border-bottom: 3px solid transparent;
  color: inherit;
  &._active_1x0fah {
    border-bottom-color: var(--primary);
    color: var(--black);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--black);
  }
}

/* styles for irene/components/password-recover/index.scss */
._container_1seek4 {
  width: 100%;
  margin-top: 3em;
}

._flex-center_1seek4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

._form_1seek4 {
  width: 25%;
  min-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 2em 1.6em;
  box-sizing: border-box;
}

._text-center_1seek4 {
  text-align: center;
}

._reset-success-text_1seek4 {
  font-size: 1.1rem;
}

._reset-success-subtext_1seek4 {
  color: var(--black-400);
  padding-top: 1rem;
}

._send-reset-mail-btn_1seek4 {
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

._input-wrapper_1seek4 {
  margin: 1em 0;

  &._has-error_1seek4 ._input-field_1seek4 {
    border: 1px solid #fe4d3f;
  }

  ._input-field_1seek4 {
    margin: 0.2em 0;
    appearance: none;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    color: #6b6b6b;
    height: 3em;
    padding: 0 1em;
    position: relative;
    width: 100%;
    max-width: 100%;
    box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
  }
}

._error-msg_1seek4 {
  font-size: 0.9em;
  color: #fe4d3f;
}

._login-link_1seek4 {
  margin: 10px 0;
}

/* styles for irene/components/plus-n-list/index.scss */
._initial-container_qwfmmy {
  display: flex;
  align-items: center;
}

._first-item_qwfmmy {
  color: var(--black-700);
}

._list-item_qwfmmy:not(:last-child) {
  margin-bottom: 0.6rem;
}

._show-more-count-badge_qwfmmy {
  padding: 0 0.36rem 0 0.3rem;
  display: flex;
  align-items: center;
  background: var(--bg-gray-darken-060);
  min-width: 1.95em;
  height: 1.5em;
  border-radius: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.82em;
  font-weight: 600;
  border: none;

  i {
    font-size: 0.8rem;
    padding: 0 0.1rem;
    margin-right: 0.5rem;
  }
}

/* styles for irene/components/project-list/index.scss */
._filters_atifbq {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em;
}

._no-result-found_atifbq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
  box-sizing: border-box;
}

._sorting-select_atifbq {
  text-overflow: ellipsis;
  padding-right: 1.8125em;
}

._no-project-container_atifbq {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  box-sizing: border-box;

  ._no-project_atifbq {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    padding: 10px;
    border: 1px dashed var(--primary);
    border-radius: 10px;
    text-align: center;
  }
}

._project-grid_atifbq {
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2em;
}

/* styles for irene/components/project-settings/analysis-settings/index.scss */
._settings-analysis-container_11guxq {
  border: 1px solid var(--project-settings-analysis-settings-border-color);
  border-top: 0;
  border-radius: var(--project-settings-analysis-settings-border-radius);
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

._section-divider_11guxq {
  border-bottom: 1px solid
    var(--project-settings-analysis-settings-border-color);
}

/* styles for irene/components/project-settings/analysis-settings/vulnerability-details/index.scss */
._vulnerability-details-container_1ywbqi {
  height: 62px;
  border-bottom: 1px solid
    var(--project-settings-analysis-settings-vulnerability-details-border-color);
  color: var(--text-primary);
}

._edit-details-container_1ywbqi {
  border-bottom: 1px solid
    var(--project-settings-analysis-settings-vulnerability-details-border-color);
}

._comment-textarea_1ywbqi {
  width: 100%;
  border-color: var(
    --project-settings-analysis-settings-vulnerability-details-border-color
  );
  border-radius: var(
    --project-settings-analysis-settings-vulnerability-details-border-radius
  );
  padding: 1em;
  margin-bottom: 1em;
}

/* styles for irene/components/project-settings/analysis-settings/vulnerability-list/index.scss */
._warning-message_14crfl {
  border: 1px solid
    var(
      --project-settings-analysis-settings-vulnerability-list-border-color-warning
    );
  border-radius: var(
    --project-settings-analysis-settings-vulnerability-list-border-radius
  );
  background-color: var(
    --project-settings-analysis-settings-vulnerability-list-background-color-warning
  );
}

._vulnerability-list-header_14crfl {
  padding: 0.625em 2em 0.625em 1.25em;
  border-radius: var(
    --project-settings-analysis-settings-vulnerability-list-border-radius
  );
  border: 1px solid
    var(--project-settings-analysis-settings-vulnerability-list-border-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(
    --project-settings-analysis-settings-vulnerability-list-background-color
  );
}

._vulnerability-list-body_14crfl {
  border: 1px solid
    var(--project-settings-analysis-settings-vulnerability-list-border-color);
}

/* styles for irene/components/project-settings/empty-list/index.scss */
._empty-container_1gdaof {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 150px;
  border-radius: var(--project-settings-empty-list-border-radius);
  border: 1px solid var(--project-settings-empty-list-border-color);
  box-sizing: border-box;

  ._empty-svg_1gdaof {
    height: 120px;
  }
}

/* styles for irene/components/project-settings/general-settings/collaborators-table/index.scss */
._collaborators-table_1a9pjm {
  tbody tr td:last-of-type,
  thead tr th:last-of-type {
    padding-right: 2em;
  }
}

/* styles for irene/components/project-settings/general-settings/dynamicscan-automation-settings/index.scss */
._script-details-container_153pjo {
  box-shadow: var(
    --project-settings-general-settings-dynamicscan-automation-settings-box-shadow
  );
  border-radius: var(
    --project-settings-general-settings-dynamicscan-automation-settings-border-radius
  );
  border: 1px solid
    var(
      --project-settings-general-settings-dynamicscan-automation-settings-border-color
    );

  ._script-file-details_153pjo {
    border-top: 1px solid
      var(
        --project-settings-general-settings-dynamicscan-automation-settings-border-color
      );

    ._text-with-icon_153pjo {
      gap: 0.2587em;
    }
  }
}

._schedule-automation-toggle_153pjo {
  box-shadow: var(
    --project-settings-general-settings-dynamicscan-automation-settings-box-shadow
  );
}

/* styles for irene/components/project-settings/general-settings/dynamicscan-automation-settings/scenario-table/index.scss */
._dast-scenario-table_14t73c {
  tbody tr {
    cursor: pointer;
  }

  tbody tr td:last-of-type,
  thead tr th:last-of-type {
    padding-right: 2em;
  }
}

/* styles for irene/components/project-settings/general-settings/dynamicscan-automation-settings/scenario-table/status-header/index.scss */
._tooltip-content_fwl2v8 {
  width: 264px;
  padding: 0.5em;
  box-sizing: border-box;
  white-space: normal;
}

/* styles for irene/components/project-settings/general-settings/dynamicscan-automation-settings/scenario/index.scss */
._scenario-name-form-control_1rrd9y {
  background-color: var(
    --project-settings-general-settings-dynamicscan-automation-scenario-textfield-bg
  );
}

._scenario-form-footer-container_1rrd9y {
  box-shadow: var(
    --project-settings-general-settings-dynamicscan-automation-scenario-form-footer-box-shadow
  );
}

/* styles for irene/components/project-settings/general-settings/dynamicscan-automation-settings/scripts-archived/index.scss */
._script-details-container_ya32p3 {
  box-shadow: var(
    --project-settings-general-settings-dynamicscan-automation-settings-box-shadow
  );
  border-radius: var(
    --project-settings-general-settings-dynamicscan-automation-settings-border-radius
  );
  border: 1px solid
    var(
      --project-settings-general-settings-dynamicscan-automation-settings-border-color
    );

  ._script-file-details_ya32p3 {
    border-top: 1px solid
      var(
        --project-settings-general-settings-dynamicscan-automation-settings-border-color
      );

    ._text-with-icon_ya32p3 {
      gap: 0.2587em;
    }
  }
}

/* styles for irene/components/project-settings/general-settings/github-project/index.scss */
._threshold-risk-label_mkg62a {
  padding: 0.2587em 0.4286em;
  width: fit-content;
}

._threshold-risk-divider_mkg62a {
  display: flex;
  width: 1px;
  height: 30px;
  background-color: var(
    --project-settings-general-settings-github-project-background-color
  );
  margin-left: 0.7143em;
  margin-right: 0.7143em;
}

/* styles for irene/components/project-settings/general-settings/index.scss */
._general-settings-root_13vh14 {
  border: 1px solid var(--project-settings-general-settings-root-border-color);
  border-top: 0em;
  background-color: var(
    --project-settings-general-settings-root-background-color
  );

  ._settings-section_13vh14 {
    max-width: 800px;
  }
}

/* styles for irene/components/project-settings/general-settings/jira-project/index.scss */
._threshold-risk-label_1ffth5 {
  padding: 0.2587em 0.4286em;
  width: fit-content;
}

._threshold-risk-divider_1ffth5 {
  display: flex;
  width: 1px;
  height: 30px;
  background-color: var(
    --project-settings-general-settings-jira-project-background-color
  );
  margin-left: 0.7143em;
  margin-right: 0.7143em;
}

/* styles for irene/components/project-settings/general-settings/project-team-table/index.scss */
._teams-table_3f7i61 {
  tbody tr td:last-of-type,
  thead tr th:last-of-type {
    padding-right: 2em;
  }
}

/* styles for irene/components/project-settings/header/index.scss */
._project-settings-header-root_lbf5t {
  position: sticky;
  z-index: 10;
  top: -0.5em;
  background-color: var(--project-settings-header-background-color);
  padding-top: 1.2857em;

  ._settings-header-info-text_lbf5t {
    color: var(--project-settings-header-info-text-color);
  }

  ._file-icon_lbf5t {
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    border-radius: var(--project-settings-header-border-radius);
    border: 1px solid var(--project-settings-header-border-color);
    box-sizing: border-box;

    img {
      object-fit: contain;
      background-image: none;
      overflow: hidden;
    }
  }

  ._project-details-card_lbf5t {
    border: 1px solid var(--project-settings-header-border-color);
    border-radius: var(--project-settings-header-border-radius);
    box-shadow: var(--project-settings-header-project-details-box-shadow);
  }

  ._settings-header-tabs_lbf5t {
    border: 1px solid var(--project-settings-header-border-color);
    border-radius: var(--project-settings-header-border-radius)
      var(--project-settings-header-border-radius) 0 0;
    margin-top: 0.75em;
    box-shadow: var(--project-settings-header-tabs-box-shadow);
  }

  // Icon styles
  ._platform-android_lbf5t {
    color: var(--project-settings-header-color-android);
  }

  ._platform-apple_lbf5t {
    color: var(--project-settings-header-color-ios);
  }
}

/* styles for irene/components/project-settings/loader/index.scss */
._loading-container_1j8utc {
  width: 100%;
  background: var(--project-settings-loading-container-background);
  border: 1px solid var(--project-settings-loading-container-border);
  border-radius: var(--project-settings-loading-container-border-radius);
  padding: 2em;
}

/* styles for irene/components/project-settings/page-wrapper/index.scss */
._project-settings-page-wrapper_15pko6 {
  padding: 1.4286em;
  padding-top: 0em;
  margin: -0.7143em -0.5em;
  background-color: var(--project-settings-page-wrapper-background-color);
  min-height: calc(100vh - 56px);
}

/* styles for irene/components/project-settings/view-scenario/add-parameter-form/index.scss */
._scenario-add-form-container_o2dhfl {
  width: 100%;
  display: grid;
  grid-template-columns: 260px 260px 80px;
  column-gap: 2.1428em;
  align-content: center;
  min-width: 800px;
  border: 1px solid var(--project-settings-view-scenario-border-light);
  background: var(--project-settings-view-scenario-background-dark);
  margin-bottom: 1.4286em;

  ._add-parameter-text-field-form-root_o2dhfl {
    background-color: var(--project-settings-view-scenario-background-white);
  }

  ._tooltip-content_o2dhfl {
    width: 264px;
    padding: 0.5em;
    box-sizing: border-box;
  }
}

/* styles for irene/components/project-settings/view-scenario/details-column-header/index.scss */
._scenario-details-columns-container_1qo5j9 {
  width: 100%;
  display: grid;
  grid-template-columns: 260px 260px 80px;
  column-gap: 2.1428em;
  align-content: center;
  min-width: 800px;
  padding-top: 0.7857em;
  padding-bottom: 0.7857em;

  ._tooltip-content_1qo5j9 {
    width: 264px;
    padding: 0.5em;
    box-sizing: border-box;
  }
}

/* styles for irene/components/project-settings/view-scenario/header/index.scss */
._view-scenario-header-root_pwt0e8 {
  background-color: var(--project-settings-view-scenario-background-white);
}

._view-scenario-header-root_pwt0e8 {
  border: 1px solid var(--project-settings-view-scenario-header-border-color);
  border-radius: var(--project-settings-view-scenario-header-border-radius);
  box-shadow: var(
    --project-settings-view-scenario-header-scenario-details-box-shadow
  );
}

._scenario-delete-footer-container_pwt0e8 {
  box-shadow: var(--project-settings-view-scenario-modal-footer-box-shadow);
}

/* styles for irene/components/project-settings/view-scenario/index.scss */
._empty-secanario-container_1aolxr {
  padding: 5.7143em 3.7143em;
  background-color: var(--project-settings-view-scenario-background-white);
}

/* styles for irene/components/project-settings/view-scenario/parameter-item/index.scss */
._scenario-details-group_11chd9 {
  width: 100%;
  column-gap: 2.1428em;
  display: grid;
  grid-template-columns: 260px 260px 80px 1fr;
  align-content: center;
  min-width: 800px;
  min-height: 60px;
  background-color: var(--project-settings-view-scenario-background-white);
  border-bottom: 1px solid
    var(--project-settings-view-scenario-parameter-item-border-color);

  ._details-delete-btn_11chd9,
  ._secure-icon_11chd9._hide_11chd9 {
    display: none;
  }

  &:hover ._details-delete-btn_11chd9,
  &:hover ._secure-icon_11chd9._hide_11chd9,
  ._secure-icon_11chd9._show_11chd9 {
    display: flex;
  }

  ._parameter-value-text-field-form-control_11chd9 {
    background-color: var(
      --project-settings-view-scenario-parameter-text-field-background
    );
  }

  ._parameter-value-text-field-root_11chd9 {
    cursor: text;
    background: transparent;
  }

  ._parameter-value-text-field_11chd9 {
    & ~ .ak-text-input-outlined {
      border: 0px solid var(--project-settings-view-scenario-border-light) !important;
    }

    &:active ~ .ak-text-input-outlined,
    &:focus ~ .ak-text-input-outlined {
      border-width: 2px !important;
    }
  }
}

._parameter-modal-footer-container_11chd9 {
  box-shadow: var(--project-settings-view-scenario-modal-footer-box-shadow);
}

/* styles for irene/components/proxy-settings-view/index.scss */
._bordered-box_16nv9q {
  border: 1px solid var(--proxy-settings-view-border-color);
  padding: 1.25em;
  box-sizing: border-box;
}

._api-proxy-toggle_16nv9q {
  margin-left: 0;
  margin-right: 0;
}

/* styles for irene/components/register-via-invite/index.scss */
._container_1ymzua {
  height: 100vh;
}

._inner-container_1ymzua {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 3em;
}

._register-title_1ymzua {
  margin: 1.5em 0em 0em 0em;
}

._spacing_1ymzua {
  padding: 0em 3em 0em 3em;
  min-width: 30em;
}

@mixin bg-register {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  margin: 1em 1em 3em 1em;
}

._register-form_1ymzua {
  @include bg-register;
  min-width: 42em;
}

._register-form-accept_1ymzua span {
  margin-left: 0.7em;
}

._checkbox-field_1ymzua {
  display: inline-block;
}

._register-button_1ymzua {
  width: 100%;
  height: 3em;
  margin-bottom: 3em;
}

._loading_1ymzua {
  text-align: center;
  font-size: 1.5em;
  margin: 5em 0;
}

._wrong-location_1ymzua {
  text-align: center;
  font-size: 1.1em;
  margin: 5em 0;
}

/* styles for irene/components/registration/index.scss */
@mixin bg-register {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  margin: 1em 1em 3em 1em;
}

._container_1vhi1o {
  height: 100vh;
}

._inner-container_1vhi1o {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 3em;
}

._register-form_1vhi1o {
  @include bg-register;
}

._register-title_1vhi1o {
  margin: 1.5em 0em 0em 0em;
}

._spacing_1vhi1o {
  padding: 0em 3em 0em 3em;
  min-width: 30em;
}

._login-footer-container_1vhi1o {
  display: flex;
  justify-content: center;
  padding: 1em 0em;
}

._login-footer-container-link_1vhi1o {
  margin-left: 0.5em;
}

._register-button_1vhi1o {
  width: 100%;
  height: 3em;
}

._register-button-container_1vhi1o {
  width: 100%;
  text-align: center;
}

._register-confirm_1vhi1o {
  @include bg-register;
  display: flex;
  padding: 2em 1em;
  display: None;
  flex-direction: column;
  overflow: hidden;
}

._register-confirm-icon_1vhi1o {
  font-size: 8em;
  margin: auto;
  color: #80c081;
}

._register-confirm-text_1vhi1o {
  font-size: 1.2em;
  padding: 1em 1em 0;
}

._is_success_1vhi1o ._register-confirm_1vhi1o {
  display: flex;
}

._is_success_1vhi1o ._register-form_1vhi1o {
  display: none;
}

/* styles for irene/components/regulatory-preference-organization/index.scss */
._preferences-container_1p2hhk {
  max-width: 600px;
}

/* styles for irene/components/risk-tag/index.scss */
._risk-tag_224qj4 {
  width: 115px;
  height: 26px;
  font-family: var(--risk-tag-font-family);
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: var(--risk-tag-border-radius);
  white-space: nowrap;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  vertical-align: middle;
  box-sizing: border-box;
  color: inherit;

  ._risk-tag-label_224qj4 {
    color: var(--risk-tag-text-color);
  }

  ._risk-tag-icon_224qj4 {
    color: var(--risk-tag-text-color);
    font-size: 1rem !important;
  }
}

._is-critical_224qj4 {
  background-color: var(--risk-tag-color-critical);
}

._is-danger_224qj4 {
  background-color: var(--risk-tag-color-high);
}

._is-warning_224qj4 {
  background-color: var(--risk-tag-color-medium);
}

._is-info_224qj4 {
  background-color: var(--risk-tag-color-low);
}

._is-success_224qj4 {
  background-color: var(--risk-tag-color-passed);
}

._is-default_224qj4 {
  background-color: var(--risk-tag-color-none);
}

._is-waiting_224qj4,
._is-progress_224qj4 {
  border: 1px solid var(--risk-tag-color-waiting);

  ._risk-tag-label_224qj4 {
    color: var(--risk-tag-color-waiting);
  }
}

._is-errored_224qj4 {
  border: 1px solid var(--risk-tag-color-critical);

  ._risk-tag-label_224qj4 {
    color: var(--risk-tag-color-critical);
  }
}

/* styles for irene/components/sbom/app-logo/index.scss */
._app-logo-container_1ba9cm {
  width: 40px;
  height: 40px;
  padding: 0.5em;
  border-radius: var(--sbom-app-logo-border-radius);
  border: 1px solid var(--sbom-app-logo-border-color);
  box-sizing: border-box;

  img {
    object-fit: contain;
    background-image: none;
    overflow: hidden;
  }
}

/* styles for irene/components/sbom/app-platform/index.scss */
._app-platform-icon-container_vl3ler {
  width: 40px;
  height: 40px;
  padding: 0.25em;
  border-radius: var(--sbom-app-platform-border-radius);
  box-sizing: border-box;

  &._bordered_vl3ler {
    border: 1px solid var(--sbom-app-platform-border-color);
  }
}

._app-platform-icon-apple_vl3ler {
  color: var(--sbom-app-platform-color-ios);
}

._app-platform-icon-android_vl3ler {
  color: var(--sbom-app-platform-color-android);
}

._app-platform-icon_vl3ler {
  font-size: 1.5rem;
  vertical-align: unset;
}

/* styles for irene/components/sbom/component-details/vulnerabilities/detail-wrapper/index.scss */
._vulnerability-detail-content-root_123qps {
  width: calc(100% - 2.4em);
  margin: 0.25em 1.2em 1.2em;
  background-color: var(
    --sbom-component-details-vulnerabilities-detail-wrapper-background-color-light
  );
  border-radius: var(
    --sbom-component-details-vulnerabilities-detail-wrapper-border-radius
  );
  border: 1px solid
    var(--sbom-component-details-vulnerabilities-detail-wrapper-border-color);
  box-sizing: border-box;
}

._empty-container_123qps {
  width: 100%;
  padding: 2em;
  background-color: var(
    --sbom-component-details-vulnerabilities-detail-wrapper-background-color-main
  );
  border: 1px solid
    var(--sbom-component-details-vulnerabilities-detail-wrapper-border-color);
  border-radius: var(
    --sbom-component-details-vulnerabilities-detail-wrapper-border-radius
  );
}

._vulnerability-detail-content_123qps {
  max-width: 650px;
  padding: 2em;

  ._vulnerability-reference-list_123qps {
    list-style: unset;
    padding: unset;

    li {
      margin: 0.25em;
    }
  }
}

/* styles for irene/components/sbom/component-details/vulnerabilities/index.scss */
._vulnerability-list-header_b58vpp,
._vulnerability-detail-wrapper_b58vpp {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
}

._vulnerability-list-header_b58vpp {
  background-color: var(
    --sbom-component-details-vulnerabilities-list-header-background-color
  );
  border: 1px solid
    var(--sbom-component-details-vulnerabilities-list-header-border-color-dark);

  ._vulnerability-list-header-item_b58vpp {
    padding: 0.75em 1.2143em;
  }
}

._vulnerability-list-item_b58vpp {
  flex-direction: column;
  border: 1px solid
    var(--sbom-component-details-vulnerabilities-list-border-color-light);
  border-top: none;

  ._vulnerability-detail-wrapper_b58vpp {
    width: 100%;

    ._vulnerability-detail-cell_b58vpp {
      padding: 1em 1.2143em;
    }
  }
}

/* styles for irene/components/sbom/component-details/vulnerabilities/severity/index.scss */
._severity-chip_hwsq8h {
  width: 80px;
  color: var(
    --sbom-component-details-vulnerabilities-severity-chip-text-color
  ) !important;
}

._severity-chip-critical_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-critical
  ) !important;
}

._severity-chip-high_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-high
  ) !important;
}

._severity-chip-medium_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-medium
  ) !important;
}

._severity-chip-low_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-low
  ) !important;
}

._severity-chip-info_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-info
  ) !important;
}

._severity-chip-none_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-none
  ) !important;
}

._severity-chip-unknown_hwsq8h {
  background-color: var(
    --sbom-component-details-vulnerabilities-severity-unknown
  ) !important;
}

/* styles for irene/components/sbom/empty-loading-view/index.scss */
._empty-loading-container_ca9gry {
  width: 100%;
  border: 1px solid var(--sbom-empty-loading-view-border-color);
  border-radius: var(--sbom-empty-loading-view-border-radius);
  padding: 2em;
}

/* styles for irene/components/sbom/scan-details/index.scss */
._ak-link-btn_1ekpdh {
  padding: 0.4em 0.7em;
  border: 1px solid var(--sbom-scan-details-link-border-color);
  border-radius: var(--sbom-scan-details-link-border-radius);

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.5px var(--sbom-scan-details-link-border-color);
  }
}

._show-more-container_1ekpdh {
  width: 100%;
}

/* styles for irene/components/sbom/scan-report-drawer/app-details/index.scss */
._app-details-container_1fpb13 {
  padding: 0.5em 1.5em 1.5em;
  box-shadow: var(--sbom-scan-report-dr-app-details-box-shadow);
}

/* styles for irene/components/sbom/scan-report-drawer/report-list/item/index.scss */
._generate-container_1j0tyc {
  width: 100%;
  border: 1px solid var(--sbom-scan-report-dr-border-color);
  border-radius: var(--sbom-scan-report-dr-border-radius);
  padding: 1.5em;
  margin-bottom: 1em;
}

/* styles for irene/components/sbom/summary-header/index.scss */
._summary-header-root_xb74tq {
  border-radius: var(--sbom-summary-header-border-radius);
  border: 1px solid var(--sbom-summary-header-border-color);
  box-sizing: border-box;

  ._summary-header-container_xb74tq,
  ._summary-header-collapsible-container_xb74tq {
    padding: 0.75em 1em;
  }

  ._summary-header-collapsible-container_xb74tq {
    border-top: 1px solid var(--sbom-summary-header-border-color);
  }
}

/* styles for irene/components/security/analysis-report-btn/index.scss */
._report-btn_12qssn {
  display: flex;
  column-gap: 0.7rem;

  ._report-download_12qssn {
    cursor: pointer;

    ._dd-trigger_12qssn {
      ._trigger-item_12qssn {
          vertical-align: middle;
      }
    }

    ._dd-content_12qssn {
      margin-top: 0.5em;
      width: max-content;

      ._report-item_12qssn {
        color: var(--black);
        width: auto;
        align-items: center;
        padding: 0.5em 1em;
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 0.8rem;
        height: inherit;

        i {
          font-size: 1rem;
        }
      }
    }
  }
}

/* styles for irene/components/sso-settings/index.scss */
._sso-settings-header-container_18e999 {
  max-width: 600px;
}

._sso-settings-main-container_18e999 {
  max-width: 800px;
}

._bordered-box_18e999 {
  border: 1px solid var(--sso-settings-border-color);
  border-radius: var(--sso-settings-border-radius);
  box-sizing: border-box;

  ._bordered-box-section_18e999 {
    padding: 1em;
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid var(--sso-settings-border-color);
    }
  }

  ._full-bordered-box-section_18e999 {
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid var(--sso-settings-border-color);
    }

    ._full-bordered-box-section-column_18e999 {
      padding: 1em;

      &:last-child {
        border-left: 1px solid var(--sso-settings-border-color);
      }
    }
  }

  ._header-icon_18e999 {
    padding-right: 0.25em;
  }
}

._dropzone-upload-active_18e999 {
  box-shadow: inset 0 0 6px var(--sso-settings-dropzone-active-shadow-color);
}

._browse-file-btn_18e999 {
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.75em;
  color: var(--sso-settings-file-upload-btn-color);

  &:hover {
    text-decoration: underline;
    color: var(--sso-settings-file-upload-btn-color);
  }
}

/* styles for irene/components/system-status/index.scss */
._operational-status_od7kog {
  color: $red;
  padding-top: 0.2em;
}

._operational-text_od7kog {
  display: none;
}

._operational-status_od7kog._online_od7kog {
  color: $green;

  ._operational-text_od7kog {
    display: inline;
  }

  ._unavailable-text_od7kog {
    display: none;
  }
}

._status-label_od7kog,
._operational-status_od7kog {
  font-size: 1.2em;
}

._system-status-container_od7kog {
  margin: 50px 0;
  background: lighten($default, 50%);
  padding-bottom: 10px;
  padding-top: 20px;
  border-radius: 10px;

  ._system-status-box_od7kog {
    ._loading-indicator_od7kog {
      font-size: 1rem;
      text-transform: none;
      margin: 0 0.5rem;
    }

    li {
      text-transform: capitalize;
      position: relative;
      top: -0.4em;
    }

    li:before {
      position: relative;
      top: 6px;
      content: '• ';
      font-size: 30px;
    }

    small {
      position: relative;
      top: 10px;
      text-transform: none;
      display: block;
    }

    h4 {
      text-transform: uppercase;
      text-align: center;
    }

    ._system-status-table_od7kog {
      width: 97%;
      margin: 9px;
      padding-bottom: 0.3em;

      th {
        color: #000;
        padding: 20px;
        border-color: lighten($default, 50%);
      }

      td {
        padding: 0.5em;
        border: none;
        vertical-align: middle;
      }

      th {
        text-transform: uppercase;
        font-size: 1.1em;
      }

      td,
      th {
        text-align: center;
      }
    }
  }
}

/* styles for irene/components/three-bounce-spinner/styles.scss */
/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
._three-bounce-spinner_1hhvhy {
  width: 70px;
  text-align: center;

  >div {
    width: 18px;
    height: 18px;
    background-color: #EF4836;

    border-radius: 100%;
    display: inline-block;
    animation: _sk-bouncedelay_1hhvhy 1.4s infinite ease-in-out both;
  }

  ._bounce1_1hhvhy {
    animation-delay: -0.32s;
  }

  ._bounce2_1hhvhy {
    animation-delay: -0.16s;
  }
}

@keyframes _sk-bouncedelay_1hhvhy {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1.0);
  }
}

/* styles for irene/components/tri-state-checkbox/index.scss */
._container_196ml3 {
  width: 10rem;
  position: relative;

  ._overridden_196ml3 {
    position: relative;
    border: 1px solid var(--tri-state-checkbox-border-color);
    margin-top: 0.3rem;
    margin-bottom: 0.35rem;
    border-radius: 0.35rem;
    background: var(--tri-state-checkbox-overridden-bg);
  }

  ._reset-icon-btn-container_196ml3 {
    position: absolute;
    right: 0;
    top: 0px;
    transform: translateY(-47%) translateX(50%);
  }

  ._reset-icon_196ml3 {
    background-color: var(--tri-state-reset-icon-bg);
    border-radius: 100%;
  }
}

/* styles for irene/components/upload-app/status/details/index.scss */
._progress-card_1fvhgg {
  width: 100%;
  border: 1px solid var(--app-upload-status-details-footer-border-color);
  border-radius: 2px;
}

._upload-status-footer_1fvhgg {
  border-top: 1px solid var(--app-upload-status-details-footer-border-color);
  background: var(--app-upload-status-details-footer-background-color);
}

._file-icon_1fvhgg {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  box-sizing: border-box;

  img {
    object-fit: contain;
    background-image: none;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid var(--app-upload-status-details-image-border-color);
  }
}

._loader_1fvhgg {
  .ak-loader-linear-indicator {
    background-color: var(--app-upload-status-details-loader-background-color);
  }
}

/* styles for irene/components/upload-app/status/index.scss */
._submission-status-popover-container_nfqkjc {
  inset: 0;
}

._submission-status-dropdown-container_nfqkjc {
  margin-top: 10px;
  position: relative;
  width: 30em;
  background: var(--app-upload-status-dropdown-background-color);
  border: 1px solid var(--app-upload-status-dropdown-border-color);
  box-shadow: var(--app-upload-status-dropdown-box-shadow);
}

._green-loader_nfqkjc {
  .ak-loader-circle-indicator {
    stroke: var(--app-upload-status-green-loader-color) !important;
  }

  .ak-loader-svg-track {
    stroke: var(--app-upload-status-green-loader-track-color) !important;
  }
}

._divider_nfqkjc {
  border-left: 1px solid;
  height: 30px;
}

._cursor-pointer_nfqkjc {
  cursor: pointer;
}

._popup-height_nfqkjc {
  max-height: 70vh;
  overflow: auto;
}

/* styles for irene/components/upload-app/via-link/index.scss */
._upload-button-stack_nq4wzj {
  box-shadow: var(--app-upload-via-link-button-stack-shadow);
}

._link-icon_nq4wzj {
  color: var(--app-upload-via-link-button-color);
}

._upload-modal-container_nq4wzj {
  width: 450px;
}

._valid-url-format-list_nq4wzj {
  list-style: disc;
  padding-left: 1em;
  color: var(--app-upload-via-link-url-format-bullet-color);
}

/* styles for irene/components/upload-app/via-link/preview/index.scss */
._loading-preview-stack_1j6p5m {
  background-color: var(--app-upload-via-link-preview-background-color);
  border: 1px solid var(--app-upload-via-link-preview-border-color);
  border-radius: 2px;
  height: 125px;
}

._preview-stack_1j6p5m {
  border: 1px solid var(--app-upload-via-link-preview-background-color);
}

._store-link-stack_1j6p5m {
  background-color: var(--app-upload-via-link-preview-footer-background-color);
}

._preview-error-stack_1j6p5m {
  border: 1px solid var(--app-upload-via-link-preview-background-color);
  padding: 32px;
}

/* styles for irene/components/vnc-viewer/index.scss */
._vnc-viewer-root_9dz5a8 {
  padding: 1.5em;
}

._vnc-viewer-fullscreen_9dz5a8 {
  position: fixed;
  inset: 0px;
  z-index: var(--vnc-viewer-modal-zIndex);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._vnc-viewer-backdrop_9dz5a8 {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  background-color: var(--vnc-viewer-modal-backdrop-overlay-background);
  z-index: -1;
}

._vnc-viewer-fullscreen-container_9dz5a8 {
  padding: 2.5em;
  background-color: var(--vnc-viewer-modal-background);
  color: var(--vnc-viewer-modal-text-color);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
  flex: 1 0 auto;
  z-index: var(--vnc-viewer-modal-zIndex);
  position: fixed;
  outline: 0px;
}

/* styles for irene/styles/_color.scss */
/*------- sass vars for function start -------*/
/* these should not be used unless required for sass functions */
$black: #000000;

/*------- sass vars for function end -------*/

//---------- Theme variables--------------//
// Note: going forward only these variables should be used
// and any other existing variable should be replaced by these
body {
  --common-black: #000000;
  --common-white: #ffffff;

  --background-main: #ffffff;
  --background-dark: #424651;
  --background-light: #fafafa;

  --primary-main: #ff4d3f;
  --primary-dark: #e23123;
  --primary-light: rgba(254, 77, 63, 0.2);
  --primary-contrast-text: #ffffff;

  --primary-main-10: rgba(254, 77, 63, 0.1);

  --secondary-main: #424651;
  --secondary-contrast-text: #ffffff;

  --success-main: #2db421;
  // --success-dark -
  --success-light: #e9f5ed;

  --error-main: #d72f2f;
  // --error-dark -
  --error-light: #ffd1d4;

  --warn-main: #ffd52e;
  --warn-dark: #a5872d;
  --warn-light: #fff7d7;

  --info-main: #087edb;
  // --info-dark: Skip for now
  // --info-light: Skip for now

  --neutral-grey-50: #fcfcfc;
  --neutral-grey-100: #f5f5f5;
  --neutral-grey-200: #e9e9e9;
  --neutral-grey-300: #d9d9d9;
  --neutral-grey-400: #c4c4c4;
  --neutral-grey-500: #9d9d9d;
  --neutral-grey-600: #7b7b7b;
  --neutral-grey-700: #555555;
  --neutral-grey-800: #434343;
  --neutral-grey-900: #262626;

  --text-primary: #171717;
  --text-secondary: #7b7b7b;
  --text-disabled: #c4c4c4;

  --divider: #f5f5f5;
  --divider-dark: #e9e9e9;

  --border-radius: 2px;

  --border-color-1: #e9e9e9;
  --border-color-2: #c4c4c4;

  --box-shadow-1: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --box-shadow-2: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  --box-shadow-3: 4px 4px 15px rgba(0, 0, 0, 0.04);
  --box-shadow-4: 0px -4px 8px 4px rgba(0, 0, 0, 0.03);
  --box-shadow-5: 3px 4px 6px rgba(216, 216, 216, 0.25);
  --box-shadow-6: 0px 5px 14px 0px rgba(64, 64, 64, 0.05);
  --box-shadow-7: 0px 2px 6px 0px rgba(64, 64, 64, 0.15);
  --box-shadow-8: -5px 0px 9px 0px rgba(64, 64, 64, 0.03);
  --box-shadow-9: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

  --hover-dark-background: rgba(255, 255, 255, 0.15);
  --hover-light-background: rgba(0, 0, 0, 0.04);

  --backdrop-overlay-background: rgba(0, 0, 0, 0.25);

  --disabled-background-textfield: #f5f5f5;
  --disabled-background-button: #c4c4c4;

  --html-font-size: 14px;
  --font-family: 'Open Sans', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;

  --zIndex-appbar: 1100;
  --zIndex-drawer: 1200;
  --zIndex-modal: 1300;
  --zIndex-snackbar: 1400;
  --zIndex-tooltip: 1500;

  // special case variable for home-page/organization-dashboard/side-nav
  --side-menu-active-background-dark: #303339;
  --side-menu-active-background-light: rgba(254, 77, 63, 0.1);

  // special case variable for app-bar & side-nav
  --box-shadow-dark: 1px 0px 8px -1px rgba(0, 0, 0, 0.3);
  --box-shadow-light: 4px 4px 20px rgba(222, 222, 222, 0.25);

  // special case variable for severity
  --severity-critical: #d72f2f;
  --severity-high: #f98746;
  --severity-medium: #fad34a;
  --severity-low: #46cef9;
  --severity-info: #2141b4;
  --severity-passed: #2db421;
  --severity-none: #a0a0a0;
  --severity-untested: #a0a0a0;
  --severity-unknown: #202020;
}

/*------- static colors start -------*/
body {
  --white: #ffffff;
  --black: #{$black};

  --body-type-color: #171717;

  --am-drawer-header-title: #ff4d3f;
  --green-approval: #80c081;

  --neutral-white-100: #f1f1f1;
}
/*------- static colors end -------*/

/*------- text colors start ------*/
body {
  --text-black-91: #{lighten($black, 9%)};
  --body-text-1: var(--text-black-91);
  --text-primary-color: var(--text-black-91);
  --text-secondary-color: #9d9d9d;
}
/*------- text colors end ------*/

/*------- ember-attacher colors start ------*/
/* popover white */
body {
  --popover-white-bg: var(--white);
  --popover-white-border-color: var(--black-150);
}
/*------- ember-attacher colors end ------*/

/*------- input colors start ------*/
body {
  --input-border-color: #fff2f1;
}
/*------- input colors end ------*/

/* styles for irene/styles/_component-variables.scss */
body {
  // variables for ak-icon
  --ak-icon-color-primary: var(--primary-main);
  --ak-icon-color-secondary: var(--secondary-main);
  --ak-icon-color-text-primary: var(--text-primary);
  --ak-icon-color-text-secondary: var(--text-secondary);
  --ak-icon-color-error: var(--error-main);
  --ak-icon-color-success: var(--success-main);
  --ak-icon-color-warn: var(--warn-main);
  --ak-icon-color-info: var(--info-main);

  // variables for ak-loader
  --ak-loader-stroke-primary-main: var(--primary-main);
  --ak-loader-stroke-primary-light: var(--primary-light);
  --ak-loader-linear-root-bg-color: var(--neutral-grey-200);

  // variables for ak-popover
  --ak-popover-arrow-background-light: var(--background-main);
  --ak-popover-arrow-border-light: var(--border-color-1);
  --ak-popover-arrow-background-dark: var(--background-dark);
  --ak-popover-zIndex: var(--zIndex-tooltip);

  // variables for ak-popover
  --ak-tooltip-border-radius: var(--border-radius);
  --ak-tooltip-font-weight-regular: var(--font-weight-regular);
  --ak-tooltip-font-family: var(--font-family);
  --ak-tooltip-box-shadow-2: var(--box-shadow-2);
  --ak-tooltip-background-light: var(--background-main);
  --ak-tooltip-background-dark: var(--background-dark);
  --ak-tooltip-color-on-light: var(--text-primary);
  --ak-tooltip-color-on-dark: var(--common-white);
  --ak-tooltip-border-light: var(--border-color-1);
  --ak-tooltip-zIndex: var(--zIndex-tooltip);

  // variables for ak-toggle
  --ak-toggle-slider-background-color: var(--background-main);
  --ak-toggle-checked-background-color: var(--success-main);
  --ak-toggle-unchecked-background-color: var(--neutral-grey-300);

  // variables for ak-divider
  --ak-divider-color-light: var(--divider);
  --ak-divider-color-dark: var(--divider-dark);

  // variables for ak-link
  --ak-link-color-text-disabled: var(--text-disabled);
  --ak-link-color-primary: var(--primary-main);
  --ak-link-color-secondary: var(--secondary-main);
  --ak-link-color-text-primary: var(--text-primary);
  --ak-link-color-text-secondary: var(--text-secondary);
  --ak-link-color-error: var(--error-main);
  --ak-link-color-success: var(--success-main);
  --ak-link-color-warn: var(--warn-main);
  --ak-link-color-info: var(--info-main);

  // variables for ak-icon-button
  --ak-icon-button-color-text-disabled: var(--text-disabled);
  --ak-icon-button-color-text-primary: var(--text-primary);
  --ak-icon-button-border-radius: var(--border-radius);
  --ak-icon-button-border-color-default: var(--border-color-2);
  --ak-icon-button-border-color-primary: var(--primary-main);
  --ak-icon-button-border-color-secondary: var(--secondary-main);
  --ak-icon-button-hover-background: var(--hover-light-background);

  // variables for ak-list
  --ak-list-item-color-text-disabled: var(--text-disabled);
  --ak-list-item-hover-background: var(--hover-light-background);
  --ak-list-item-selected-background: var(--primary-light);
  --ak-list-item-color-text-primary: var(--text-primary);

  // variables for ak-menu
  --ak-menu-background: var(--background-main);
  --ak-menu-border-radius: var(--border-radius);
  --ak-menu-box-shadow: var(--box-shadow-2);
  --ak-menu-border-color: var(--border-color-1);
  --ak-menu-z-index: var(--zIndex-tooltip);

  // variables for ak-select
  --ak-select-form-helper-color: var(--text-secondary);
  --ak-select-error-color: var(--error-main);
  --ak-select-dropdown-background: var(--background-main);
  --ak-select-dropdown-border-radius: var(--border-radius);
  --ak-select-dropdown-box-shadow: var(--box-shadow-2);
  --ak-select-dropdown-border-color: var(--border-color-1);
  --ak-select-dropdown-zIndex: var(--zIndex-tooltip);
  --ak-select-option-color-text-primary: var(--text-primary);
  --ak-select-option-hover-background: var(--hover-light-background);
  --ak-select-option-selected-background: var(--primary-light);
  --ak-select-option-divider-color: var(--divider);
  --ak-select-trigger-color-text-primary: var(--text-primary);
  --ak-select-trigger-border-radius: var(--border-radius);
  --ak-select-trigger-border-color: var(--border-color-2);
  --ak-select-trigger-disabled-color: var(--text-secondary);
  --ak-select-trigger-disabled-background: var(--disabled-background-textfield);
  --ak-select-trigger-arrow-color: var(--text-primary);

  // variables for ak-modal
  --ak-modal-zIndex: var(--zIndex-modal);
  --ak-modal-backdrop-overlay-background: var(--backdrop-overlay-background);
  --ak-modal-border-radius: var(--border-radius);

  // variables for ak-table
  --ak-table-font-weight-regular: var(--font-weight-regular);
  --ak-table-font-weight-medium: var(--font-weight-medium);
  --ak-table-font-family: var(--font-family);
  --ak-table-color-text-primary: var(--text-primary);
  --ak-table-header-background-neutral: var(--neutral-grey-100);
  --ak-table-header-border-neutral: var(--border-color-1);
  --ak-table-border-light: var(--divider);
  --ak-table-border-dark: var(--divider-dark);
  --ak-table-hover-background: var(--hover-light-background);

  // variables for ak-checkbox
  --ak-checkbox-color-checked: var(--success-main);
  --ak-checkbox-color-indeterminate: var(--success-main);
  --ak-checkbox-color-text-disabled: var(--text-disabled);
  --ak-checkbox-hover-background: var(--hover-light-background);

  // variables for ak-button
  --ak-button-border-radius: var(--border-radius);
  --ak-button-color-primary: var(--primary-main);
  --ak-button-filled-primary-hover-background: var(--primary-dark);
  --ak-button-color-primary-contrast-text: var(--primary-contrast-text);
  --ak-button-color-secondary: var(--secondary-main);
  --ak-button-color-text-primary: var(--text-primary);
  --ak-button-color-text-secondary: var(--text-secondary);
  --ak-button-color-error: var(--error-main);
  --ak-button-color-success: var(--success-main);
  --ak-button-color-warn: var(--warn-main);
  --ak-button-color-info: var(--info-main);
  --ak-button-color-neutral: var(--neutral-grey-400);
  --ak-button-color-text-disabled: var(--text-disabled);
  --ak-button-disabled-background: var(--disabled-background-button);

  // variables for ak-chip
  --ak-chip-font-family: var(--font-family);
  --ak-chip-border-radius: var(--border-radius);
  --ak-chip-color-text-primary: var(--text-primary);
  --ak-chip-delete-btn-hover-background: var(--hover-light-background);

  --ak-chip-color-filled-default-background: var(--neutral-grey-100);
  --ak-chip-color-filled-default-contrast-text: var(--text-primary);
  --ak-chip-color-filled-primary-background: var(--primary-main);
  --ak-chip-color-filled-primary-contrast-text: var(--common-white);
  --ak-chip-color-filled-secondary-background: var(--secondary-main);
  --ak-chip-color-filled-secondary-contrast-text: var(--common-white);
  --ak-chip-color-filled-success-background: var(--success-main);
  --ak-chip-color-filled-success-contrast-text: var(--common-white);
  --ak-chip-color-filled-error-background: var(--error-main);
  --ak-chip-color-filled-error-contrast-text: var(--common-white);
  --ak-chip-color-filled-warn-background: var(--warn-main);
  --ak-chip-color-filled-warn-contrast-text: var(--common-white);
  --ak-chip-color-filled-info-background: var(--info-main);
  --ak-chip-color-filled-info-contrast-text: var(--common-white);

  --ak-chip-color-semi-filled-default-background: var(--neutral-grey-200);
  --ak-chip-color-semi-filled-default-contrast-text: var(--neutral-grey-700);
  --ak-chip-color-semi-filled-primary-background: var(--primary-light);
  --ak-chip-color-semi-filled-primary-contrast-text: var(--primary-dark);
  --ak-chip-color-semi-filled-secondary-background: var(--secondary-light);
  --ak-chip-color-semi-filled-secondary-contrast-text: var(--secondary-main);
  --ak-chip-color-semi-filled-success-background: var(--success-light);
  --ak-chip-color-semi-filled-success-contrast-text: var(--success-main);
  --ak-chip-color-semi-filled-error-background: var(--error-light);
  --ak-chip-color-semi-filled-error-contrast-text: var(--error-main);
  --ak-chip-color-semi-filled-warn-background: var(--warn-light);
  --ak-chip-color-semi-filled-warn-contrast-text: var(--warn-dark);
  --ak-chip-color-semi-filled-info-background: var(--info-light);
  --ak-chip-color-semi-filled-info-contrast-text: var(--info-dark);

  --ak-chip-color-outlined-default: var(--border-color-1);
  --ak-chip-color-outlined-primary: var(--primary-main);
  --ak-chip-color-outlined-secondary: var(--secondary-main);
  --ak-chip-color-outlined-success: var(--success-main);
  --ak-chip-color-outlined-error: var(--error-main);
  --ak-chip-color-outlined-warn: var(--warn-main);
  --ak-chip-color-outlined-info: var(--info-main);

  // variables for ak-app-bar
  --ak-appbar-default-background: var(--background-main);
  --ak-appbar-default-border-bottom: 1px solid var(--border-color-1);
  --ak-appbar-default-background-text-color: var(--text-primary);
  --ak-appbar-light-background: var(--background-light);
  --ak-appbar-light-border-bottom: 1px solid var(--border-color-1);
  --ak-appbar-light-background-text-color: var(--text-primary);
  --ak-appbar-dark-background: var(--background-dark);
  --ak-appbar-dark-border-bottom: none;
  --ak-appbar-dark-background-text-color: var(--common-white);
  --ak-appbar-dark-hover-background: var(--hover-dark-background);
  --ak-appbar-zIndex: var(--zIndex-appbar);

  &._theme-light_4z5thp {
    --ak-appbar-inherit-background: var(--background-light);
    --ak-appbar-inherit-border-bottom: 1px solid var(--border-color-1);
    --ak-appbar-inherit-background-text-color: var(--text-primary);
    --ak-appbar-inherit-hover-background: var(--hover-light-background);
    --ak-appbar-box-shadow: var(--box-shadow-light);
  }

  &._theme-dark_4z5thp {
    --ak-appbar-inherit-background: var(--background-dark);
    --ak-appbar-inherit-border-bottom: none;
    --ak-appbar-inherit-background-text-color: var(--common-white);
    --ak-appbar-inherit-hover-background: var(--hover-dark-background);
    --ak-appbar-box-shadow: var(--box-shadow-dark);
  }

  // variables for ak-autocomplete
  --ak-autocomplete-x-btn-zIndex: var(--zIndex-tooltip);
  --ak-autocomplete-background: var(--background-main);
  --ak-autocomplete-border-radius: var(--border-radius);
  --ak-autocomplete-box-shadow: var(--box-shadow-2);
  --ak-autocomplete-border-color: var(--border-color-1);

  // variables for ak-drawer
  --ak-drawer-backdrop-overlay-background: var(--backdrop-overlay-background);
  --ak-drawer-background: var(--background-main);
  --ak-drawer-color-text-primary: var(--text-primary);
  --ak-drawer-zIndex: var(--zIndex-drawer);

  // variables for ak-radio
  --ak-radio-hover-background: var(--hover-light-background);
  --ak-radio-color-text-disabled: var(--text-disabled);
  --ak-radio-color-success-main: var(--success-main);

  // variables for ak-date-picker
  --ak-date-picker-background: var(--background-main);
  --ak-date-picker-border-color: var(--border-color-1);
  --ak-date-picker-day-border-color: var(--common-white);
  --ak-date-picker-border-radius: var(--border-radius);
  --ak-date-picker-box-shadow: var(--box-shadow-2);
  --ak-date-picker-day-btn-hover-background: var(--hover-light-background);
  --ak-date-picker-color-text-primary: var(--text-primary);
  --ak-date-picker-color-text-secondary: var(--text-secondary);
  --ak-date-picker-color-text-disabled: var(--text-disabled);
  --ak-date-picker-color-primary: var(--primary-main);
  --ak-date-picker-color-primary-light: var(--primary-light);
  --ak-date-picker-color-primary-contrast-text: var(--primary-contrast-text);
  --ak-date-picker-font-family: var(--font-family);
  --ak-date-picker-font-weight-regular: var(--font-weight-regular);
  --ak-date-picker-font-weight-medium: var(--font-weight-medium);

  // variables for ak-pagination
  --ak-pagination-background: var(--neutral-grey-100);
  --ak-pagination-border-radius: var(--border-radius);
  --ak-pagination-divider-background: var(--divider-dark);
  --ak-pagination-button-background: var(--common-white);

  // variables for ak-tabs
  --ak-tabs-font-family: var(--font-family);
  --ak-tabs-border-color: var(--border-color-1);
  --ak-tabs-color-primary: var(--primary-main);
  --ak-tabs-color-primary-light: var(--primary-light);
  --ak-tabs-text-color-secondary: var(--text-secondary);
  --ak-tabs-text-color-primary: var(--text-primary);
  --ak-tabs-font-weight-bold: var(--font-weight-bold);
  --ak-tabs-font-weight-regular: var(--font-weight-regular);
  --ak-tabs-border-radius: var(--border-radius);
  --ak-tabs-neutral-background: var(--neutral-grey-200);

  // variables for ak-breadcrumbs
  --ak-breadcrumbs-color-text-primary: var(--text-primary);
  --ak-breadcrumbs-color-text-secondary: var(--text-secondary-color);
  --ak-breadcrumbs-font-weight-medium: var(--font-weight-medium);

  // variables for ak-accordion
  --ak-accordion-summary-secondary-background: var(--background-light);
  --ak-accordion-summary-secondary-border-color: var(--border-color-1);
  --ak-accordion-summary-primary-background: var(--primary-main-10);
  --ak-accordion-summary-primary-border-color: var(--primary-main-10);

  // variables for ak-skeleton
  --ak-skeleton-linear-gradient-color-1: var(--neutral-grey-100);
  --ak-skeleton-linear-gradient-color-2: var(--neutral-grey-200);
}

body {
  //variables for home-page/organization-dashboard/header
  &._theme-light_4z5thp {
    --organization-dashboard-header-background-color: var(--background-main);
    --organization-dashboard-header-text-color: var(--text-primary);
    --organization-dashboard-header-security-dashboard-btn-color: var(
      --secondary-main
    );
  }

  &._theme-dark_4z5thp {
    --organization-dashboard-header-background-color: var(--secondary-main);
    --organization-dashboard-header-text-color: var(--secondary-contrast-text);
    --organization-dashboard-header-security-dashboard-btn-color: var(
      --secondary-contrast-text
    );
  }

  //variables for home-page/organization-dashboard/side-nav
  --organization-dashboard-side-nav-font-weight-medium: var(
    --font-weight-medium
  );
  --organization-dashboard-side-nav-text-primary: var(--primary-main);
  --organization-dashboard-side-nav-text-secondary: var(--text-secondary);
  --organization-dashboard-side-nav-item-active-badge-background: var(
    --primary-main
  );
  --organization-dashboard-side-nav-item-active-badge-text-color: var(
    --primary-contrast-text
  );
  --organization-dashboard-side-nav-item-active-border-color: var(
    --primary-main
  );
  --organization-dashboard-side-nav-zIndex: calc(var(--zIndex-appbar) + 1);

  &._theme-light_4z5thp {
    --organization-dashboard-side-nav-bg-color: var(--background-main);
    --organization-dashboard-side-nav-text-color: var(--text-primary);
    --organization-dashboard-side-nav-box-shadow: var(--box-shadow-light);
    --organization-dashboard-side-nav-item-badge-background: var(
      --neutral-grey-200
    );
    --organization-dashboard-side-nav-item-badge-text-color: var(
      --text-primary
    );
    --organization-dashboard-side-nav-item-hover-background: var(
      --hover-light-background
    );
    --organization-dashboard-side-nav-item-active-background: var(
      --side-menu-active-background-light
    );
    --organization-dashboard-side-nav-border: 1px solid var(--border-color-1);
  }

  &._theme-dark_4z5thp {
    --organization-dashboard-side-nav-bg-color: var(--background-dark);
    --organization-dashboard-side-nav-text-color: var(
      --secondary-contrast-text
    );
    --organization-dashboard-side-nav-box-shadow: var(--box-shadow-dark);
    --organization-dashboard-side-nav-item-badge-background: var(
      --neutral-grey-900
    );
    --organization-dashboard-side-nav-item-badge-text-color: var(
      --secondary-contrast-text
    );
    --organization-dashboard-side-nav-item-hover-background: var(
      --hover-dark-background
    );
    --organization-dashboard-side-nav-item-active-background: var(
      --side-menu-active-background-dark
    );
    --organization-dashboard-side-nav-border: none;
  }

  // variables for organization-namespace/namespace-value
  --organization-namespace-value-color-ios: var(--ios);
  --organization-namespace-value-color-android: var(--android);

  // variables for organization-namespace
  --organization-namespace-loading-container-background: var(
    --background-light
  );
  --organization-namespace-loading-container-border: var(--border-color-1);
  --organization-namespace-loading-container-border-radius: var(
    --border-radius
  );

  // variables for organization-member/list
  --organization-member-list-loading-container-background: var(
    --background-light
  );
  --organization-member-list-loading-container-border: var(--border-color-1);
  --organization-member-list-loading-container-border-radius: var(
    --border-radius
  );

  // variables for organization-member/list/member-details
  --organization-member-list-member-details-border-radius: var(--border-radius);
  --organization-member-list-member-details-border-color: var(--border-color-1);
  --organization-member-list-member-details-divider-color: var(
    --neutral-grey-200
  );
  --organization-member-list-member-details-box-shadow: var(--box-shadow-3);

  // variables for organization-member/list/add-to-team
  --organization-member-list-add-to-team-border-radius: var(--border-radius);
  --organization-member-list-add-to-team-border-color: var(--border-color-1);

  // variables for organization-team
  --organization-team-loading-container-background: var(--background-light);
  --organization-team-loading-container-border: var(--border-color-1);
  --organization-team-loading-container-border-radius: var(--border-radius);

  // variables for organization-team/add-team-member
  --add-team-member-loading-container-border-radius: var(--border-radius);
  --add-team-member-loading-container-border-color: var(--border-color-1);

  // variables for organization-team/add-team-project
  --add-team-project-color-ios: var(--ios);
  --add-team-project-color-android: var(--android);
  --add-team-project-loading-container-border-radius: var(--border-radius);
  --add-team-project-loading-container-border-color: var(--border-color-1);

  // variables for organization-team/overview
  --organization-team-overview-border-radius: var(--border-radius);
  --organization-team-overview-border-color: var(--border-color-1);
  --organization-team-overview-hover-background: var(--hover-light-background);

  // variables for organization-team/details/active-action
  --organization-team-details-active-action-footer-shadow: var(--box-shadow-4);

  // variables for organization-team/details/team-info
  --organization-team-details-team-info-summary-shadow: var(--box-shadow-3);

  // variables for organization-team/invitation-list
  --organization-team-invitation-list-loading-container-border-radius: var(
    --border-radius
  );
  --organization-team-invitation-list-loading-container-border-color: var(
    --border-color-1
  );

  // variables for organization-team/member-list
  --organization-team-member-list-loading-container-border-radius: var(
    --border-radius
  );
  --organization-team-member-list-loading-container-border-color: var(
    --border-color-1
  );

  // variables for organization-team/project-list
  --organization-team-project-list-loading-container-border-radius: var(
    --border-radius
  );
  --organization-team-project-list-loading-container-border-color: var(
    --border-color-1
  );

  // variables for organization-team/project-list/project-info
  --organization-team-project-list-info-color-ios: var(--ios);
  --organization-team-project-list-info-color-android: var(--android);

  // variables for organization-mfa
  --organization-mfa-border-radius: var(--border-radius);
  --organization-mfa-alert-error-border-color: var(--error-main);
  --organization-mfa-alert-error-background: var(--error-light);
  --organization-mfa-alert-warn-border-color: var(--warn-dark);
  --organization-mfa-alert-warn-text-color: var(--warn-dark);
  --organization-mfa-alert-warn-background: var(--warn-light);

  // variables for sso-settings
  --sso-settings-border-color: var(--border-color-1);
  --sso-settings-border-radius: var(--border-radius);
  --sso-settings-dropzone-active-shadow-color: var(--neutral-grey-200);
  --sso-settings-file-upload-btn-color: var(--primary-main);

  // variables for organization-archive
  --organization-archive-border-color: var(--border-color-1);
  --organization-archive-border-radius: var(--border-radius);

  // variables for organization-archive/list
  --organization-archive-list-expired-background: var(--neutral-grey-50);
  --organization-archive-list-bordered-container-border-radius: var(
    --border-radius
  );
  --organization-archive-list-bordered-container-border-color: var(
    --border-color-1
  );

  // variables for jira-account
  --jira-account-border-radius: var(--border-radius);
  --jira-account-border-color: var(--border-color-1);

  // variables for github-account
  --github-account-border-radius: var(--border-radius);
  --github-account-border-color: var(--border-color-1);

  // variables for organization-name-header
  --organization-name-header-border-color: var(--neutral-grey-100);
  --organization-name-header-border-radius: var(--border-radius);
  --organization-name-color-primary-light: var(--primary-light);
  --organization-name-color-text-disabled: var(--text-disabled);
  --organization-name-add-btn-disabled-background: var(--neutral-white-100);

  // variables for organization-details
  --organization-details-color-primary: var(--primary-main);
  --organization-details-color-primary-light: var(--primary-light);
  --organization-details-color-text-primary: var(--text-primary);
  --organization-details-color-text-secondary: var(--text-secondary);
  --organization-details-border-color: var(--border-color-1);
  --organization-details-border-radius: var(--border-radius);
  --organization-details-neutral-background: var(--neutral-grey-200);
  --organization-details-font-weight-regular: var(--font-weight-regular);
  --organization-details-font-weight-bold: var(--font-weight-bold);

  //variables for notifications
  --notification-bell-icon-dot-background-color: var(--primary);
  --notification-bell-icon-dot-border-color: var(--black-700);
  --notification-bell-icon-color: var(--background-main);

  &._theme-light_4z5thp {
    --notification-bell-icon-dot-border-color: var(--background-main);
    --notification-bell-icon-color: var(--text-primary);
  }

  --notification-unread-count-color-primary: var(--primary-main);
  --notification-unread-count-background-color: var(--primary-main-10);
  --notification-divider-border-color: var(--common-black);
  --notification-message-hover-background-color: var(--neutral-grey-50);
  --notification-message-read-unread-dot-background-color: var(
    --secondary-main
  );
  --notification-message-read-unread-ring-border-color: var(
    --notification-message-read-unread-dot-background-color
  );
  --notification-message-time-color: var(--neutral-grey-400);
  --notification-message-color-text-primary: var(--text-primary);
  --notification-dropdown-border-color: var(--neutral-grey-100);
  --notification-dropdown-arrow-border-color: var(--neutral-grey-200);

  //variables for notifications-dropdown
  --notifications-dropdown-background-color: var(--background-main);
  --notifications-dropdown-border-color: var(--border-color-1);
  --notifications-dropdown-box-shadow: var(--box-shadow-9);

  //variables for appmonitoring
  --appmonitoring-font-weight-medium: var(--font-weight-medium);
  --appmonitoring-color-black: var(--common-black);
  --appmonitoring-platform-ios-color: var(--ios);
  --appmonitoring-platform-android-color: var(--android);
  --appmonitoring-border-radius: var(--border-radius);
  --appmonitoring-border-color: var(--neutral-white-100);

  //variables for appmonitoring/details
  --appmonitoring-details-border-color: var(--neutral-grey-100);
  --appmonitoring-details-toggle-border-color: var(--neutral-grey-200);

  //variables for appmonitoring/settings
  --appmonitoring-settings-block-description-text-color: var(
    --text-secondary-color
  );

  //variables for appmonitoring/table/status-column
  --appmonitoring-table-status-last-sync-color: var(--neutral-grey-600);

  // variables for file/report-drawer/va-reports
  --file-report-dr-va-reports-border-radius: var(--border-radius);
  --file-report-dr-va-reports-border-color: var(--border-color-1);
  --file-report-dr-va-reports-gen-progress-indicator-bg: var(
    --background-light
  );

  // variables for file/report-drawer/sbom-reports
  --file-report-dr-sbom-reports-border-color: var(--neutral-grey-200);
  --file-report-dr-sbom-reports-border-radius: var(--border-radius);

  // variables for sbom/app-logo
  --sbom-app-logo-border-radius: var(--border-radius);
  --sbom-app-logo-border-color: var(--neutral-grey-100);

  // variables for sbom/app-platform
  --sbom-app-platform-border-radius: var(--border-radius);
  --sbom-app-platform-border-color: var(--neutral-grey-100);
  --sbom-app-platform-color-ios: var(--ios);
  --sbom-app-platform-color-android: var(--android);

  // variables for sbom/scan-report-drawer
  --sbom-scan-report-dr-border-radius: var(--border-radius);
  --sbom-scan-report-dr-border-color: var(--border-color-1);

  // variables for sbom/scan-report-drawer/app-details
  --sbom-scan-report-dr-app-details-box-shadow: var(--box-shadow-3);

  // variables for sbom/empty-loading-view
  --sbom-empty-loading-view-border-radius: var(--border-radius);
  --sbom-empty-loading-view-border-color: var(--border-color-1);

  // variables for sbom/scan-details
  --sbom-scan-details-link-border-radius: var(--border-radius);
  --sbom-scan-details-link-border-color: var(--border-color-1);

  // variables for sbom/component-details/vulnerabilities
  --sbom-component-details-vulnerabilities-list-header-background-color: var(
    --ak-table-header-background-neutral
  );
  --sbom-component-details-vulnerabilities-list-header-border-color-dark: var(
    --ak-table-border-dark
  );
  --sbom-component-details-vulnerabilities-list-border-color-light: var(
    --ak-table-border-light
  );

  // variables for sbom/component-details/vulnerabilities/detail-wrapper
  --sbom-component-details-vulnerabilities-detail-wrapper-background-color-main: var(
    --background-main
  );
  --sbom-component-details-vulnerabilities-detail-wrapper-background-color-light: var(
    --background-light
  );
  --sbom-component-details-vulnerabilities-detail-wrapper-border-color: var(
    --border-color-1
  );
  --sbom-component-details-vulnerabilities-detail-wrapper-border-radius: var(
    --border-radius
  );

  // variables for sbom/component-details/vulnerabilities/severity
  --sbom-component-details-vulnerabilities-severity-critical: var(
    --severity-critical
  );
  --sbom-component-details-vulnerabilities-severity-high: var(--severity-high);
  --sbom-component-details-vulnerabilities-severity-medium: var(
    --severity-medium
  );
  --sbom-component-details-vulnerabilities-severity-low: var(--severity-low);
  --sbom-component-details-vulnerabilities-severity-info: var(--severity-info);
  --sbom-component-details-vulnerabilities-severity-none: var(--severity-none);
  --sbom-component-details-vulnerabilities-severity-unknown: var(
    --severity-unknown
  );
  --sbom-component-details-vulnerabilities-severity-chip-text-color: var(
    --common-white
  );

  // variables for sbom/summary-header
  --sbom-summary-header-border-radius: var(--border-radius);
  --sbom-summary-header-border-color: var(--border-color-1);

  // variables for risk-tag
  --risk-tag-font-family: var(--font-family);
  --risk-tag-border-radius: var(--border-radius);
  --risk-tag-text-color: var(--common-white);
  --risk-tag-color-critical: var(--severity-critical);
  --risk-tag-color-high: var(--severity-high);
  --risk-tag-color-medium: var(--severity-medium);
  --risk-tag-color-low: var(--severity-low);
  --risk-tag-color-passed: var(--severity-passed);
  --risk-tag-color-none: var(--severity-none);
  --risk-tag-color-waiting: var(--neutral-grey-500);

  // variables for file-details/severity-level
  --file-details-severity-level-border-color: var(--border-color-1);

  // variables for file-details/insights-wrapper
  --file-details-insights-wrapper-border-color: var(--border-color-1);
  --file-details-insights-wrapper-background-color: var(--background-main);

  // variables for file-details/skeleton-loader
  --file-details-skeleton-loader-border-color: var(--border-color-1);
  --file-details-skeleton-loader-background-color: var(--background-main);

  // variables for file-details/key-insights
  --file-details-key-insights-border-color: var(--border-color-1);
  --file-details-key-insights-border-radius: var(--border-radius);
  --file-details-key-insights-link-btn-background-color: var(--primary-main);
  --file-details-key-insights-link-btn-contrast-text-color: var(
    --primary-contrast-text
  );
  --file-details-key-insights-link-btn-hover-color: var(--primary-dark);

  // variables for file-details/summary
  --file-details-summary-background-color: var(--background-main);
  --file-details-summary-border-color: var(--border-color-1);
  --file-details-summary-border-radius: var(--border-radius);
  --file-details-summary-store-logo-container-background-color: var(
    --common-white
  );
  --file-details-summary-store-logo-container-border-color: var(
    --neutral-grey-200
  );

  // variables for file-details/summary/app-platform
  --file-details-summary-app-platform-color-ios: var(--ios);
  --file-details-summary-app-platform-color-android: var(--android);

  // variables for file-details/scan-actions
  --file-details-scan-actions-border-color: var(--border-color-1);
  --file-details-scan-actions-background-color: var(--background-main);

  // variables for file-details/scan-actions/api-scan
  --file-details-scan-actions-api-scan-border-radius: var(--border-radius);
  --file-details-scan-actions-api-scan-alert-background-color: var(
    --error-light
  );
  --file-details-scan-actions-api-scan-alert-text-color: var(--error-main);
  --file-details-scan-actions-api-scan-alert-border-color: var(--error-main);

  // variables for file-details/scan-actions/api-scan/captured-apis
  --file-details-scan-actions-api-scan-captured-apis-border-radius: var(
    --border-radius
  );
  --file-details-scan-actions-api-scan-captured-apis-border-color: var(
    --border-color-1
  );

  // variables for file-details/vulnerability-analysis
  --file-details-vulnerability-analysis-border-color: var(--border-color-1);
  --file-details-vulnerability-analysis-background-color: var(
    --background-main
  );

  // variables for file-details/vulnerability-analysis-details
  --file-details-vulnerability-analysis-details-background-main: var(
    --background-main
  );
  --file-details-vulnerability-analysis-details-header-box-shadow: var(
    --box-shadow-5
  );
  --file-details-vulnerability-analysis-details-zIndex: var(--zIndex-appbar);
  --file-details-vulnerability-analysis-details-vul-finding-border-color: var(
    --border-color-1
  );
  --file-details-vulnerability-analysis-details-vul-finding-background-color: var(
    --neutral-grey-100
  );
  --file-details-vulnerability-analysis-details-vul-finding-font-family: var(
    --font-family
  );
  --file-details-vulnerability-analysis-details-color-primary: var(
    --primary-main
  );
  --file-details-vulnerability-analysis-details-border-radius: var(
    --border-radius
  );
  --file-details-vulnerability-analysis-details-divider-color: var(
    --divider-dark
  );

  // variables for file-details/vulnerability-analysis-details/edit-analysis-button
  --file-details-vulnerability-analysis-details-edit-analysis-button-background-main: var(
    --background-main
  );
  --file-details-vulnerability-analysis-details-edit-analysis-button-box-shadow: var(
    --box-shadow-5
  );

  // variables for vnc-viewer
  --vnc-viewer-modal-zIndex: var(--zIndex-drawer);
  --vnc-viewer-modal-backdrop-overlay-background: var(
    --backdrop-overlay-background
  );
  --vnc-viewer-modal-background: var(--background-main);
  --vnc-viewer-modal-text-color: var(--text-primary);

  // variables for proxy-settings-view
  --proxy-settings-view-border-color: var(--border-color-1);

  // variables for dynamic-scan/modal
  --dynamic-scan-modal-border-color: var(--border-color-1);
  --dynamic-scan-modal-border-radius: var(--border-radius);
  --dynamic-scan-modal-alert-background-color: var(--warn-light);
  --dynamic-scan-modal-alert-text-color: var(--warn-dark);
  --dynamic-scan-modal-alert-border-color: var(--warn-dark);
  --dynamic-scan-modal-device-settings-warning-text-color: var(
    --neutral-grey-500
  );

  // variables for file-list
  --file-list-root-background-color: var(--background-main);
  --file-list-selected-file-chip-color: var(--text-primary);
  --file-list-selected-file-chip-background: var(--primary-main-10);

  // variables for file-compare/index
  --file-compare-tabs-background-color: var(--common-white);

  // variables for file-compare/analysis-details
  --file-compare-analysis-details-border-color: var(--border-color-1);

  // variables for file-compare/file-overview
  --file-compare-file-overview-border-radius: var(--border-radius);
  --file-compare-file-overview-background-white: var(--common-white);
  --file-compare-file-overview-background-grey: var(--neutral-grey-100);
  --file-compare-file-overview-border-color-light: var(--neutral-grey-100);
  --file-compare-file-overview-open-in-new-icon-background-color: var(
    --hover-light-background
  );
  --file-compare-file-overview-border-color-dark: var(--border-color-1);
  --file-compare-file-overview-color-android: var(--android);
  --file-compare-file-overview-color-ios: var(--ios);
  --file-compare-store-logo-container-background-color: var(--neutral-grey-200);

  // variables for file-compare/compare-list
  --file-compare-compare-list-wrapper-background-color: var(--background-main);

  // variables for file-compare/header
  --file-compare-header-white-background-color: var(--common-white);
  --file-compare-header-divider-background-color: var(--border-color-2);
  --file-compare-header-dark-border-color: var(--neutral-grey-300);
  --file-compare-header-files-overview-border-color: var(--neutral-grey-200);
  --file-compare-header-file-icon-border-color: var(--neutral-grey-100);
  --file-compare-header-file-icon-border-radius: var(--border-radius);
  --file-compare-header-info-box-shadow: var(--box-shadow-6);
  --file-compare-header-platform-android-color: var(--android);
  --file-compare-header-platform-ios-color: var(--ios);

  // variables for file-compare/vulnerability-details
  --file-compare-vulnerability-details-white-background: var(--common-white);
  --file-compare-vulnerability-details-border-color: var(--neutral-grey-200);
  --file-compare-vulnerability-details-header-wrapper-background-color: var(
    --common-white
  );

  // variables for file-compare/compare-list/skeleton-loader
  --file-compare-list-skeleton-loader-border-color: var(--border-color-1);
  --file-compare-list-skeleton-loader-background-color: var(--background-main);
  --file-compare-list-skeleton-loader-breadcrumbs-seperator-color: var(
    --neutral-grey-500
  );
  --file-compare-list-skeleton-loader-divider-background-color: var(
    --border-color-2
  );

  // variables for login-component
  --login-component-button-loader-color: var(--primary-contrast-text);

  // variables for file-chart
  --file-chart-severity-level-color-critical: var(--severity-critical);
  --file-chart-severity-level-color-high: var(--severity-high);
  --file-chart-severity-level-color-medium: var(--severity-medium);
  --file-chart-severity-level-color-low: var(--severity-low);
  --file-chart-severity-level-color-passed: var(--severity-passed);
  --file-chart-severity-level-color-untested: var(--severity-untested);
  --file-chart-severity-level-color-none: var(--severity-none);

  // variables for account-settings/mfa
  --mfa-loader-primary-contrast-text: var(--primary-contrast-text);

  // variables for project-settings/empty-list
  --project-settings-empty-list-border-radius: var(--border-radius);
  --project-settings-empty-list-border-color: var(--border-color-1);

  // variables for project-settings/empty-list
  --project-settings-loading-container-background: var(--background-light);
  --project-settings-loading-container-border: var(--border-color-1);
  --project-settings-loading-container-border-radius: var(--border-radius);

  // variables for project-settings/page-wrapper
  --project-settings-page-wrapper-background-color: var(--background-light);

  // variables for project-settings/header
  --project-settings-header-background-color: var(--background-light);
  --project-settings-header-border-color: var(--border-color-1);
  --project-settings-header-border-radius: var(--border-radius);
  --project-settings-header-info-text-color: var(--text-secondary-color);
  --project-settings-header-tabs-box-shadow: var(--box-shadow-6);
  --project-settings-header-project-details-box-shadow: var(--box-shadow-8);
  --project-settings-header-color-android: var(--android);
  --project-settings-header-color-ios: var(--ios);

  // variables for project-settings/general-settings
  --project-settings-general-settings-root-border-color: var(--border-color-1);
  --project-settings-general-settings-root-background-color: var(
    --background-main
  );

  // variables for project-settings/general-settings/dynamicscan-automation-settings
  --project-settings-general-settings-dynamicscan-automation-settings-box-shadow: var(
    --box-shadow-7
  );
  --project-settings-general-settings-dynamicscan-automation-settings-border-radius: var(
    --border-radius
  );
  --project-settings-general-settings-dynamicscan-automation-settings-border-color: var(
    --border-color-1
  );

  // variables for project-settings/general-settings/dynamicscan-automation-settings/scenario
  --project-settings-general-settings-dynamicscan-automation-scenario-textfield-bg: var(
    --neutral-grey-100
  );
  --project-settings-general-settings-dynamicscan-automation-scenario-form-footer-box-shadow: var(
    --box-shadow-4
  );

  // variables for project-settings/general-settings/github-project
  --project-settings-general-settings-github-project-background-color: var(
    --neutral-grey-200
  );

  // variables for project-settings/general-settings/jira-project
  --project-settings-general-settings-jira-project-background-color: var(
    --neutral-grey-200
  );

  // variables for project-settings/analysis-settings
  --project-settings-analysis-settings-border-color: var(--neutral-grey-200);
  --project-settings-analysis-settings-border-radius: var(--border-radius);

  // variables for project-settings/analysis-settings/vulnerability-details
  --project-settings-analysis-settings-vulnerability-details-border-color: var(
    --neutral-grey-200
  );
  --project-settings-analysis-settings-vulnerability-details-border-radius: var(
    --border-radius
  );

  // variables for project-settings/analysis-settings/vulnerability-list
  --project-settings-analysis-settings-vulnerability-list-border-color: var(
    --neutral-grey-200
  );
  --project-settings-analysis-settings-vulnerability-list-background-color: var(
    --neutral-grey-100
  );
  --project-settings-analysis-settings-vulnerability-list-border-color-warning: var(
    --error-main
  );
  --project-settings-analysis-settings-vulnerability-list-background-color-warning: var(
    --primary-light
  );
  --project-settings-analysis-settings-vulnerability-list-border-radius: var(
    --border-radius
  );

  // variables for project-settings/view-scenario
  --project-settings-view-scenario-background-white: var(--background-main);
  --project-settings-view-scenario-background-dark: var(--neutral-grey-100);
  --project-settings-view-scenario-modal-footer-box-shadow: var(--box-shadow-4);
  --project-settings-view-scenario-border-light: var(--border-color-1);
  --project-settings-view-scenario-header-border-color: var(--border-color-1);
  --project-settings-view-scenario-header-border-radius: var(--border-radius);
  --project-settings-view-scenario-header-scenario-details-box-shadow: var(
    --box-shadow-8
  );

  // variables for project-settings/view-scenario/parameter-item
  --project-settings-view-scenario-parameter-item-border-color: var(
    --neutral-grey-100
  );
  --project-settings-view-scenario-parameter-text-field-background: var(
    --neutral-grey-100
  );

  // variables for tri-state-checkbox
  --tri-state-checkbox-overridden-bg: var(--success-light);
  --tri-state-checkbox-border-color: var(--success-main);
  --tri-state-reset-icon-bg: var(--common-white);

  // variables for organization-analytics/app-scan-chart
  --organization-analytics-app-scan-chart-border-color: var(--border-color-1);
  --organization-analytics-app-scan-chart-border-radius: var(--border-radius);

  // variables for app-upload/status/details
  --app-upload-status-details-footer-background-color: var(--background-light);
  --app-upload-status-details-footer-border-color: var(--neutral-grey-100);
  --app-upload-status-details-image-border-color: var(--neutral-grey-100);
  --app-upload-status-details-loader-background-color: var(--success-main);

  // variables for app-upload/status
  --app-upload-status-arrow-border-color: var(--neutral-grey-200);
  --app-upload-status-dropdown-background-color: var(--common-white);
  --app-upload-status-dropdown-border-color: var(--neutral-white-100);
  --app-upload-status-dropdown-box-shadow: var(--box-shadow-9);
  --app-upload-status-green-loader-color: var(--success-main);
  --app-upload-status-green-loader-track-color: var(--success-light);

  // variables for app-upload/via-link
  --app-upload-via-link-button-stack-shadow: var(--box-shadow-4);
  --app-upload-via-link-url-format-bullet-color: var(--text-primary);

  &._theme-light_4z5thp {
    --app-upload-via-link-button-color: var(--text-primary);
  }

  &._theme-dark_4z5thp {
    --app-upload-via-link-button-color: var(--common-white);
  }

  // variables for app-upload/via-link/preview
  --app-upload-via-link-preview-background-color: var(--background-light);
  --app-upload-via-link-preview-border-color: var(--neutral-grey-100);
  --app-upload-via-link-preview-footer-background-color: var(
    --neutral-grey-100
  );

  // variables for file-overview
  --file-overview-store-logo-container-background-color: var(--common-white);
  --file-overview-store-logo-container-border-color: var(--neutral-grey-200);
}

/* styles for irene/styles/_devices.scss */
._marvel-device_cek5jl{
	display: inline-block;
	position: relative;
	box-sizing: content-box !important;

	._screen_cek5jl {
		width: 100%;
		position: relative;
		height: 100%;
		z-index: 3;
		background: white;
		overflow: hidden;
		display: block;
		border-radius: 1px;
		box-shadow: 0 0 0 3px #111;
	}

	._top-bar_cek5jl, ._bottom-bar_cek5jl {
		height: 3px;
		background: black;
		width: 100%;
		display: block;
	}

	._middle-bar_cek5jl {
		width: 3px;
		height: 4px;
		top: 0px;
		left: 90px;
		background: black;
		position: absolute;
	}

	&._iphone8_cek5jl{
		width: 375px;
		height: 667px;
		padding: 105px 24px;
		background: #d9dbdc;
		border-radius: 56px;
		box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.2);

		&:before{
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			position: absolute;
			top: 6px;
			content: '';
			left: 6px;
			border-radius: 50px;
			background: #f8f8f8;
			z-index: 1;
		}

		&:after{
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			position: absolute;
			top: 8px;
			content: '';
			left: 8px;
			border-radius: 48px;
			box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
			inset 0 0 6px 3px #FFFFFF;
			z-index: 2;
		}

		._home_cek5jl {
			border-radius: 100%;
			width: 68px;
			height: 68px;
			position: absolute;
			left: 50%;
			margin-left: -34px;
			bottom: 22px;
			z-index: 3;
			background: rgb(48,50,51);
			background: linear-gradient(135deg, rgba(48,50,51,1) 0%,rgba(181,183,185,1) 50%,rgba(240,242,242,1) 69%,rgba(48,50,51,1) 100%);

			&:before{
				background: #f8f8f8;
				position: absolute;
				content: '';
				border-radius: 100%;
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				top: 4px;
				left: 4px;
			}
		}

		._top-bar_cek5jl{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			top: 68px;
			left: 0;
		}

		._bottom-bar_cek5jl{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			bottom: 68px;
			left: 0;
		}

		._sleep_cek5jl{
			position: absolute;
			top: 190px;
			right: -4px;
			width: 4px;
			height: 66px;
			border-radius: 0px 2px 2px 0px;
			background: #d9dbdc;
		}

		._volume_cek5jl{
			position: absolute;
			left: -4px;
			top: 188px;
			z-index: 0;
			height: 66px;
			width: 4px;
			border-radius: 2px 0px 0px 2px;
			background: #d9dbdc;

			&:before {
				position: absolute;
				left: 2px;
				top: -78px;
				height: 40px;
				width: 2px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}

			&:after {
				position: absolute;
				left: 0px;
				top: 82px;
				height: 66px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 24px;
			left: 50%;
			margin-left: -6px;
			border-radius: 100%;
			z-index: 3;
		}

		._sensor_cek5jl {
			background: #3c3d3d;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 49px;
			left: 134px;
			z-index: 3;
			border-radius: 100%;
		}

		._speaker_cek5jl {
			background: #292728;
			width: 70px;
			height: 6px;
			position: absolute;
			top: 54px;
			left: 50%;
			margin-left: -35px;
			border-radius: 6px;
			z-index: 3;
		}

		&._gold_cek5jl{
			background: #f9e7d3;

			._top-bar_cek5jl, ._bottom-bar_cek5jl{
				background: white;
			}

			._sleep_cek5jl, ._volume_cek5jl{
				background: #f9e7d3;
			}

			._home_cek5jl{
				background: rgb(206,187,169);
				background: linear-gradient(135deg, rgba(206,187,169,1) 0%,rgba(249,231,211,1) 50%,rgba(206,187,169,1) 100%);
			}
		}

		&._black_cek5jl{
			background: #464646;
			box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7);

			&:before{
				background: #080808;
			}

			&:after{
				box-shadow:
					inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
					inset 0 0 6px 3px #212121;
			}

			._top-bar_cek5jl, ._bottom-bar_cek5jl{
				background: #212121;
			}

			._volume_cek5jl, ._sleep_cek5jl{
				background: #464646;
			}

			._camera_cek5jl{
				background: #080808;
			}

			._home_cek5jl{
				background: rgb(8,8,8);
				background: linear-gradient(135deg, rgba(8,8,8,1) 0%,rgba(70,70,70,1) 50%,rgba(8,8,8,1) 100%);

				&:before{
					background: #080808;
				}
			}

		}

		&._landscape_cek5jl{
			padding: 24px 105px;
			height: 375px;
			width: 667px;

			._sleep_cek5jl{
				top: 100%;
				border-radius: 0px 0px 2px 2px;
				right: 190px;
				height: 4px;
				width: 66px;
			}

			._volume_cek5jl {
				width: 66px;
				height: 4px;
				top: -4px;
				left: calc(100% - 188px - 66px);
				border-radius: 2px 2px 0px 0px;

				&:before {
					width: 40px;
					height: 2px;
					top: 2px;
					right: -78px;
					left: auto;
					border-radius: 2px 2px 0px 0px;
				}

				&:after{
					left: -82px;
					width: 66px;
					height: 4px;
					top: 0;
					border-radius: 2px 2px 0px 0px;
				}
			}

			._top-bar_cek5jl{
				width: 14px;
				height: 100%;
				left: calc(100% - 68px -  14px);
				top: 0;
			}

			._bottom-bar_cek5jl{
				width: 14px;
				height: 100%;
				left: 68px;
				top: 0;
			}

			._home_cek5jl{
				top: 50%;
				margin-top: -34px;
				margin-left: 0;
				left: 22px;
			}

			._sensor_cek5jl {
				top: 134px;
				left: calc(100% - 49px - 16px);
			}

			._speaker_cek5jl {
				height: 70px;
				width: 6px;
				left: calc(100% - 54px - 6px);
				top: 50%;
				margin-left: 0px;
				margin-top: -35px;
			}

			._camera_cek5jl {
				left: calc(100% - 32px);
				top: 50%;
				margin-left: 0px;
				margin-top: -5px;
			}
		}
	}

	&._iphone8plus_cek5jl{
		width: 414px;
		height: 736px;
		padding: 112px 26px;
		background: #d9dbdc;
		border-radius: 56px;
		box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.2);

		&:before{
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			position: absolute;
			top: 6px;
			content: '';
			left: 6px;
			border-radius: 50px;
			background: #f8f8f8;
			z-index: 1;
		}

		&:after{
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			position: absolute;
			top: 8px;
			content: '';
			left: 8px;
			border-radius: 48px;
			box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
				inset 0 0 6px 3px #FFFFFF;
			z-index: 2;
		}

		._home_cek5jl {
			border-radius: 100%;
			width: 68px;
			height: 68px;
			position: absolute;
			left: 50%;
			margin-left: -34px;
			bottom: 24px;
			z-index: 3;
			background: rgb(48,50,51);
			background: linear-gradient(135deg, rgba(48,50,51,1) 0%,rgba(181,183,185,1) 50%,rgba(240,242,242,1) 69%,rgba(48,50,51,1) 100%);

			&:before{
				background: #f8f8f8;
				position: absolute;
				content: '';
				border-radius: 100%;
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				top: 4px;
				left: 4px;
			}
		}

		._top-bar_cek5jl{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			top: 68px;
			left: 0;
		}

		._bottom-bar_cek5jl{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			bottom: 68px;
			left: 0;
		}

		._sleep_cek5jl{
			position: absolute;
			top: 190px;
			right: -4px;
			width: 4px;
			height: 66px;
			border-radius: 0px 2px 2px 0px;
			background: #d9dbdc;
		}

		._volume_cek5jl{
			position: absolute;
			left: -4px;
			top: 188px;
			z-index: 0;
			height: 66px;
			width: 4px;
			border-radius: 2px 0px 0px 2px;
			background: #d9dbdc;

			&:before {
				position: absolute;
				left: 2px;
				top: -78px;
				height: 40px;
				width: 2px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}

			&:after {
				position: absolute;
				left: 0px;
				top: 82px;
				height: 66px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 29px;
			left: 50%;
			margin-left: -6px;
			border-radius: 100%;
			z-index: 3;
		}

		._sensor_cek5jl {
			background: #3c3d3d;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 54px;
			left: 154px;
			z-index: 3;
			border-radius: 100%;
		}

		._speaker_cek5jl {
			background: #292728;
			width: 70px;
			height: 6px;
			position: absolute;
			top: 59px;
			left: 50%;
			margin-left: -35px;
			border-radius: 6px;
			z-index: 3;
		}

		&._gold_cek5jl{
			background: #f9e7d3;

			._top-bar_cek5jl, ._bottom-bar_cek5jl{
				background: white;
			}

			._sleep_cek5jl, ._volume_cek5jl{
				background: #f9e7d3;
			}

			._home_cek5jl{
				background: rgb(206,187,169);
				background: linear-gradient(135deg, rgba(206,187,169,1) 0%,rgba(249,231,211,1) 50%,rgba(206,187,169,1) 100%);
			}
		}

		&._black_cek5jl{
			background: #464646;
			box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.7);

			&:before{
				background: #080808;
			}

			&:after{
				box-shadow:
					inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
					inset 0 0 6px 3px #212121;
			}

			._top-bar_cek5jl, ._bottom-bar_cek5jl{
				background: #212121;
			}

			._volume_cek5jl, ._sleep_cek5jl{
				background: #464646;
			}

			._camera_cek5jl{
				background: #080808;
			}

			._home_cek5jl{
				background: rgb(8,8,8);
				background: linear-gradient(135deg, rgba(8,8,8,1) 0%,rgba(70,70,70,1) 50%,rgba(8,8,8,1) 100%);

				&:before{
					background: #080808;
				}
			}

		}

		&._landscape_cek5jl{
			padding: 26px 112px;
			height: 414px;
			width: 736px;

			._sleep_cek5jl{
				top: 100%;
				border-radius: 0px 0px 2px 2px;
				right: 190px;
				height: 4px;
				width: 66px;
			}

			._volume_cek5jl {
				width: 66px;
				height: 4px;
				top: -4px;
				left: calc(100% - 188px - 66px);
				border-radius: 2px 2px 0px 0px;

				&:before {
					width: 40px;
					height: 2px;
					top: 2px;
					right: -78px;
					left: auto;
					border-radius: 2px 2px 0px 0px;
				}

				&:after{
					left: -82px;
					width: 66px;
					height: 4px;
					top: 0;
					border-radius: 2px 2px 0px 0px;
				}
			}

			._top-bar_cek5jl{
				width: 14px;
				height: 100%;
				left: calc(100% - 68px -  14px);
				top: 0;
			}

			._bottom-bar_cek5jl{
				width: 14px;
				height: 100%;
				left: 68px;
				top: 0;
			}

			._home_cek5jl{
				top: 50%;
				margin-top: -34px;
				margin-left: 0;
				left: 24px;
			}

			._sensor_cek5jl {
				top: 154px;
				left: calc(100% - 54px - 16px);
			}

			._speaker_cek5jl {
				height: 70px;
				width: 6px;
				left: calc(100% - 59px - 6px);
				top: 50%;
				margin-left: 0px;
				margin-top: -35px;
			}

			._camera_cek5jl {
				left: calc(100% - 29px);
				top: 50%;
				margin-left: 0px;
				margin-top: -5px;
			}
		}
	}

	&._iphone5s_cek5jl, &._iphone5c_cek5jl{
		padding: 105px 22px;
		background: #2c2b2c;
		width: 320px;
		height: 568px;
		border-radius: 50px;

		&:before{
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			top: 4px;
			content: '';
			left: 4px;
			border-radius: 46px;
			background: #1e1e1e;
			z-index: 1;
		}

		._sleep_cek5jl{
			position: absolute;
			top: -4px;
			right: 60px;
			width: 60px;
			height: 4px;
			border-radius: 2px 2px 0px 0px;
			background: #282727;
		}

		._volume_cek5jl{
			position: absolute;
			left: -4px;
			top: 180px;
			z-index: 0;
			height: 27px;
			width: 4px;
			border-radius: 2px 0px 0px 2px;
			background: #282727;

			&:before {
				position: absolute;
				left: 0px;
				top: -75px;
				height: 35px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}

			&:after {
				position: absolute;
				left: 0px;
				bottom: -64px;
				height: 27px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 32px;
			left: 50%;
			margin-left: -5px;
			border-radius: 5px;
			z-index: 3;
		}

		._sensor_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 60px;
			left: 160px;
			z-index: 3;
			margin-left: -32px;
			border-radius: 5px;
		}

		._speaker_cek5jl {
			background: #292728;
			width: 64px;
			height: 10px;
			position: absolute;
			top: 60px;
			left: 50%;
			margin-left: -32px;
			border-radius: 5px;
			z-index: 3;
		}

		&._landscape_cek5jl{
			padding: 22px 105px;
			height: 320px;
			width: 568px;

			._sleep_cek5jl{
				right: -4px;
				top: calc(100% - 120px);
				height: 60px;
				width: 4px;
				border-radius: 0px 2px 2px 0px;
			}

			._volume_cek5jl {
				width: 27px;
				height: 4px;
				top: -4px;
				left: calc(100% - 180px);
				border-radius: 2px 2px 0px 0px;

				&:before {
					width: 35px;
					height: 4px;
					top: 0px;
					right: -75px;
					left: auto;
					border-radius: 2px 2px 0px 0px;
				}

				&:after{
					bottom: 0px;
					left: -64px;
					z-index: 999;
					height: 4px;
					width: 27px;
					border-radius: 2px 2px 0px 0px;
				}
			}

			._sensor_cek5jl {
				top: 160px;
				left: calc(100% - 60px);
				margin-left: 0px;
				margin-top: -32px;
			}

			._speaker_cek5jl {
				height: 64px;
				width: 10px;
				left: calc(100% - 60px);
				top: 50%;
				margin-left: 0px;
				margin-top: -32px;
			}

			._camera_cek5jl {
				left: calc(100% - 32px);
				top: 50%;
				margin-left: 0px;
				margin-top: -5px;
			}
		}
	}

	&._iphone5s_cek5jl{
		._home_cek5jl {
			border-radius: 36px;
			width: 68px;
			box-shadow: inset 0 0 0 4px #2c2b2c;
			height: 68px;
			position: absolute;
			left: 50%;
			margin-left: -34px;
			bottom: 19px;
			z-index: 3;
		}

		._top-bar_cek5jl{
			top: 70px;
			position: absolute;
			left: 0;
		}

		._bottom-bar_cek5jl {
			bottom: 70px;
			position: absolute;
			left: 0;
		}

		&._landscape_cek5jl{
			._home_cek5jl {
				left: 19px;
				bottom: 50%;
				margin-bottom: -34px;
				margin-left: 0px;
			}

			._top-bar_cek5jl {
				left: 70px;
				top: 0px;
				width: 3px;
				height: 100%;
			}

			._bottom-bar_cek5jl {
				right: 70px;
				left: auto;
				bottom: 0px;
				width: 3px;
				height: 100%;
			}
		}

		&._silver_cek5jl{
			background: #bcbcbc;

			&:before{
				background: #fcfcfc;
			}

			._volume_cek5jl, ._sleep_cek5jl{
				background: #d6d6d6;
			}

			._top-bar_cek5jl, ._bottom-bar_cek5jl{
				background: #eaebec;
			}

			._home_cek5jl{
				box-shadow: inset 0 0 0 4px #bcbcbc;
			}
		}

		&._gold_cek5jl{
			background: #f9e7d3;

			&:before{
				background: #fcfcfc;
			}

			._volume_cek5jl, ._sleep_cek5jl{
				background: #f9e7d3;
			}

			._top-bar_cek5jl, ._bottom-bar_cek5jl{
				background: white;
			}

			._home_cek5jl{
				box-shadow: inset 0 0 0 4px #f9e7d3;
			}
		}
	}

	&._iphone5c_cek5jl{
		background: white;
		box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);

		._top-bar_cek5jl, ._bottom-bar_cek5jl{
			display: none;
		}

		._home_cek5jl{
			background: #242324;
			border-radius: 36px;
			width: 68px;
			height: 68px;
			z-index: 3;
			position: absolute;
			left: 50%;
			margin-left: -34px;
			bottom: 19px;

			&:after{
				width: 20px;
				height: 20px;
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 4px;
				position: absolute;
				display: block;
				content: '';
				top: 50%;
				left: 50%;
				margin-top: -11px;
				margin-left: -11px;
			}
		}

		&._landscape_cek5jl{
			._home_cek5jl {
				left: 19px;
				bottom: 50%;
				margin-bottom: -34px;
				margin-left: 0px;
			}
		}

		._volume_cek5jl, ._sleep_cek5jl{
			background: #dddddd;
		}

		&._red_cek5jl{
			background: #f96b6c;

			._volume_cek5jl, ._sleep_cek5jl{
				background: #ed5758;
			}
		}

		&._yellow_cek5jl{
			background: #f2dc60;

			._volume_cek5jl, ._sleep_cek5jl{
				background: #e5ce4c;
			}
		}

		&._green_cek5jl{
			background: #97e563;

			._volume_cek5jl, ._sleep_cek5jl{
				background: #85d94d;
			}
		}

		&._blue_cek5jl{
			background: #33a2db;

			._volume_cek5jl, ._sleep_cek5jl{
				background: #2694cd;
			}
		}
	}

	&._iphone4s_cek5jl{
		padding: 129px 27px;
		width: 320px;
		height: 480px;
		background: #686868;
		border-radius: 54px;

		&:before{
			content: '';
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			top: 4px;
			left: 4px;
			z-index: 1;
			border-radius: 50px;
			background: #1e1e1e;
		}

		._top-bar_cek5jl {
			top: 60px;
			position: absolute;
			left: 0;
		}

		._bottom-bar_cek5jl {
			bottom: 90px;
			position: absolute;
			left: 0;
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 72px;
			left: 134px;
			z-index: 3;
			margin-left: -5px;
			border-radius: 100%;
		}

		._speaker_cek5jl {
			background: #292728;
			width: 64px;
			height: 10px;
			position: absolute;
			top: 72px;
			left: 50%;
			z-index: 3;
			margin-left: -32px;
			border-radius: 5px;
		}

		._sensor_cek5jl {
			background: #292728;
			width: 40px;
			height: 10px;
			position: absolute;
			top: 36px;
			left: 50%;
			z-index: 3;
			margin-left: -20px;
			border-radius: 5px;
		}

		._home_cek5jl {
			background: #242324;
			border-radius: 100%;
			width: 72px;
			height: 72px;
			z-index: 3;
			position: absolute;
			left: 50%;
			margin-left: -36px;
			bottom: 30px;

			&:after {
				width: 20px;
				height: 20px;
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 4px;
				position: absolute;
				display: block;
				content: '';
				top: 50%;
				left: 50%;
				margin-top: -11px;
				margin-left: -11px;
			}
		}

		._sleep_cek5jl {
			position: absolute;
			top: -4px;
			right: 60px;
			width: 60px;
			height: 4px;
			border-radius: 2px 2px 0px 0px;
			background: #4D4D4D;
		}

		._volume_cek5jl {
			position: absolute;
			left: -4px;
			top: 160px;
			height: 27px;
			width: 4px;
			border-radius: 2px 0px 0px 2px;
			background: #4D4D4D;

			&:before {
				position: absolute;
				left: 0px;
				top: -70px;
				height: 35px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}

			&:after {
				position: absolute;
				left: 0px;
				bottom: -64px;
				height: 27px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}
		}

		&._landscape_cek5jl{
			padding: 27px 129px;
			height: 320px;
			width: 480px;

			._bottom-bar_cek5jl {
				left: 90px;
				bottom: 0px;
				height: 100%;
				width: 3px;
			}

			._top-bar_cek5jl {
				left: calc(100% - 60px);
				top: 0px;
				height: 100%;
				width: 3px;
			}

			._camera_cek5jl {
				top: 134px;
				left: calc(100% - 72px);
				margin-left: 0;
			}

			._speaker_cek5jl{
				top: 50%;
				margin-left: 0;
				margin-top: -32px;
				left: calc(100% - 72px);
				width: 10px;
				height: 64px;
			}

			._sensor_cek5jl {
				height: 40px;
				width: 10px;
				left: calc(100% - 36px);
				top: 50%;
				margin-left: 0;
				margin-top: -20px;
			}

			._home_cek5jl {
				left: 30px;
				bottom: 50%;
				margin-left: 0;
				margin-bottom: -36px;
			}

			._sleep_cek5jl {
				height: 60px;
				width: 4px;
				right: -4px;
				top: calc(100% - 120px);
				border-radius: 0px 2px 2px 0px;
			}

			._volume_cek5jl {
				top: -4px;
				left: calc(100% - 187px);
				height: 4px;
				width: 27px;
				border-radius: 2px 2px 0px 0px;

				&:before {
					right: -70px;
					left: auto;
					top: 0px;
					width: 35px;
					height: 4px;
					border-radius: 2px 2px 0px 0px;
				}

				&:after{
					width: 27px;
					height: 4px;
					bottom: 0px;
					left: -64px;
					border-radius: 2px 2px 0px 0px;
				}
			}
		}

		&._silver_cek5jl{
			background: #bcbcbc;

			&:before{
				background: #fcfcfc;
			}

			._home_cek5jl{
				background: #fcfcfc;
				box-shadow: inset 0 0 0 1px #bcbcbc;

				&:after{
					border: 1px solid rgba(0, 0, 0, 0.2);
				}
			}

			._volume_cek5jl, ._sleep_cek5jl{
				background: #d6d6d6;
			}
		}
	}

	&._nexus5_cek5jl{
		padding: 50px 15px 50px 15px;
		width: 320px;
		height: 568px;
		background: #1e1e1e;
		border-radius: 20px;

		&:before{
			border-radius: 600px / 50px;
			background: inherit;
			content: '';
			top: 0;
			position: absolute;
			height: 103.1%;
			width: calc(100% - 26px);
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		._top-bar_cek5jl{
			width: calc(100% - 8px);
			height: calc(100% - 6px);
			position: absolute;
			top: 3px;
			left: 4px;
			border-radius: 20px;
			background: #181818;

			&:before {
				border-radius: 600px / 50px;
				background: inherit;
				content: '';
				top: 0;
				position: absolute;
				height: 103.0%;
				width: calc(100% - 26px);
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}

		._bottom-bar_cek5jl{
			display: none;
		}

		._sleep_cek5jl{
			width: 3px;
			position: absolute;
			left: -3px;
			top: 110px;
			height: 100px;
			background: inherit;
			border-radius: 2px 0px 0px 2px;
		}

		._volume_cek5jl{
			width: 3px;
			position: absolute;
			right: -3px;
			top: 70px;
			height: 45px;
			background: inherit;
			border-radius: 0px 2px 2px 0px;
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 18px;
			left: 50%;
			z-index: 3;
			margin-left: -5px;
			border-radius: 100%;

			&:before {
				background: #3c3d3d;
				width: 6px;
				height: 6px;
				content: '';
				display: block;
				position: absolute;
				top: 2px;
				left: -100px;
				z-index: 3;
				border-radius: 100%;
			}
		}

		&._landscape_cek5jl{
			padding: 15px 50px 15px 50px;
			height: 320px;
			width: 568px;

			&:before {
				width: 103.1%;
				height: calc(100% - 26px);
				border-radius: 50px / 600px;
			}

			._top-bar_cek5jl {
				left: 3px;
				top: 4px;
				height: calc(100% - 8px);
				width: calc(100% - 6px);

				&:before {
					width: 103%;
					height: calc(100% - 26px);
					border-radius: 50px / 600px;
				}
			}

			._sleep_cek5jl{
				height: 3px;
				width: 100px;
				left: calc(100% - 210px);
				top: -3px;
				border-radius: 2px 2px 0px 0px;
			}

			._volume_cek5jl{
				height: 3px;
				width: 45px;
				right: 70px;
				top: 100%;
				border-radius: 0px 0px 2px 2px;
			}

			._camera_cek5jl {
				top: 50%;
				left: calc(100% - 18px);
				margin-left: 0;
				margin-top: -5px;

				&:before {
					top: -100px;
					left: 2px;
				}
			}
		}
	}

	&._s5_cek5jl{
		padding: 60px 18px;
		border-radius: 42px;
		width: 320px;
		height: 568px;
		background: #bcbcbc;

		&:before, &:after{
			width: calc(100% - 52px);
			content: '';
			display: block;
			height: 26px;
			background: inherit;
			position: absolute;
			border-radius: 500px / 40px;
			left: 50%;
			transform: translateX(-50%);
		}

		&:before{
			top: -7px;
		}

		&:after{
			bottom: -7px;
		}

		._bottom-bar_cek5jl{
			display: none;
		}

		._top-bar_cek5jl{
			border-radius: 37px;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			top: 5px;
			left: 5px;
			background:
				radial-gradient(rgba(0, 0, 0, 0.02) 20%, transparent 60%) 0 0,
				radial-gradient(rgba(0, 0, 0, 0.02) 20%, transparent 60%) 3px 3px;
			background-color: white;
			background-size: 4px 4px;
			background-position: center;
			z-index: 2;
			position: absolute;

			&:before, &:after{
				width: calc(100% - 48px);
				content: '';
				display: block;
				height: 26px;
				background: inherit;
				position: absolute;
				border-radius: 500px / 40px;
				left: 50%;
				transform: translateX(-50%);
			}

			&:before{
				top: -7px;
			}

			&:after{
				bottom: -7px;
			}
		}

		._sleep_cek5jl{
			width: 3px;
			position: absolute;
			left: -3px;
			top: 100px;
			height: 100px;
			background: #cecece;
			border-radius: 2px 0px 0px 2px;
		}

		._speaker_cek5jl {
			width: 68px;
			height: 8px;
			position: absolute;
			top: 20px;
			display: block;
			z-index: 3;
			left: 50%;
			margin-left: -34px;
			background-color: #bcbcbc;
			background-position: top left;
			border-radius: 4px;
		}

		._sensor_cek5jl {
			display: block;
			position: absolute;
			top: 20px;
			right: 110px;
			background: #3c3d3d;
			border-radius: 100%;
			width: 8px;
			height: 8px;
			z-index: 3;

			&:after {
				display: block;
				content: '';
				position: absolute;
				top: 0px;
				right: 12px;
				background: #3c3d3d;
				border-radius: 100%;
				width: 8px;
				height: 8px;
				z-index: 3;
			}
		}

		._camera_cek5jl {
			display: block;
			position: absolute;
			top: 24px;
			right: 42px;
			background: black;
			border-radius: 100%;
			width: 10px;
			height: 10px;
			z-index: 3;

			&:before{
				width: 4px;
				height: 4px;
				background: #3c3d3d;
				border-radius: 100%;
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				margin-top: -2px;
				margin-left: -2px;
			}
		}

		._home_cek5jl {
			position: absolute;
			z-index: 3;
			bottom: 17px;
			left: 50%;
			width: 70px;
			height: 20px;
			background: white;
			border-radius: 18px;
			display: block;
			margin-left: -35px;
			border: 2px solid black;
		}

		&._landscape_cek5jl{
			padding: 18px 60px;
			height: 320px;
			width: 568px;

			&:before, &:after{
				height: calc(100% - 52px);
				width: 26px;
				border-radius: 40px / 500px;
				transform: translateY(-50%);
			}

			&:before {
				top: 50%;
				left: -7px;
			}

			&:after {
				top: 50%;
				left: auto;
				right: -7px;
			}

			._top-bar_cek5jl{
				&:before, &:after{
					width: 26px;
					height: calc(100% - 48px);
					border-radius: 40px / 500px;
					transform: translateY(-50%);
				}

				&:before{
					right: -7px;
					top: 50%;
					left: auto;
				}

				&:after{
					left: -7px;
					top: 50%;
					right: auto;
				}
			}

			._sleep_cek5jl{
				height: 3px;
				width: 100px;
				left: calc(100% - 200px);
				top: -3px;
				border-radius: 2px 2px 0px 0px;
			}

			._speaker_cek5jl {
				height: 68px;
				width: 8px;
				left: calc(100% - 20px);
				top: 50%;
				margin-left: 0;
				margin-top: -34px;
			}

			._sensor_cek5jl {
				right: 20px;
				top: calc(100% - 110px);

				&:after{
					left: -12px;
					right: 0px;
				}
			}

			._camera_cek5jl {
				top: calc(100% - 42px);
				right: 24px;
			}

			._home_cek5jl {
				width: 20px;
				height: 70px;
				bottom: 50%;
				margin-bottom: -35px;
				margin-left: 0;
				left: 17px;
			}
		}

		&._black_cek5jl{
			background: #1e1e1e;

			._speaker_cek5jl{
				background: black;
			}

			._sleep_cek5jl{
				background: #1e1e1e;
			}

			._top-bar_cek5jl{
				background: radial-gradient(rgba(0, 0, 0, 0.05) 20%, transparent 60%) 0 0,
				radial-gradient(rgba(0, 0, 0, 0.05) 20%, transparent 60%) 3px 3px;
				background-color: #2c2b2c;
				background-size: 4px 4px;
			}

			._home_cek5jl{
				background: #2c2b2c;
			}
		}
	}

	&._lumia920_cek5jl{
		padding: 80px 35px 125px 35px;
		background: #ffdd00;
		width: 320px;
		height: 533px;
		border-radius: 40px / 3px;

		._bottom-bar_cek5jl{
			display: none;
		}

		._top-bar_cek5jl{
			width: calc(100% - 24px);
			height: calc(100% - 32px);
			position: absolute;
			top: 16px;
			left: 12px;
			border-radius: 24px;
			background: black;
			z-index: 1;

			&:before{
				background: #1e1e1e;
				display: block;
				content: '';
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				top: 2px;
				left: 2px;
				position: absolute;
				border-radius: 22px;
			}
		}

		._volume_cek5jl{
			width: 3px;
			position: absolute;
			top: 130px;
			height: 100px;
			background: #1e1e1e;
			right: -3px;
			border-radius: 0px 2px 2px 0px;

			&:before {
				width: 3px;
				position: absolute;
				top: 190px;
				content: '';
				display: block;
				height: 50px;
				background: inherit;
				right: 0px;
				border-radius: 0px 2px 2px 0px;
			}

			&:after {
				width: 3px;
				position: absolute;
				top: 460px;
				content: '';
				display: block;
				height: 50px;
				background: inherit;
				right: 0px;
				border-radius: 0px 2px 2px 0px;
			}
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 34px;
			right: 130px;
			z-index: 5;
			border-radius: 5px;
		}

		._speaker_cek5jl {
			background: #292728;
			width: 64px;
			height: 10px;
			position: absolute;
			top: 38px;
			left: 50%;
			margin-left: -32px;
			border-radius: 5px;
			z-index: 3;
		}

		&._landscape_cek5jl{
			padding: 35px 80px 35px 125px;
			height: 320px;
			width: 568px;
			border-radius: 2px / 100px;

			._top-bar_cek5jl{
				height: calc(100% - 24px);
				width: calc(100% - 32px);
				left: 16px;
				top: 12px;
			}

			._volume_cek5jl {
				height: 3px;
				right: 130px;
				width: 100px;
				top: 100%;
				border-radius: 0px 0px 2px 2px;

				&:before{
					height: 3px;
					right: 190px;
					top: 0px;
					width: 50px;
					border-radius: 0px 0px 2px 2px;
				}

				&:after{
					height: 3px;
					right: 430px;
					top: 0px;
					width: 50px;
					border-radius: 0px 0px 2px 2px;
				}
			}

			._camera_cek5jl {
				right: 30px;
				top: calc(100% - 140px);
			}

			._speaker_cek5jl {
				width: 10px;
				height: 64px;
				top: 50%;
				margin-left: 0;
				margin-top: -32px;
				left: calc(100% - 48px);
			}
		}

		&._black_cek5jl{
			background: black;
		}

		&._white_cek5jl{
			background: white;
			box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
		}

		&._blue_cek5jl{
			background: #00acdd;
		}

		&._red_cek5jl{
			background: #CC3E32;
		}
	}

	&._htc-one_cek5jl {
		padding: 72px 25px 100px 25px;
		width: 320px;
		height: 568px;
		background: #bebebe;
		border-radius: 34px;

		&:before{
			content: '';
			display: block;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			position: absolute;
			top: 2px;
			left: 2px;
			background: #adadad;
			border-radius: 32px;
		}

		&:after{
			content: '';
			display: block;
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			top: 4px;
			left: 4px;
			background: #eeeeee;
			border-radius: 30px;
		}

		._top-bar_cek5jl{
			width: calc(100% - 4px);
			height: 635px;
			position: absolute;
			background: #424242;
			top: 50px;
			z-index: 1;
			left: 2px;

			&:before{
				content: '';
				position: absolute;
				width: calc(100% - 4px);
				height: 100%;
				position: absolute;
				background: black;
				top: 0px;
				z-index: 1;
				left: 2px;
			}
		}

		._bottom-bar_cek5jl{
			display: none;
		}

		._speaker_cek5jl {
			height: 16px;
			width: 216px;
			display: block;
			position: absolute;
			top: 22px;
			z-index: 2;
			left: 50%;
			margin-left: -108px;
			background: radial-gradient(#343434 25%, transparent 50%) 0 0,
			radial-gradient(#343434 25%, transparent 50%) 4px 4px;
			background-size: 4px 4px;
			background-position: top left;

			&:after {
				content: '';
				height: 16px;
				width: 216px;
				display: block;
				position: absolute;
				top: 676px;
				z-index: 2;
				left: 50%;
				margin-left: -108px;
				background: inherit;
			}
		}

		._camera_cek5jl {
			display: block;
			position: absolute;
			top: 18px;
			right: 38px;
			background: #3c3d3d;
			border-radius: 100%;
			width: 24px;
			height: 24px;
			z-index: 3;

			&:before {
				width: 8px;
				height: 8px;
				background: black;
				border-radius: 100%;
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				margin-top: -4px;
				margin-left: -4px;
			}
		}

		._sensor_cek5jl {
			display: block;
			position: absolute;
			top: 29px;
			left: 60px;
			background: #3c3d3d;
			border-radius: 100%;
			width: 8px;
			height: 8px;
			z-index: 3;

			&:after {
				display: block;
				content: '';
				position: absolute;
				top: 0px;
				right: 12px;
				background: #3c3d3d;
				border-radius: 100%;
				width: 8px;
				height: 8px;
				z-index: 3;
			}
		}

		&._landscape_cek5jl{
			padding: 25px 72px 25px 100px;
			height: 320px;
			width: 568px;

			._top-bar_cek5jl{
				height: calc(100% - 4px);
				width: 635px;
				left: calc(100% - 685px);
				top: 2px;
			}

			._speaker_cek5jl {
				width: 16px;
				height: 216px;
				left: calc(100% - 38px);
				top: 50%;
				margin-left: 0px;
				margin-top: -108px;

				&:after {
					width: 16px;
					height: 216px;
					left: calc(100% - 692px);
					top: 50%;
					margin-left: 0;
					margin-top: -108px;
				}
			}

			._camera_cek5jl {
				right: 18px;
				top: calc(100% - 38px);
			}

			._sensor_cek5jl {
				left: calc(100% - 29px);
				top: 60px;

				:after {
					right: 0;
					top: -12px;
				}
			}
		}
	}

	&._ipad_cek5jl{
		width: 576px;
		height: 768px;
		padding: 90px 25px;
		background: #242324;
		border-radius: 44px;

		&:before{
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			content: '';
			display: block;
			top: 4px;
			left: 4px;
			border-radius: 40px;
			background: #1e1e1e;
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 44px;
			left: 50%;
			margin-left: -5px;
			border-radius: 100%;
		}

		._top-bar_cek5jl, ._bottom-bar_cek5jl{
			display: none;
		}

		._home_cek5jl {
			background: #242324;
			border-radius: 36px;
			width: 50px;
			height: 50px;
			position: absolute;
			left: 50%;
			margin-left: -25px;
			bottom: 22px;

			&:after {
				width: 15px;
				height: 15px;
				margin-top: -8px;
				margin-left: -8px;
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 4px;
				position: absolute;
				display: block;
				content: '';
				top: 50%;
				left: 50%;
			}
		}

		&._landscape_cek5jl{
			height: 576px;
			width: 768px;
			padding: 25px 90px;

			._camera_cek5jl {
				left: calc(100% - 44px);
				top: 50%;
				margin-left: 0;
				margin-top: -5px;
			}

			._home_cek5jl {
				top: 50%;
				left: 22px;
				margin-left: 0;
				margin-top: -25px;
			}
		}

		&._silver_cek5jl{
			background: #bcbcbc;

			&:before{
				background: #fcfcfc;
			}

			._home_cek5jl{
				background: #fcfcfc;
				box-shadow: inset 0 0 0 1px #bcbcbc;

				&:after{
					border: 1px solid rgba(0, 0, 0, 0.2);
				}
			}
		}
	}

	&._macbook_cek5jl {
		width: 960px;
		height: 600px;
		padding: 44px 44px 76px;
		margin: 0 auto;
		background: #bebebe;
		border-radius: 34px;

		&:before {
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			content: '';
			display: block;
			top: 4px;
			left: 4px;
			border-radius: 30px;
			background: #1e1e1e;
		}

		._top-bar_cek5jl {
			width: calc(100% + 2 * 70px);
			height: 40px;
			position: absolute;
			content: '';
			display: block;
			top: 680px;
			left: -70px;
			border-bottom-left-radius: 90px 18px;
			border-bottom-right-radius: 90px 18px;
			background: #bebebe;
			box-shadow: inset 0px -4px 13px 3px rgba(34, 34, 34, 0.6);

			&:before {
				width: 100%;
				height: 24px;
				content: '';
				display: block;
				top: 0;
				left: 0;
				background: #f0f0f0;
				border-bottom: 2px solid #aaa;
				border-radius: 5px;
				position: relative;
			}

			&:after {
				width: 16%;
				height: 14px;
				content: '';
				display: block;
				top: 0;
				background: #ddd;
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				border-radius: 0 0 20px 20px;
				box-shadow: inset 0px -3px 10px #999;
			}
		}

		._bottom-bar_cek5jl {
			background: transparent;
			width: calc(100% + 2 * 70px);
			height: 26px;
			position: absolute;
			content: '';
			display: block;
			top: 680px;
			left: -70px;

			&:before,
			&:after {
				height: calc(100% - 2px);
				width: 80px;
				content: '';
				display: block;
				top: 0;

				position: absolute;
			}

			&:before {
				left: 0;
				background: #f0f0f0;
				background: linear-gradient(to right, #747474 0%, #c3c3c3 5%, #ebebeb 14%, #979797 41%, #f0f0f0 80%, #f0f0f0 100%, #f0f0f0 100%);
			}

			&:after {
				right: 0;
				background: #f0f0f0;
				background: linear-gradient(to right, #f0f0f0 0%, #f0f0f0 0%, #f0f0f0 20%, #979797 59%, #ebebeb 86%, #c3c3c3 95%, #747474 100%);
			}
		}

		._camera_cek5jl {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 20px;
			left: 50%;
			margin-left: -5px;
			border-radius: 100%;
		}

		._home_cek5jl {
			display: none;
		}
	}

	&._iphone-x_cek5jl {
		width: 375px;
		height: 812px;
		padding: 26px;
		background: #fdfdfd;
		box-shadow:inset 0 0 11px 0 black;
		border-radius: 66px;

		._overflow_cek5jl {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 66px;
			overflow: hidden;
		}

		._shadow_cek5jl {
			border-radius: 100%;
			width: 90px;
			height: 90px;
			position: absolute;
			background: radial-gradient(ellipse at center, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 60%);
		}

		._shadow--tl_cek5jl {
			top: -20px;
			left: -20px;
		}

		._shadow--tr_cek5jl {
			top: -20px;
			right: -20px;
		}

		._shadow--bl_cek5jl {
			bottom: -20px;
			left: -20px;
		}

		._shadow--br_cek5jl {
			bottom: -20px;
			right: -20px;
		}

		&:before{
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			position: absolute;
			top: 5px;
			content: '';
			left: 5px;
			border-radius: 61px;
			background: black;
			z-index: 1;
		}

		._inner-shadow_cek5jl{
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			position: absolute;
			top: 10px;
			overflow: hidden;
			left: 10px;
			border-radius: 56px;
			box-shadow: inset 0 0 15px 0 rgba(white, 0.66);
			z-index: 1;

			&:before{
				box-shadow:inset 0 0 20px 0 #FFFFFF;
				width: 100%;
				height: 116%;
				position: absolute;
				top: -8%;
				content: '';
				left: 0;
				border-radius: 200px / 112px;
				z-index: 2;
			}
		}

		._screen_cek5jl {
			border-radius: 40px;
			box-shadow: none;
		}

		._top-bar_cek5jl, ._bottom-bar_cek5jl {
			width: 100%;
			position: absolute;
			height: 8px;
			background: rgba(black, 0.1);
			left: 0;
		}

		._top-bar_cek5jl {
			top: 80px;
		}

		._bottom-bar_cek5jl {
			bottom: 80px;
		}

		._volume_cek5jl, ._volume_cek5jl:before, ._volume_cek5jl:after, ._sleep_cek5jl {
			width: 3px;
			background: #b5b5b5;
			position: absolute;
		}

		._volume_cek5jl {
			left: -3px;
			top: 116px;
			height: 32px;

			&:before {
				height: 62px;
				top: 62px;
				content: '';
				left: 0;
			}

			&:after {
				height: 62px;
				top: 140px;
				content: '';
				left: 0;
			}
		}

		._sleep_cek5jl {
			height: 96px;
			top: 200px;
			right: -3px;
		}

		._camera_cek5jl {
			width: 6px;
			height: 6px;
			top: 9px;
			border-radius: 100%;
			position: absolute;
			left: 154px;
			background: #0d4d71;
		}

		._speaker_cek5jl {
			height: 6px;
			width: 60px;
			left: 50%;
			position: absolute;
			top: 9px;
			margin-left: -30px;
			background: #171818;
			border-radius: 6px;
		}

		._notch_cek5jl {
			position: absolute;
			width: 210px;
			height: 30px;
			top: 26px;
			left: 108px;
			z-index: 4;
			background: black;
			border-bottom-left-radius: 24px;
		    border-bottom-right-radius: 24px;

		    &:before, &:after {
				content: '';
				height: 8px;
				position: absolute;
				top: 0;
				width: 8px;
		    }

			&:after {
				background: radial-gradient(circle at bottom left, transparent 0, transparent 70%, black 70%, black 100%);
				left: -8px;
			}

			&:before {
				background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
				right: -8px;
			}
		}

		&._landscape_cek5jl {
			height: 375px;
			width: 812px;

			._top-bar_cek5jl, ._bottom-bar_cek5jl {
				width: 8px;
				height: 100%;
				top: 0;
			}

			._top-bar_cek5jl {
				left: 80px;
			}

			._bottom-bar_cek5jl {
				right: 80px;
				bottom: auto;
				left: auto;
			}

			._volume_cek5jl, ._volume_cek5jl:before, ._volume_cek5jl:after, ._sleep_cek5jl {
				height: 3px;
			}

			._inner-shadow_cek5jl:before {
				height: 100%;
				width: 116%;
				left: -8%;
				top: 0;
				border-radius: 112px / 200px;
			}

			._volume_cek5jl {
				bottom: -3px;
				top: auto;
				left: 116px;
				width: 32px;

				&:before {
					width: 62px;
					left: 62px;
					top: 0;
				}

				&:after {
					width: 62px;
					left: 140px;
					top: 0;
				}
			}

			._sleep_cek5jl {
				width: 96px;
				left: 200px;
				top: -3px;
				right: auto;
			}

			._camera_cek5jl {
				left: 9px;
				bottom: 154px;
				top: auto;
			}

			._speaker_cek5jl {
				width: 6px;
				height: 60px;
				left: 9px;
				top: 50%;
				margin-top: -30px;
				margin-left: 0;
			}

			._notch_cek5jl {
				height: 210px;
				width: 30px;
				left: 26px;
				bottom: 108px;
				top: auto;
				border-top-right-radius: 24px;
			    border-bottom-right-radius: 24px;
			    border-bottom-left-radius: 0;

				&:before, &:after {
					left: 0;
				}

				&:after {
					background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
					bottom: -8px;
					top: auto;
				}

				&:before {
					background: radial-gradient(circle at top right, transparent 0, transparent 70%, black 70%, black 100%);
					top: -8px;
				}
			}
		}
	}

	&._note8_cek5jl {
		width: 400px;
		height: 822px;
		background: black;
		border-radius: 34px;
		padding: 45px 10px;

		._overflow_cek5jl {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 34px;
			overflow: hidden;
		}

		._speaker_cek5jl {
			height: 8px;
			width: 56px;
			left: 50%;
			position: absolute;
			top: 25px;
			margin-left: -28px;
			background: #171818;
			z-index: 1;
			border-radius: 8px;
		}

		._camera_cek5jl {
			height: 18px;
			width: 18px;
			left: 86px;
			position: absolute;
			top: 18px;
			background: #212b36;
			z-index: 1;
			border-radius: 100%;

			&:before{
				content: '';
				height: 8px;
				width: 8px;
				left: -22px;
				position: absolute;
				top: 5px;
				background: #212b36;
				z-index: 1;
				border-radius: 100%;
			}
		}

		._sensors_cek5jl {
			height: 10px;
			width: 10px;
			left: 120px;
			position: absolute;
			top: 22px;
			background: #1d233b;
			z-index: 1;
			border-radius: 100%;

			&:before{
				content: '';
				height: 10px;
				width: 10px;
				left: 18px;
				position: absolute;
				top: 0;
				background: #1d233b;
				z-index: 1;
				border-radius: 100%;
			}
		}

		._more-sensors_cek5jl {
			height: 16px;
			width: 16px;
			left: 285px;
			position: absolute;
			top: 18px;
			background: #33244a;
			box-shadow: 0 0 0 2px rgba(white, 0.1);
			z-index: 1;
			border-radius: 100%;

			&:before{
				content: '';
				height: 11px;
				width: 11px;
				left: 40px;
				position: absolute;
				top: 4px;
				background: #214a61;
				z-index: 1;
				border-radius: 100%;
			}
		}

		._sleep_cek5jl {
			width: 2px;
			height: 56px;
			background: black;
			position: absolute;
			top: 288px;
			right: -2px;
		}

		._volume_cek5jl {
			width: 2px;
			height: 120px;
			background: black;
			position: absolute;
			top: 168px;
			left: -2px;

			&:before {
				content: '';
				top: 168px;
				width: 2px;
				position: absolute;
				left: 0;
				background: black;
				height: 56px;
			}
		}

		._inner_cek5jl {
			width: 100%;
			height: calc(100% - 8px);
			position: absolute;
			top: 2px;
			content: '';
			left: 0px;
			border-radius: 34px;
			border-top: 2px solid #9fa0a2;
			border-bottom: 2px solid #9fa0a2;
			background: black;
			z-index: 1;
			box-shadow: inset 0 0 6px 0 rgba(white, 0.5);
		}

		._shadow_cek5jl{
			box-shadow:
				inset 0 0 60px 0 white,
				inset 0 0 30px 0 rgba(white, 0.5),
				0 0 20px 0 white,
				0 0 20px 0 rgba(white, 0.5);
			height: 101%;
			position: absolute;
			top: -0.5%;
			content: '';
			width: calc(100% - 20px);
			left: 10px;
			border-radius: 38px;
			z-index: 5;
			pointer-events: none;
		}

		._screen_cek5jl {
			border-radius: 14px;
			box-shadow: none;
		}

		&._landscape_cek5jl {
			height: 400px;
			width: 822px;
			padding: 10px 45px;

			._speaker_cek5jl {
				height: 56px;
				width: 8px;
				top: 50%;
				margin-top: -28px;
				margin-left: 0;
				right: 25px;
				left: auto;
			}

			._camera_cek5jl {
				top: 86px;
				right: 18px;
				left: auto;

				&:before {
					top: -22px;
					left: 5px;
				}
			}

			._sensors_cek5jl {
				top: 120px;
				right: 22px;
				left: auto;

				&:before {
					top: 18px;
					left: 0;
				}
			}

			._more-sensors_cek5jl {
				top: 285px;
				right: 18px;
				left: auto;

				&:before {
					top: 40px;
					left: 4px;
				}
			}

			._sleep_cek5jl {
				bottom: -2px;
				top: auto;
				right: 288px;
				width: 56px;
				height: 2px;
			}

			._volume_cek5jl {
				width: 120px;
				height: 2px;
				top: -2px;
				right: 168px;
				left: auto;

				&:before {
					right: 168px;
					left: auto;
					top: 0;
					width: 56px;
					height: 2px;
				}
			}

			._inner_cek5jl {
				height: 100%;
				width: calc(100% - 8px);
				left: 2px;
				top: 0;
				border-top: 0;
				border-bottom: 0;
				border-left: 2px solid #9fa0a2;
				border-right: 2px solid #9fa0a2;
			}

			._shadow_cek5jl {
				width: 101%;
				height: calc(100% - 20px);
				left: -0.5%;
				top: 10px;
			}
		}
	}
}

/* styles for irene/styles/_form.scss */
$color-error: #FE4D3F;

._form_1elb9o {
  background-color: #FFF;
  border-radius: 5px;
  overflow: hidden;
  padding: 2em 1.6em;
  ._checkbox-field_1elb9o {
    display: inline-block
  }
}

._input-wrap_1elb9o {
  margin: 1em 0;
  label {
    font-size: 0.9em;
    font-weight: bold;
  }
  ._label-error_1elb9o {
    min-height: 1.2em;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    ._error-msg_1elb9o {
      font-size: 0.9em;
      color: $color-error;
    }
  }
  ._half-wrap_1elb9o {
    display: flex;
    justify-content: space-between;
    ._input-field_1elb9o {
      width: 49%;
    }
  }
  ._input-field_1elb9o {
    margin: 0.2em 0;
    appearance: none;
    box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
    background-color: white;
    border: 1px solid #fff2f1;
    border-radius: 3px;
    color: #6B6B6B;
    height: 3em;
    padding: 0 1em;
    position: relative;
    border-color: #e6e6e6;
    width: 100%;
    max-width: 100%;
    &:active, &:focus, &:hover {
      outline: none;
      border-color: #b3b3b3;
      box-shadow: 0 0 1px #808080;
    }
    &::placeholder {
      color: #c1c1c1;
      font-weight: light;
    }
    &._has-error_1elb9o {
      border: 1px solid $color-error;
    }
  }
  &._has-error_1elb9o {
    ._input-field_1elb9o {
      border: 1px solid $color-error;
    }
  }
}

/* styles for irene/styles/_freshdesk.scss */
// Freshdesk support widget style override
#_freshworks-frame-wrapper_58aka1 {
  right: 0px !important;
  bottom: 0px !important;
  max-width: 568px !important;
  max-height: unset !important;
  width: 100% !important;
  height: 100vh !important;
  border-radius: 0px !important;
}

/* styles for irene/styles/_global-styles-deprecated.scss */
$color-text: #6b6b6b;
$color-table: #565d66;
$sidebar-bg: #424651;
$color-lightgray: #f7f7f7;

$risk-names: (
  warning: (
    $warning,
    $warning-invert,
  ),
  info: (
    $info,
    $info-invert,
  ),
  danger: (
    $danger,
    $danger-invert,
  ),
  critical: (
    $critical,
    $critical-invert,
  ),
  success: (
    $success,
    $success-invert,
  ),
) !default;

._bold_1qb8fw,
strong {
  font-weight: bold;
  color: currentcolor;
}

._semi-bold_1qb8fw {
  font-weight: 600;
}

h2 {
  font-size: 1.65em;
}

h3 {
  font-size: 1.5em;
}

._font-medium_1qb8fw {
  font-size: 1.2em;
}

._font-legible_1qb8fw {
  font-size: 1.1em;
}

._font-regular_1qb8fw {
  font-size: 1em;
}

._font-small_1qb8fw {
  font-size: 0.95em;
}

._text-lightgray_1qb8fw {
  color: lighten($color-table, 28%);
}

._text-darkgray_1qb8fw {
  color: $color-table;
}

._text-success_1qb8fw {
  color: $success;
}

._text-danger_1qb8fw {
  color: $danger;
}

// table related styling
._table-lightgray_1qb8fw {
  tr {
    border: 1px solid lighten(#eaecef, 2%);
    min-height: 3em;
  }

  th {
    background-color: darken(#f6f8fa, 1%);
    color: darken(#586069, 1%);
  }

  td {
    font-size: 0.9em;
  }

  th,
  td {
    border-width: 0px;
    vertical-align: middle;

    &:first-child {
      text-align: left;
      padding-left: 1em;
    }
  }

  ._btn-table_1qb8fw {
    height: auto;

    i {
      padding-right: 0.15em;
    }
  }

  ._td-with-btn_1qb8fw {
    height: 3.5em;
    display: flex;
    align-items: center;
  }
}

._table-compact_1qb8fw td {
  padding: 0.4em;
}

._empty-state_1qb8fw {
  font-style: italic;
  color: lighten($color-table, 30%);
  background: lighten($color-table, 61%);
  border: 0.1em solid lighten($color-table, 59%);
  border-radius: 0.2em;
  padding: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  ._fa_1qb8fw {
    font-size: 0.9em;
    margin-right: 0.5em;
    color: lighten($color-table, 45%);
  }
}

._fill-primary_1qb8fw {
  background: $primary;
  color: $light;
}

._fill-silver_1qb8fw {
  background: darken(#f6f8fa, 1%);
}

._fill-lightgray_1qb8fw {
  background: #f7f7f7;
}

._fill-white_1qb8fw {
  background: white;
}

// button related styling
._btn_1qb8fw {
  &-default {
    background: $default;
    border-color: $default;
    color: white;

    &:hover,
    &:active,
    &:focus {
      background: darken($default, 12%);
      border-color: darken($default, 12%);
      color: white;
    }

    &[disabled] {
      background: $default;
      border-color: $default;
    }
  }

  &-success {
    background: $success;
    border-color: $success;
    color: white;

    &:hover,
    &:active,
    &:focus {
      background: darken($success, 12%);
      border-color: darken($success, 12%);
      color: white;
    }

    &[disabled] {
      background: $success;
      border-color: $success;
    }
  }
}

._bordered-box_1qb8fw {
  border: 1px solid darken($color-lightgray, 5%);
  border-radius: 0.2em;
  padding: 0.75em 1.1em 0.65em;
}

// alert related styling
._alert_1qb8fw {
  background: $color-lightgray;
  border: 1px solid darken($color-lightgray, 3%);
  color: darken($color-lightgray, 37%);
  border-radius: 0.2em;
  padding: 0.75em 1.1em 0.65em;

  &-table {
    background: transparent;
    color: currentColor;

    tr:hover {
      background: inherit;
      color: inherit;
    }

    td {
      border-color: currentColor;
      padding: 0.35em 0.5em;
    }
  }

  &-link {
    font-weight: bold;
  }

  &-small {
    font-size: 0.93em;
    line-height: 1.5;
  }

  strong {
    color: inherit;
  }

  &-critical {
    background: rgba($critical, 0.11);
    border-color: rgba($critical, 0.2);
    color: mix($color-table, $critical, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $critical, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    ._alert-table_1qb8fw {
      background: lighten(rgba($critical, 0.11), 60%);

      td {
        border-color: rgba($critical, 0.35);
      }
    }
  }

  &-danger {
    background: rgba($danger, 0.11);
    border-color: rgba($danger, 0.2);
    color: mix($color-table, $danger, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $danger, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    ._alert-table_1qb8fw {
      background: lighten(rgba($danger, 0.11), 60%);

      td {
        border-color: rgba($danger, 0.35);
      }
    }
  }

  &-warning {
    background: rgba($warning, 0.11);
    border-color: rgba($warning, 0.3);
    color: mix($color-table, mix($primary, $warning, 15%), 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, mix($primary, $warning, 25%), 60%);

      &:hover {
        text-decoration: underline;
      }
    }

    ._alert-table_1qb8fw {
      background: lighten(rgba($warning, 0.11), 60%);

      td {
        border-color: rgba($warning, 0.4);
      }
    }
  }

  &-info {
    background: rgba($info, 0.11);
    border-color: rgba($info, 0.2);
    color: mix($color-table, $info, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $info, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    ._alert-table_1qb8fw {
      background: lighten(rgba($info, 0.11), 60%);

      td {
        border-color: rgba($info, 0.35);
      }
    }
  }

  &-success {
    background: rgba($success, 0.11);
    border-color: rgba($success, 0.2);
    color: mix($color-table, $success, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $success, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    ._alert-table_1qb8fw {
      background: lighten(rgba($success, 0.11), 60%);

      td {
        border-color: rgba($success, 0.35);
      }
    }
  }
}

._fill-success-box_1qb8fw {
  border-color: rgba($success, 0.5);
  background: rgba($success, 0.15);

  &:hover {
    background: rgba($success, 0.15);
  }
}

._alert_1qb8fw {
  background: $color-lightgray;
  border: 1px solid darken($color-lightgray, 3%);
  color: darken($color-lightgray, 37%);
  border-radius: 0.2em;
  padding: 0.75em 1.1em 0.65em;

  &-link {
    font-weight: bold;
  }

  &-small {
    font-size: 0.93em;
    line-height: 1.5;
  }
  &-critical {
    background: rgba($critical, 0.11);
    border-color: rgba($critical, 0.2);
    color: mix($color-table, $critical, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $critical, 55%);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-danger {
    background: rgba($danger, 0.11);
    border-color: rgba($danger, 0.2);
    color: mix($color-table, $danger, 30%);
    ._alert-link_1qb8fw {
      color: mix($color-table, $danger, 55%);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-warning {
    background: rgba($warning, 0.11);
    border-color: rgba($warning, 0.3);
    color: mix($color-table, mix($primary, $warning, 15%), 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, mix($primary, $warning, 25%), 60%);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-info {
    background: rgba($info, 0.11);
    border-color: rgba($info, 0.2);
    color: mix($color-table, $info, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $info, 55%);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-success {
    background: rgba($success, 0.11);
    border-color: rgba($success, 0.2);
    color: mix($color-table, $success, 30%);

    ._alert-link_1qb8fw {
      color: mix($color-table, $success, 55%);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

$badge-small-size: 1.5em;
._badge-square_1qb8fw {
  min-width: $badge-small-size * 1.3;
  height: $badge-small-size;
  border-radius: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.82em;
  font-weight: bold;

  &._is-default_1qb8fw {
    background: lighten($color-table, 51%);
  }

  &._is-sidebar_1qb8fw {
    background: darken($sidebar-bg, 5%);
    color: lighten($sidebar-bg, 65%);
  }
}

$spacers: margin padding;
$fractional-pts: q 0.25, h 0.5, 3Q 0.75;

@each $spacer in $spacers {
  @each $fp in $fractional-pts {
    ._#{$spacer}-t-#{nth($fp, 1)_1qb8fw},
    ._#{$spacer}-v-#{nth($fp, 1)_1qb8fw} {
      #{$spacer}-top: #{nth($fp, 2)}em;
    }
    ._#{$spacer}-b-#{nth($fp, 1)_1qb8fw},
    ._#{$spacer}-v-#{nth($fp, 1)_1qb8fw} {
      #{$spacer}-bottom: #{nth($fp, 2)}em;
    }
    ._#{$spacer}-l-#{nth($fp, 1)_1qb8fw},
    ._#{$spacer}-h-#{nth($fp, 1)_1qb8fw} {
      #{$spacer}-left: #{nth($fp, 2)}em;
    }
    ._#{$spacer}-r-#{nth($fp, 1)_1qb8fw},
    ._#{$spacer}-h-#{nth($fp, 1)_1qb8fw} {
      #{$spacer}-right: #{nth($fp, 2)}em;
    }
  }
}

@each $spacer in $spacers {
  @for $i from 0 through 4 {
    ._#{$spacer}-t-#{$i}_1qb8fw,
    ._#{$spacer}-v-#{$i}_1qb8fw {
      #{$spacer}-top: #{$i}em;
    }
    ._#{$spacer}-b-#{$i}_1qb8fw,
    ._#{$spacer}-v-#{$i}_1qb8fw {
      #{$spacer}-bottom: #{$i}em;
    }
    ._#{$spacer}-l-#{$i}_1qb8fw,
    ._#{$spacer}-h-#{$i}_1qb8fw {
      #{$spacer}-left: #{$i}em;
    }
    ._#{$spacer}-r-#{$i}_1qb8fw,
    ._#{$spacer}-h-#{$i}_1qb8fw {
      #{$spacer}-right: #{$i}em;
    }
    ._#{$spacer}-t-#{$i}h_1qb8fw,
    ._#{$spacer}-v-#{$i}h_1qb8fw {
      #{$spacer}-top: #{$i + 0.5}em;
    }
    ._#{$spacer}-b-#{$i}h_1qb8fw,
    ._#{$spacer}-v-#{$i}h_1qb8fw {
      #{$spacer}-bottom: #{$i + 0.5}em;
    }
    ._#{$spacer}-l-#{$i}h_1qb8fw,
    ._#{$spacer}-h-#{$i}h_1qb8fw {
      #{$spacer}-left: #{$i + 0.5}em;
    }
    ._#{$spacer}-r-#{$i}h_1qb8fw,
    ._#{$spacer}-h-#{$i}h_1qb8fw {
      #{$spacer}-right: #{$i + 0.5}em;
    }
  }
}

// margin & padding util classes
._margin-top30_1qb8fw {
  margin-top: 30px;
}

._margin-top-10_1qb8fw {
  margin-top: -10px;
}

._margin-top-20_1qb8fw {
  margin-top: -20px;
}

._margin-top-5_1qb8fw {
  margin-top: -5px;
}

._margin-top50_1qb8fw {
  margin-top: 50px;
}

._margin-top_1qb8fw {
  margin-top: 10px;
}

._margin-top5_1qb8fw {
  margin-top: 5px;
}

._margin-top3_1qb8fw {
  margin-top: 3px;
}

._margin-top20_1qb8fw {
  margin-top: 20px;
}

._margin-top35_1qb8fw {
  margin-top: 35px;
}

._margin-left_1qb8fw {
  margin-left: 10px;
}

._margin-left5_1qb8fw {
  margin-left: 5px;
}

._margin-left30_1qb8fw {
  margin-left: 30px;
}

._margin-left0_1qb8fw {
  margin-left: 0px;
}

._margin-top50_1qb8fw {
  margin-top: 50px;
}

._margin-left20_1qb8fw {
  margin-left: 20px;
}

._margin-right_1qb8fw {
  margin-right: 10px;
}

._margin-right20_1qb8fw {
  margin-right: 40px;
}

._margin-left-10_1qb8fw {
  margin-left: -10px;
}

._margin-right-40_1qb8fw {
  margin-right: -40px;
}

._margin-right100_1qb8fw {
  margin-right: 100px;
}

._margin-top-bottom_1qb8fw {
  margin-top: 10px;
  margin-bottom: 10px;
}

._margin-bottom0_1qb8fw {
  margin-bottom: 0px !important;
}

._margin-bottom_1qb8fw {
  margin-bottom: 10px;
}

._margin-bottom20_1qb8fw {
  margin-bottom: 20px;
}

._margin-bottom-20_1qb8fw {
  margin-bottom: -20px;
}

._margin-bottom40_1qb8fw {
  margin-bottom: 40px;
}

._margin-bottom50_1qb8fw {
  margin-bottom: 50px;
}

._margin-h-neg-20_1qb8fw {
  margin-left: -20px;
  margin-right: -20px;
}

._margin-v-neg-20_1qb8fw {
  margin-top: -20px;
  margin-bottom: -20px;
}

._margin-t-neg-20_1qb8fw {
  margin-top: -20px;
}

._margin-b-neg-20_1qb8fw {
  margin-bottom: -20px;
}

._padding-bottom0_1qb8fw {
  padding-bottom: 0px !important;
}

._top0_1qb8fw {
  top: 0px !important;
}

._maxwidth65_1qb8fw {
  max-width: 65% !important;
}

._margin-both_1qb8fw {
  margin-left: 10px;
  margin-right: 10px;
}

._multiple-files-manual_1qb8fw {
  margin-left: 10%;
}

._multiple-files-no-manual_1qb8fw {
  margin-left: 25%;
}

._no-multiple-files-manual_1qb8fw {
  margin-left: -10%;
}

._no-multiple-files-no-manual_1qb8fw {
  margin-left: 10%;
}

._reduce-padding_1qb8fw {
  padding: 5px 20px 0px 20px !important;
}

._padding-bottom0_1qb8fw {
  padding-bottom: 0px;
}

._margin-auto_1qb8fw {
  margin: auto;
}

._flex-grow-1_1qb8fw {
  flex-grow: 1;
}

._flex-grow-2_1qb8fw {
  flex-grow: 2;
}

._margin-side-auto_1qb8fw {
  margin: 0px auto;
}

._width-100_1qb8fw {
  width: 100px;
}

._display-inline_1qb8fw {
  display: inline;
}

._display-inline-block_1qb8fw {
  display: inline-block;
}

._contain-width_1qb8fw {
  width: 100%;
}

/* Appknox specific */
._appknox-logo_1qb8fw {
  margin-top: 13px;
}

._appknox-logo_1qb8fw img {
  max-width: 56%;
  max-height: 9em;
  margin: auto;
}

._font-size12_1qb8fw {
  font-size: 12px;
}

button,
._button_1qb8fw {
  border-radius: 5px;
  ._fa_1qb8fw {
    font-size: inherit;
  }
}

button {
  &._no-border_1qb8fw {
    border: none;
  }

  &._is-icon_1qb8fw {
    font-size: 16px;
    padding: 0.3em 0.6em;
  }
}

._width100_1qb8fw {
  width: 100px;
}

._black-text_1qb8fw {
  color: #323232;
}

._red-text_1qb8fw {
  color: $red;
}

._default-text_1qb8fw {
  color: $default;
}

._upper-nav_1qb8fw {
  position: relative;
  background: white;
  box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.3);
}

._scan-status_1qb8fw {
  li {
    ._fa-check_1qb8fw {
      color: #80c081;
    }

    ._fa-times_1qb8fw {
      color: #ef4836;
    }
  }
}

._float-right_1qb8fw {
  float: right;
}

._current-user_1qb8fw {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 50px;
}

._logo-img_1qb8fw {
  margin: 0 auto;
  width: 100%;
}

._edit-button_1qb8fw {
  margin-left: 20px;
  position: relative;
  top: 5px;
  ._fa-check_1qb8fw {
    color: $green;
  }
  ._fa-times_1qb8fw {
    color: $red;
  }
}

._edit-input_1qb8fw {
  max-width: 30%;
}

input {
  display: block;

  &::placeholder {
    font-style: italic;
    opacity: 0.5;
    color: lighten($color-table, 30%);
  }
}

._textarea-input_1qb8fw {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  color: $color-table;
  display: inline-flex;
  justify-content: flex-start;
  padding: 0.3em 0.6em;
  box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
  max-width: 100%;
  width: 100%;

  &::placeholder {
    color: lighten($color-table, 30%);
  }
}

canvas._other-devices_1qb8fw {
  margin-top: 30px;
}

._title-tag_1qb8fw {
  background-color: #5bc0de;
  border-radius: 0px;
  color: #fff;
  align-items: center;
}

._login-phone-image_1qb8fw {
  img {
    width: 70%;
  }
}

._box-section_1qb8fw {
  margin-top: 20px;
  border-radius: 5px;

  form {
    padding: 10px;
    font-size: 16px;

    input {
      margin-top: 10px;
    }
  }

  ._plan-text_1qb8fw {
    text-align: center;
    margin-top: 20px;
    color: lighten($default, 20%);
  }

  ._choose-units_1qb8fw {
    padding: 20px 50px 0 50px;
    text-align: center;

    ._column_1qb8fw {
      margin-top: 2px;

      h3 {
        border: 2px solid lighten($default, 30%);
      }
    }
  }
}

._plus-icon_1qb8fw {
  background-color: lighten($success, 5%) !important;
  color: $white;
  border-radius: 50% !important;
  font-size: 20px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken($success, 5%);
    border-color: #80c081 !important;
  }
}

._minus-icon_1qb8fw {
  background-color: lighten($critical, 5%) !important;
  border-color: lighten($critical, 5%) !important;
  color: $white;
  border-radius: 50% !important;
  font-size: 20px;
  width: 35px;
  height: 35px;
  cursor: pointer;

  &:hover {
    background-color: darken($critical, 5%) !important;
  }
}

._box-sub-section_1qb8fw {
  box-shadow: 0 0 6px #ccc;
  margin-bottom: 0px !important;
  margin-top: 20px;
  height: 50px;
}

._box-section-content_1qb8fw {
  margin-top: 10px;
  line-height: 20px;
  padding: 10px;
  font-size: 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

._pricing-section-content_1qb8fw {
  line-height: 30px;
}

._invoice-overview_1qb8fw {
  flex: none;
  width: 95%;
  margin: 10px auto;
  border: 1px solid #a9a9a9;
}

._horizontal-seperator_1qb8fw {
  margin: 30px 0px;
  border-top-color: #dcdcdc;
}

._is-reverse_1qb8fw {
  display: flex;
  flex-direction: row-reverse;
}

._status-box_1qb8fw {
  color: #31708f;
  margin-top: 10px;
  box-shadow: 0px 2px 2px #888, 0px -2px 2px #888;
  padding: 5px 5px;
}

// TODO: Remove this when Bohemia is updated
select {
  appearance: button;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  user-select: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAeCAYAAAAo5+5WAAAAdUlEQVRIx2MQllCs+P//vwcQM1ATM4waPGrwqMEjzmBSweAxGMgu+k8YFJHjYsvFixfvwWUiSA6khtygsFmxYsV+dENBYiA5SsPYfvXq1QdghoLYIDFqRZ7jxo0bD4MwiE3tVOECxYM0uVHd4NFCaNTgQW4wAKpugq+9Zk7QAAAAAElFTkSuQmCC),
    -webkit-linear-gradient(#fafafa, #f4f4f4 40%, #e5e5e5);
  background-position: 97% center;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  font-size: inherit;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 175px;
}

._file-input_1qb8fw {
  position: fixed;
  top: -100px;
}

._input-file_1qb8fw {
  padding: 0.45em;
}

._or_1qb8fw {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    height: 0.1em;
    background: lighten($color-table, 58%);
    width: 47%;
    position: absolute;
    left: 0;
  }

  &::after {
    content: '';
    height: 0.1em;
    background: lighten($color-table, 58%);
    width: 47%;
    position: absolute;
    right: 0;
  }
}

._not-found_1qb8fw {
  text-align: center;
  margin-top: 50px;

  i {
    margin-top: 7px;
    margin-right: 5px;
  }
}

._implication-list_1qb8fw {
  margin-left: 20px;
  list-style-type: circle;
}

._scrollable-box_1qb8fw {
  display: inline-block;
  max-width: 30vw;
  min-width: 100%;
  overflow: scroll;
  text-overflow: clip;
  max-height: 300px;
  min-height: 50px;
  padding: 9.5px;
  margin: 10px 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

._pre-code_1qb8fw {
  background-color: #f5f5f5;
}

._user-info_1qb8fw {
  margin: 5px 5px;
  padding-right: 10px;
  border-right: 2px solid #888;
}

._user-box_1qb8fw {
  margin-top: 8px;
  border: 2px solid #888;
  padding: 3px;
}

._text-centre_1qb8fw {
  text-align: center;
}

._text-right_1qb8fw {
  text-align: right;
}

._vertical-align-center_1qb8fw {
  display: flex;
  align-items: center;
}

._vertical-align-top_1qb8fw {
  display: flex;
  align-items: flex-start;
}

._vertical-align-stretch_1qb8fw {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: stretch;
  height: 100%;
}

._flex-column_1qb8fw {
  display: flex;
  flex-direction: column;
}

._align-middle_1qb8fw {
  display: flex;
  justify-content: center;
  align-items: center;
}

._align-start_1qb8fw {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

._align-end_1qb8fw {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._align-both_1qb8fw {
  display: flex;
  justify-content: space-between;
}

._align-both-centered_1qb8fw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._align-center_1qb8fw {
  display: flex;
  justify-content: center;
}

._strip_1qb8fw {
  margin: -25px -25px 10px -25px;
  background-color: #80c081;
  height: 50px;
  color: white;
}

._recover-form_1qb8fw {
  width: 20% !important;
}

._account-overview_1qb8fw {
  padding: 5px 30px;
  font-weight: bolder;
}

._account-overview-details_1qb8fw {
  padding: 5px 45px;
  font-size: initial;
}

._modal-footer_1qb8fw {
  margin-top: 20px;
}

._delete_1qb8fw {
  margin-left: 20px;
}

._form-spacing_1qb8fw {
  input,
  button {
    margin-top: 10px;
  }
}

div[class^='_c-notification__container'] {
  z-index: 2000 !important;
}

._is-progress_1qb8fw {
  border: 1px solid #ffe0dd;
}

._padding-10_1qb8fw {
  padding: 10px !important;
}

._capitalize-text_1qb8fw {
  text-transform: capitalize;
}

._uppercase-text_1qb8fw {
  text-transform: uppercase;
}

._lowercase-text_1qb8fw {
  text-transform: lowercase;
}

._copy-icon_1qb8fw {
  font-size: 16px;
}

._risk-icons_1qb8fw {
  font-size: 14px;
}

._fa-font-size-small_1qb8fw i {
  font-size: 13px !important;
  margin-top: 6px;
}

._fa-font-size_1qb8fw i {
  font-size: 15px !important;
  margin-top: 5px;
}

._columns-main-container_1qb8fw {
  margin-top: 0px !important;
}

._is-two-third_1qb8fw {
  width: 66.67%;
}

._box-container_1qb8fw {
  padding: 5px 20px 20px 20px;
  border-bottom: 3px solid #f0f0f0;
  margin-left: -9px;
  margin-right: -9px;
  margin-bottom: 10px;
}

._box-container-w-b_1qb8fw {
  @extend .box-container;

  border-bottom: unset;
}

._divider_1qb8fw {
  height: 0;
  width: 100%;
  border-bottom: 3px solid #f0f0f0;
}

._project-container_1qb8fw {
  margin-top: 10px;

  ._project-loading-container_1qb8fw {
    margin: 10% auto 0 auto;
  }
}

._top-nav_1qb8fw {
  --top-nav-icon-color: #e5e5e5;
  --top-nav-icon-color-hover: #fff;
  width: 100%;
  margin-top: -10px;
  height: 50px;
  // background-color: #424651
  background: darken($sidebar-bg, 3%);
  margin-left: 0px;
  // border-left: 1px solid #ccc
  position: fixed;
  z-index: 11;

  ._scan-text_1qb8fw {
    font-weight: lighter;
    margin-top: 5px;
    margin-left: 1.5em;
    color: #fff;
    padding: 10px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ._project-count_1qb8fw {
    padding: 3px;
    min-width: 120px;
    margin-top: 3px;

    a {
      font-size: 14px;
      border-radius: 5px;
      padding: 3px 10px;
      color: #fff;
    }
  }

  ._top-icons_1qb8fw {
    margin-right: 15%;
    margin-top: -5px;

    a {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #e5e5e5;
      margin: 10px 10px;

      ._sidebar-icons_1qb8fw {
        margin-top: 1px;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}

._side-menu_1qb8fw {
  background-color: $sidebar-bg;
  z-index: 13;
  box-shadow: none;

  &-fixed {
    padding-top: 1em;
    box-sizing: border-box;
    position: fixed;
    width: 16.67%;
    height: 100%;
    top: 0;
    background-color: var(--org-side-menu-bg-color);
    box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.3);
  }

  ._box_1qb8fw {
    background-color: #f0f0f0;
    box-shadow: none;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 5px;

    ._card_1qb8fw {
      background-color: lighten(#424651, 70%);
      color: $default;
      border-radius: 50px;
      max-width: 100px;
      margin: auto;
      padding: 5px;
    }
  }

  ._menu_1qb8fw {
    position: fixed;
    width: 16%;
    margin-top: 20px;

    ._container_1qb8fw {
      margin: 0px 5px !important;
    }
  }

  ._appknox-logo_1qb8fw {
    width: 100%;
    margin-bottom: 1em;
  }
}

._menu-list_1qb8fw {
  ._menu-list-title-beta_1qb8fw {
    margin-left: 0.15em;
  }

  ._menu-list-link_1qb8fw {
    padding: 0.4em 1em;
    color: lighten($sidebar-bg, 45%);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      color: lighten($sidebar-bg, 65%);
      background-color: lighten(#424651, 3%);
      font-weight: 500;
    }

    &._active_1qb8fw {
      color: lighten($sidebar-bg, 65%);
      background-color: lighten($sidebar-bg, 5%);
    }

    ._menu-list-title-container_1qb8fw {
      display: flex;
      align-items: center;

      ._menu-list-icons_1qb8fw {
        color: lighten($sidebar-bg, 25%);
        margin-right: 0.3em;
      }
    }
  }
}

._bg-color-container_1qb8fw {
  background-color: #424651;
}

._theme-dark_1qb8fw {
  ._bg-color-container_1qb8fw {
    background-color: #424651;
  }
}

._theme-light_1qb8fw {
  ._bg-color-container_1qb8fw {
    background-color: #ffffff;
  }

  ._side-menu_1qb8fw {
    background-color: #ffffff;

    ._menu-list_1qb8fw a {
      color: #6b6b6b;

      &:hover,
      &:active,
      &:focus {
        color: #414651;
        background-color: lighten(#ededed, 3%);
      }

      &._active_1qb8fw,
      &._active_1qb8fw:hover,
      &._active_1qb8fw:active,
      &._active_1qb8fw:focus {
        background-color: #ededed;
        color: #414651;
      }
    }

    ._menu-label_1qb8fw {
      background-color: lighten(#ededed, 3%);
      color: #6b6b6b;
    }
  }

  ._auth-main-container_1qb8fw {
    background-color: #ffffff;

    ._has-text-centered_1qb8fw h6 {
      color: #6b6b6b;
    }
  }

  ._badge-square_1qb8fw._is-sidebar_1qb8fw {
    background: #9a9fab;
    color: #ffffff;
  }

  ._top-nav_1qb8fw {
    --top-nav-icon-color: #7c7f88;
    --top-nav-icon-color-hover: #28282b;

    background: #f3f3f3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    ._scan-text_1qb8fw {
      color: #7c7f88;
    }

    ._top-icons_1qb8fw a {
      color: #7c7f88;

      &:hover {
        color: #28282b;
      }

      &._button_1qb8fw {
        border-color: #7c7f88;

        &:hover {
          border-color: #28282b;
        }
      }
    }
  }
}

._scroll-down_1qb8fw {
  width: 14%;
  position: fixed;
  top: 510px;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

._input_1qb8fw {
  border-color: #e6e6e6 !important;
}

._input_1qb8fw:hover {
  border-color: #b3b3b3 !important;
  box-shadow: 0 0 1px #808080;
}

._card-form_1qb8fw {
  width: 40%;
  margin-left: 30%;
  margin-top: 10px;
}

._copy-password_1qb8fw {
  cursor: pointer;
}

._api-scanning_1qb8fw {
  ._fa-exclamation-triangle_1qb8fw._exclamation-warning_1qb8fw {
    margin-top: 0;
    vertical-align: baseline;
    color: #f5d76e;
  }
}

._api-scanning_1qb8fw {
  ._no-data_1qb8fw {
    color: #404040;

    ._column_1qb8fw {
      &:first-child {
        background-color: #f5d76e;
      }

      &:nth-child(2) {
        border-width: 2px 2px 2px 0px;
        border-style: solid;
        border-color: lighten($default, 50%);
      }
    }
  }
}

._api-scanning_1qb8fw {
  ._warning-band_1qb8fw {
    // background-color: #F5D76E
    padding: 0px 10px;
    color: #404040;

    ._column_1qb8fw {
      &:first-child {
        background-color: #f5d76e;
      }

      &:nth-child(2) {
        border-width: 2px 2px 2px 0px;
        border-style: solid;
        border-color: lighten($default, 50%);
      }
    }
  }

  ._fa_1qb8fw._fa-trash-o_1qb8fw {
    color: $red;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  ._position-icons_1qb8fw {
    margin-top: 18px !important;
  }

  ._edit-filter_1qb8fw {
    padding-top: 15px;
  }

  button._modal-button_1qb8fw {
    min-width: 70px;
  }

  form {
    p._control_1qb8fw {
      ._column_1qb8fw:nth-child(1) {
        display: flex;
        justify-content: flex-end;
      }

      ._column_1qb8fw:nth-child(2) {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  ._start-scan_1qb8fw {
    width: 150px;
  }

  ul {
    li {
      margin-top: 10px;
    }
  }
}

._overflow_1qb8fw._ellipse_1qb8fw {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 155px;
}

._hide-overflow_1qb8fw {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    z-index: 1;
    overflow: visible;
  }
}

._hide-overflow_1qb8fw._package-name_1qb8fw {
  &:hover {
    z-index: 1;
    overflow: hidden;
  }
}

@media screen and (min-width: 1380px) {
  ._hide-overflow_1qb8fw._package-name_1qb8fw {
    max-width: 250px;
  }
}

@media screen and (max-width: 1380px) {
  ._hide-overflow_1qb8fw._package-name_1qb8fw {
    max-width: 200px;
  }
}

._wrap-text_1qb8fw {
  word-break: break-word;
}

._app-icon_1qb8fw {
  margin-top: 10px;
}

._overflow-hidden_1qb8fw {
  @extend .hide-overflow;

  max-width: 100px;
}

._hidden-overflow_1qb8fw {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._fixed-icon_1qb8fw {
  position: absolute;
  left: 85%;
}

._error-page_1qb8fw {
  margin-top: 150px;

  h1,
  h2,
  div {
    display: flex;
    justify-content: center;
  }

  h1 {
    font-size: 85px;
  }
}

._package-desc_1qb8fw {
  margin-right: 0px;
  margin-top: 0px !important;

  table {
    width: 80%;
    font-size: 13px;
    margin-top: 10px;

    tr {
      td {
        text-align: center;
        padding: 2px;
        border: 2px solid lighten($default, 45%) !important;
        vertical-align: middle;
      }
    }
  }

  table._api-scan-status_1qb8fw {
    tr {
      td {
        padding: 7px;
      }
    }
  }
}

._personal-token-component_1qb8fw {
  @extend .namespace-component;

  table._token-table_1qb8fw tr {
    td {
      font-size: 13px;
    }

    td,
    th {
      text-align: center !important;
    }
  }
}

._namespace-component_1qb8fw {
  ._input-column_1qb8fw {
    max-width: 250px;
  }

  input._input_1qb8fw {
    width: 300px;
    margin-left: -10px;
    margin-top: 10px;
  }

  button {
    margin-top: 10px;
  }

  table {
    @extend .api-filter-table;

    width: 230px !important;
    margin-top: 10px !important;
    max-width: auto;
    margin-left: -10px;

    tr {
      td,
      th {
        text-align: left !important;
      }
    }
  }
}

._api-filter-table_1qb8fw {
  width: 370px;
  margin-top: 20px;

  ._edit-form-input_1qb8fw {
    width: 80%;
    height: 25px;
  }

  tr {
    th {
      border: none;
      font-size: 12px;

      &:last-child {
        text-align: right;
      }
    }

    td {
      border: none;
      background-color: #f2f2f2;
      padding-left: 20px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        text-align: right;
        padding-right: 30px;
      }
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  ._fa_1qb8fw {
    font-size: 16px;
  }

  ._fa-trash-o_1qb8fw {
    color: $critical;
  }
}

._device-table_1qb8fw {
  width: 30%;

  tr {
    td {
      border-color: #e5e5e5;

      &:first-child {
        color: #000;
      }
    }
  }
}

._report-preferences_1qb8fw {
  ._question_1qb8fw {
    ._column_1qb8fw:first-child {
      position: relative;
      top: 10px;
    }
  }
}

._invoice-table_1qb8fw {
  table {
    tr {
      th {
        background-color: lighten(#424651, 5%);
        color: #fff;
      }

      th,
      td {
        text-align: center;
        border: 1px solid #b8b8b8;
        padding: 8px 0px;
        vertical-align: middle;
      }
    }
  }
}

._invoice-list-container_1qb8fw {
  padding: 10px 100px;
}

._box-section_1qb8fw {
  button {
    background-color: #80c081;
    border-color: #80c081;
    color: white !important;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  button:hover,
  button:focus {
    background-color: darken(#80c081, 10%);
    border-color: darken(#80c081, 10%);
  }
}

._card-container_1qb8fw {
  border: 5px solid #80c081;
  padding: 25px;
  margin: 25px;
}

._card-details_1qb8fw {
  form {
    ._card-form-header_1qb8fw {
      @extend .strip;
      @extend .align-middle;

      margin: 0px;
      background-color: #a9a9a9;
    }

    ._columns_1qb8fw {
      margin-left: -1px;

      ._column_1qb8fw:first-child {
        margin-left: -10px;
      }

      ._column_1qb8fw:nth-child(2) {
        margin-left: 10px;
      }
    }

    ._control_1qb8fw {
      border-bottom: 1px solid #808080;
      margin-left: 10px;
      margin-right: 10px;

      ._input_1qb8fw {
        border: 0px solid black !important;
        box-shadow: none;
      }

      button {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    ._control_1qb8fw._last-child_1qb8fw {
      border-bottom: none;
    }
  }
}

._card-header_1qb8fw {
  ._pricing-title_1qb8fw {
    align-items: center;
    justify-content: center;
  }
}

._auto-adjust_1qb8fw {
  button {
    width: 100px;
    margin: auto;
  }
}

._hideOrShow_1qb8fw {
  cursor: pointer;
}

._os-icon_1qb8fw {
  margin-left: 20px;
  max-width: 20px;
}

._card-icon_1qb8fw {
  margin-top: 15px;
}

._file-card_1qb8fw {
  ._card-header_1qb8fw {
    background: darken($color-lightgray, 0.5%);
    padding: 0.4em 0 0.5em 0.4em;
    box-shadow: none;
  }

  ._card-content_1qb8fw {
    padding: 0px;

    ._content_1qb8fw {
      ._columns_1qb8fw._is-reverse_1qb8fw {
        margin-left: 40px;
      }
    }
  }
}

._app-information_1qb8fw {
  background: lighten($color-lightgray, 2%);
  border-bottom: 1px solid lighten($color-lightgray, 0.5%);
  padding: 0.2em 0.2em 0.15em 0.8em;
}

._file-overview-scan-status_1qb8fw {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  ._scan-status-tag_1qb8fw {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;

    &-label {
      padding-left: 0.3em;
      font-size: 0.83em;
      line-height: 1.6;
      display: flex;
      align-items: center;
    }

    &-icon {
      display: flex;
      align-items: center;

      &-done {
        color: $success;
      }

      ._fa_1qb8fw {
        font-size: 0.83em;
        line-height: 1.4;
      }
    }
  }
}

._compare-container_1qb8fw {
  padding: 0px 5px;
}

._file-card_1qb8fw,
._box-section_1qb8fw,
._team-overview-card_1qb8fw {
  box-shadow: 0px 0px 6px #ccc;

  &:hover {
    box-shadow: 1px 1px 20px #999;
    z-index: 1;
  }
}

._project-specific_1qb8fw {
  ._column_1qb8fw {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  ._card-button_1qb8fw {
    margin-top: 10px;
    margin-right: 10px;
  }

  ._card-button_1qb8fw:nth-child(1) {
    margin-left: 30px;
  }

  ._card-button_1qb8fw:nth-child(2) {
    margin-left: -20px;
  }
}

._file-hide-overflow_1qb8fw {
  margin-top: -15px;
}

._more-details_1qb8fw {
  margin-left: 10px;
  color: $info;
  font-size: 18px;
  margin-top: 1px;
}

._file-buttons_1qb8fw {
  margin-top: 7px;

  button {
    &:nth-child(1) {
      margin-left: 20px;
    }

    &:nth-child(2) {
      margin-left: 10px;
    }

    &:last-child {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

._file-icon_1qb8fw {
  margin-top: -5px;
  margin-left: 5px;
  max-width: 70px;
}

._user-roles-table_1qb8fw {
  width: 520px;

  ._tag_1qb8fw {
    color: #fff;
    background-color: $default;
  }

  ._columns_1qb8fw {
    ._column_1qb8fw {
      text-align: center;
      padding: 2px 10px;
    }
  }

  ._table-head_1qb8fw {
    color: #000;
  }

  tr {
    th {
      border: none;
      font-size: 12px;
      background-color: #ccc;
      color: #000;
      text-align: center;

      &:last-child {
        padding-left: 80px;
      }
    }

    td {
      border: none;
      background-color: #e5e5e5;
      padding-left: 20px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        margin-left: 30px;
        text-align: right;
        padding-right: 60px;
        vertical-align: middle;

        ._tag_1qb8fw {
          margin-right: -15px;
        }
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

._manual-scan-modal_1qb8fw {
  ul {
    li {
      margin-top: 10px;

      a {
        color: $default;
      }
    }
  }

  ._modal-sub-container_1qb8fw {
    border-radius: 3px;
    margin-top: 10px;
    padding: 10px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
  }

  ._modal-card_1qb8fw {
    width: 600px;
    height: 580px;

    li {
      background-color: #f2f2f2;
      color: #000;

      a {
        ._column_1qb8fw:first-child {
          margin-left: 10px;
        }

        ._column_1qb8fw:last-child {
          margin-right: 10px;
        }
      }
    }

    select {
      text-align: center;
      width: 210px;
    }

    ._input_1qb8fw {
      width: 210px;
    }

    ._text-area_1qb8fw {
      width: 300px;
      height: 100px;
    }

    label {
      margin-right: 35px;
      color: $default;
    }

    ._no-border-column_1qb8fw {
      border-bottom: none !important;
    }

    ._user-roles_1qb8fw {
      ._input_1qb8fw {
        margin-top: -20px;
      }

      label {
        position: relative;
        top: -20px;
      }

      ._columns_1qb8fw {
        border-bottom: none;

        &:last-child {
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }

    ._columns_1qb8fw {
      margin-top: 0px;
      border-bottom: 1px solid #e5e5e5;
      align-items: center;

      ._column_1qb8fw {
        &:first-child {
          font-size: 14px;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

._card-button_1qb8fw,
._hideOrShow_1qb8fw,
._modal-footer_1qb8fw {
  ._card-footer-item_1qb8fw {
    padding: 1px;
    margin-left: 50px;
  }

  button {
    border-radius: 5px;
    margin-bottom: 5px;
    border-color: rgb(254, 77, 63) !important;
    color: rgb(254, 77, 63);
  }

  button:hover,
  button:active,
  button:focus {
    background-color: #fe4d3f;
    color: #fff;
  }
}

._card-header-icon_1qb8fw {
  cursor: default;

  img {
    height: 50px;
    width: 50px;
    background-image: none;
    overflow: hidden;

    &:before {
      content: '.';
      font-size: 1000px;
    }
  }
}

b._bold_1qb8fw {
  font-weight: bold;
  color: black;
}

._condition-text-small_1qb8fw {
  font-weight: bold;
  color: black;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 0px;
}

._warning-modal_1qb8fw {
  width: 500px;
}

._modal-small_1qb8fw {
  width: 80%;
  @media (min-width: 500px) {
    width: 36em;
  }
}

._modal-medium_1qb8fw {
  width: 80%;
  @media (min-width: 500px) {
    width: 40em;
  }
}

._condition-text_1qb8fw {
  margin-top: -7px;
  margin-right: 2px;
}

._show_1qb8fw {
  display: block;
}

._edit_1qb8fw {
  display: block;
}

._save_1qb8fw {
  display: none;
}

._visible_1qb8fw {
  visibility: visible;
}

._invisible_1qb8fw {
  visibility: hidden;
}

._hide_1qb8fw {
  display: none;
}

._details_1qb8fw {
  padding: 2px 3px;
  color: #808080;
  max-height: 25px;
}

._progress-wrap_1qb8fw._progress_1qb8fw._is-success_1qb8fw {
  border: 1px solid #80c081;
  background-color: #80c081;
}

._card-content_1qb8fw {
  ._chartjs-render-monitor_1qb8fw {
    max-width: 320px !important;
  }
}

._is-progress_1qb8fw {
  ._tag_1qb8fw {
    border: 1px solid #808080;
  }
}

._message-body_1qb8fw {
  border-color: #f6f8fa !important;
  border-width: 0px 2px 2px 2px !important;
}

._icon-effect_1qb8fw {
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  width: 29px;

  &:hover {
    background-color: grey;
    color: #fff;
    border-radius: 50%;
  }
}

._message-header_1qb8fw {
  border-radius: 5px 0px 0px 5px;
  border-width: 0px !important;
  padding: 4px 10px;
  background-color: #f6f8fa;
  border-color: #f6f8fa;

  ._severity-tags_1qb8fw {
    width: 11.8em;
  }

  h6 {
    font-size: 16px;
  }

  ._columns_1qb8fw {
    margin-top: 0px;
  }
}

._message_1qb8fw ._message-header_1qb8fw {
  &._is-progress_1qb8fw {
    border-color: rgb(255, 224, 221);
    border-width: 1px 1px 1px 5px;
    border-style: solid;
  }

  &._is-untested_1qb8fw {
    border-color: #f5f5f5;
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    background-color: #f5f5f5;
  }

  &._is-errored_1qb8fw {
    border-color: rgb(255, 224, 221);
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    background-color: lighten(rgb(255, 224, 221), 2%);
  }
}

._vuln-header_1qb8fw {
  border-width: 1px 1px 1px 5px;

  ._column_1qb8fw {
    padding: 1px 10px;
  }

  ._tag_1qb8fw {
    cursor: text;
    margin-top: 3px;
    font-size: 10px;
    border-radius: 5px;
    height: 20px;
  }

  ._seperator_1qb8fw {
    font-size: 18px;
    margin-top: 0px;
    color: #ccc;
  }

  small {
    margin-top: 5px;
  }
}

._mp-minus_1qb8fw + ._message-meta-data_1qb8fw {
  display: none;
}

._finished-scan_1qb8fw {
  background-color: $green !important;
  border-color: $green !important;
}

._unfinished-scan_1qb8fw {
  background-color: lighten($red, 3%) !important;
  border-color: lighten($red, 3%) !important;
}

._scan-tag_1qb8fw {
  background-color: $default;
  color: #fff;

  ._fa_1qb8fw {
    font-size: 12px;
    margin-left: 4px;
    margin-right: -4px;
  }
}

._status-tag_1qb8fw {
  color: $default;
}

table._vuln-table_1qb8fw {
  th,
  td {
    max-width: 130px;
    text-align: center;
  }
}

._vulnerability-types_1qb8fw {
  text-align: center;

  h4 {
    background-color: rgb(239, 72, 54);
  }
}

ul._scan-table_1qb8fw {
  li {
    width: 130px;
    padding: 11px 5px;
  }

  h5 {
    margin-left: 10px;
  }
}

ul._risk-table_1qb8fw {
  h5 {
    margin-left: 15px;
    margin-bottom: -5px;
    margin-top: 5px;
  }

  li {
    font-size: 13px;
    padding: 1px;
    width: 120px;
    margin-left: 15px;
    margin-top: -20px;
  }

  ._risk-values_1qb8fw {
    margin-top: 1em;
    margin-left: -10px;
    line-height: 1.2;

    ._columns_1qb8fw {
      ._column_1qb8fw {
        margin-top: 5px;
        padding: 3px;
      }

      ._column_1qb8fw:nth-child(2) {
        text-align: center;
        margin-left: -20px;
      }
    }
  }

  li:before {
    font-size: 30px;
    position: relative;
    top: 4px;
  }
}

._progress-wrap_1qb8fw {
  width: 60%;
  border-radius: 10px !important;
}

._button-width_1qb8fw {
  ._column_1qb8fw {
    text-align: center;

    button {
      padding: 5px;
      line-height: 0px;
    }
  }
}

._app-details-card_1qb8fw {
  ._card-header_1qb8fw {
    height: 40px;
  }

  ._card-content_1qb8fw {
    height: 220px;

    ._columns_1qb8fw {
      ._column_1qb8fw {
        padding-top: 10px;
        margin-top: -10px;
      }
    }
  }

  ._card-button_1qb8fw button {
    margin-top: -5px;
  }
}

._scan-status-card_1qb8fw {
  ._warning-band_1qb8fw {
    ._columns_1qb8fw ._column_1qb8fw {
      height: 100px !important;
    }
  }

  ._info-band_1qb8fw {
    ._columns_1qb8fw ._column_1qb8fw {
      height: 65px !important;
    }
  }

  ._card-header_1qb8fw {
    height: 40px;
    h5 {
      margin-top: 7px;
    }
  }

  ._card-content_1qb8fw {
    height: 100px;

    ._columns_1qb8fw {
      ._column_1qb8fw {
        height: 40px;
        ._progress-wrap_1qb8fw {
          margin-top: 50px;
        }
      }
    }
  }
}

._mfa_1qb8fw {
  ._fa-check_1qb8fw {
    color: $green;
  }

  ._fa-times_1qb8fw {
    color: $red;
  }
}

._qr-code_1qb8fw {
  margin-top: -20px;
  margin-left: 95px;
}

._otp-apps_1qb8fw {
  font-size: 12px;
}

._fa-upload_1qb8fw {
  font-size: 14px;
  vertical-align: middle;
  margin-right: 1px;
  margin-top: -3px;
}

._font-size-18_1qb8fw {
  font-size: 18px;
}

._font-size-16_1qb8fw {
  font-size: 16px;
}

._font-size-14_1qb8fw {
  font-size: 14px;
}

._font-size-25_1qb8fw {
  font-size: 25px;
}

._card-button_1qb8fw {
  ._fa-download_1qb8fw,
  ._fa-cog_1qb8fw,
  ._fa-trash-o_1qb8fw {
    font-size: 14px;
    vertical-align: middle;
  }
}

._fa-exchange_1qb8fw,
._fa-navicon_1qb8fw {
  font-size: 12px;
  vertical-align: middle;
}

._cvss-box_1qb8fw {
  border-radius: 5px;
  margin: 10px auto;
  height: 100px;
  display: block;
  padding-right: 30px;

  h1 {
    padding-top: 30px;
  }
}

._team-container_1qb8fw {
  padding: 0px 100px;
}

._table-container_1qb8fw {
  @extend .align-center;
}

._finding-list-table_1qb8fw,
._attachments-list-table_1qb8fw {
  min-width: 600px;

  @extend .api-filter-table;

  tr {
    td {
      text-align: center;
      min-width: 100px;
      vertical-align: middle;

      &:last-child {
        text-align: center;
      }
    }

    th {
      text-align: center;

      &:last-child {
        text-align: center;
      }
    }
  }
}

._security-list-table_1qb8fw {
  @extend .api-filter-table;
  width: 100%;

  th {
    text-align: center;
  }

  tr {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      white-space: nowrap;
    }

    td:nth-child(2n - 1) {
      background: #ececec;
    }
  }

  ._button-small_1qb8fw {
    font-size: 0.8em;
    height: auto;
    padding: 0.3em 0.4em;
  }
}

._file-action-container_1qb8fw {
  margin: auto;
  width: 90%;

  @media (min-width: 1200px) {
    width: 70%;
  }
}

._attachments-list-table_1qb8fw {
  margin-left: 0px !important;
  min-width: 680px;

  ._attachment_1qb8fw {
    span {
      max-width: 400px;
      word-wrap: break-word;
    }
  }
}

._finding-list-table_1qb8fw {
  min-width: 98%;
  margin-left: 10px;
  margin-top: 0px;

  tr {
    td:nth-child(1) {
      text-align: left;
    }

    td:nth-child(2) {
      max-width: 500px;
      text-align: left;
      padding: 10px 5px;
      word-wrap: break-word;
    }

    td:nth-child(3) {
      padding: 5px;

      button {
        margin-top: -10px;
      }
    }

    textarea::placeholder {
      color: lighten($default, 30%);
    }
  }

  i {
    cursor: pointer;
  }
}

._edit-analysis-box_1qb8fw {
  flex: none;
  width: 70%;

  ._marked-analysis_1qb8fw {
    width: 90px;

    ._edit-marked-analysis_1qb8fw {
      height: 25px;
      line-height: 18px;
    }
  }
}

._attachments-list-table_1qb8fw {
  margin-left: 27px;
  margin-top: 0px;
}

._team-container_1qb8fw._box-container_1qb8fw {
  padding-bottom: 20px;
}

._org-tab-content-container_1qb8fw {
  padding: 0 1em 5em;
}

._org_1qb8fw {
  &-settings-subtitle {
    font-size: 1.35em;
    font-weight: bold;
    padding: 0.5em 0 0.8em;
    @extend .black-text;
  }

  &-header {
    margin: -10px -10px 10px -10px;
    background: darken(#f6f8fa, 1%);

    &-name {
      padding: 2em 0 1em;
    }

    ._tabs_1qb8fw._is-boxed_1qb8fw {
      li._is-active_1qb8fw a {
        padding-top: 5px;
        padding-bottom: 3px;
      }

      a {
        padding-top: 5px;
        border-top-width: 3px;

        i {
          margin-right: 0.5em;
          margin-left: 0.2em;
        }
      }
    }
  }

  &-container {
    max-width: 50em;
    margin: auto;
    padding-bottom: 5em;

    ._no-hover-button_1qb8fw {
      &:hover {
        background: none;
        color: darken($red, 20%);
      }
    }
  }

  &-view {
    background-color: #fafbfc;
    margin-left: -10px;
    margin-right: -10px;

    ._column_1qb8fw {
      padding-bottom: 0px;
    }
  }

  &-table {
    tr {
      border: 1px solid lighten(#eaecef, 2%);
    }

    td {
      font-size: 12px;
    }

    th {
      background-color: darken(#f6f8fa, 1%);
      color: darken(#586069, 1%);

      &._success_1qb8fw {
        border-color: rgba($success, 0.5);
        background: rgba($success, 0.15);
      }

      &._critical_1qb8fw {
        border-color: rgba($critical, 0.5);
        background: rgba($critical, 0.15);
      } // padding: 10px 0px
    }

    th,
    td {
      border-width: 0px;
      vertical-align: middle;

      &:first-child {
        text-align: left;
        padding-left: 1em;
      } // &:last-child
      //   text-align: right
      //   padding-right: 30px
    }

    ._fa-times_1qb8fw {
      color: #ef4836;
      cursor: pointer;
    }

    ._remove_1qb8fw {
      color: $critical;
    }

    ._input-checkbox_1qb8fw {
      margin-top: 2px;
    }
  }
}

._no-hover-button_1qb8fw {
  &:hover {
    background: none !important;
    color: $primary !important;
  }
}

._copy-token_1qb8fw {
  cursor: pointer;
}

._remove_1qb8fw {
  cursor: pointer;
}

._table-h-bordered-gray_1qb8fw {
  td,
  th {
    border-color: lighten(#eaecef, 2%);
  }

  th {
    color: darken(#586069, 1%);
  }
}

._table-bordered-gray_1qb8fw {
  border-radius: 0.3em;

  tr,
  th,
  td {
    border-color: lighten(#eaecef, 2%);
  }

  th {
    background-color: darken(#f6f8fa, 1%);
    color: darken(#586069, 1%);
  }
}

._team-overview-card_1qb8fw {
  header:nth-child(1) {
    background-color: lighten(#424651, 5%);
    color: $white;
  }

  ._fa-times_1qb8fw {
    color: #ef4836;
    cursor: pointer;
  }

  ._card-header_1qb8fw {
    margin-left: 0px;
    margin-right: 0px;

    ._column_1qb8fw {
      margin-top: 10px;
      border-right: 1px solid rgba(107, 107, 107, 0.1);
    }
  }
}

._invoice-table_1qb8fw,
._token-table_1qb8fw {
  ._fa_1qb8fw {
    font-size: 16px;
  }

  ._fa-trash-o_1qb8fw {
    color: $critical;
  }

  ._copy-token_1qb8fw {
    color: $info;
  }
}

._pending-invitation-table_1qb8fw {
  margin: auto;
  width: 70%;
}

._team-card_1qb8fw {
  min-height: 260px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h6 {
    border-bottom: 1px solid $grey;
    width: 60px;
    margin-left: 35px;
    margin-top: -20px;
  }

  select {
    width: 110px;
    margin-left: 15px;
    margin-top: 60px;
    font-size: 10px;
    height: 25px;
    line-height: 15px;
  }

  ._team-header_1qb8fw {
    margin-top: 5px;
  }

  ._card-content_1qb8fw {
    padding-top: 0px;
  }

  button {
    margin-top: -40px;
  }
}

._filter-list_1qb8fw {
  width: 250px;

  ._fa_1qb8fw {
    color: $red;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}

._api-scanning_1qb8fw {
  ._api-filter_1qb8fw {
    input {
      width: 100%;
    }

    button {
      margin-left: 0px;
    }
  }
}

._new-input-box_1qb8fw {
  margin-top: -20px;
}

button._delete-button_1qb8fw {
  border: none;
}

._bi-span_1qb8fw {
  ul {
    list-style: disc;
    margin-left: 20px;
  }
}

._bullet-list_1qb8fw {
  list-style-type: square;
}

._code-block_1qb8fw {
  background: $color-lightgray;
  color: #4d525e;
  white-space: pre-wrap;
  padding: 1em;
  margin: 0.5em 0;
}

._tag_1qb8fw {
  border: 1px solid transparent;

  &._delete-tag_1qb8fw {
    background-color: $red;
    color: #fff;
  }

  &._is-errored_1qb8fw {
    color: $red;
    border-color: rgba($red, 0.4);
  }

  &._is-waiting_1qb8fw {
    color: rgba($color-table, 0.9);
    border-color: rgba($color-table, 0.2);
  }

  &._status-tag-0_1qb8fw {
    background-color: $red;
    color: #fff;
  }

  &._status-tag-1_1qb8fw {
    background-color: $default;
    color: #fff;
  }

  &._status-tag-2_1qb8fw {
    @extend .is-progress;
  }

  &._status-tag-3_1qb8fw {
    color: $success;
  }

  &._edit-tag_1qb8fw {
    background-color: grey;
    color: #fff;
  }

  &._threshold-tag_1qb8fw {
    background-color: #007eff;
    color: #fff;
  }
}

._colored-box_1qb8fw {
  margin: auto;
  cursor: pointer;
  width: 90px;
  border-radius: 5px;

  @each $name, $pair in $risk-names {
    &._is-#{$name}_1qb8fw {
      background-color: nth($pair, 1);
      border: 4px solid nth($pair, 1);
      color: $white;
    }
  }

  &._is-progress_1qb8fw {
    color: $default;
  }

  &._is-error_1qb8fw {
    background: $primary;
    color: $white;
  }
}

._vuln-header_1qb8fw {
  ._scan-tag_1qb8fw,
  ._owasp-tag_1qb8fw {
    background-color: $grey;
    color: #fff;
  }
}

._vuln-body_1qb8fw {
  border-radius: 0px 0px 5px 5px !important;

  ._risk-type-tag_1qb8fw {
    margin: auto;
  }

  ._scan-category_1qb8fw {
    font-size: 14px;
    margin-top: 5px;

    span {
      margin-top: -2px;
    }
  }

  ._owasp-category_1qb8fw {
    font-size: 14px;
    margin-top: 5px;

    span {
      margin-top: -2px;
    }
  }
}

._mp-minus_1qb8fw {
  border-radius: 5px 5px 0px 0px !important;
}

._feedback-button_1qb8fw {
  margin-top: 20px;
  text-align: center;
}

._pending-invitation_1qb8fw {
  ._box-title_1qb8fw {
    margin: 20px 0 20px 10px;
    border: 1px solid #000;
    width: 150px;
    text-align: center;
  }

  ul._invitation-list_1qb8fw {
    margin-left: 30px;

    li {
      list-style-type: disc;
    }
  }
}

._no-project-container_1qb8fw {
  padding: 150px 400px 70px 400px;

  ._no-project_1qb8fw {
    padding: 10px;
    border: 1px dashed $critical;
    border-radius: 10px;
    text-align: center;
  }
}

._repeat-icon_1qb8fw {
  margin-left: 10px;
  margin-top: 15px;
  color: $red;
  cursor: pointer;
}

._device-modal_1qb8fw {
  position: relative;

  ._modal-card-head_1qb8fw {
    border-bottom: 0px;
  }

  ._vnc-modal-body_1qb8fw {
    min-height: 582px;

    button {
      margin-top: 45px;
    }
  }
}

._tabs_1qb8fw._is-boxed_1qb8fw {
  li {
    min-width: 100px;

    &._is-active_1qb8fw a {
      padding: 2px 10px;
      border-color: #f0f0f0;
      border-top: 3px solid #ef4836;
      color: #000;
    }
  }

  a {
    padding: 3px 10px;

    span._count_1qb8fw {
      background-color: #e1e4e8;
      padding: 0px 5px;
      margin-left: 5px;
      border-radius: 60px;
      font-size: 11px;
    }

    i {
      margin-top: -2px;
      font-size: 14px;
    }

    &:hover {
      border-bottom-color: #f0f0f0;
      background: none;
    }
  }

  ul {
    border-color: #f0f0f0;
  }
}

._loading-container_1qb8fw {
  margin: 20% auto;

  i {
    font-size: 87px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  div {
    font-size: 20px;
  }
}

._login-wrapper_1qb8fw {
  h6 {
    margin-top: -15px;
    margin-bottom: 25px;
  }
}

._vnc-viewer_1qb8fw {
  padding: 0px;

  ._device-modal_1qb8fw {
    margin: 0px;
  }
}

._marvel-device_1qb8fw {
  ._screen_1qb8fw {
    box-shadow: none;

    ._vnc-box_1qb8fw {
      display: none;
    }
  }

  ._top-bar_1qb8fw {
    background: none;
  }
}

._modal_1qb8fw._is-active_1qb8fw {
  ._marvel-device_1qb8fw._ipad_1qb8fw {
    margin-left: 0px;
  }

  ._marvel-device_1qb8fw._tablet_1qb8fw {
    margin-left: 20px;
  }
}

._marvel-device_1qb8fw._ipad_1qb8fw {
  width: 282px !important;
  height: 385px !important;
  margin-left: -10px;

  ._screen_1qb8fw canvas._canvas_1qb8fw {
    width: 282px !important;
    height: auto !important;
  }
}

._marvel-device_1qb8fw._tablet_1qb8fw {
  width: 320px !important;
  height: 480px !important;

  canvas._canvas_1qb8fw {
    cursor: pointer !important;
    width: 300px !important;
    height: 480px !important;
  }

  ._vnc-text_1qb8fw {
    display: none;
  }
}

._marvel-device_1qb8fw._iphone5s_1qb8fw {
  width: 245px !important;
  height: 385px !important;
  padding: 88px 22px;

  ._screen_1qb8fw {
    width: 88%;
    margin-left: 6%;

    canvas._canvas_1qb8fw {
      width: 216px !important;
      height: auto !important;
    }
  }

  ._home_1qb8fw {
    width: 50px;
    height: 50px;
    left: 52%;
  }
}

._marvel-device_1qb8fw._nexus5_1qb8fw {
  width: 270px !important;
  height: 480px !important;

  ._screen_1qb8fw {
    background-color: #1e1e1e;

    ._vnc-box_1qb8fw {
      display: block;

      div div h6 {
        padding-top: 10px;
      }
    }
  }
}

._marvel-device_1qb8fw._iphone5s_1qb8fw,
._marvel-device_1qb8fw._ipad_1qb8fw {
  ._screen_1qb8fw {
    background-color: #1e1e1e;

    ._vnc-box_1qb8fw {
      display: block;
      margin: 50px 30px;
    }
  }

  ._top-bar_1qb8fw {
    background: black;
  }
}

._marvel-device_1qb8fw._ipad_1qb8fw {
  ._vnc-box_1qb8fw {
    div div h6 {
      padding-top: 35px;
    }
  }
}

._sidebar-icons_1qb8fw {
  margin-top: 5px;
  margin-right: 4px;
  font-size: 18px;
}

._vnc-text_1qb8fw {
  height: 100%;
  text-align: center;
  margin-top: 50%;

  ._vnc-box_1qb8fw {
    margin: 50px 60px;

    div {
      background-color: #fff;
      color: $default;
      height: 110px;
      margin: 20px;
      border-radius: 7px;

      i {
        font-size: 30px;
        margin-top: 20px;
      }

      h6 {
        margin-top: 10px;
      }

      div {
        h6 {
          padding-top: 18px;
        }
      }
    }
  }
}

button[disabled],
._input_1qb8fw[disabled] {
  cursor: not-allowed;
  pointer-events: auto;
}

._button_1qb8fw[disabled]._ac-upload-app_1qb8fw {
  opacity: 0.8;
}

button._is-progress_1qb8fw:hover {
  background-color: white;
  color: #fe4d3f;
  border-color: #fe4d3f;
}

._url-filters_1qb8fw {
  ._columns_1qb8fw {
    ._column_1qb8fw:nth-child(2) {
      button {
        margin-top: -50px;
        margin-left: -50px;
      }
    }
  }
}

._add-filter-list_1qb8fw {
  border: 2px solid lighten($default, 40%);
  padding: 10px;
}

._js-duration-button_1qb8fw._is-default_1qb8fw:hover {
  background-color: lighten($red, 10%);
  border-color: lighten($red, 10%);
}

._box-section_1qb8fw button:focus {
  background-color: #80c081;
}

._plan-durations_1qb8fw {
  h4 {
    margin-left: 20px;
    margin-top: 10px;
  }

  ._column_1qb8fw {
    margin-left: -80px;
  }
}

._plan-list_1qb8fw {
  padding: 0px 90px;

  ._box-section_1qb8fw {
    h2 {
      text-align: center;
      padding-top: 10px;
    }
  }
}

._payment-info-page_1qb8fw {
  margin-top: 150px;

  h2,
  div,
  h5 {
    display: flex;
    justify-content: center;
  }

  h2 {
    font-size: 50px;

    i {
      padding: 8px;
      font-size: 50px;
      width: 70px;
      height: 70px;
      color: #fff;
      border-radius: 50px;
    }

    i._fa-check_1qb8fw {
      background-color: $success;
    }

    i._fa-times_1qb8fw {
      background-color: $critical;
    }
  }
}

._language-select_1qb8fw {
  margin-top: 20px;

  select {
    width: 50%;
  }
}

._input-modal_1qb8fw ._modal-card_1qb8fw {
  min-width: 25%;

  ._input-width_1qb8fw {
    width: 80%;
  }
}

._input-width_1qb8fw {
  width: 50%;
}

._prompt-modal_1qb8fw {
  ._deleted-item_1qb8fw {
    width: 50%;
    display: block;
  }
}

select {
  cursor: pointer;
  border-radius: 5px !important;
  color: $default !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAeCAYAAAAo5+5WAAAAdUlEQVRIx2MQllCs+P//vwcQM1ATM4waPGrwqMEjzmBSweAxGMgu+k8YFJHjYsvFixfvwWUiSA6khtygsFmxYsV+dENBYiA5SsPYfvXq1QdghoLYIDFqRZ7jxo0bD4MwiE3tVOECxYM0uVHd4NFCaNTgQW4wAKpugq+9Zk7QAAAAAElFTkSuQmCC),
    -webkit-linear-gradient(lighten($default, 55%), lighten($default, 55%) 40%, lighten($default, 55%)) !important;
  border: 1px solid lighten($default, 40%) !important;
}

select:focus {
  outline: none !important;
}

select:hover {
  box-shadow: 0px 0px 3px $default !important;
}

select:disabled {
  cursor: not-allowed;
  color: lighten($default, 30%) !important;
  border: 1px solid lighten($default, 45%) !important;
}

._modal-card_1qb8fw {
  border-radius: 1px;
}

._modal-card-head_1qb8fw {
  border-bottom: 0px;
  background-color: lighten(#424651, 5%);

  ._modal-card-title_1qb8fw {
    color: #fff;
    font-size: 20px;
  }

  ._delete_1qb8fw {
    background-color: #fff;

    &:before,
    &:after {
      background-color: #424651;
    }

    &:hover {
      background-color: darken(#fff, 5%);
    }
  }
}

._team-overview-card_1qb8fw {
  a {
    color: #6b6b6b;
  }
}

._subscription-container_1qb8fw {
  padding: 20px;
  margin: 20px auto;

  table {
    box-shadow: rgb(204, 204, 204) 0px 0px 5px;

    th {
      background-color: lighten(#424651, 5%);
      color: #fff;
    }

    td,
    th {
      text-align: center;
      border-width: 0px;
      border-bottom: 1px solid lighten($default, 30%);
      padding: 10px 60px;
    }

    td._td-heading_1qb8fw {
      font-size: 14px;
      color: #000;
    }

    tr:last-child {
      td {
        border-width: 0px;
      }
    }

    button {
      margin: 10px auto 0px auto;
    }
  }
}

._scan-section_1qb8fw {
  text-align: center;
  margin: auto;

  ._box_1qb8fw {
    padding-top: 37px;
    height: 140px;
  }
}

._all-inputs_1qb8fw {
  padding: 10px 30px;

  ._margin-top_1qb8fw {
    width: 270px;
  }

  ._ac-upload-app_1qb8fw {
    width: 220px;
  }
}

._upload-app_1qb8fw {
  margin-top: -10px;
}

._success-button_1qb8fw {
  background-color: lighten($success, 2%);
  color: #fff !important;

  &:hover {
    background-image: lighten($success, 0%);
  }
}

._highlighted-button_1qb8fw {
  background-image: linear-gradient(
    -180deg,
    lighten($critical, 5%) 0%,
    lighten($critical, 3%) 90%
  );
  color: #fff !important;

  &:hover {
    background-image: linear-gradient(
      -180deg,
      lighten($critical, 0%) 0%,
      lighten($critical, 0%) 90%
    );
  }
}

._risk-status-box_1qb8fw {
  width: 300px;
  margin: 60px auto 10px auto;

  ._columns_1qb8fw {
    h5 {
      border-bottom: 2px solid $critical;

      &:last-child {
        margin-top: 20px;
      }
    }

    &:first-child {
      margin-top: -40px;
    }

    &:nth-child(2) {
      margin-top: 0px;
    }

    &:nth-child(5) {
      margin-top: 0px;
    }

    &:last-child {
      margin-top: 0px;
      margin-bottom: -20px;
    }
  }
}

._stat-heading_1qb8fw {
  text-align: center;
  margin-top: 10px;
}

._user-info-popover_1qb8fw {
  background-color: #424651;
  margin-right: 20px;
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  border-radius: 5px;
  color: #fff;

  ._user-name_1qb8fw,
  ._user-email_1qb8fw {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ._user-name_1qb8fw {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  ._user-email_1qb8fw {
    padding-bottom: 10px;
    margin: 10px 0px 10px 0px;
    font-size: 11px;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }

  a {
    padding: 2px 10px;
    font-size: 12px;
    margin-right: 5px;
    color: #fff;
    border-radius: 5px;
    i {
      font-size: 16px;
      margin-top: 2px;
      margin-right: 5px;
    }
  }
}

._menu-label_1qb8fw {
  text-align: center;
  margin-left: 0px;
  background-color: #424651;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 2px;
}
// move this to bohemia

._status-container_1qb8fw {
  border: 1px solid #ccc;
  height: auto;
  border-radius: 5px;
  margin: 0px 10px;
  max-height: 200px;
  overflow: scroll;

  ._status-list_1qb8fw {
    background-color: #fff;
    margin: 15px 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;

    ._no-reason_1qb8fw {
      margin: -5px;
      padding: 5px;
      border-radius: 5px;
      text-align: center;
    }

    ._status-details_1qb8fw {
      margin: -5px -5px 5px -5px;
      border-radius: 5px 5px 0px 0px;
      color: #000;
      background-color: #ccc;
      text-align: center;

      &._is-critical_1qb8fw {
        background-color: $critical;
        color: #fff;
      }

      &._is-danger_1qb8fw {
        background-color: $critical;
        color: #fff;
      }

      &._is-warning_1qb8fw {
        background-color: $warning;
        color: #fff;
      }

      &._is-info_1qb8fw {
        background-color: $info;
        color: #fff;
      }

      &._is-success_1qb8fw {
        background-color: $success;
        color: #fff;
      }

      &._is-progress_1qb8fw {
        background-color: #fff;
      }
    }

    ._no-reason_1qb8fw {
      border-radius: 5px;
    }

    ._status-reason_1qb8fw {
      color: $default;
    }
    // end
  }
}

._click-here_1qb8fw {
  margin-left: -130px;
  text-decoration: none !important;
  border: 1px solid #fff;
  padding: 0 5px;
  background-color: #fff;
  color: #000 !important;
  border-radius: 3px;
}

._new-users_1qb8fw {
  font-size: 16px;

  ._welcome-text_1qb8fw {
    font-size: 24px;
  }

  ._welcome-desc_1qb8fw {
    margin-top: 20px;
  }

  ._welcome-sub-desc_1qb8fw {
    margin-top: 30px;
  }

  ._welcome-footer-text_1qb8fw {
    margin-top: 40px;
  }

  & + ._column_1qb8fw {
    margin-top: -60px;
  }
}

._chart-container_1qb8fw {
  width: 50vw;
  margin: -10px 10px;
}

._stat-home-page_1qb8fw {
  margin: -10px;
}

._scan-list_1qb8fw {
  text-align: center;
  padding: 0.5em;
  margin: 0;
  border-bottom: 1px solid lighten($default, 50%);

  &:last-child {
    border-bottom: none;
  }
}

._main-box-container_1qb8fw {
  margin: 20px 20px 0px 40px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  background-color: #fff;

  ._box-content_1qb8fw._scrolling-box_1qb8fw {
    height: 16.5em;

    ._box-lists_1qb8fw {
      overflow: hidden;
      height: 16.5em;

      ._box-list-items_1qb8fw {
        border-bottom: 1px solid lighten($default, 50%);
        padding: 0.3em 0.8em;
      }

      &:hover {
        overflow-y: scroll;
      }
    }
  }

  ._box-content_1qb8fw {
    margin-top: 20px;
    height: 300px;

    h3 {
      margin-left: 20px;
      margin-bottom: 0px;
    }

    ._tag_1qb8fw {
      margin-left: 20px;
    }

    ._chart_1qb8fw {
      margin-top: -50px;
    }

    ._chart-2_1qb8fw {
      margin-top: 10px;
    }
  }

  ._box-main-heading_1qb8fw {
    text-align: center;
    background-color: #efefef;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 10px;
    padding: 5px;
  }
}

._app-scanned_1qb8fw {
  margin: 10px 80px;
}

._home-page_1qb8fw {
  background-color: #fff;
  margin: 10px;
  position: relative;
  top: 10px;
  border-radius: 5px;
}

._stat-table-container_1qb8fw {
  padding: 0px 10px;

  table {
    @extend .api-filter-table;
    width: 495px;

    tr {
      th,
      td {
        text-align: center !important;
      }
    }

    ._tag_1qb8fw {
      margin-left: 0px !important;
    }
  }
}

._scan-counts_1qb8fw {
  margin-top: 30px;
  margin-bottom: 20px;

  ._columns_1qb8fw {
    background-color: #fff;
    padding: 10px;
    min-width: 200px;
    border-radius: 10px;

    i {
      margin-top: 25px;
    }

    h3,
    h5 {
      margin-left: 20px;
    }

    h5 {
      margin-top: -20px;
    }
  }
}

$check-circle-size: 1.5em;
$check-circle-false-color: lighten($color-text, 30%);
$check-circle-true-color: $success;

._check-circle_1qb8fw {
  position: relative;
  display: flex;
  align-items: center;

  &-text {
    padding-left: $check-circle-size + $check-circle-size/2.5;
    word-wrap: break-word;
    width: 100%;
    font-size: 0.95em;
  }

  &-check {
    position: absolute;
    left: 0;
    height: $check-circle-size;
    width: $check-circle-size;
    border-radius: 0.3em;
    border: 2px solid $check-circle-false-color;

    &::after {
      content: '';
      position: absolute;
      background: transparent;
      width: $check-circle-size/2;
      height: $check-circle-size/3;
      left: $check-circle-size/5.5;
      top: $check-circle-size/5.5;
      border-width: 0 0 2px 2px;
      border-color: transparent;
      border-style: solid;
      transform: rotate(-46deg);
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + ._check-circle-check_1qb8fw {
      background-color: $check-circle-true-color;
      border-color: $check-circle-true-color;

      &::after {
        border-color: white;
      }
    }
  }

  &-align-top {
    align-items: flex-start;

    ._check-circle-text_1qb8fw {
      padding-top: 0.1em;
    }
  }
}

@mixin toggle-knob($size, $on, $off) {
  position: relative;
  display: flex;
  align-items: center;
  height: $size;
  width: $size * 1.7;

  ._knob_1qb8fw {
    height: 100%;
    width: 100%;
    background: $default;
    border-radius: $size;

    &:before {
      position: absolute;
      content: $off;
      width: $size + 0.15em;
      height: $size;
      height: calc(100% - 0.6em);
      margin: 0.3em;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      transition: 0.4s;
      font-size: 0.8em;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input {
    display: none;

    &:checked,
    &:focus {
      & + ._knob_1qb8fw {
        background-color: $success;
      }
    }

    &:checked + ._knob_1qb8fw:before {
      content: $on;
      transform: translateX($size * 0.86);
    }

    &[disabled] + ._knob_1qb8fw {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

._toggle-switch_1qb8fw {
  @include toggle-knob(2.6em, '', '');
}

._toggle-switch-small_1qb8fw {
  @include toggle-knob(1.8em, '', '');
}

._toggle-switch-on-off_1qb8fw {
  @include toggle-knob(2.6em, 'ON', 'OFF');
}

._toggle-switch-small-on-off_1qb8fw {
  @include toggle-knob(1.8em, 'ON', 'OFF');
}

._switch_1qb8fw {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 38px;
}

._switch_1qb8fw input {
  display: none;
}

._slider_1qb8fw {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $default;
  transition: 0.4s;
  font-size: 8px;
  line-height: 30px;
  text-align: center;
}

._slider_1qb8fw:before {
  position: absolute;
  content: 'HIDE';
  height: 30px;
  width: 30px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked,
input:focus {
  & + ._slider_1qb8fw {
    background-color: $success;
  }
}

input:checked + ._slider_1qb8fw:before {
  content: 'SHOW';
  transform: translateX(26px);
}

._yes-no-slider_1qb8fw:before {
  content: 'NO';
}

input:checked + ._yes-no-slider_1qb8fw:before {
  content: 'YES';
}

._slider-nolabel_1qb8fw:before {
  content: '';
}

input:checked + ._slider-nolabel_1qb8fw:before {
  content: '';
}

._slider_1qb8fw._round_1qb8fw {
  border-radius: 34px;
}

._slider_1qb8fw._round_1qb8fw:before {
  border-radius: 50%;
}

._stat-container_1qb8fw {
  margin-left: 15px;
}

._switch-input_1qb8fw[disabled] + ._slider_1qb8fw {
  opacity: 0.7;
  cursor: not-allowed;
}

._not-found-container_1qb8fw {
  text-align: center;
  background-color: #424651;
  height: 100vh;

  ._not-found-section_1qb8fw {
    width: 700px;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    position: relative;
    top: 150px;
    border-radius: 10px;

    i {
      margin-top: -3px;
    }
  }
}

$login-button-width: 100%;

._auth-main-container_1qb8fw {
  height: 100vh;
  background-color: #424651;

  a img {
    margin-top: 50px;
    width: 250px;
  }

  ._has-text-centered_1qb8fw {
    h6 {
      color: #fff;
    }
  }

  ._login-form_1qb8fw {
    background-color: #fff;
    width: 100%;
    border-radius: 5px;

    h4 {
      padding-bottom: 0.5em;
    }

    h6 {
      margin-left: 25px;
    }

    p {
      text-align: center;

      i {
        margin: auto;
      }

      ._input_1qb8fw {
        max-width: 80%;
        margin: auto;
      }

      button {
        margin-top: 10px;
        width: $login-button-width !important;
      }

      ._sso-button_1qb8fw {
        margin-left: -32px;
        margin-top: unset;
        width: unset !important;
      }
    }

    &-sso {
      &-separator {
        height: 0.06em;
        width: 100%;
        background: lighten($default, 50%);
        margin-top: 0.7em;
        margin-bottom: 0.7em;
        position: relative;
      }

      &-or {
        text-align: center;
        background: white;
        width: 2.3em;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &-button {
        width: $login-button-width;
        display: block;
        margin: auto;
        background: #ececec;
        border-color: #ececec;

        &:hover,
        &:focus,
        &:active {
          background: darken(#ececec, 5%);
          border-color: darken(#ececec, 5%);
        }
      }
    }
  }
}

._top-columns_1qb8fw {
  margin-right: 30px;
}

._login-button_1qb8fw {
  ._fa-font-size_1qb8fw {
    i {
      margin-top: 5px !important;
    }
  }
}

._analysis-header_1qb8fw {
  ._column_1qb8fw {
    &:first-child {
      margin-top: 10px;
      margin-left: 10px;
    }

    &:nth-child(2) {
      margin-top: 10px;
    }
  }

  ._sort-icons_1qb8fw {
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;

    &._fa-sort-up_1qb8fw {
      margin-top: 5px;
    }

    &._fa-sort-down_1qb8fw {
      margin-top: 0px;
    }
  }
}

._my-popper_1qb8fw {
  display: none;
}

._popper--white_1qb8fw {
  background-color: var(--popover-white-bg);
  border: 1px solid var(--popover-white-border-color);
  border-radius: 0.3rem;
  position: relative;
  padding: 1rem;

  div[x-arrow] {
    background-color: var(--popover-white-bg);
    border-bottom: 1px solid var(--popover-white-border-color);
    border-left: 1px solid var(--popover-white-border-color);
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: -1;
  }
}

._regulatary-section_1qb8fw {
  ._section_1qb8fw {
    border-bottom: 1px solid #f5f5f5;
    margin: 10px -15px;
  }

  a:hover {
    color: darken($red, 20%);
  }
  // attachments
}

$attachment-icon-width: 4em;

._attachment_1qb8fw {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  color: $text;
  margin-bottom: 1em;

  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }

  &::before {
    content: attr(data-type);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 0.8em;
    font-weight: bold;
    width: $attachment-icon-width;
    height: $attachment-icon-width / 1.4;
    text-align: center;
    background: $grey;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @each $extn, $color in $extensions-list {
    &[data-type='#{$extn}'] {
      &::before {
        background: $color;
      }
    }
  }

  &-label {
    max-width: 35em;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0.5em;
  }

  &-icon {
    position: absolute;
    right: 1em;
  }
}

._signup-container_1qb8fw {
  padding: 2em 0;
  padding-bottom: 7em;
  display: flex;
  justify-content: center;
  background-color: #424651;
}

._edit-analysis_1qb8fw {
  width: 30px !important;
  margin-top: 10px;
  margin-left: -10px;
  border-radius: 0px 5px 5px 0px;
  height: 35px;

  i {
    font-size: 18px;

    &:hover {
      color: $red;
    }
  }
}

._edit-analysis-modal_1qb8fw {
  ._box-container_1qb8fw {
    margin-left: -20px;
    margin-right: -20px;
  }

  ._marked-analysis-text_1qb8fw {
    width: 25px;
    margin-left: 5px;
  }

  ._marked-analysis-text2_1qb8fw {
    width: 40px;
    margin-left: 10px;
    margin-top: 5px;
  }

  ._mark-text_1qb8fw {
    width: 85px;
    margin-top: 5px;
  }

  ._modal-card_1qb8fw {
    width: 700px;
  }

  ._mark-analysis-all_1qb8fw {
    width: 200px;
  }

  select {
    width: 110px;
    text-transform: uppercase;
  }

  form {
    label {
      position: relative;
      top: -15px;
      left: 20px;
    }
  }

  ._tag_1qb8fw {
    margin-top: -2px;
    margin-left: 5px;
  }

  ._edit-marked-analysis_1qb8fw {
    height: 25px;
    line-height: 17px;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}

._device-modal_1qb8fw {
  overflow: scroll;

  ._modal-card-foot_1qb8fw {
    border-top-width: 0px;
  }
}

._signup-form_1qb8fw {
  background-color: #fff;
  width: 40em;
  border-radius: 5px;
  overflow: hidden;

  &._success_1qb8fw {
    ._register-form_1qb8fw {
      display: None;
    }

    ._register-confirm_1qb8fw {
      display: flex;
    }
  }

  ._register-form_1qb8fw {
    padding-top: 0.6em;

    &-container {
      padding: 2em 1.6em;
    }

    &-footer {
      display: flex;
      justify-content: center;
      padding: 1.5em 1em 1.6em;
      border-top: 1px solid #efefef;
      background: lighten(#424651, 68%);

      &-link {
        margin-left: 0.5em;
      }
    }

    &-accept {
      span {
        margin-left: 0.7em;
      }
    }
  }

  ._intl-tel-input_1qb8fw {
    width: 100%;
  }

  ._register-confirm_1qb8fw {
    padding: 2em 1em;
    display: None;
    flex-direction: column;

    &-icon {
      font-size: 8em;
      margin: auto;
    }

    &-text {
      font-size: 1.2em;
      padding: 1em 1em 0;
    }
  }

  ._is-success_1qb8fw {
    color: $success;
  }

  ._g-recaptcha_1qb8fw {
    display: flex;
    justify-content: center;
  }

  ._checkbox-field_1qb8fw {
    display: inline-block;
  }
}

._user-edited-icon_1qb8fw {
  margin-left: 5px;
}

._vulnerability-container_1qb8fw {
  margin-top: 30px;
  width: 70%;
  margin-left: 10px;
}

._vuln-warning-band_1qb8fw {
  background-color: lighten($red, 20%);
  padding: 10px;
  font-size: 16px;
  color: darken($red, 30%);
  width: 70%;
  text-align: center;
  border-radius: 5px;
}

._select-box_1qb8fw {
  max-width: 110px;
}

._vuln-band_1qb8fw {
  @extend .message-header;
  margin-top: 5px;

  ._columns_1qb8fw {
    ._column_1qb8fw {
      padding: 0px 10px;
    }
  }

  ._checkbox-field_1qb8fw {
    display: inline-block;
  }

  ._risk-comment_1qb8fw {
    margin-left: 0;
    background: darken(#f6f8fa, 3%);
    border: 1px solid darken(#f6f8fa, 6%);
    &-label {
      background: darken(#f6f8fa, 6%);
      color: $color-table;
      padding: 0 0.5em;
    }
  }
}

._device-modal-body_1qb8fw {
  position: relative;
}

._dynamic-scan-on-vnc_1qb8fw {
  ._dynamic-scan-note_1qb8fw {
    max-width: 170px;
    font-size: 12px;
    margin: auto;
  }

  ._dynamic-scan-buttons_1qb8fw {
    position: absolute;
    top: 16em;
    left: 0;
    right: 0;
    z-index: 3;
    text-align: center;

    ._vnc-button_1qb8fw {
      background-color: #fff;
      border-color: #fff;
      width: 160px;

      &:hover {
        background-color: $red;
        border-color: $red;
      }
    }

    ._vnc-button_1qb8fw[disabled] {
      opacity: 0.5;

      &:hover {
        color: #fff;
      }
    }

    ._vnc-button-repeat_1qb8fw {
      width: 30px;
    }

    ._vnc-completed-button_1qb8fw[disabled]:hover {
      background-color: #fff;
      border-color: #fff;
      color: $red;
      opacity: 0.8;
    }
  }
}

._notification-position_1qb8fw {
  margin-left: -35vw;
  width: 400px;
  margin-bottom: 45vw;
  height: 50px;
  border-radius: 50px;
  background-color: $green;
  box-shadow: 0px 0px 10px lighten($green, 20%);

  .__c-notification__content_of6y9q_1qb8fw {
    margin-top: 5px;
    font-size: 18px;
    color: #fff;
  }

  .__c-notification__icon_of6y9q_1qb8fw {
    background-color: $green;
  }

  ._fa-times_1qb8fw {
    position: relative;
    top: 3px;
  }

  ._fa-info-circle_1qb8fw {
    position: relative;
    top: 7px;
    left: 8px;
  }
}

._copy-password-modal_1qb8fw {
  ._modal-card_1qb8fw {
    table td {
      text-align: center;
      border: 2px solid lighten($default, 45%) !important;
      vertical-align: middle;
    }
  }
}

._compare-details_1qb8fw {
  select {
    margin-left: 10px;
    margin-top: -7px;
    width: 100px;
  }
}

._file-id-container_1qb8fw {
  text-align: center;

  ._file-id-text_1qb8fw {
    padding: 0.1em 0.5em 0.12em;
    text-align: center;
    font-weight: bold;
    font-size: 0.75em;
    line-height: 1.4;
    color: lighten(#000, 30%);
    border-radius: 2px;

    ._file-in-active-icon_1qb8fw {
      font-size: 14px;
    }
  }
}

._app-details-card_1qb8fw {
  ._file-id-container_1qb8fw {
    margin-left: -30px;

    ._file-id-text_1qb8fw {
      margin-top: 20px;
    }
  }
}

._app-risk-info_1qb8fw {
  margin-top: 0;
}

._compare-text_1qb8fw {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
}

._arrow-left_1qb8fw {
  display: inline;
  position: absolute;
  left: 100%;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;

  i._fa_1qb8fw {
    font-size: 26px;
  }
}

._device-preferences_1qb8fw {
  select {
    width: 170px;
  }

  ._fa-font-size_1qb8fw {
    margin-top: 5px;

    i {
      margin-top: 2px;
    }
  }

  ._fa-check_1qb8fw {
    color: $green;
  }

  ._fa-times_1qb8fw {
    color: $red;
  }
  // .project-preference, .project-preference-component
  //   padding-bottom: 0px!important
  //   select
  //     width: 170px
  //
  //   .fa-check
  //     color: $green
  //   .fa-times
  //     color: $red

  // .project-preference-component
  //   border: 2px solid lighten($grey, 40%)
  //   padding: 10px
  //   .columns .column
  //     height: auto!important
}

._request-status_1qb8fw {
  margin-top: -10px;
  padding: 0px 10px;
  min-height: 45px;
  position: relative;
  top: 12px;
}

._back-link_1qb8fw {
  width: 600px;
}

._list-heading_1qb8fw {
  margin-left: -100px;
}

._border-bottom-container_1qb8fw {
  border-bottom: 2px solid #f0f0f0;
  margin: 10px -20px 20px -20px;
}

._analysis-box_1qb8fw {
  padding-top: 0px;

  textarea {
    height: 100px;
  }
}

._cvss-score-section_1qb8fw {
  margin-top: 50px;
  text-align: center;

  ._column_1qb8fw {
    margin: auto;
  }
}

._input-checkbox_1qb8fw {
  margin-top: 2px;

  ._checkbox_1qb8fw {
    font-size: 16px;
  }

  span {
    position: relative;
    top: 1px;
  }
}

._security-link_1qb8fw {
  margin-top: 4px !important;

  &:hover,
  &:focus {
    border-color: #fff;
  }
}

._security-dashboard_1qb8fw {
  ._appknox-logo_1qb8fw {
    margin-top: 18px;
    width: 12em;

    a {
      pointer-events: none;
    }
  }

  ._top-columns_1qb8fw {
    margin-right: 0;
  }

  ._top-nav_1qb8fw {
    height: 60px;
    margin-left: 10px;
    border-left-width: 0px;

    ._top-icons_1qb8fw {
      margin-top: 10px;
      margin-right: 0;
    }
  }

  & + div {
    ._tabs_1qb8fw {
      margin-top: 60px !important;
    }

    ._paginate_1qb8fw {
      padding: 10px 200px;
    }
  }
}

ul._email-list_1qb8fw {
  margin-top: 10px;

  li {
    color: #000;

    &:before {
      content: '• ';
      font-size: 30px;
      color: $green;
      position: relative;
      left: 2px;
      top: 7px;
    }
  }
}

._all-scan-status_1qb8fw {
  button {
    padding-left: 8px;
    padding-right: 8px;
  }

  ._column_1qb8fw._flex-center_1qb8fw {
    padding: 4px;
  }
}

._user_1qb8fw {
  &-search-modal {
    ._modal-card_1qb8fw {
      max-width: 400px;
      min-width: 400px;

      ._input_1qb8fw {
        border-radius: 0px;
        width: 93%;
      }
    }
  }

  &-list-container {
    margin: 0px 1px 0px 25px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #e5e5e5;
    overflow: scroll;
    max-height: 160px;
    background-color: #fafbfc;

    li {
      padding-top: 10px;
      border-bottom: 1px solid #e5e5e5;
      max-height: 60px;

      ._show-button_1qb8fw {
        text-align: right;
        margin-right: 5px;

        button {
          font-size: 20px;
          color: $default;
          border: none;
          margin-top: 10px;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }

      ._columns_1qb8fw {
        margin-left: 0px;
        margin-right: 0px;

        ._column_1qb8fw {
          padding: 5px;
        }
      }

      ._user-name_1qb8fw {
        margin-top: 15px;
      }

      ._user-email_1qb8fw {
        font-size: 10px;
      }

      ._fa-user-circle_1qb8fw {
        display: flex;
        align-items: center;
        color: $info;
      }
    }
  }
}

._teamcard_1qb8fw {
  width: 100%;
  display: block;
  border: 1px solid lighten($color-table, 57%);
  background: lighten($color-table, 62%);

  &:hover {
    background: lighten($color-table, 60%);
    color: #000;
  }

  h6 {
    margin-bottom: 0;
  }

  &-name {
    color: $primary;
    font-size: 1.1em;
  }

  &-stats {
    display: flex;
  }

  &-count {
    color: lighten($color-table, 10%);

    &-label {
      font-size: 0.8em;
    }

    &-value {
      font-size: 0.95em;
    }
  }
}

._add-team-objects-scroll_1qb8fw {
  overflow-y: scroll;
  max-height: 27.5em;
  max-height: 50vh;
  min-height: 4.6em;
}

._team-project_1qb8fw {
  &-action {
    width: 3em;
  }

  &-icon {
    display: inline-block;
    width: 2.3em;

    &-android {
      color: #33a852;
    }

    &-apple {
      color: #8d9096;
    }

    &-windows {
      color: #46ade9;
    }
  }

  &-name {
    font-size: 1.15em;
  }
}

._platform-icon_1qb8fw {
  &--android {
    color: var(--android);
  }

  &--apple {
    color: var(--ios);
  }
}

._vuln-modal_1qb8fw {
  ._modal-card_1qb8fw {
    width: 50%;
    min-height: 300px;
  }
}

._modal-l_1qb8fw {
  ._modal-card_1qb8fw {
    width: 50%;
  }
}

._network-state_1qb8fw {
  visibility: visible;
  text-align: center;
  background-color: $red;
  margin: auto;
  margin-top: -30px;
  color: #fff;
  border-radius: 5px;
  position: fixed;
  top: 36px;
  left: 40%;
  z-index: 10000;
  font-size: 12px;
  padding: 10px;

  &._green_1qb8fw {
    background-color: $green;
    left: 43%;
  }
}

._collaboration-settings_1qb8fw {
  width: 70%;
}

._dropdown_1qb8fw {
  position: relative;

  &-content {
    z-index: 10;

    ._dropdown_1qb8fw {
      &-pointer {
        height: 0;
        width: 0;
        border-bottom: 0.6em solid;
        border-top: none;
        border-left: 0.5em solid transparent;
        border-right: 0.5em solid transparent;
        right: 0.4em;
        position: absolute;
      }

      &-container {
        border: 1px solid;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 0.5em;
      }

      &-item {
        padding: 0.5em 1em;
        display: block;
        margin-left: 0;
        width: 100%;

        &-icon {
          display: inline-block;
          width: 2em;
          padding-right: 0.7em;

          ._fa_1qb8fw {
            font-size: 16px;
            line-height: 1.25;
          }
        }
      }
    }
  }
}

$dropdown-default-theme: $color-table;

._dropdown--default_1qb8fw {
  &-content {
    background: transparent;

    ._dropdown_1qb8fw {
      &-pointer {
        border-bottom-color: lighten($dropdown-default-theme, 55%);
      }

      &-container {
        background: lighten($dropdown-default-theme, 60%);
        border-color: lighten($dropdown-default-theme, 55%);
      }

      &-item {
        color: darken($dropdown-default-theme, 5%);

        &:hover {
          background: lighten($dropdown-default-theme, 53%);
        }

        &-icon {
          color: lighten($dropdown-default-theme, 25%);
        }
      }
    }
  }
}

._date-input-class_1qb8fw {
  width: 7em;
  margin-left: 8px;
  padding: 2px;
  text-align: center;
  background-color: #efefef;
  font-weight: bold;
  border: 0px;
  cursor: pointer;
  border-radius: 3px;
}

*:focus {
  outline: none;
}

._no-issues_1qb8fw {
  padding-bottom: 1em;

  i {
    margin-top: 0;
  }
}

._flex-2_1qb8fw {
  flex: 2;
}

._flex-3_1qb8fw {
  flex: 3;
}

._warning-msg_1qb8fw {
  height: 30px;
}

._code-link_1qb8fw {
  padding: 1px 1px 2px 5px;
  text-decoration: underline;
}

._w-inherit_1qb8fw {
  width: inherit;
}

._power-select-custom-threshold_1qb8fw ul {
  height: 5.5em;
}

._power-select-custom-repo_1qb8fw ul {
  height: 8em;
}

._power-select-custom-width_1qb8fw {
  width: 150px;
}

._font-size-unset_1qb8fw {
  font-size: unset;
}

._integration_1qb8fw {
  border: 1px solid #efefef;
  border-radius: 0.3em;
  display: flex;
  align-items: stretch;

  &-logo {
    padding: 0.5em;

    &-container {
      width: 5em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-account {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-container {
      border-left: 1px solid #efefef;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

._bottom-change_1qb8fw {
  border-top-right-radius: 10px;
  box-shadow: 3px 0 3px -2px #888;
  width: 90%;
}

._archive-row_1qb8fw {
  &-expired {
    &,
    &:hover {
      background: lighten($color-lightgray, 2%);
      color: lighten($color-table, 43%);
    }
  }
}

._license-detail_1qb8fw {
  ._fa_1qb8fw {
    font-size: 1.2em;
    line-height: 1.2;
  }
}

._full-height_1qb8fw {
  height: 100vh;
}

._automation-script-upload_1qb8fw label._file-upload_1qb8fw {
  display: block;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

._primary-color_1qb8fw {
  color: #ef4836;
}

/* styles for irene/styles/_global-styles.scss */
@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/fonts/SourceCodePro-Regular.ttf') format('truetype');
}

// hide pendo img icon
img.__pendo-image_1l4qey.__pendo-badge-image_1l4qey {
  visibility: hidden;
}

// text styling and utils
body {
  font-family: 'Open Sans', sans-serif;
}

html {
  overflow-y: auto;
}

/* styles for irene/styles/_icons.scss */
@import 'material-icons.scss';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/2ef373830aa561f31f385b4f343fd646/material-icons.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/80ffd7f38b1f58a1a8c7125c12cc7878/material-icons-outlined.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/82f9b1b404daf3f2637660b24d6e0e8e/material-icons-round.woff2')
    format('woff2');
}

._ak-icon_1ahf46 {
  @extend .mi;
}

._ak-icon-outlined_1ahf46 {
  @extend .mi-outlined;
}

._ak-icon-round_1ahf46 {
  @extend .mi-round;
}

._ak-icon-folder_1ahf46 {
  @extend .mi-folder;
}

._ak-icon-graphic-eq_1ahf46 {
  @extend .mi-graphic-eq;
}

._ak-icon-people_1ahf46 {
  @extend .mi-people;
}

._ak-icon-inventory-2_1ahf46 {
  @extend .mi-inventory-2;
}

._ak-icon-account-box_1ahf46 {
  @extend .mi-account-box;
}

._ak-icon-account-balance_1ahf46 {
  @extend .mi-account-balance;
}

._ak-icon-credit-card_1ahf46 {
  @extend .mi-credit-card;
}

._ak-icon-groups-2_1ahf46 {
  @extend .mi-groups-2;
}

._ak-icon-groups_1ahf46 {
  @extend .mi-groups;
}

._ak-icon-arrow-left_1ahf46 {
  @extend .mi-arrow-left;
}

._ak-icon-arrow-right_1ahf46 {
  @extend .mi-arrow-right;
}

._ak-icon-west_1ahf46 {
  @extend .mi-west;
}

._ak-icon-warning_1ahf46 {
  @extend .mi-warning;
}

._ak-icon-warning-amber_1ahf46 {
  @extend .mi-warning-amber;
}

._ak-icon-arrow-back_1ahf46 {
  @extend .mi-arrow-back;
}

._ak-icon-arrow-drop-down_1ahf46 {
  @extend .mi-arrow-drop-down;
}

._ak-icon-arrow-drop-up_1ahf46 {
  @extend .mi-arrow-drop-up;
}

._ak-icon-search_1ahf46 {
  @extend .mi-search;
}

._ak-icon-check-box-outline-blank_1ahf46 {
  @extend .mi-check-box-outline-blank;
}

._ak-icon-check-box_1ahf46 {
  @extend .mi-check-box;
}

._ak-icon-indeterminate-check-box_1ahf46 {
  @extend .mi-indeterminate-check-box;
}

._ak-icon-radio-button-unchecked_1ahf46 {
  @extend .mi-radio-button-unchecked;
}

._ak-icon-radio-button-checked_1ahf46 {
  @extend .mi-radio-button-checked;
}

._ak-icon-check-circle_1ahf46 {
  @extend .mi-check-circle;
}

._ak-icon-cancel_1ahf46 {
  @extend .mi-cancel;
}

._ak-icon-close_1ahf46 {
  @extend .mi-close;
}

._ak-icon-done_1ahf46 {
  @extend .mi-done;
}

._ak-icon-refresh_1ahf46 {
  @extend .mi-refresh;
}

._ak-icon-delete_1ahf46 {
  @extend .mi-delete;
}

._ak-icon-more-vert_1ahf46 {
  @extend .mi-more-vert;
}

._ak-icon-note-add_1ahf46 {
  @extend .mi-note-add;
}

._ak-icon-group-add_1ahf46 {
  @extend .mi-group-add;
}

._ak-icon-integration-instructions_1ahf46 {
  @extend .mi-integration-instructions;
}

._ak-icon-verified_1ahf46 {
  @extend .mi-verified;
}

._ak-icon-subdirectory-arrow-right_1ahf46 {
  @extend .mi-subdirectory-arrow-right;
}

._ak-icon-download_1ahf46 {
  @extend .mi-download;
}

._ak-icon-security_1ahf46 {
  @extend .mi-security;
}

._ak-icon-settings_1ahf46 {
  @extend .mi-settings;
}

._ak-icon-settings-applications_1ahf46 {
  @extend .mi-settings-applications;
}

._ak-icon-person-remove_1ahf46 {
  @extend .mi-person-remove;
}

._ak-icon-remove_1ahf46 {
  @extend .mi-remove;
}

._ak-icon-edit_1ahf46 {
  @extend .mi-edit;
}

._ak-icon-file-upload_1ahf46 {
  @extend .mi-file-upload;
}

._ak-icon-event_1ahf46 {
  @extend .mi-event;
}

._ak-icon-add_1ahf46 {
  @extend .mi-add;
}

._ak-icon-assignment_1ahf46 {
  @extend .mi-assignment;
}

._ak-icon-notifications_1ahf46 {
  @extend .mi-notifications;
}

._ak-icon-dynamic-feed_1ahf46 {
  @extend .mi-dynamic-feed;
}

._ak-icon-cloud-upload_1ahf46 {
  @extend .mi-cloud-upload;
}

._ak-icon-import-contacts_1ahf46 {
  @extend .mi-import-contacts;
}

._ak-icon-support_1ahf46 {
  @extend .mi-support;
}

._ak-icon-account-circle_1ahf46 {
  @extend .mi-account-circle;
}

._ak-icon-mail_1ahf46 {
  @extend .mi-mail;
}

._ak-icon-logout_1ahf46 {
  @extend .mi-logout;
}

._ak-icon-open-in-new_1ahf46 {
  @extend .mi-open-in-new;
}

._ak-icon-view-list_1ahf46 {
  @extend .mi-view-list;
}

._ak-icon-file-download_1ahf46 {
  @extend .mi-file-download;
}

._ak-icon-content-copy_1ahf46 {
  @extend .mi-content-copy;
}

._ak-icon-receipt-long_1ahf46 {
  @extend .mi-receipt-long;
}

._ak-icon-history-toggle-off_1ahf46 {
  @extend .mi-history-toggle-off;
}

._ak-icon-trending-up_1ahf46 {
  @extend .mi-trending-up;
}

._ak-icon-view-stream_1ahf46 {
  @extend .mi-view-stream;
}

._ak-icon-trending-down_1ahf46 {
  @extend .mi-trending-down;
}

._ak-icon-info_1ahf46 {
  @extend .mi-info;
}

._ak-icon-block_1ahf46 {
  @extend .mi-block;
}

._ak-icon-help_1ahf46 {
  @extend .mi-help;
}

._ak-icon-description_1ahf46 {
  @extend .mi-description;
}

._ak-icon-compare-arrows_1ahf46 {
  @extend .mi-compare-arrows;
}

._ak-icon-format-list-bulleted_1ahf46 {
  @extend .mi-format-list-bulleted;
}

._ak-icon-play-arrow_1ahf46 {
  @extend .mi-play-arrow;
}

._ak-icon-stop_1ahf46 {
  @extend .mi-stop;
}

._ak-icon-build_1ahf46 {
  @extend .mi-build;
}

._ak-icon-person-off_1ahf46 {
  @extend .mi-person-off;
}

._ak-icon-person-add_1ahf46 {
  @extend .mi-person-add;
}

._ak-icon-person_1ahf46 {
  @extend .mi-person;
}

._ak-icon-error_1ahf46 {
  @extend .mi-error;
}

._ak-icon-undo_1ahf46 {
  @extend .mi-replay;
}

._ak-icon-arrow-down_1ahf46 {
  @extend .mi-keyboard-arrow-down;
}

._ak-icon-keyboard-backspace_1ahf46 {
  @extend .mi-keyboard-backspace;
}

._ak-icon-south_1ahf46 {
  @extend .mi-south;
}

._ak-icon-east_1ahf46 {
  @extend .mi-east;
}

._ak-icon-menu_1ahf46 {
  @extend .mi-menu;
}

._ak-icon-sync-alt_1ahf46 {
  @extend .mi-sync-alt;
}

._ak-icon-home_1ahf46 {
  @extend .mi-home;
}

._ak-icon-report-problem_1ahf46 {
  @extend .mi-report-problem;
}

._ak-icon-android_1ahf46 {
  @extend .mi-android;
}

._ak-icon-apple_1ahf46 {
  @extend .mi-apple;
}

._ak-icon-windows_1ahf46 {
  @extend .mi-laptop;
}

._ak-icon-mobile_1ahf46 {
  @extend .mi-phone-android;
}

._ak-icon-lock_1ahf46 {
  @extend .mi-lock;
}

._ak-icon-circle_1ahf46 {
  @extend .mi-circle;
}

._ak-icon-timer_1ahf46 {
  @extend .mi-timer;
}

._ak-icon-drag-handle_1ahf46 {
  @extend .mi-drag-handle;
}

._ak-icon-remove-circle_1ahf46 {
  @extend .mi-remove-circle;
}

._ak-icon-text-snippet_1ahf46 {
  @extend .mi-text-snippet;
}

._ak-icon-schedule_1ahf46 {
  @extend .mi-schedule;
}

._ak-icon-key_1ahf46 {
  @extend .mi-key;
}

._ak-icon-arrow-forward_1ahf46 {
  @extend .mi-arrow-forward;
}

._ak-icon-calendar-month_1ahf46 {
  @extend .mi-calendar-month;
}

._ak-icon-content-paste_1ahf46 {
  @extend .mi-content-paste;
}

._ak-icon-email_1ahf46 {
  @extend .mi-email;
}

._ak-icon-send_1ahf46 {
  @extend .mi-send;
}

._ak-icon-chevron-left_1ahf46 {
  @extend .mi-chevron-left;
}

._ak-icon-chevron-right_1ahf46 {
  @extend .mi-chevron-right;
}

._ak-icon-double-arrow-left_1ahf46 {
  @extend .mi-keyboard-double-arrow-left;
}

._ak-icon-double-arrow-right_1ahf46 {
  @extend .mi-keyboard-double-arrow-right;
}

._ak-icon-apps_1ahf46 {
  @extend .mi-apps;
}

._ak-icon-compare_1ahf46 {
  @extend .mi-compare;
}

._ak-icon-shape-line_1ahf46 {
  @extend .mi-shape-line;
}

._ak-icon-check_1ahf46 {
  @extend .mi-check;
}

._ak-icon-article_1ahf46 {
  @extend .mi-article;
}

._ak-icon-link_1ahf46 {
  @extend .mi-link;
}

._ak-icon-show-chart_1ahf46 {
  @extend .mi-show-chart;
}

._ak-icon-downloading_1ahf46 {
  @extend .mi-downloading;
}

._ak-icon-download-done_1ahf46 {
  @extend .mi-download-done;
}

._ak-icon-desktop-windows_1ahf46 {
  @extend .mi-desktop-windows;
}

._ak-icon-visibility_1ahf46 {
  @extend .mi-visibility;
}

._ak-icon-visibility-off_1ahf46 {
  @extend .mi-visibility-off;
}

._ak-icon-lock-open_1ahf46 {
  @extend .mi-lock-open;
}

._ak-icon-chat-bubble_1ahf46 {
  @extend .mi-chat-bubble;
}

._ak-icon-keyboard-tab_1ahf46 {
  @extend .mi-keyboard-tab;
}

._ak-icon-event-note_1ahf46 {
  @extend .mi-event-note;
}

/* styles for irene/styles/_theme.scss */
$primary: #fe4d3f;
$secondary: #424651;
$white: #ffffff;

$success: #80c081;
$success-border: #2db421;
$cancel-color: #1c1b1f;

$lightgreen: #ccf3cc;
$lightblue: #dbe6ff;
$lightorange: #fde6ca;
$darkgreen: #156347;
$darkblue: #4160a9;
$darkorange: #a9560f;

$bg-gray: #f6f7f9;
$color-gray: #6b6b6b;
$color-error: #fe4d3f;

:root {
  /* base colors */
  --white: #{$white};
  --primary: #{$primary};
  --secondary: #{$secondary};
  --lightgreen: #{$lightgreen};
  --darkgreen: #{$darkgreen};
  --lightblue: #{$lightblue};
  --darkblue: #{$darkblue};
  --lightorange: #{$lightorange};
  --darkorange: #{$darkorange};
  --success: #{$success};
  --success-border: #{$success-border};

  --black-025: #f0f1f3;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-150: #c8ccd0;
  --black-200: #bbc0c4;
  --black-300: #9fa6ad;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #5e5f63;
  --black-700: #3b3f49;
  --black-750: #2f3337;
  --black-800: #242729;
  --black-900: #0c0d0e;

  --android: #33a852;
  --ios: #8d9096;

  --risk-critical: #ef4836;
  --risk-high: #ff8c00;
  --risk-medium: #f5d76e;
  --risk-low: #2cc2f8;
  --risk-passed: #80c081;
  --risk-untested: #6b6b6b;

  /* generic derived colors */
  --text-default: #{$color-gray};
  --text-error: #{$color-error};

  --primary-transparent-005: #{rgba($primary, 0.05)};

  --bg-gray: #{$bg-gray};
  --bg-gray-darken-020: #{darken($bg-gray, 2%)};
  --bg-gray-darken-040: #{darken($bg-gray, 4%)};
  --bg-gray-darken-050: #{darken($bg-gray, 5%)};
  --bg-gray-darken-060: #{darken($bg-gray, 6%)};
  --bg-gray-darken-070: #{darken($bg-gray, 7%)};
  --bg-gray-darken-080: #{darken($bg-gray, 8%)};
  --bg-gray-lighten-015: #{lighten($bg-gray, 1.5%)};
  --bg-darkgray: #{darken(mix($color-gray, $bg-gray, 2%), 14%)};

  --border-table: var(--bg-gray-darken-020);

  /* element specific derived colors */
  --label-green-bg: var(--lightgreen);
  --label-green-color: var(--darkgreen);
  --label-blue-bg: var(--lightblue);
  --label-blue-color: var(--darkblue);
  --label-orange-bg: var(--lightorange);
  --label-orange-color: var(--darkorange);

  --loading-error-color: #{lighten($color-error, 14%)};
  --empty-state-color: var(--black-300);

  // --sidebar-bg: var(--secondary);
  // --navbar-bg: #{darken($secondary, 2%)};
}

// .theme-light {
//   --secondary: #{lighten($bg-gray, 3%)};
//   --sidebar-bg: #{lighten($bg-gray, 4%)};
//   --navbar-bg: var(--secondary);
// }

/* styles for irene/styles/_utils.scss */
// Position utils
._position-relative_9zl9yl {
  position: relative;
}

._position-absolute_9zl9yl {
  position: absolute;
}

._position-sticky_9zl9yl {
  position: sticky;
}

._position-fixed_9zl9yl {
  position: fixed;
}

._position-static_9zl9yl {
  position: static;
}

// flex utils
._flex-row_9zl9yl {
  display: flex;
  box-sizing: border-box;
}

._flex-column_9zl9yl {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

._flex-justify-start_9zl9yl {
  justify-content: flex-start;
}

._flex-justify-end_9zl9yl {
  justify-content: flex-end;
}

._flex-justify-center_9zl9yl {
  justify-content: center;
}

._flex-justify-space-between_9zl9yl {
  justify-content: space-between;
}

._flex-justify-space-around_9zl9yl {
  justify-content: space-around;
}

._flex-align-start_9zl9yl {
  align-items: flex-start;
}

._flex-align-end_9zl9yl {
  align-items: flex-end;
}

._flex-align-center_9zl9yl {
  align-items: center;
}

._flex-align-space-between_9zl9yl {
  align-items: space-between;
}

._flex-align-space-around_9zl9yl {
  align-items: space-around;
}

._flex-wrap_9zl9yl {
  flex-wrap: wrap;
}

@for $i from 1 to 4 {
  $gap: $i * 0.5;

  ._flex-row-gap-#{$i}_9zl9yl {
    margin: #{-1 * $gap}em 0 0 #{-1 * $gap}em;

    & > * {
      margin: #{$gap}em 0 0 #{$gap}em;
    }
  }
}

/* 
========== spacing utils ========== 
spacing utils has padding and margin utility classes
it ranges from 0 - 8 and mutliplication factor is 0.5em
*/
@for $i from 0 to 8 {
  $space: $i * 0.5;

  // paddding utils
  ._p-#{$i}_9zl9yl {
    padding: #{$space}em;
  }

  ._py-#{$i}_9zl9yl {
    padding-top: #{$space}em;
    padding-bottom: #{$space}em;
  }

  ._px-#{$i}_9zl9yl {
    padding-left: #{$space}em;
    padding-right: #{$space}em;
  }

  ._pt-#{$i}_9zl9yl {
    padding-top: #{$space}em;
  }

  ._pb-#{$i}_9zl9yl {
    padding-bottom: #{$space}em;
  }

  ._pl-#{$i}_9zl9yl {
    padding-left: #{$space}em;
  }

  ._pr-#{$i}_9zl9yl {
    padding-right: #{$space}em;
  }

  // margin utils
  ._m-#{$i}_9zl9yl {
    margin: #{$space}em;
  }

  ._my-#{$i}_9zl9yl {
    margin-top: #{$space}em;
    margin-bottom: #{$space}em;
  }

  ._mx-#{$i}_9zl9yl {
    margin-left: #{$space}em;
    margin-right: #{$space}em;
  }

  ._mt-#{$i}_9zl9yl {
    margin-top: #{$space}em;
  }

  ._mb-#{$i}_9zl9yl {
    margin-bottom: #{$space}em;
  }

  ._ml-#{$i}_9zl9yl {
    margin-left: #{$space}em;
  }

  ._mr-#{$i}_9zl9yl {
    margin-right: #{$space}em;
  }
}

._mx-auto_9zl9yl {
  margin-left: auto;
  margin-right: auto;
}

/*
===================== width util =====================
Examples:
------------------
| class  | value |
------------------
| w-1/12 | 8.33% |
| w-3/12 | 25%   |
| w-6/12 | 50%   |
| w-9/12 | 75%   |
------------------
*/

@for $i from 1 to 12 {
  ._w-#{$i}\/12_9zl9yl {
    width: ($i / 12) * 100%;
  }
}

._w-full_9zl9yl {
  width: 100%;
}

/* styles for irene/styles/_vars.scss */
$extensions-list: (
  apk: #5eb533,
  ipa: #8e44ad,
  txt: #a6b1b5,
  log: #373a43,
  pdf: #db3e3d,
  doc: #307cf1,
  docx: #307cf1,
  xls: #0f9d58,
  xlsx: #0f9d58,
  xlsm: #0f9d58,
  zip: #855d40,
  jpg: #80c081,
  jpeg: #80c081,
  png: #80c081,
  bmp: #80c081,
);

$risk-critical: #ef4836;
$risk-danger: #ff8c00;
$risk-warning: #f5d76e;
$risk-info: #2cc2f8;
$risk-success: #80c081;
$risk-default: #6b6b6b;

$attachment-icon-width: 4em;

/*------- button vars start -------*/
/* button generic vars */
body {
  --button-border-radius: 2px;
}

/* button primary filled */
body {
  --button-primary-filled-border-width__focus: 1px;
}

/* button primary outlined */
body {
  --button-primary-outlined-border-width: 1px;
  --button-primary-outlined-border-width__hover: 1.5px;
  --button-primary-outlined-border-width__focus: 2px;
}

/* button neutral outlined */
body {
  --button-neutral-outlined-border-width: 1px;
  --button-neutral-outlined-border-width__hover: 1.5px;
  --button-neutral-outlined-border-width__focus: 2px;
}
/*------- button vars end -------*/
