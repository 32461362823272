@import 'material-icons.scss';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/2ef373830aa561f31f385b4f343fd646/material-icons.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/80ffd7f38b1f58a1a8c7125c12cc7878/material-icons-outlined.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/fonts/82f9b1b404daf3f2637660b24d6e0e8e/material-icons-round.woff2')
    format('woff2');
}

.ak-icon {
  @extend .mi;
}

.ak-icon-outlined {
  @extend .mi-outlined;
}

.ak-icon-round {
  @extend .mi-round;
}

.ak-icon-folder {
  @extend .mi-folder;
}

.ak-icon-graphic-eq {
  @extend .mi-graphic-eq;
}

.ak-icon-people {
  @extend .mi-people;
}

.ak-icon-inventory-2 {
  @extend .mi-inventory-2;
}

.ak-icon-account-box {
  @extend .mi-account-box;
}

.ak-icon-account-balance {
  @extend .mi-account-balance;
}

.ak-icon-credit-card {
  @extend .mi-credit-card;
}

.ak-icon-groups-2 {
  @extend .mi-groups-2;
}

.ak-icon-groups {
  @extend .mi-groups;
}

.ak-icon-arrow-left {
  @extend .mi-arrow-left;
}

.ak-icon-arrow-right {
  @extend .mi-arrow-right;
}

.ak-icon-west {
  @extend .mi-west;
}

.ak-icon-warning {
  @extend .mi-warning;
}

.ak-icon-warning-amber {
  @extend .mi-warning-amber;
}

.ak-icon-arrow-back {
  @extend .mi-arrow-back;
}

.ak-icon-arrow-drop-down {
  @extend .mi-arrow-drop-down;
}

.ak-icon-arrow-drop-up {
  @extend .mi-arrow-drop-up;
}

.ak-icon-search {
  @extend .mi-search;
}

.ak-icon-check-box-outline-blank {
  @extend .mi-check-box-outline-blank;
}

.ak-icon-check-box {
  @extend .mi-check-box;
}

.ak-icon-indeterminate-check-box {
  @extend .mi-indeterminate-check-box;
}

.ak-icon-radio-button-unchecked {
  @extend .mi-radio-button-unchecked;
}

.ak-icon-radio-button-checked {
  @extend .mi-radio-button-checked;
}

.ak-icon-check-circle {
  @extend .mi-check-circle;
}

.ak-icon-cancel {
  @extend .mi-cancel;
}

.ak-icon-close {
  @extend .mi-close;
}

.ak-icon-done {
  @extend .mi-done;
}

.ak-icon-refresh {
  @extend .mi-refresh;
}

.ak-icon-delete {
  @extend .mi-delete;
}

.ak-icon-more-vert {
  @extend .mi-more-vert;
}

.ak-icon-note-add {
  @extend .mi-note-add;
}

.ak-icon-group-add {
  @extend .mi-group-add;
}

.ak-icon-integration-instructions {
  @extend .mi-integration-instructions;
}

.ak-icon-verified {
  @extend .mi-verified;
}

.ak-icon-subdirectory-arrow-right {
  @extend .mi-subdirectory-arrow-right;
}

.ak-icon-download {
  @extend .mi-download;
}

.ak-icon-security {
  @extend .mi-security;
}

.ak-icon-settings {
  @extend .mi-settings;
}

.ak-icon-settings-applications {
  @extend .mi-settings-applications;
}

.ak-icon-person-remove {
  @extend .mi-person-remove;
}

.ak-icon-remove {
  @extend .mi-remove;
}

.ak-icon-edit {
  @extend .mi-edit;
}

.ak-icon-file-upload {
  @extend .mi-file-upload;
}

.ak-icon-event {
  @extend .mi-event;
}

.ak-icon-add {
  @extend .mi-add;
}

.ak-icon-assignment {
  @extend .mi-assignment;
}

.ak-icon-notifications {
  @extend .mi-notifications;
}

.ak-icon-dynamic-feed {
  @extend .mi-dynamic-feed;
}

.ak-icon-cloud-upload {
  @extend .mi-cloud-upload;
}

.ak-icon-import-contacts {
  @extend .mi-import-contacts;
}

.ak-icon-support {
  @extend .mi-support;
}

.ak-icon-account-circle {
  @extend .mi-account-circle;
}

.ak-icon-mail {
  @extend .mi-mail;
}

.ak-icon-logout {
  @extend .mi-logout;
}

.ak-icon-open-in-new {
  @extend .mi-open-in-new;
}

.ak-icon-view-list {
  @extend .mi-view-list;
}

.ak-icon-file-download {
  @extend .mi-file-download;
}

.ak-icon-content-copy {
  @extend .mi-content-copy;
}

.ak-icon-receipt-long {
  @extend .mi-receipt-long;
}

.ak-icon-history-toggle-off {
  @extend .mi-history-toggle-off;
}

.ak-icon-trending-up {
  @extend .mi-trending-up;
}

.ak-icon-view-stream {
  @extend .mi-view-stream;
}

.ak-icon-trending-down {
  @extend .mi-trending-down;
}

.ak-icon-info {
  @extend .mi-info;
}

.ak-icon-block {
  @extend .mi-block;
}

.ak-icon-help {
  @extend .mi-help;
}

.ak-icon-description {
  @extend .mi-description;
}

.ak-icon-compare-arrows {
  @extend .mi-compare-arrows;
}

.ak-icon-format-list-bulleted {
  @extend .mi-format-list-bulleted;
}

.ak-icon-play-arrow {
  @extend .mi-play-arrow;
}

.ak-icon-stop {
  @extend .mi-stop;
}

.ak-icon-build {
  @extend .mi-build;
}

.ak-icon-person-off {
  @extend .mi-person-off;
}

.ak-icon-person-add {
  @extend .mi-person-add;
}

.ak-icon-person {
  @extend .mi-person;
}

.ak-icon-error {
  @extend .mi-error;
}

.ak-icon-undo {
  @extend .mi-replay;
}

.ak-icon-arrow-down {
  @extend .mi-keyboard-arrow-down;
}

.ak-icon-keyboard-backspace {
  @extend .mi-keyboard-backspace;
}

.ak-icon-south {
  @extend .mi-south;
}

.ak-icon-east {
  @extend .mi-east;
}

.ak-icon-menu {
  @extend .mi-menu;
}

.ak-icon-sync-alt {
  @extend .mi-sync-alt;
}

.ak-icon-home {
  @extend .mi-home;
}

.ak-icon-report-problem {
  @extend .mi-report-problem;
}

.ak-icon-android {
  @extend .mi-android;
}

.ak-icon-apple {
  @extend .mi-apple;
}

.ak-icon-windows {
  @extend .mi-laptop;
}

.ak-icon-mobile {
  @extend .mi-phone-android;
}

.ak-icon-lock {
  @extend .mi-lock;
}

.ak-icon-circle {
  @extend .mi-circle;
}

.ak-icon-timer {
  @extend .mi-timer;
}

.ak-icon-drag-handle {
  @extend .mi-drag-handle;
}

.ak-icon-remove-circle {
  @extend .mi-remove-circle;
}

.ak-icon-text-snippet {
  @extend .mi-text-snippet;
}

.ak-icon-schedule {
  @extend .mi-schedule;
}

.ak-icon-key {
  @extend .mi-key;
}

.ak-icon-arrow-forward {
  @extend .mi-arrow-forward;
}

.ak-icon-calendar-month {
  @extend .mi-calendar-month;
}

.ak-icon-content-paste {
  @extend .mi-content-paste;
}

.ak-icon-email {
  @extend .mi-email;
}

.ak-icon-send {
  @extend .mi-send;
}

.ak-icon-chevron-left {
  @extend .mi-chevron-left;
}

.ak-icon-chevron-right {
  @extend .mi-chevron-right;
}

.ak-icon-double-arrow-left {
  @extend .mi-keyboard-double-arrow-left;
}

.ak-icon-double-arrow-right {
  @extend .mi-keyboard-double-arrow-right;
}

.ak-icon-apps {
  @extend .mi-apps;
}

.ak-icon-compare {
  @extend .mi-compare;
}

.ak-icon-shape-line {
  @extend .mi-shape-line;
}

.ak-icon-check {
  @extend .mi-check;
}

.ak-icon-article {
  @extend .mi-article;
}

.ak-icon-link {
  @extend .mi-link;
}

.ak-icon-show-chart {
  @extend .mi-show-chart;
}

.ak-icon-downloading {
  @extend .mi-downloading;
}

.ak-icon-download-done {
  @extend .mi-download-done;
}

.ak-icon-desktop-windows {
  @extend .mi-desktop-windows;
}

.ak-icon-visibility {
  @extend .mi-visibility;
}

.ak-icon-visibility-off {
  @extend .mi-visibility-off;
}

.ak-icon-lock-open {
  @extend .mi-lock-open;
}

.ak-icon-chat-bubble {
  @extend .mi-chat-bubble;
}

.ak-icon-keyboard-tab {
  @extend .mi-keyboard-tab;
}

.ak-icon-event-note {
  @extend .mi-event-note;
}
