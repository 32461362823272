// Freshdesk support widget style override
#freshworks-frame-wrapper {
  right: 0px !important;
  bottom: 0px !important;
  max-width: 568px !important;
  max-height: unset !important;
  width: 100% !important;
  height: 100vh !important;
  border-radius: 0px !important;
}
