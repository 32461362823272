$primary: #fe4d3f;
$secondary: #424651;
$white: #ffffff;

$success: #80c081;
$success-border: #2db421;
$cancel-color: #1c1b1f;

$lightgreen: #ccf3cc;
$lightblue: #dbe6ff;
$lightorange: #fde6ca;
$darkgreen: #156347;
$darkblue: #4160a9;
$darkorange: #a9560f;

$bg-gray: #f6f7f9;
$color-gray: #6b6b6b;
$color-error: #fe4d3f;

:root {
  /* base colors */
  --white: #{$white};
  --primary: #{$primary};
  --secondary: #{$secondary};
  --lightgreen: #{$lightgreen};
  --darkgreen: #{$darkgreen};
  --lightblue: #{$lightblue};
  --darkblue: #{$darkblue};
  --lightorange: #{$lightorange};
  --darkorange: #{$darkorange};
  --success: #{$success};
  --success-border: #{$success-border};

  --black-025: #f0f1f3;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-150: #c8ccd0;
  --black-200: #bbc0c4;
  --black-300: #9fa6ad;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #5e5f63;
  --black-700: #3b3f49;
  --black-750: #2f3337;
  --black-800: #242729;
  --black-900: #0c0d0e;

  --android: #33a852;
  --ios: #8d9096;

  --risk-critical: #ef4836;
  --risk-high: #ff8c00;
  --risk-medium: #f5d76e;
  --risk-low: #2cc2f8;
  --risk-passed: #80c081;
  --risk-untested: #6b6b6b;

  /* generic derived colors */
  --text-default: #{$color-gray};
  --text-error: #{$color-error};

  --primary-transparent-005: #{rgba($primary, 0.05)};

  --bg-gray: #{$bg-gray};
  --bg-gray-darken-020: #{darken($bg-gray, 2%)};
  --bg-gray-darken-040: #{darken($bg-gray, 4%)};
  --bg-gray-darken-050: #{darken($bg-gray, 5%)};
  --bg-gray-darken-060: #{darken($bg-gray, 6%)};
  --bg-gray-darken-070: #{darken($bg-gray, 7%)};
  --bg-gray-darken-080: #{darken($bg-gray, 8%)};
  --bg-gray-lighten-015: #{lighten($bg-gray, 1.5%)};
  --bg-darkgray: #{darken(mix($color-gray, $bg-gray, 2%), 14%)};

  --border-table: var(--bg-gray-darken-020);

  /* element specific derived colors */
  --label-green-bg: var(--lightgreen);
  --label-green-color: var(--darkgreen);
  --label-blue-bg: var(--lightblue);
  --label-blue-color: var(--darkblue);
  --label-orange-bg: var(--lightorange);
  --label-orange-color: var(--darkorange);

  --loading-error-color: #{lighten($color-error, 14%)};
  --empty-state-color: var(--black-300);

  // --sidebar-bg: var(--secondary);
  // --navbar-bg: #{darken($secondary, 2%)};
}

// .theme-light {
//   --secondary: #{lighten($bg-gray, 3%)};
//   --sidebar-bg: #{lighten($bg-gray, 4%)};
//   --navbar-bg: var(--secondary);
// }
