/*------- sass vars for function start -------*/
/* these should not be used unless required for sass functions */
$black: #000000;

/*------- sass vars for function end -------*/

//---------- Theme variables--------------//
// Note: going forward only these variables should be used
// and any other existing variable should be replaced by these
body {
  --common-black: #000000;
  --common-white: #ffffff;

  --background-main: #ffffff;
  --background-dark: #424651;
  --background-light: #fafafa;

  --primary-main: #ff4d3f;
  --primary-dark: #e23123;
  --primary-light: rgba(254, 77, 63, 0.2);
  --primary-contrast-text: #ffffff;

  --primary-main-10: rgba(254, 77, 63, 0.1);

  --secondary-main: #424651;
  --secondary-contrast-text: #ffffff;

  --success-main: #2db421;
  // --success-dark -
  --success-light: #e9f5ed;

  --error-main: #d72f2f;
  // --error-dark -
  --error-light: #ffd1d4;

  --warn-main: #ffd52e;
  --warn-dark: #a5872d;
  --warn-light: #fff7d7;

  --info-main: #087edb;
  // --info-dark: Skip for now
  // --info-light: Skip for now

  --neutral-grey-50: #fcfcfc;
  --neutral-grey-100: #f5f5f5;
  --neutral-grey-200: #e9e9e9;
  --neutral-grey-300: #d9d9d9;
  --neutral-grey-400: #c4c4c4;
  --neutral-grey-500: #9d9d9d;
  --neutral-grey-600: #7b7b7b;
  --neutral-grey-700: #555555;
  --neutral-grey-800: #434343;
  --neutral-grey-900: #262626;

  --text-primary: #171717;
  --text-secondary: #7b7b7b;
  --text-disabled: #c4c4c4;

  --divider: #f5f5f5;
  --divider-dark: #e9e9e9;

  --border-radius: 2px;

  --border-color-1: #e9e9e9;
  --border-color-2: #c4c4c4;

  --box-shadow-1: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --box-shadow-2: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  --box-shadow-3: 4px 4px 15px rgba(0, 0, 0, 0.04);
  --box-shadow-4: 0px -4px 8px 4px rgba(0, 0, 0, 0.03);
  --box-shadow-5: 3px 4px 6px rgba(216, 216, 216, 0.25);
  --box-shadow-6: 0px 5px 14px 0px rgba(64, 64, 64, 0.05);
  --box-shadow-7: 0px 2px 6px 0px rgba(64, 64, 64, 0.15);
  --box-shadow-8: -5px 0px 9px 0px rgba(64, 64, 64, 0.03);
  --box-shadow-9: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

  --hover-dark-background: rgba(255, 255, 255, 0.15);
  --hover-light-background: rgba(0, 0, 0, 0.04);

  --backdrop-overlay-background: rgba(0, 0, 0, 0.25);

  --disabled-background-textfield: #f5f5f5;
  --disabled-background-button: #c4c4c4;

  --html-font-size: 14px;
  --font-family: 'Open Sans', sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;

  --zIndex-appbar: 1100;
  --zIndex-drawer: 1200;
  --zIndex-modal: 1300;
  --zIndex-snackbar: 1400;
  --zIndex-tooltip: 1500;

  // special case variable for home-page/organization-dashboard/side-nav
  --side-menu-active-background-dark: #303339;
  --side-menu-active-background-light: rgba(254, 77, 63, 0.1);

  // special case variable for app-bar & side-nav
  --box-shadow-dark: 1px 0px 8px -1px rgba(0, 0, 0, 0.3);
  --box-shadow-light: 4px 4px 20px rgba(222, 222, 222, 0.25);

  // special case variable for severity
  --severity-critical: #d72f2f;
  --severity-high: #f98746;
  --severity-medium: #fad34a;
  --severity-low: #46cef9;
  --severity-info: #2141b4;
  --severity-passed: #2db421;
  --severity-none: #a0a0a0;
  --severity-untested: #a0a0a0;
  --severity-unknown: #202020;
}

/*------- static colors start -------*/
body {
  --white: #ffffff;
  --black: #{$black};

  --body-type-color: #171717;

  --am-drawer-header-title: #ff4d3f;
  --green-approval: #80c081;

  --neutral-white-100: #f1f1f1;
}
/*------- static colors end -------*/

/*------- text colors start ------*/
body {
  --text-black-91: #{lighten($black, 9%)};
  --body-text-1: var(--text-black-91);
  --text-primary-color: var(--text-black-91);
  --text-secondary-color: #9d9d9d;
}
/*------- text colors end ------*/

/*------- ember-attacher colors start ------*/
/* popover white */
body {
  --popover-white-bg: var(--white);
  --popover-white-border-color: var(--black-150);
}
/*------- ember-attacher colors end ------*/

/*------- input colors start ------*/
body {
  --input-border-color: #fff2f1;
}
/*------- input colors end ------*/
