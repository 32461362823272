$color-error: #FE4D3F;

.form {
  background-color: #FFF;
  border-radius: 5px;
  overflow: hidden;
  padding: 2em 1.6em;
  .checkbox-field {
    display: inline-block
  }
}

.input-wrap {
  margin: 1em 0;
  label {
    font-size: 0.9em;
    font-weight: bold;
  }
  .label-error {
    min-height: 1.2em;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .error-msg {
      font-size: 0.9em;
      color: $color-error;
    }
  }
  .half-wrap {
    display: flex;
    justify-content: space-between;
    .input-field {
      width: 49%;
    }
  }
  .input-field {
    margin: 0.2em 0;
    appearance: none;
    box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
    background-color: white;
    border: 1px solid #fff2f1;
    border-radius: 3px;
    color: #6B6B6B;
    height: 3em;
    padding: 0 1em;
    position: relative;
    border-color: #e6e6e6;
    width: 100%;
    max-width: 100%;
    &:active, &:focus, &:hover {
      outline: none;
      border-color: #b3b3b3;
      box-shadow: 0 0 1px #808080;
    }
    &::placeholder {
      color: #c1c1c1;
      font-weight: light;
    }
    &.has-error {
      border: 1px solid $color-error;
    }
  }
  &.has-error {
    .input-field {
      border: 1px solid $color-error;
    }
  }
}
