$color-text: #6b6b6b;
$color-table: #565d66;
$sidebar-bg: #424651;
$color-lightgray: #f7f7f7;

$risk-names: (
  warning: (
    $warning,
    $warning-invert,
  ),
  info: (
    $info,
    $info-invert,
  ),
  danger: (
    $danger,
    $danger-invert,
  ),
  critical: (
    $critical,
    $critical-invert,
  ),
  success: (
    $success,
    $success-invert,
  ),
) !default;

.bold,
strong {
  font-weight: bold;
  color: currentcolor;
}

.semi-bold {
  font-weight: 600;
}

h2 {
  font-size: 1.65em;
}

h3 {
  font-size: 1.5em;
}

.font-medium {
  font-size: 1.2em;
}

.font-legible {
  font-size: 1.1em;
}

.font-regular {
  font-size: 1em;
}

.font-small {
  font-size: 0.95em;
}

.text-lightgray {
  color: lighten($color-table, 28%);
}

.text-darkgray {
  color: $color-table;
}

.text-success {
  color: $success;
}

.text-danger {
  color: $danger;
}

// table related styling
.table-lightgray {
  tr {
    border: 1px solid lighten(#eaecef, 2%);
    min-height: 3em;
  }

  th {
    background-color: darken(#f6f8fa, 1%);
    color: darken(#586069, 1%);
  }

  td {
    font-size: 0.9em;
  }

  th,
  td {
    border-width: 0px;
    vertical-align: middle;

    &:first-child {
      text-align: left;
      padding-left: 1em;
    }
  }

  .btn-table {
    height: auto;

    i {
      padding-right: 0.15em;
    }
  }

  .td-with-btn {
    height: 3.5em;
    display: flex;
    align-items: center;
  }
}

.table-compact td {
  padding: 0.4em;
}

.empty-state {
  font-style: italic;
  color: lighten($color-table, 30%);
  background: lighten($color-table, 61%);
  border: 0.1em solid lighten($color-table, 59%);
  border-radius: 0.2em;
  padding: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  .fa {
    font-size: 0.9em;
    margin-right: 0.5em;
    color: lighten($color-table, 45%);
  }
}

.fill-primary {
  background: $primary;
  color: $light;
}

.fill-silver {
  background: darken(#f6f8fa, 1%);
}

.fill-lightgray {
  background: #f7f7f7;
}

.fill-white {
  background: white;
}

// button related styling
.btn {
  &-default {
    background: $default;
    border-color: $default;
    color: white;

    &:hover,
    &:active,
    &:focus {
      background: darken($default, 12%);
      border-color: darken($default, 12%);
      color: white;
    }

    &[disabled] {
      background: $default;
      border-color: $default;
    }
  }

  &-success {
    background: $success;
    border-color: $success;
    color: white;

    &:hover,
    &:active,
    &:focus {
      background: darken($success, 12%);
      border-color: darken($success, 12%);
      color: white;
    }

    &[disabled] {
      background: $success;
      border-color: $success;
    }
  }
}

.bordered-box {
  border: 1px solid darken($color-lightgray, 5%);
  border-radius: 0.2em;
  padding: 0.75em 1.1em 0.65em;
}

// alert related styling
.alert {
  background: $color-lightgray;
  border: 1px solid darken($color-lightgray, 3%);
  color: darken($color-lightgray, 37%);
  border-radius: 0.2em;
  padding: 0.75em 1.1em 0.65em;

  &-table {
    background: transparent;
    color: currentColor;

    tr:hover {
      background: inherit;
      color: inherit;
    }

    td {
      border-color: currentColor;
      padding: 0.35em 0.5em;
    }
  }

  &-link {
    font-weight: bold;
  }

  &-small {
    font-size: 0.93em;
    line-height: 1.5;
  }

  strong {
    color: inherit;
  }

  &-critical {
    background: rgba($critical, 0.11);
    border-color: rgba($critical, 0.2);
    color: mix($color-table, $critical, 30%);

    .alert-link {
      color: mix($color-table, $critical, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    .alert-table {
      background: lighten(rgba($critical, 0.11), 60%);

      td {
        border-color: rgba($critical, 0.35);
      }
    }
  }

  &-danger {
    background: rgba($danger, 0.11);
    border-color: rgba($danger, 0.2);
    color: mix($color-table, $danger, 30%);

    .alert-link {
      color: mix($color-table, $danger, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    .alert-table {
      background: lighten(rgba($danger, 0.11), 60%);

      td {
        border-color: rgba($danger, 0.35);
      }
    }
  }

  &-warning {
    background: rgba($warning, 0.11);
    border-color: rgba($warning, 0.3);
    color: mix($color-table, mix($primary, $warning, 15%), 30%);

    .alert-link {
      color: mix($color-table, mix($primary, $warning, 25%), 60%);

      &:hover {
        text-decoration: underline;
      }
    }

    .alert-table {
      background: lighten(rgba($warning, 0.11), 60%);

      td {
        border-color: rgba($warning, 0.4);
      }
    }
  }

  &-info {
    background: rgba($info, 0.11);
    border-color: rgba($info, 0.2);
    color: mix($color-table, $info, 30%);

    .alert-link {
      color: mix($color-table, $info, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    .alert-table {
      background: lighten(rgba($info, 0.11), 60%);

      td {
        border-color: rgba($info, 0.35);
      }
    }
  }

  &-success {
    background: rgba($success, 0.11);
    border-color: rgba($success, 0.2);
    color: mix($color-table, $success, 30%);

    .alert-link {
      color: mix($color-table, $success, 55%);

      &:hover {
        text-decoration: underline;
      }
    }

    .alert-table {
      background: lighten(rgba($success, 0.11), 60%);

      td {
        border-color: rgba($success, 0.35);
      }
    }
  }
}

.fill-success-box {
  border-color: rgba($success, 0.5);
  background: rgba($success, 0.15);

  &:hover {
    background: rgba($success, 0.15);
  }
}

.alert {
  background: $color-lightgray;
  border: 1px solid darken($color-lightgray, 3%);
  color: darken($color-lightgray, 37%);
  border-radius: 0.2em;
  padding: 0.75em 1.1em 0.65em;

  &-link {
    font-weight: bold;
  }

  &-small {
    font-size: 0.93em;
    line-height: 1.5;
  }
  &-critical {
    background: rgba($critical, 0.11);
    border-color: rgba($critical, 0.2);
    color: mix($color-table, $critical, 30%);

    .alert-link {
      color: mix($color-table, $critical, 55%);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-danger {
    background: rgba($danger, 0.11);
    border-color: rgba($danger, 0.2);
    color: mix($color-table, $danger, 30%);
    .alert-link {
      color: mix($color-table, $danger, 55%);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-warning {
    background: rgba($warning, 0.11);
    border-color: rgba($warning, 0.3);
    color: mix($color-table, mix($primary, $warning, 15%), 30%);

    .alert-link {
      color: mix($color-table, mix($primary, $warning, 25%), 60%);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-info {
    background: rgba($info, 0.11);
    border-color: rgba($info, 0.2);
    color: mix($color-table, $info, 30%);

    .alert-link {
      color: mix($color-table, $info, 55%);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-success {
    background: rgba($success, 0.11);
    border-color: rgba($success, 0.2);
    color: mix($color-table, $success, 30%);

    .alert-link {
      color: mix($color-table, $success, 55%);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

$badge-small-size: 1.5em;
.badge-square {
  min-width: $badge-small-size * 1.3;
  height: $badge-small-size;
  border-radius: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.82em;
  font-weight: bold;

  &.is-default {
    background: lighten($color-table, 51%);
  }

  &.is-sidebar {
    background: darken($sidebar-bg, 5%);
    color: lighten($sidebar-bg, 65%);
  }
}

$spacers: margin padding;
$fractional-pts: q 0.25, h 0.5, 3Q 0.75;

@each $spacer in $spacers {
  @each $fp in $fractional-pts {
    .#{$spacer}-t-#{nth($fp, 1)},
    .#{$spacer}-v-#{nth($fp, 1)} {
      #{$spacer}-top: #{nth($fp, 2)}em;
    }
    .#{$spacer}-b-#{nth($fp, 1)},
    .#{$spacer}-v-#{nth($fp, 1)} {
      #{$spacer}-bottom: #{nth($fp, 2)}em;
    }
    .#{$spacer}-l-#{nth($fp, 1)},
    .#{$spacer}-h-#{nth($fp, 1)} {
      #{$spacer}-left: #{nth($fp, 2)}em;
    }
    .#{$spacer}-r-#{nth($fp, 1)},
    .#{$spacer}-h-#{nth($fp, 1)} {
      #{$spacer}-right: #{nth($fp, 2)}em;
    }
  }
}

@each $spacer in $spacers {
  @for $i from 0 through 4 {
    .#{$spacer}-t-#{$i},
    .#{$spacer}-v-#{$i} {
      #{$spacer}-top: #{$i}em;
    }
    .#{$spacer}-b-#{$i},
    .#{$spacer}-v-#{$i} {
      #{$spacer}-bottom: #{$i}em;
    }
    .#{$spacer}-l-#{$i},
    .#{$spacer}-h-#{$i} {
      #{$spacer}-left: #{$i}em;
    }
    .#{$spacer}-r-#{$i},
    .#{$spacer}-h-#{$i} {
      #{$spacer}-right: #{$i}em;
    }
    .#{$spacer}-t-#{$i}h,
    .#{$spacer}-v-#{$i}h {
      #{$spacer}-top: #{$i + 0.5}em;
    }
    .#{$spacer}-b-#{$i}h,
    .#{$spacer}-v-#{$i}h {
      #{$spacer}-bottom: #{$i + 0.5}em;
    }
    .#{$spacer}-l-#{$i}h,
    .#{$spacer}-h-#{$i}h {
      #{$spacer}-left: #{$i + 0.5}em;
    }
    .#{$spacer}-r-#{$i}h,
    .#{$spacer}-h-#{$i}h {
      #{$spacer}-right: #{$i + 0.5}em;
    }
  }
}

// margin & padding util classes
.margin-top30 {
  margin-top: 30px;
}

.margin-top-10 {
  margin-top: -10px;
}

.margin-top-20 {
  margin-top: -20px;
}

.margin-top-5 {
  margin-top: -5px;
}

.margin-top50 {
  margin-top: 50px;
}

.margin-top {
  margin-top: 10px;
}

.margin-top5 {
  margin-top: 5px;
}

.margin-top3 {
  margin-top: 3px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top35 {
  margin-top: 35px;
}

.margin-left {
  margin-left: 10px;
}

.margin-left5 {
  margin-left: 5px;
}

.margin-left30 {
  margin-left: 30px;
}

.margin-left0 {
  margin-left: 0px;
}

.margin-top50 {
  margin-top: 50px;
}

.margin-left20 {
  margin-left: 20px;
}

.margin-right {
  margin-right: 10px;
}

.margin-right20 {
  margin-right: 40px;
}

.margin-left-10 {
  margin-left: -10px;
}

.margin-right-40 {
  margin-right: -40px;
}

.margin-right100 {
  margin-right: 100px;
}

.margin-top-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-bottom0 {
  margin-bottom: 0px !important;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom-20 {
  margin-bottom: -20px;
}

.margin-bottom40 {
  margin-bottom: 40px;
}

.margin-bottom50 {
  margin-bottom: 50px;
}

.margin-h-neg-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.margin-v-neg-20 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.margin-t-neg-20 {
  margin-top: -20px;
}

.margin-b-neg-20 {
  margin-bottom: -20px;
}

.padding-bottom0 {
  padding-bottom: 0px !important;
}

.top0 {
  top: 0px !important;
}

.maxwidth65 {
  max-width: 65% !important;
}

.margin-both {
  margin-left: 10px;
  margin-right: 10px;
}

.multiple-files-manual {
  margin-left: 10%;
}

.multiple-files-no-manual {
  margin-left: 25%;
}

.no-multiple-files-manual {
  margin-left: -10%;
}

.no-multiple-files-no-manual {
  margin-left: 10%;
}

.reduce-padding {
  padding: 5px 20px 0px 20px !important;
}

.padding-bottom0 {
  padding-bottom: 0px;
}

.margin-auto {
  margin: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.margin-side-auto {
  margin: 0px auto;
}

.width-100 {
  width: 100px;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.contain-width {
  width: 100%;
}

/* Appknox specific */
.appknox-logo {
  margin-top: 13px;
}

.appknox-logo img {
  max-width: 56%;
  max-height: 9em;
  margin: auto;
}

.font-size12 {
  font-size: 12px;
}

button,
.button {
  border-radius: 5px;
  .fa {
    font-size: inherit;
  }
}

button {
  &.no-border {
    border: none;
  }

  &.is-icon {
    font-size: 16px;
    padding: 0.3em 0.6em;
  }
}

.width100 {
  width: 100px;
}

.black-text {
  color: #323232;
}

.red-text {
  color: $red;
}

.default-text {
  color: $default;
}

.upper-nav {
  position: relative;
  background: white;
  box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.3);
}

.scan-status {
  li {
    .fa-check {
      color: #80c081;
    }

    .fa-times {
      color: #ef4836;
    }
  }
}

.float-right {
  float: right;
}

.current-user {
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 50px;
}

.logo-img {
  margin: 0 auto;
  width: 100%;
}

.edit-button {
  margin-left: 20px;
  position: relative;
  top: 5px;
  .fa-check {
    color: $green;
  }
  .fa-times {
    color: $red;
  }
}

.edit-input {
  max-width: 30%;
}

input {
  display: block;

  &::placeholder {
    font-style: italic;
    opacity: 0.5;
    color: lighten($color-table, 30%);
  }
}

.textarea-input {
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  color: $color-table;
  display: inline-flex;
  justify-content: flex-start;
  padding: 0.3em 0.6em;
  box-shadow: inset 0 1px 2px rgba(107, 107, 107, 0.1);
  max-width: 100%;
  width: 100%;

  &::placeholder {
    color: lighten($color-table, 30%);
  }
}

canvas.other-devices {
  margin-top: 30px;
}

.title-tag {
  background-color: #5bc0de;
  border-radius: 0px;
  color: #fff;
  align-items: center;
}

.login-phone-image {
  img {
    width: 70%;
  }
}

.box-section {
  margin-top: 20px;
  border-radius: 5px;

  form {
    padding: 10px;
    font-size: 16px;

    input {
      margin-top: 10px;
    }
  }

  .plan-text {
    text-align: center;
    margin-top: 20px;
    color: lighten($default, 20%);
  }

  .choose-units {
    padding: 20px 50px 0 50px;
    text-align: center;

    .column {
      margin-top: 2px;

      h3 {
        border: 2px solid lighten($default, 30%);
      }
    }
  }
}

.plus-icon {
  background-color: lighten($success, 5%) !important;
  color: $white;
  border-radius: 50% !important;
  font-size: 20px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken($success, 5%);
    border-color: #80c081 !important;
  }
}

.minus-icon {
  background-color: lighten($critical, 5%) !important;
  border-color: lighten($critical, 5%) !important;
  color: $white;
  border-radius: 50% !important;
  font-size: 20px;
  width: 35px;
  height: 35px;
  cursor: pointer;

  &:hover {
    background-color: darken($critical, 5%) !important;
  }
}

.box-sub-section {
  box-shadow: 0 0 6px #ccc;
  margin-bottom: 0px !important;
  margin-top: 20px;
  height: 50px;
}

.box-section-content {
  margin-top: 10px;
  line-height: 20px;
  padding: 10px;
  font-size: 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pricing-section-content {
  line-height: 30px;
}

.invoice-overview {
  flex: none;
  width: 95%;
  margin: 10px auto;
  border: 1px solid #a9a9a9;
}

.horizontal-seperator {
  margin: 30px 0px;
  border-top-color: #dcdcdc;
}

.is-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.status-box {
  color: #31708f;
  margin-top: 10px;
  box-shadow: 0px 2px 2px #888, 0px -2px 2px #888;
  padding: 5px 5px;
}

// TODO: Remove this when Bohemia is updated
select {
  appearance: button;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  user-select: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAeCAYAAAAo5+5WAAAAdUlEQVRIx2MQllCs+P//vwcQM1ATM4waPGrwqMEjzmBSweAxGMgu+k8YFJHjYsvFixfvwWUiSA6khtygsFmxYsV+dENBYiA5SsPYfvXq1QdghoLYIDFqRZ7jxo0bD4MwiE3tVOECxYM0uVHd4NFCaNTgQW4wAKpugq+9Zk7QAAAAAElFTkSuQmCC),
    -webkit-linear-gradient(#fafafa, #f4f4f4 40%, #e5e5e5);
  background-position: 97% center;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  font-size: inherit;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 175px;
}

.file-input {
  position: fixed;
  top: -100px;
}

.input-file {
  padding: 0.45em;
}

.or {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    height: 0.1em;
    background: lighten($color-table, 58%);
    width: 47%;
    position: absolute;
    left: 0;
  }

  &::after {
    content: '';
    height: 0.1em;
    background: lighten($color-table, 58%);
    width: 47%;
    position: absolute;
    right: 0;
  }
}

.not-found {
  text-align: center;
  margin-top: 50px;

  i {
    margin-top: 7px;
    margin-right: 5px;
  }
}

.implication-list {
  margin-left: 20px;
  list-style-type: circle;
}

.scrollable-box {
  display: inline-block;
  max-width: 30vw;
  min-width: 100%;
  overflow: scroll;
  text-overflow: clip;
  max-height: 300px;
  min-height: 50px;
  padding: 9.5px;
  margin: 10px 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pre-code {
  background-color: #f5f5f5;
}

.user-info {
  margin: 5px 5px;
  padding-right: 10px;
  border-right: 2px solid #888;
}

.user-box {
  margin-top: 8px;
  border: 2px solid #888;
  padding: 3px;
}

.text-centre {
  text-align: center;
}

.text-right {
  text-align: right;
}

.vertical-align-center {
  display: flex;
  align-items: center;
}

.vertical-align-top {
  display: flex;
  align-items: flex-start;
}

.vertical-align-stretch {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: stretch;
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.align-both {
  display: flex;
  justify-content: space-between;
}

.align-both-centered {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-center {
  display: flex;
  justify-content: center;
}

.strip {
  margin: -25px -25px 10px -25px;
  background-color: #80c081;
  height: 50px;
  color: white;
}

.recover-form {
  width: 20% !important;
}

.account-overview {
  padding: 5px 30px;
  font-weight: bolder;
}

.account-overview-details {
  padding: 5px 45px;
  font-size: initial;
}

.modal-footer {
  margin-top: 20px;
}

.delete {
  margin-left: 20px;
}

.form-spacing {
  input,
  button {
    margin-top: 10px;
  }
}

div[class^='_c-notification__container'] {
  z-index: 2000 !important;
}

.is-progress {
  border: 1px solid #ffe0dd;
}

.padding-10 {
  padding: 10px !important;
}

.capitalize-text {
  text-transform: capitalize;
}

.uppercase-text {
  text-transform: uppercase;
}

.lowercase-text {
  text-transform: lowercase;
}

.copy-icon {
  font-size: 16px;
}

.risk-icons {
  font-size: 14px;
}

.fa-font-size-small i {
  font-size: 13px !important;
  margin-top: 6px;
}

.fa-font-size i {
  font-size: 15px !important;
  margin-top: 5px;
}

.columns-main-container {
  margin-top: 0px !important;
}

.is-two-third {
  width: 66.67%;
}

.box-container {
  padding: 5px 20px 20px 20px;
  border-bottom: 3px solid #f0f0f0;
  margin-left: -9px;
  margin-right: -9px;
  margin-bottom: 10px;
}

.box-container-w-b {
  @extend .box-container;

  border-bottom: unset;
}

.divider {
  height: 0;
  width: 100%;
  border-bottom: 3px solid #f0f0f0;
}

.project-container {
  margin-top: 10px;

  .project-loading-container {
    margin: 10% auto 0 auto;
  }
}

.top-nav {
  --top-nav-icon-color: #e5e5e5;
  --top-nav-icon-color-hover: #fff;
  width: 100%;
  margin-top: -10px;
  height: 50px;
  // background-color: #424651
  background: darken($sidebar-bg, 3%);
  margin-left: 0px;
  // border-left: 1px solid #ccc
  position: fixed;
  z-index: 11;

  .scan-text {
    font-weight: lighter;
    margin-top: 5px;
    margin-left: 1.5em;
    color: #fff;
    padding: 10px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .project-count {
    padding: 3px;
    min-width: 120px;
    margin-top: 3px;

    a {
      font-size: 14px;
      border-radius: 5px;
      padding: 3px 10px;
      color: #fff;
    }
  }

  .top-icons {
    margin-right: 15%;
    margin-top: -5px;

    a {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #e5e5e5;
      margin: 10px 10px;

      .sidebar-icons {
        margin-top: 1px;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}

.side-menu {
  background-color: $sidebar-bg;
  z-index: 13;
  box-shadow: none;

  &-fixed {
    padding-top: 1em;
    box-sizing: border-box;
    position: fixed;
    width: 16.67%;
    height: 100%;
    top: 0;
    background-color: var(--org-side-menu-bg-color);
    box-shadow: 1px 0px 8px -1px rgba(0, 0, 0, 0.3);
  }

  .box {
    background-color: #f0f0f0;
    box-shadow: none;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 5px;

    .card {
      background-color: lighten(#424651, 70%);
      color: $default;
      border-radius: 50px;
      max-width: 100px;
      margin: auto;
      padding: 5px;
    }
  }

  .menu {
    position: fixed;
    width: 16%;
    margin-top: 20px;

    .container {
      margin: 0px 5px !important;
    }
  }

  .appknox-logo {
    width: 100%;
    margin-bottom: 1em;
  }
}

.menu-list {
  .menu-list-title-beta {
    margin-left: 0.15em;
  }

  .menu-list-link {
    padding: 0.4em 1em;
    color: lighten($sidebar-bg, 45%);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      color: lighten($sidebar-bg, 65%);
      background-color: lighten(#424651, 3%);
      font-weight: 500;
    }

    &.active {
      color: lighten($sidebar-bg, 65%);
      background-color: lighten($sidebar-bg, 5%);
    }

    .menu-list-title-container {
      display: flex;
      align-items: center;

      .menu-list-icons {
        color: lighten($sidebar-bg, 25%);
        margin-right: 0.3em;
      }
    }
  }
}

.bg-color-container {
  background-color: #424651;
}

.theme-dark {
  .bg-color-container {
    background-color: #424651;
  }
}

.theme-light {
  .bg-color-container {
    background-color: #ffffff;
  }

  .side-menu {
    background-color: #ffffff;

    .menu-list a {
      color: #6b6b6b;

      &:hover,
      &:active,
      &:focus {
        color: #414651;
        background-color: lighten(#ededed, 3%);
      }

      &.active,
      &.active:hover,
      &.active:active,
      &.active:focus {
        background-color: #ededed;
        color: #414651;
      }
    }

    .menu-label {
      background-color: lighten(#ededed, 3%);
      color: #6b6b6b;
    }
  }

  .auth-main-container {
    background-color: #ffffff;

    .has-text-centered h6 {
      color: #6b6b6b;
    }
  }

  .badge-square.is-sidebar {
    background: #9a9fab;
    color: #ffffff;
  }

  .top-nav {
    --top-nav-icon-color: #7c7f88;
    --top-nav-icon-color-hover: #28282b;

    background: #f3f3f3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .scan-text {
      color: #7c7f88;
    }

    .top-icons a {
      color: #7c7f88;

      &:hover {
        color: #28282b;
      }

      &.button {
        border-color: #7c7f88;

        &:hover {
          border-color: #28282b;
        }
      }
    }
  }
}

.scroll-down {
  width: 14%;
  position: fixed;
  top: 510px;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.input {
  border-color: #e6e6e6 !important;
}

.input:hover {
  border-color: #b3b3b3 !important;
  box-shadow: 0 0 1px #808080;
}

.card-form {
  width: 40%;
  margin-left: 30%;
  margin-top: 10px;
}

.copy-password {
  cursor: pointer;
}

.api-scanning {
  .fa-exclamation-triangle.exclamation-warning {
    margin-top: 0;
    vertical-align: baseline;
    color: #f5d76e;
  }
}

.api-scanning {
  .no-data {
    color: #404040;

    .column {
      &:first-child {
        background-color: #f5d76e;
      }

      &:nth-child(2) {
        border-width: 2px 2px 2px 0px;
        border-style: solid;
        border-color: lighten($default, 50%);
      }
    }
  }
}

.api-scanning {
  .warning-band {
    // background-color: #F5D76E
    padding: 0px 10px;
    color: #404040;

    .column {
      &:first-child {
        background-color: #f5d76e;
      }

      &:nth-child(2) {
        border-width: 2px 2px 2px 0px;
        border-style: solid;
        border-color: lighten($default, 50%);
      }
    }
  }

  .fa.fa-trash-o {
    color: $red;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  .position-icons {
    margin-top: 18px !important;
  }

  .edit-filter {
    padding-top: 15px;
  }

  button.modal-button {
    min-width: 70px;
  }

  form {
    p.control {
      .column:nth-child(1) {
        display: flex;
        justify-content: flex-end;
      }

      .column:nth-child(2) {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  .start-scan {
    width: 150px;
  }

  ul {
    li {
      margin-top: 10px;
    }
  }
}

.overflow.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 155px;
}

.hide-overflow {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    z-index: 1;
    overflow: visible;
  }
}

.hide-overflow.package-name {
  &:hover {
    z-index: 1;
    overflow: hidden;
  }
}

@media screen and (min-width: 1380px) {
  .hide-overflow.package-name {
    max-width: 250px;
  }
}

@media screen and (max-width: 1380px) {
  .hide-overflow.package-name {
    max-width: 200px;
  }
}

.wrap-text {
  word-break: break-word;
}

.app-icon {
  margin-top: 10px;
}

.overflow-hidden {
  @extend .hide-overflow;

  max-width: 100px;
}

.hidden-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-icon {
  position: absolute;
  left: 85%;
}

.error-page {
  margin-top: 150px;

  h1,
  h2,
  div {
    display: flex;
    justify-content: center;
  }

  h1 {
    font-size: 85px;
  }
}

.package-desc {
  margin-right: 0px;
  margin-top: 0px !important;

  table {
    width: 80%;
    font-size: 13px;
    margin-top: 10px;

    tr {
      td {
        text-align: center;
        padding: 2px;
        border: 2px solid lighten($default, 45%) !important;
        vertical-align: middle;
      }
    }
  }

  table.api-scan-status {
    tr {
      td {
        padding: 7px;
      }
    }
  }
}

.personal-token-component {
  @extend .namespace-component;

  table.token-table tr {
    td {
      font-size: 13px;
    }

    td,
    th {
      text-align: center !important;
    }
  }
}

.namespace-component {
  .input-column {
    max-width: 250px;
  }

  input.input {
    width: 300px;
    margin-left: -10px;
    margin-top: 10px;
  }

  button {
    margin-top: 10px;
  }

  table {
    @extend .api-filter-table;

    width: 230px !important;
    margin-top: 10px !important;
    max-width: auto;
    margin-left: -10px;

    tr {
      td,
      th {
        text-align: left !important;
      }
    }
  }
}

.api-filter-table {
  width: 370px;
  margin-top: 20px;

  .edit-form-input {
    width: 80%;
    height: 25px;
  }

  tr {
    th {
      border: none;
      font-size: 12px;

      &:last-child {
        text-align: right;
      }
    }

    td {
      border: none;
      background-color: #f2f2f2;
      padding-left: 20px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        text-align: right;
        padding-right: 30px;
      }
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  .fa {
    font-size: 16px;
  }

  .fa-trash-o {
    color: $critical;
  }
}

.device-table {
  width: 30%;

  tr {
    td {
      border-color: #e5e5e5;

      &:first-child {
        color: #000;
      }
    }
  }
}

.report-preferences {
  .question {
    .column:first-child {
      position: relative;
      top: 10px;
    }
  }
}

.invoice-table {
  table {
    tr {
      th {
        background-color: lighten(#424651, 5%);
        color: #fff;
      }

      th,
      td {
        text-align: center;
        border: 1px solid #b8b8b8;
        padding: 8px 0px;
        vertical-align: middle;
      }
    }
  }
}

.invoice-list-container {
  padding: 10px 100px;
}

.box-section {
  button {
    background-color: #80c081;
    border-color: #80c081;
    color: white !important;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  button:hover,
  button:focus {
    background-color: darken(#80c081, 10%);
    border-color: darken(#80c081, 10%);
  }
}

.card-container {
  border: 5px solid #80c081;
  padding: 25px;
  margin: 25px;
}

.card-details {
  form {
    .card-form-header {
      @extend .strip;
      @extend .align-middle;

      margin: 0px;
      background-color: #a9a9a9;
    }

    .columns {
      margin-left: -1px;

      .column:first-child {
        margin-left: -10px;
      }

      .column:nth-child(2) {
        margin-left: 10px;
      }
    }

    .control {
      border-bottom: 1px solid #808080;
      margin-left: 10px;
      margin-right: 10px;

      .input {
        border: 0px solid black !important;
        box-shadow: none;
      }

      button {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    .control.last-child {
      border-bottom: none;
    }
  }
}

.card-header {
  .pricing-title {
    align-items: center;
    justify-content: center;
  }
}

.auto-adjust {
  button {
    width: 100px;
    margin: auto;
  }
}

.hideOrShow {
  cursor: pointer;
}

.os-icon {
  margin-left: 20px;
  max-width: 20px;
}

.card-icon {
  margin-top: 15px;
}

.file-card {
  .card-header {
    background: darken($color-lightgray, 0.5%);
    padding: 0.4em 0 0.5em 0.4em;
    box-shadow: none;
  }

  .card-content {
    padding: 0px;

    .content {
      .columns.is-reverse {
        margin-left: 40px;
      }
    }
  }
}

.app-information {
  background: lighten($color-lightgray, 2%);
  border-bottom: 1px solid lighten($color-lightgray, 0.5%);
  padding: 0.2em 0.2em 0.15em 0.8em;
}

.file-overview-scan-status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .scan-status-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;

    &-label {
      padding-left: 0.3em;
      font-size: 0.83em;
      line-height: 1.6;
      display: flex;
      align-items: center;
    }

    &-icon {
      display: flex;
      align-items: center;

      &-done {
        color: $success;
      }

      .fa {
        font-size: 0.83em;
        line-height: 1.4;
      }
    }
  }
}

.compare-container {
  padding: 0px 5px;
}

.file-card,
.box-section,
.team-overview-card {
  box-shadow: 0px 0px 6px #ccc;

  &:hover {
    box-shadow: 1px 1px 20px #999;
    z-index: 1;
  }
}

.project-specific {
  .column {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .card-button {
    margin-top: 10px;
    margin-right: 10px;
  }

  .card-button:nth-child(1) {
    margin-left: 30px;
  }

  .card-button:nth-child(2) {
    margin-left: -20px;
  }
}

.file-hide-overflow {
  margin-top: -15px;
}

.more-details {
  margin-left: 10px;
  color: $info;
  font-size: 18px;
  margin-top: 1px;
}

.file-buttons {
  margin-top: 7px;

  button {
    &:nth-child(1) {
      margin-left: 20px;
    }

    &:nth-child(2) {
      margin-left: 10px;
    }

    &:last-child {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.file-icon {
  margin-top: -5px;
  margin-left: 5px;
  max-width: 70px;
}

.user-roles-table {
  width: 520px;

  .tag {
    color: #fff;
    background-color: $default;
  }

  .columns {
    .column {
      text-align: center;
      padding: 2px 10px;
    }
  }

  .table-head {
    color: #000;
  }

  tr {
    th {
      border: none;
      font-size: 12px;
      background-color: #ccc;
      color: #000;
      text-align: center;

      &:last-child {
        padding-left: 80px;
      }
    }

    td {
      border: none;
      background-color: #e5e5e5;
      padding-left: 20px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        margin-left: 30px;
        text-align: right;
        padding-right: 60px;
        vertical-align: middle;

        .tag {
          margin-right: -15px;
        }
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.manual-scan-modal {
  ul {
    li {
      margin-top: 10px;

      a {
        color: $default;
      }
    }
  }

  .modal-sub-container {
    border-radius: 3px;
    margin-top: 10px;
    padding: 10px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
  }

  .modal-card {
    width: 600px;
    height: 580px;

    li {
      background-color: #f2f2f2;
      color: #000;

      a {
        .column:first-child {
          margin-left: 10px;
        }

        .column:last-child {
          margin-right: 10px;
        }
      }
    }

    select {
      text-align: center;
      width: 210px;
    }

    .input {
      width: 210px;
    }

    .text-area {
      width: 300px;
      height: 100px;
    }

    label {
      margin-right: 35px;
      color: $default;
    }

    .no-border-column {
      border-bottom: none !important;
    }

    .user-roles {
      .input {
        margin-top: -20px;
      }

      label {
        position: relative;
        top: -20px;
      }

      .columns {
        border-bottom: none;

        &:last-child {
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }

    .columns {
      margin-top: 0px;
      border-bottom: 1px solid #e5e5e5;
      align-items: center;

      .column {
        &:first-child {
          font-size: 14px;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.card-button,
.hideOrShow,
.modal-footer {
  .card-footer-item {
    padding: 1px;
    margin-left: 50px;
  }

  button {
    border-radius: 5px;
    margin-bottom: 5px;
    border-color: rgb(254, 77, 63) !important;
    color: rgb(254, 77, 63);
  }

  button:hover,
  button:active,
  button:focus {
    background-color: #fe4d3f;
    color: #fff;
  }
}

.card-header-icon {
  cursor: default;

  img {
    height: 50px;
    width: 50px;
    background-image: none;
    overflow: hidden;

    &:before {
      content: '.';
      font-size: 1000px;
    }
  }
}

b.bold {
  font-weight: bold;
  color: black;
}

.condition-text-small {
  font-weight: bold;
  color: black;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 0px;
}

.warning-modal {
  width: 500px;
}

.modal-small {
  width: 80%;
  @media (min-width: 500px) {
    width: 36em;
  }
}

.modal-medium {
  width: 80%;
  @media (min-width: 500px) {
    width: 40em;
  }
}

.condition-text {
  margin-top: -7px;
  margin-right: 2px;
}

.show {
  display: block;
}

.edit {
  display: block;
}

.save {
  display: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.hide {
  display: none;
}

.details {
  padding: 2px 3px;
  color: #808080;
  max-height: 25px;
}

.progress-wrap.progress.is-success {
  border: 1px solid #80c081;
  background-color: #80c081;
}

.card-content {
  .chartjs-render-monitor {
    max-width: 320px !important;
  }
}

.is-progress {
  .tag {
    border: 1px solid #808080;
  }
}

.message-body {
  border-color: #f6f8fa !important;
  border-width: 0px 2px 2px 2px !important;
}

.icon-effect {
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  width: 29px;

  &:hover {
    background-color: grey;
    color: #fff;
    border-radius: 50%;
  }
}

.message-header {
  border-radius: 5px 0px 0px 5px;
  border-width: 0px !important;
  padding: 4px 10px;
  background-color: #f6f8fa;
  border-color: #f6f8fa;

  .severity-tags {
    width: 11.8em;
  }

  h6 {
    font-size: 16px;
  }

  .columns {
    margin-top: 0px;
  }
}

.message .message-header {
  &.is-progress {
    border-color: rgb(255, 224, 221);
    border-width: 1px 1px 1px 5px;
    border-style: solid;
  }

  &.is-untested {
    border-color: #f5f5f5;
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    background-color: #f5f5f5;
  }

  &.is-errored {
    border-color: rgb(255, 224, 221);
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    background-color: lighten(rgb(255, 224, 221), 2%);
  }
}

.vuln-header {
  border-width: 1px 1px 1px 5px;

  .column {
    padding: 1px 10px;
  }

  .tag {
    cursor: text;
    margin-top: 3px;
    font-size: 10px;
    border-radius: 5px;
    height: 20px;
  }

  .seperator {
    font-size: 18px;
    margin-top: 0px;
    color: #ccc;
  }

  small {
    margin-top: 5px;
  }
}

.mp-minus + .message-meta-data {
  display: none;
}

.finished-scan {
  background-color: $green !important;
  border-color: $green !important;
}

.unfinished-scan {
  background-color: lighten($red, 3%) !important;
  border-color: lighten($red, 3%) !important;
}

.scan-tag {
  background-color: $default;
  color: #fff;

  .fa {
    font-size: 12px;
    margin-left: 4px;
    margin-right: -4px;
  }
}

.status-tag {
  color: $default;
}

table.vuln-table {
  th,
  td {
    max-width: 130px;
    text-align: center;
  }
}

.vulnerability-types {
  text-align: center;

  h4 {
    background-color: rgb(239, 72, 54);
  }
}

ul.scan-table {
  li {
    width: 130px;
    padding: 11px 5px;
  }

  h5 {
    margin-left: 10px;
  }
}

ul.risk-table {
  h5 {
    margin-left: 15px;
    margin-bottom: -5px;
    margin-top: 5px;
  }

  li {
    font-size: 13px;
    padding: 1px;
    width: 120px;
    margin-left: 15px;
    margin-top: -20px;
  }

  .risk-values {
    margin-top: 1em;
    margin-left: -10px;
    line-height: 1.2;

    .columns {
      .column {
        margin-top: 5px;
        padding: 3px;
      }

      .column:nth-child(2) {
        text-align: center;
        margin-left: -20px;
      }
    }
  }

  li:before {
    font-size: 30px;
    position: relative;
    top: 4px;
  }
}

.progress-wrap {
  width: 60%;
  border-radius: 10px !important;
}

.button-width {
  .column {
    text-align: center;

    button {
      padding: 5px;
      line-height: 0px;
    }
  }
}

.app-details-card {
  .card-header {
    height: 40px;
  }

  .card-content {
    height: 220px;

    .columns {
      .column {
        padding-top: 10px;
        margin-top: -10px;
      }
    }
  }

  .card-button button {
    margin-top: -5px;
  }
}

.scan-status-card {
  .warning-band {
    .columns .column {
      height: 100px !important;
    }
  }

  .info-band {
    .columns .column {
      height: 65px !important;
    }
  }

  .card-header {
    height: 40px;
    h5 {
      margin-top: 7px;
    }
  }

  .card-content {
    height: 100px;

    .columns {
      .column {
        height: 40px;
        .progress-wrap {
          margin-top: 50px;
        }
      }
    }
  }
}

.mfa {
  .fa-check {
    color: $green;
  }

  .fa-times {
    color: $red;
  }
}

.qr-code {
  margin-top: -20px;
  margin-left: 95px;
}

.otp-apps {
  font-size: 12px;
}

.fa-upload {
  font-size: 14px;
  vertical-align: middle;
  margin-right: 1px;
  margin-top: -3px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-25 {
  font-size: 25px;
}

.card-button {
  .fa-download,
  .fa-cog,
  .fa-trash-o {
    font-size: 14px;
    vertical-align: middle;
  }
}

.fa-exchange,
.fa-navicon {
  font-size: 12px;
  vertical-align: middle;
}

.cvss-box {
  border-radius: 5px;
  margin: 10px auto;
  height: 100px;
  display: block;
  padding-right: 30px;

  h1 {
    padding-top: 30px;
  }
}

.team-container {
  padding: 0px 100px;
}

.table-container {
  @extend .align-center;
}

.finding-list-table,
.attachments-list-table {
  min-width: 600px;

  @extend .api-filter-table;

  tr {
    td {
      text-align: center;
      min-width: 100px;
      vertical-align: middle;

      &:last-child {
        text-align: center;
      }
    }

    th {
      text-align: center;

      &:last-child {
        text-align: center;
      }
    }
  }
}

.security-list-table {
  @extend .api-filter-table;
  width: 100%;

  th {
    text-align: center;
  }

  tr {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      white-space: nowrap;
    }

    td:nth-child(2n - 1) {
      background: #ececec;
    }
  }

  .button-small {
    font-size: 0.8em;
    height: auto;
    padding: 0.3em 0.4em;
  }
}

.file-action-container {
  margin: auto;
  width: 90%;

  @media (min-width: 1200px) {
    width: 70%;
  }
}

.attachments-list-table {
  margin-left: 0px !important;
  min-width: 680px;

  .attachment {
    span {
      max-width: 400px;
      word-wrap: break-word;
    }
  }
}

.finding-list-table {
  min-width: 98%;
  margin-left: 10px;
  margin-top: 0px;

  tr {
    td:nth-child(1) {
      text-align: left;
    }

    td:nth-child(2) {
      max-width: 500px;
      text-align: left;
      padding: 10px 5px;
      word-wrap: break-word;
    }

    td:nth-child(3) {
      padding: 5px;

      button {
        margin-top: -10px;
      }
    }

    textarea::placeholder {
      color: lighten($default, 30%);
    }
  }

  i {
    cursor: pointer;
  }
}

.edit-analysis-box {
  flex: none;
  width: 70%;

  .marked-analysis {
    width: 90px;

    .edit-marked-analysis {
      height: 25px;
      line-height: 18px;
    }
  }
}

.attachments-list-table {
  margin-left: 27px;
  margin-top: 0px;
}

.team-container.box-container {
  padding-bottom: 20px;
}

.org-tab-content-container {
  padding: 0 1em 5em;
}

.org {
  &-settings-subtitle {
    font-size: 1.35em;
    font-weight: bold;
    padding: 0.5em 0 0.8em;
    @extend .black-text;
  }

  &-header {
    margin: -10px -10px 10px -10px;
    background: darken(#f6f8fa, 1%);

    &-name {
      padding: 2em 0 1em;
    }

    .tabs.is-boxed {
      li.is-active a {
        padding-top: 5px;
        padding-bottom: 3px;
      }

      a {
        padding-top: 5px;
        border-top-width: 3px;

        i {
          margin-right: 0.5em;
          margin-left: 0.2em;
        }
      }
    }
  }

  &-container {
    max-width: 50em;
    margin: auto;
    padding-bottom: 5em;

    .no-hover-button {
      &:hover {
        background: none;
        color: darken($red, 20%);
      }
    }
  }

  &-view {
    background-color: #fafbfc;
    margin-left: -10px;
    margin-right: -10px;

    .column {
      padding-bottom: 0px;
    }
  }

  &-table {
    tr {
      border: 1px solid lighten(#eaecef, 2%);
    }

    td {
      font-size: 12px;
    }

    th {
      background-color: darken(#f6f8fa, 1%);
      color: darken(#586069, 1%);

      &.success {
        border-color: rgba($success, 0.5);
        background: rgba($success, 0.15);
      }

      &.critical {
        border-color: rgba($critical, 0.5);
        background: rgba($critical, 0.15);
      } // padding: 10px 0px
    }

    th,
    td {
      border-width: 0px;
      vertical-align: middle;

      &:first-child {
        text-align: left;
        padding-left: 1em;
      } // &:last-child
      //   text-align: right
      //   padding-right: 30px
    }

    .fa-times {
      color: #ef4836;
      cursor: pointer;
    }

    .remove {
      color: $critical;
    }

    .input-checkbox {
      margin-top: 2px;
    }
  }
}

.no-hover-button {
  &:hover {
    background: none !important;
    color: $primary !important;
  }
}

.copy-token {
  cursor: pointer;
}

.remove {
  cursor: pointer;
}

.table-h-bordered-gray {
  td,
  th {
    border-color: lighten(#eaecef, 2%);
  }

  th {
    color: darken(#586069, 1%);
  }
}

.table-bordered-gray {
  border-radius: 0.3em;

  tr,
  th,
  td {
    border-color: lighten(#eaecef, 2%);
  }

  th {
    background-color: darken(#f6f8fa, 1%);
    color: darken(#586069, 1%);
  }
}

.team-overview-card {
  header:nth-child(1) {
    background-color: lighten(#424651, 5%);
    color: $white;
  }

  .fa-times {
    color: #ef4836;
    cursor: pointer;
  }

  .card-header {
    margin-left: 0px;
    margin-right: 0px;

    .column {
      margin-top: 10px;
      border-right: 1px solid rgba(107, 107, 107, 0.1);
    }
  }
}

.invoice-table,
.token-table {
  .fa {
    font-size: 16px;
  }

  .fa-trash-o {
    color: $critical;
  }

  .copy-token {
    color: $info;
  }
}

.pending-invitation-table {
  margin: auto;
  width: 70%;
}

.team-card {
  min-height: 260px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h6 {
    border-bottom: 1px solid $grey;
    width: 60px;
    margin-left: 35px;
    margin-top: -20px;
  }

  select {
    width: 110px;
    margin-left: 15px;
    margin-top: 60px;
    font-size: 10px;
    height: 25px;
    line-height: 15px;
  }

  .team-header {
    margin-top: 5px;
  }

  .card-content {
    padding-top: 0px;
  }

  button {
    margin-top: -40px;
  }
}

.filter-list {
  width: 250px;

  .fa {
    color: $red;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}

.api-scanning {
  .api-filter {
    input {
      width: 100%;
    }

    button {
      margin-left: 0px;
    }
  }
}

.new-input-box {
  margin-top: -20px;
}

button.delete-button {
  border: none;
}

.bi-span {
  ul {
    list-style: disc;
    margin-left: 20px;
  }
}

.bullet-list {
  list-style-type: square;
}

.code-block {
  background: $color-lightgray;
  color: #4d525e;
  white-space: pre-wrap;
  padding: 1em;
  margin: 0.5em 0;
}

.tag {
  border: 1px solid transparent;

  &.delete-tag {
    background-color: $red;
    color: #fff;
  }

  &.is-errored {
    color: $red;
    border-color: rgba($red, 0.4);
  }

  &.is-waiting {
    color: rgba($color-table, 0.9);
    border-color: rgba($color-table, 0.2);
  }

  &.status-tag-0 {
    background-color: $red;
    color: #fff;
  }

  &.status-tag-1 {
    background-color: $default;
    color: #fff;
  }

  &.status-tag-2 {
    @extend .is-progress;
  }

  &.status-tag-3 {
    color: $success;
  }

  &.edit-tag {
    background-color: grey;
    color: #fff;
  }

  &.threshold-tag {
    background-color: #007eff;
    color: #fff;
  }
}

.colored-box {
  margin: auto;
  cursor: pointer;
  width: 90px;
  border-radius: 5px;

  @each $name, $pair in $risk-names {
    &.is-#{$name} {
      background-color: nth($pair, 1);
      border: 4px solid nth($pair, 1);
      color: $white;
    }
  }

  &.is-progress {
    color: $default;
  }

  &.is-error {
    background: $primary;
    color: $white;
  }
}

.vuln-header {
  .scan-tag,
  .owasp-tag {
    background-color: $grey;
    color: #fff;
  }
}

.vuln-body {
  border-radius: 0px 0px 5px 5px !important;

  .risk-type-tag {
    margin: auto;
  }

  .scan-category {
    font-size: 14px;
    margin-top: 5px;

    span {
      margin-top: -2px;
    }
  }

  .owasp-category {
    font-size: 14px;
    margin-top: 5px;

    span {
      margin-top: -2px;
    }
  }
}

.mp-minus {
  border-radius: 5px 5px 0px 0px !important;
}

.feedback-button {
  margin-top: 20px;
  text-align: center;
}

.pending-invitation {
  .box-title {
    margin: 20px 0 20px 10px;
    border: 1px solid #000;
    width: 150px;
    text-align: center;
  }

  ul.invitation-list {
    margin-left: 30px;

    li {
      list-style-type: disc;
    }
  }
}

.no-project-container {
  padding: 150px 400px 70px 400px;

  .no-project {
    padding: 10px;
    border: 1px dashed $critical;
    border-radius: 10px;
    text-align: center;
  }
}

.repeat-icon {
  margin-left: 10px;
  margin-top: 15px;
  color: $red;
  cursor: pointer;
}

.device-modal {
  position: relative;

  .modal-card-head {
    border-bottom: 0px;
  }

  .vnc-modal-body {
    min-height: 582px;

    button {
      margin-top: 45px;
    }
  }
}

.tabs.is-boxed {
  li {
    min-width: 100px;

    &.is-active a {
      padding: 2px 10px;
      border-color: #f0f0f0;
      border-top: 3px solid #ef4836;
      color: #000;
    }
  }

  a {
    padding: 3px 10px;

    span.count {
      background-color: #e1e4e8;
      padding: 0px 5px;
      margin-left: 5px;
      border-radius: 60px;
      font-size: 11px;
    }

    i {
      margin-top: -2px;
      font-size: 14px;
    }

    &:hover {
      border-bottom-color: #f0f0f0;
      background: none;
    }
  }

  ul {
    border-color: #f0f0f0;
  }
}

.loading-container {
  margin: 20% auto;

  i {
    font-size: 87px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  div {
    font-size: 20px;
  }
}

.login-wrapper {
  h6 {
    margin-top: -15px;
    margin-bottom: 25px;
  }
}

.vnc-viewer {
  padding: 0px;

  .device-modal {
    margin: 0px;
  }
}

.marvel-device {
  .screen {
    box-shadow: none;

    .vnc-box {
      display: none;
    }
  }

  .top-bar {
    background: none;
  }
}

.modal.is-active {
  .marvel-device.ipad {
    margin-left: 0px;
  }

  .marvel-device.tablet {
    margin-left: 20px;
  }
}

.marvel-device.ipad {
  width: 282px !important;
  height: 385px !important;
  margin-left: -10px;

  .screen canvas.canvas {
    width: 282px !important;
    height: auto !important;
  }
}

.marvel-device.tablet {
  width: 320px !important;
  height: 480px !important;

  canvas.canvas {
    cursor: pointer !important;
    width: 300px !important;
    height: 480px !important;
  }

  .vnc-text {
    display: none;
  }
}

.marvel-device.iphone5s {
  width: 245px !important;
  height: 385px !important;
  padding: 88px 22px;

  .screen {
    width: 88%;
    margin-left: 6%;

    canvas.canvas {
      width: 216px !important;
      height: auto !important;
    }
  }

  .home {
    width: 50px;
    height: 50px;
    left: 52%;
  }
}

.marvel-device.nexus5 {
  width: 270px !important;
  height: 480px !important;

  .screen {
    background-color: #1e1e1e;

    .vnc-box {
      display: block;

      div div h6 {
        padding-top: 10px;
      }
    }
  }
}

.marvel-device.iphone5s,
.marvel-device.ipad {
  .screen {
    background-color: #1e1e1e;

    .vnc-box {
      display: block;
      margin: 50px 30px;
    }
  }

  .top-bar {
    background: black;
  }
}

.marvel-device.ipad {
  .vnc-box {
    div div h6 {
      padding-top: 35px;
    }
  }
}

.sidebar-icons {
  margin-top: 5px;
  margin-right: 4px;
  font-size: 18px;
}

.vnc-text {
  height: 100%;
  text-align: center;
  margin-top: 50%;

  .vnc-box {
    margin: 50px 60px;

    div {
      background-color: #fff;
      color: $default;
      height: 110px;
      margin: 20px;
      border-radius: 7px;

      i {
        font-size: 30px;
        margin-top: 20px;
      }

      h6 {
        margin-top: 10px;
      }

      div {
        h6 {
          padding-top: 18px;
        }
      }
    }
  }
}

button[disabled],
.input[disabled] {
  cursor: not-allowed;
  pointer-events: auto;
}

.button[disabled].ac-upload-app {
  opacity: 0.8;
}

button.is-progress:hover {
  background-color: white;
  color: #fe4d3f;
  border-color: #fe4d3f;
}

.url-filters {
  .columns {
    .column:nth-child(2) {
      button {
        margin-top: -50px;
        margin-left: -50px;
      }
    }
  }
}

.add-filter-list {
  border: 2px solid lighten($default, 40%);
  padding: 10px;
}

.js-duration-button.is-default:hover {
  background-color: lighten($red, 10%);
  border-color: lighten($red, 10%);
}

.box-section button:focus {
  background-color: #80c081;
}

.plan-durations {
  h4 {
    margin-left: 20px;
    margin-top: 10px;
  }

  .column {
    margin-left: -80px;
  }
}

.plan-list {
  padding: 0px 90px;

  .box-section {
    h2 {
      text-align: center;
      padding-top: 10px;
    }
  }
}

.payment-info-page {
  margin-top: 150px;

  h2,
  div,
  h5 {
    display: flex;
    justify-content: center;
  }

  h2 {
    font-size: 50px;

    i {
      padding: 8px;
      font-size: 50px;
      width: 70px;
      height: 70px;
      color: #fff;
      border-radius: 50px;
    }

    i.fa-check {
      background-color: $success;
    }

    i.fa-times {
      background-color: $critical;
    }
  }
}

.language-select {
  margin-top: 20px;

  select {
    width: 50%;
  }
}

.input-modal .modal-card {
  min-width: 25%;

  .input-width {
    width: 80%;
  }
}

.input-width {
  width: 50%;
}

.prompt-modal {
  .deleted-item {
    width: 50%;
    display: block;
  }
}

select {
  cursor: pointer;
  border-radius: 5px !important;
  color: $default !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAeCAYAAAAo5+5WAAAAdUlEQVRIx2MQllCs+P//vwcQM1ATM4waPGrwqMEjzmBSweAxGMgu+k8YFJHjYsvFixfvwWUiSA6khtygsFmxYsV+dENBYiA5SsPYfvXq1QdghoLYIDFqRZ7jxo0bD4MwiE3tVOECxYM0uVHd4NFCaNTgQW4wAKpugq+9Zk7QAAAAAElFTkSuQmCC),
    -webkit-linear-gradient(lighten($default, 55%), lighten($default, 55%) 40%, lighten($default, 55%)) !important;
  border: 1px solid lighten($default, 40%) !important;
}

select:focus {
  outline: none !important;
}

select:hover {
  box-shadow: 0px 0px 3px $default !important;
}

select:disabled {
  cursor: not-allowed;
  color: lighten($default, 30%) !important;
  border: 1px solid lighten($default, 45%) !important;
}

.modal-card {
  border-radius: 1px;
}

.modal-card-head {
  border-bottom: 0px;
  background-color: lighten(#424651, 5%);

  .modal-card-title {
    color: #fff;
    font-size: 20px;
  }

  .delete {
    background-color: #fff;

    &:before,
    &:after {
      background-color: #424651;
    }

    &:hover {
      background-color: darken(#fff, 5%);
    }
  }
}

.team-overview-card {
  a {
    color: #6b6b6b;
  }
}

.subscription-container {
  padding: 20px;
  margin: 20px auto;

  table {
    box-shadow: rgb(204, 204, 204) 0px 0px 5px;

    th {
      background-color: lighten(#424651, 5%);
      color: #fff;
    }

    td,
    th {
      text-align: center;
      border-width: 0px;
      border-bottom: 1px solid lighten($default, 30%);
      padding: 10px 60px;
    }

    td.td-heading {
      font-size: 14px;
      color: #000;
    }

    tr:last-child {
      td {
        border-width: 0px;
      }
    }

    button {
      margin: 10px auto 0px auto;
    }
  }
}

.scan-section {
  text-align: center;
  margin: auto;

  .box {
    padding-top: 37px;
    height: 140px;
  }
}

.all-inputs {
  padding: 10px 30px;

  .margin-top {
    width: 270px;
  }

  .ac-upload-app {
    width: 220px;
  }
}

.upload-app {
  margin-top: -10px;
}

.success-button {
  background-color: lighten($success, 2%);
  color: #fff !important;

  &:hover {
    background-image: lighten($success, 0%);
  }
}

.highlighted-button {
  background-image: linear-gradient(
    -180deg,
    lighten($critical, 5%) 0%,
    lighten($critical, 3%) 90%
  );
  color: #fff !important;

  &:hover {
    background-image: linear-gradient(
      -180deg,
      lighten($critical, 0%) 0%,
      lighten($critical, 0%) 90%
    );
  }
}

.risk-status-box {
  width: 300px;
  margin: 60px auto 10px auto;

  .columns {
    h5 {
      border-bottom: 2px solid $critical;

      &:last-child {
        margin-top: 20px;
      }
    }

    &:first-child {
      margin-top: -40px;
    }

    &:nth-child(2) {
      margin-top: 0px;
    }

    &:nth-child(5) {
      margin-top: 0px;
    }

    &:last-child {
      margin-top: 0px;
      margin-bottom: -20px;
    }
  }
}

.stat-heading {
  text-align: center;
  margin-top: 10px;
}

.user-info-popover {
  background-color: #424651;
  margin-right: 20px;
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  border-radius: 5px;
  color: #fff;

  .user-name,
  .user-email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user-name {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .user-email {
    padding-bottom: 10px;
    margin: 10px 0px 10px 0px;
    font-size: 11px;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }

  a {
    padding: 2px 10px;
    font-size: 12px;
    margin-right: 5px;
    color: #fff;
    border-radius: 5px;
    i {
      font-size: 16px;
      margin-top: 2px;
      margin-right: 5px;
    }
  }
}

.menu-label {
  text-align: center;
  margin-left: 0px;
  background-color: #424651;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 2px;
}
// move this to bohemia

.status-container {
  border: 1px solid #ccc;
  height: auto;
  border-radius: 5px;
  margin: 0px 10px;
  max-height: 200px;
  overflow: scroll;

  .status-list {
    background-color: #fff;
    margin: 15px 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;

    .no-reason {
      margin: -5px;
      padding: 5px;
      border-radius: 5px;
      text-align: center;
    }

    .status-details {
      margin: -5px -5px 5px -5px;
      border-radius: 5px 5px 0px 0px;
      color: #000;
      background-color: #ccc;
      text-align: center;

      &.is-critical {
        background-color: $critical;
        color: #fff;
      }

      &.is-danger {
        background-color: $critical;
        color: #fff;
      }

      &.is-warning {
        background-color: $warning;
        color: #fff;
      }

      &.is-info {
        background-color: $info;
        color: #fff;
      }

      &.is-success {
        background-color: $success;
        color: #fff;
      }

      &.is-progress {
        background-color: #fff;
      }
    }

    .no-reason {
      border-radius: 5px;
    }

    .status-reason {
      color: $default;
    }
    // end
  }
}

.click-here {
  margin-left: -130px;
  text-decoration: none !important;
  border: 1px solid #fff;
  padding: 0 5px;
  background-color: #fff;
  color: #000 !important;
  border-radius: 3px;
}

.new-users {
  font-size: 16px;

  .welcome-text {
    font-size: 24px;
  }

  .welcome-desc {
    margin-top: 20px;
  }

  .welcome-sub-desc {
    margin-top: 30px;
  }

  .welcome-footer-text {
    margin-top: 40px;
  }

  & + .column {
    margin-top: -60px;
  }
}

.chart-container {
  width: 50vw;
  margin: -10px 10px;
}

.stat-home-page {
  margin: -10px;
}

.scan-list {
  text-align: center;
  padding: 0.5em;
  margin: 0;
  border-bottom: 1px solid lighten($default, 50%);

  &:last-child {
    border-bottom: none;
  }
}

.main-box-container {
  margin: 20px 20px 0px 40px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  background-color: #fff;

  .box-content.scrolling-box {
    height: 16.5em;

    .box-lists {
      overflow: hidden;
      height: 16.5em;

      .box-list-items {
        border-bottom: 1px solid lighten($default, 50%);
        padding: 0.3em 0.8em;
      }

      &:hover {
        overflow-y: scroll;
      }
    }
  }

  .box-content {
    margin-top: 20px;
    height: 300px;

    h3 {
      margin-left: 20px;
      margin-bottom: 0px;
    }

    .tag {
      margin-left: 20px;
    }

    .chart {
      margin-top: -50px;
    }

    .chart-2 {
      margin-top: 10px;
    }
  }

  .box-main-heading {
    text-align: center;
    background-color: #efefef;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 10px;
    padding: 5px;
  }
}

.app-scanned {
  margin: 10px 80px;
}

.home-page {
  background-color: #fff;
  margin: 10px;
  position: relative;
  top: 10px;
  border-radius: 5px;
}

.stat-table-container {
  padding: 0px 10px;

  table {
    @extend .api-filter-table;
    width: 495px;

    tr {
      th,
      td {
        text-align: center !important;
      }
    }

    .tag {
      margin-left: 0px !important;
    }
  }
}

.scan-counts {
  margin-top: 30px;
  margin-bottom: 20px;

  .columns {
    background-color: #fff;
    padding: 10px;
    min-width: 200px;
    border-radius: 10px;

    i {
      margin-top: 25px;
    }

    h3,
    h5 {
      margin-left: 20px;
    }

    h5 {
      margin-top: -20px;
    }
  }
}

$check-circle-size: 1.5em;
$check-circle-false-color: lighten($color-text, 30%);
$check-circle-true-color: $success;

.check-circle {
  position: relative;
  display: flex;
  align-items: center;

  &-text {
    padding-left: $check-circle-size + $check-circle-size/2.5;
    word-wrap: break-word;
    width: 100%;
    font-size: 0.95em;
  }

  &-check {
    position: absolute;
    left: 0;
    height: $check-circle-size;
    width: $check-circle-size;
    border-radius: 0.3em;
    border: 2px solid $check-circle-false-color;

    &::after {
      content: '';
      position: absolute;
      background: transparent;
      width: $check-circle-size/2;
      height: $check-circle-size/3;
      left: $check-circle-size/5.5;
      top: $check-circle-size/5.5;
      border-width: 0 0 2px 2px;
      border-color: transparent;
      border-style: solid;
      transform: rotate(-46deg);
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .check-circle-check {
      background-color: $check-circle-true-color;
      border-color: $check-circle-true-color;

      &::after {
        border-color: white;
      }
    }
  }

  &-align-top {
    align-items: flex-start;

    .check-circle-text {
      padding-top: 0.1em;
    }
  }
}

@mixin toggle-knob($size, $on, $off) {
  position: relative;
  display: flex;
  align-items: center;
  height: $size;
  width: $size * 1.7;

  .knob {
    height: 100%;
    width: 100%;
    background: $default;
    border-radius: $size;

    &:before {
      position: absolute;
      content: $off;
      width: $size + 0.15em;
      height: $size;
      height: calc(100% - 0.6em);
      margin: 0.3em;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      transition: 0.4s;
      font-size: 0.8em;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input {
    display: none;

    &:checked,
    &:focus {
      & + .knob {
        background-color: $success;
      }
    }

    &:checked + .knob:before {
      content: $on;
      transform: translateX($size * 0.86);
    }

    &[disabled] + .knob {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.toggle-switch {
  @include toggle-knob(2.6em, '', '');
}

.toggle-switch-small {
  @include toggle-knob(1.8em, '', '');
}

.toggle-switch-on-off {
  @include toggle-knob(2.6em, 'ON', 'OFF');
}

.toggle-switch-small-on-off {
  @include toggle-knob(1.8em, 'ON', 'OFF');
}

.switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 38px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $default;
  transition: 0.4s;
  font-size: 8px;
  line-height: 30px;
  text-align: center;
}

.slider:before {
  position: absolute;
  content: 'HIDE';
  height: 30px;
  width: 30px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked,
input:focus {
  & + .slider {
    background-color: $success;
  }
}

input:checked + .slider:before {
  content: 'SHOW';
  transform: translateX(26px);
}

.yes-no-slider:before {
  content: 'NO';
}

input:checked + .yes-no-slider:before {
  content: 'YES';
}

.slider-nolabel:before {
  content: '';
}

input:checked + .slider-nolabel:before {
  content: '';
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.stat-container {
  margin-left: 15px;
}

.switch-input[disabled] + .slider {
  opacity: 0.7;
  cursor: not-allowed;
}

.not-found-container {
  text-align: center;
  background-color: #424651;
  height: 100vh;

  .not-found-section {
    width: 700px;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    position: relative;
    top: 150px;
    border-radius: 10px;

    i {
      margin-top: -3px;
    }
  }
}

$login-button-width: 100%;

.auth-main-container {
  height: 100vh;
  background-color: #424651;

  a img {
    margin-top: 50px;
    width: 250px;
  }

  .has-text-centered {
    h6 {
      color: #fff;
    }
  }

  .login-form {
    background-color: #fff;
    width: 100%;
    border-radius: 5px;

    h4 {
      padding-bottom: 0.5em;
    }

    h6 {
      margin-left: 25px;
    }

    p {
      text-align: center;

      i {
        margin: auto;
      }

      .input {
        max-width: 80%;
        margin: auto;
      }

      button {
        margin-top: 10px;
        width: $login-button-width !important;
      }

      .sso-button {
        margin-left: -32px;
        margin-top: unset;
        width: unset !important;
      }
    }

    &-sso {
      &-separator {
        height: 0.06em;
        width: 100%;
        background: lighten($default, 50%);
        margin-top: 0.7em;
        margin-bottom: 0.7em;
        position: relative;
      }

      &-or {
        text-align: center;
        background: white;
        width: 2.3em;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &-button {
        width: $login-button-width;
        display: block;
        margin: auto;
        background: #ececec;
        border-color: #ececec;

        &:hover,
        &:focus,
        &:active {
          background: darken(#ececec, 5%);
          border-color: darken(#ececec, 5%);
        }
      }
    }
  }
}

.top-columns {
  margin-right: 30px;
}

.login-button {
  .fa-font-size {
    i {
      margin-top: 5px !important;
    }
  }
}

.analysis-header {
  .column {
    &:first-child {
      margin-top: 10px;
      margin-left: 10px;
    }

    &:nth-child(2) {
      margin-top: 10px;
    }
  }

  .sort-icons {
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;

    &.fa-sort-up {
      margin-top: 5px;
    }

    &.fa-sort-down {
      margin-top: 0px;
    }
  }
}

.my-popper {
  display: none;
}

.popper--white {
  background-color: var(--popover-white-bg);
  border: 1px solid var(--popover-white-border-color);
  border-radius: 0.3rem;
  position: relative;
  padding: 1rem;

  div[x-arrow] {
    background-color: var(--popover-white-bg);
    border-bottom: 1px solid var(--popover-white-border-color);
    border-left: 1px solid var(--popover-white-border-color);
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: -1;
  }
}

.regulatary-section {
  .section {
    border-bottom: 1px solid #f5f5f5;
    margin: 10px -15px;
  }

  a:hover {
    color: darken($red, 20%);
  }
  // attachments
}

$attachment-icon-width: 4em;

.attachment {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  color: $text;
  margin-bottom: 1em;

  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }

  &::before {
    content: attr(data-type);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 0.8em;
    font-weight: bold;
    width: $attachment-icon-width;
    height: $attachment-icon-width / 1.4;
    text-align: center;
    background: $grey;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @each $extn, $color in $extensions-list {
    &[data-type='#{$extn}'] {
      &::before {
        background: $color;
      }
    }
  }

  &-label {
    max-width: 35em;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0.5em;
  }

  &-icon {
    position: absolute;
    right: 1em;
  }
}

.signup-container {
  padding: 2em 0;
  padding-bottom: 7em;
  display: flex;
  justify-content: center;
  background-color: #424651;
}

.edit-analysis {
  width: 30px !important;
  margin-top: 10px;
  margin-left: -10px;
  border-radius: 0px 5px 5px 0px;
  height: 35px;

  i {
    font-size: 18px;

    &:hover {
      color: $red;
    }
  }
}

.edit-analysis-modal {
  .box-container {
    margin-left: -20px;
    margin-right: -20px;
  }

  .marked-analysis-text {
    width: 25px;
    margin-left: 5px;
  }

  .marked-analysis-text2 {
    width: 40px;
    margin-left: 10px;
    margin-top: 5px;
  }

  .mark-text {
    width: 85px;
    margin-top: 5px;
  }

  .modal-card {
    width: 700px;
  }

  .mark-analysis-all {
    width: 200px;
  }

  select {
    width: 110px;
    text-transform: uppercase;
  }

  form {
    label {
      position: relative;
      top: -15px;
      left: 20px;
    }
  }

  .tag {
    margin-top: -2px;
    margin-left: 5px;
  }

  .edit-marked-analysis {
    height: 25px;
    line-height: 17px;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}

.device-modal {
  overflow: scroll;

  .modal-card-foot {
    border-top-width: 0px;
  }
}

.signup-form {
  background-color: #fff;
  width: 40em;
  border-radius: 5px;
  overflow: hidden;

  &.success {
    .register-form {
      display: None;
    }

    .register-confirm {
      display: flex;
    }
  }

  .register-form {
    padding-top: 0.6em;

    &-container {
      padding: 2em 1.6em;
    }

    &-footer {
      display: flex;
      justify-content: center;
      padding: 1.5em 1em 1.6em;
      border-top: 1px solid #efefef;
      background: lighten(#424651, 68%);

      &-link {
        margin-left: 0.5em;
      }
    }

    &-accept {
      span {
        margin-left: 0.7em;
      }
    }
  }

  .intl-tel-input {
    width: 100%;
  }

  .register-confirm {
    padding: 2em 1em;
    display: None;
    flex-direction: column;

    &-icon {
      font-size: 8em;
      margin: auto;
    }

    &-text {
      font-size: 1.2em;
      padding: 1em 1em 0;
    }
  }

  .is-success {
    color: $success;
  }

  .g-recaptcha {
    display: flex;
    justify-content: center;
  }

  .checkbox-field {
    display: inline-block;
  }
}

.user-edited-icon {
  margin-left: 5px;
}

.vulnerability-container {
  margin-top: 30px;
  width: 70%;
  margin-left: 10px;
}

.vuln-warning-band {
  background-color: lighten($red, 20%);
  padding: 10px;
  font-size: 16px;
  color: darken($red, 30%);
  width: 70%;
  text-align: center;
  border-radius: 5px;
}

.select-box {
  max-width: 110px;
}

.vuln-band {
  @extend .message-header;
  margin-top: 5px;

  .columns {
    .column {
      padding: 0px 10px;
    }
  }

  .checkbox-field {
    display: inline-block;
  }

  .risk-comment {
    margin-left: 0;
    background: darken(#f6f8fa, 3%);
    border: 1px solid darken(#f6f8fa, 6%);
    &-label {
      background: darken(#f6f8fa, 6%);
      color: $color-table;
      padding: 0 0.5em;
    }
  }
}

.device-modal-body {
  position: relative;
}

.dynamic-scan-on-vnc {
  .dynamic-scan-note {
    max-width: 170px;
    font-size: 12px;
    margin: auto;
  }

  .dynamic-scan-buttons {
    position: absolute;
    top: 16em;
    left: 0;
    right: 0;
    z-index: 3;
    text-align: center;

    .vnc-button {
      background-color: #fff;
      border-color: #fff;
      width: 160px;

      &:hover {
        background-color: $red;
        border-color: $red;
      }
    }

    .vnc-button[disabled] {
      opacity: 0.5;

      &:hover {
        color: #fff;
      }
    }

    .vnc-button-repeat {
      width: 30px;
    }

    .vnc-completed-button[disabled]:hover {
      background-color: #fff;
      border-color: #fff;
      color: $red;
      opacity: 0.8;
    }
  }
}

.notification-position {
  margin-left: -35vw;
  width: 400px;
  margin-bottom: 45vw;
  height: 50px;
  border-radius: 50px;
  background-color: $green;
  box-shadow: 0px 0px 10px lighten($green, 20%);

  ._c-notification__content_of6y9q {
    margin-top: 5px;
    font-size: 18px;
    color: #fff;
  }

  ._c-notification__icon_of6y9q {
    background-color: $green;
  }

  .fa-times {
    position: relative;
    top: 3px;
  }

  .fa-info-circle {
    position: relative;
    top: 7px;
    left: 8px;
  }
}

.copy-password-modal {
  .modal-card {
    table td {
      text-align: center;
      border: 2px solid lighten($default, 45%) !important;
      vertical-align: middle;
    }
  }
}

.compare-details {
  select {
    margin-left: 10px;
    margin-top: -7px;
    width: 100px;
  }
}

.file-id-container {
  text-align: center;

  .file-id-text {
    padding: 0.1em 0.5em 0.12em;
    text-align: center;
    font-weight: bold;
    font-size: 0.75em;
    line-height: 1.4;
    color: lighten(#000, 30%);
    border-radius: 2px;

    .file-in-active-icon {
      font-size: 14px;
    }
  }
}

.app-details-card {
  .file-id-container {
    margin-left: -30px;

    .file-id-text {
      margin-top: 20px;
    }
  }
}

.app-risk-info {
  margin-top: 0;
}

.compare-text {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
}

.arrow-left {
  display: inline;
  position: absolute;
  left: 100%;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;

  i.fa {
    font-size: 26px;
  }
}

.device-preferences {
  select {
    width: 170px;
  }

  .fa-font-size {
    margin-top: 5px;

    i {
      margin-top: 2px;
    }
  }

  .fa-check {
    color: $green;
  }

  .fa-times {
    color: $red;
  }
  // .project-preference, .project-preference-component
  //   padding-bottom: 0px!important
  //   select
  //     width: 170px
  //
  //   .fa-check
  //     color: $green
  //   .fa-times
  //     color: $red

  // .project-preference-component
  //   border: 2px solid lighten($grey, 40%)
  //   padding: 10px
  //   .columns .column
  //     height: auto!important
}

.request-status {
  margin-top: -10px;
  padding: 0px 10px;
  min-height: 45px;
  position: relative;
  top: 12px;
}

.back-link {
  width: 600px;
}

.list-heading {
  margin-left: -100px;
}

.border-bottom-container {
  border-bottom: 2px solid #f0f0f0;
  margin: 10px -20px 20px -20px;
}

.analysis-box {
  padding-top: 0px;

  textarea {
    height: 100px;
  }
}

.cvss-score-section {
  margin-top: 50px;
  text-align: center;

  .column {
    margin: auto;
  }
}

.input-checkbox {
  margin-top: 2px;

  .checkbox {
    font-size: 16px;
  }

  span {
    position: relative;
    top: 1px;
  }
}

.security-link {
  margin-top: 4px !important;

  &:hover,
  &:focus {
    border-color: #fff;
  }
}

.security-dashboard {
  .appknox-logo {
    margin-top: 18px;
    width: 12em;

    a {
      pointer-events: none;
    }
  }

  .top-columns {
    margin-right: 0;
  }

  .top-nav {
    height: 60px;
    margin-left: 10px;
    border-left-width: 0px;

    .top-icons {
      margin-top: 10px;
      margin-right: 0;
    }
  }

  & + div {
    .tabs {
      margin-top: 60px !important;
    }

    .paginate {
      padding: 10px 200px;
    }
  }
}

ul.email-list {
  margin-top: 10px;

  li {
    color: #000;

    &:before {
      content: '• ';
      font-size: 30px;
      color: $green;
      position: relative;
      left: 2px;
      top: 7px;
    }
  }
}

.all-scan-status {
  button {
    padding-left: 8px;
    padding-right: 8px;
  }

  .column.flex-center {
    padding: 4px;
  }
}

.user {
  &-search-modal {
    .modal-card {
      max-width: 400px;
      min-width: 400px;

      .input {
        border-radius: 0px;
        width: 93%;
      }
    }
  }

  &-list-container {
    margin: 0px 1px 0px 25px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #e5e5e5;
    overflow: scroll;
    max-height: 160px;
    background-color: #fafbfc;

    li {
      padding-top: 10px;
      border-bottom: 1px solid #e5e5e5;
      max-height: 60px;

      .show-button {
        text-align: right;
        margin-right: 5px;

        button {
          font-size: 20px;
          color: $default;
          border: none;
          margin-top: 10px;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }

      .columns {
        margin-left: 0px;
        margin-right: 0px;

        .column {
          padding: 5px;
        }
      }

      .user-name {
        margin-top: 15px;
      }

      .user-email {
        font-size: 10px;
      }

      .fa-user-circle {
        display: flex;
        align-items: center;
        color: $info;
      }
    }
  }
}

.teamcard {
  width: 100%;
  display: block;
  border: 1px solid lighten($color-table, 57%);
  background: lighten($color-table, 62%);

  &:hover {
    background: lighten($color-table, 60%);
    color: #000;
  }

  h6 {
    margin-bottom: 0;
  }

  &-name {
    color: $primary;
    font-size: 1.1em;
  }

  &-stats {
    display: flex;
  }

  &-count {
    color: lighten($color-table, 10%);

    &-label {
      font-size: 0.8em;
    }

    &-value {
      font-size: 0.95em;
    }
  }
}

.add-team-objects-scroll {
  overflow-y: scroll;
  max-height: 27.5em;
  max-height: 50vh;
  min-height: 4.6em;
}

.team-project {
  &-action {
    width: 3em;
  }

  &-icon {
    display: inline-block;
    width: 2.3em;

    &-android {
      color: #33a852;
    }

    &-apple {
      color: #8d9096;
    }

    &-windows {
      color: #46ade9;
    }
  }

  &-name {
    font-size: 1.15em;
  }
}

.platform-icon {
  &--android {
    color: var(--android);
  }

  &--apple {
    color: var(--ios);
  }
}

.vuln-modal {
  .modal-card {
    width: 50%;
    min-height: 300px;
  }
}

.modal-l {
  .modal-card {
    width: 50%;
  }
}

.network-state {
  visibility: visible;
  text-align: center;
  background-color: $red;
  margin: auto;
  margin-top: -30px;
  color: #fff;
  border-radius: 5px;
  position: fixed;
  top: 36px;
  left: 40%;
  z-index: 10000;
  font-size: 12px;
  padding: 10px;

  &.green {
    background-color: $green;
    left: 43%;
  }
}

.collaboration-settings {
  width: 70%;
}

.dropdown {
  position: relative;

  &-content {
    z-index: 10;

    .dropdown {
      &-pointer {
        height: 0;
        width: 0;
        border-bottom: 0.6em solid;
        border-top: none;
        border-left: 0.5em solid transparent;
        border-right: 0.5em solid transparent;
        right: 0.4em;
        position: absolute;
      }

      &-container {
        border: 1px solid;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 0.5em;
      }

      &-item {
        padding: 0.5em 1em;
        display: block;
        margin-left: 0;
        width: 100%;

        &-icon {
          display: inline-block;
          width: 2em;
          padding-right: 0.7em;

          .fa {
            font-size: 16px;
            line-height: 1.25;
          }
        }
      }
    }
  }
}

$dropdown-default-theme: $color-table;

.dropdown--default {
  &-content {
    background: transparent;

    .dropdown {
      &-pointer {
        border-bottom-color: lighten($dropdown-default-theme, 55%);
      }

      &-container {
        background: lighten($dropdown-default-theme, 60%);
        border-color: lighten($dropdown-default-theme, 55%);
      }

      &-item {
        color: darken($dropdown-default-theme, 5%);

        &:hover {
          background: lighten($dropdown-default-theme, 53%);
        }

        &-icon {
          color: lighten($dropdown-default-theme, 25%);
        }
      }
    }
  }
}

.date-input-class {
  width: 7em;
  margin-left: 8px;
  padding: 2px;
  text-align: center;
  background-color: #efefef;
  font-weight: bold;
  border: 0px;
  cursor: pointer;
  border-radius: 3px;
}

*:focus {
  outline: none;
}

.no-issues {
  padding-bottom: 1em;

  i {
    margin-top: 0;
  }
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.warning-msg {
  height: 30px;
}

.code-link {
  padding: 1px 1px 2px 5px;
  text-decoration: underline;
}

.w-inherit {
  width: inherit;
}

.power-select-custom-threshold ul {
  height: 5.5em;
}

.power-select-custom-repo ul {
  height: 8em;
}

.power-select-custom-width {
  width: 150px;
}

.font-size-unset {
  font-size: unset;
}

.integration {
  border: 1px solid #efefef;
  border-radius: 0.3em;
  display: flex;
  align-items: stretch;

  &-logo {
    padding: 0.5em;

    &-container {
      width: 5em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-account {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-container {
      border-left: 1px solid #efefef;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.bottom-change {
  border-top-right-radius: 10px;
  box-shadow: 3px 0 3px -2px #888;
  width: 90%;
}

.archive-row {
  &-expired {
    &,
    &:hover {
      background: lighten($color-lightgray, 2%);
      color: lighten($color-table, 43%);
    }
  }
}

.license-detail {
  .fa {
    font-size: 1.2em;
    line-height: 1.2;
  }
}

.full-height {
  height: 100vh;
}

.automation-script-upload label.file-upload {
  display: block;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

.primary-color {
  color: #ef4836;
}
